import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import React from 'react';
import swal from 'sweetalert2';
import axios from '../../axios';
import AxiosConfig from '../../axios/axios';
import Features_Form from '../../components/Forms/Features_form';
import '../../components/Forms/Forms.css';
import MediaForm from '../../components/Forms/Media_form';
import PropertyForm from '../../components/Forms/PropertyForm';
import ShowMessage from '../../components/Forms/ShowMessage';
import SpaceAvailabilityForm from '../../components/Forms/SpaceAvailabilityForm';
import SpaceForm from '../../components/Forms/SpaceForm';
import Space_info_form from '../../components/Forms/space_info_form';

const mql = window.matchMedia(`(min-width: 980px)`);
class MyProperties extends React.Component {
  constructor(props) {
    super(props);
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
  }
  state = {
    step: 0,
    propertyName: "",
    propertyName2: "",
    propertyFloor: '',
    spaceType: '',
    spaceTypes: [],
    spaceCategory: 2,
    spaceName: '',
    spaceCapacity: 0,
    spaceMaxCapacity: '',
    spacePrice: '',
    spaceStartDate: '',
    spaceEndDate: '',
    spacePictures: [],
    images: [],
    desc: "",
    city: '',
    currency: [],
    property_id: '',
    amenities_types: [],
    amenities: [],
    features: [],
    floor: '',
    space_id: 0,
    request_type: "post",
    media_images: [],
    amenities_stats: [],
    feature_ids: [],
    removed_features: [],
    new_features: [],
    propertyFloor2: 0,
    amenities2: [],
    first: '',
    second: '',
    third: '',
    available_hours: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
    space_id2: 0,
    week: ['0', '0', '0', '0', '0', '0', '0'],
    time_c: [],
    editedFeatures: [],
    det_features: {},
    mediaId: [],
    mediaOrder: [],
    mediaDict: {},
    codeNumber: 0,
    phoneNumber: '',
    showProperty2: false,
    lat: 0,
    lng: 0,
    lat1: 0,
    lng1: 0,
    prop_id: 0,
    dial_code: "+966",
    check: false,
    country: '',
    spaceSize: '',
    currnecy_code: [],
    cities: [],
    city_name: '',
    currency_code2: '',
    countries: [],
    markerPosition: null,
    currency_value: '',
    PhoneError: false,
    moveMap: false,
    disabledLastbutton: false,
    Rent_type: [false, false],
    daily_space_price: '',
    check_in_time: 'Wed Mar 25 2015 08:00:00 GMT+0300 (Arabian Standard Time)',
    check_out_time: 'Wed Mar 25 2015 17:00:00 GMT+0300 (Arabian Standard Time)',
    check_in_time2: '07:30',
    check_out_time2: '07:30',
    zoom: 8,
    bannarImageSelected: false,
    available_hours_calc: 24,
    AddspecialRoomFlag: false,
    specialRoomValue: '',
    spaceVideo: [],
    spaceVideo_old_value:[],
    spaceVideo_old_value_id:'',
    content_type: [],
    updateSpaceVideo:false,
    updateSpaceImage:false

  };

  UNSAFE_componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  mediaQueryChanged() {
    this.screen_sizes();
  }
  screen_sizes(i) {
    var connectors_root = document.getElementsByClassName('MuiStepConnector-root');
    var lines = document.getElementsByClassName('MuiStepConnector-line');
    var MuiStep_root = document.getElementsByClassName('MuiStep-root');
    if (mql.matches) {
      document.getElementById('add_properties').appendChild(document.getElementById('step'))
      document.getElementById('stepper').classList.remove('MuiStepper-vertical');
      // lines between each point when moving throught
      document.getElementById('stepper').classList.add('MuiStepper-vertical');
      for (let i = 0; i < connectors_root.length; i++) {
        connectors_root[i].classList.remove('MuiStepConnector-vertical');
        connectors_root[i].classList.add('MuiStepConnector-horizontal');
        lines[i].classList.remove('MuiStepConnector-lineVertical');
        lines[i].classList.add('MuiStepConnector-lineHorizontal');
      }
      for (let i = 0; i < MuiStep_root.length; i++) {
        MuiStep_root[i].classList.remove('MuiStep-vertical');
        MuiStep_root[i].classList.add('MuiStep-horizontal');
      }
    } else {
      // console.log('current classes: ', document.getElementsByClassName('StepContent').length)
      if (i !== undefined)
        document.getElementsByClassName('StepContent')[i].appendChild(document.getElementById('step'))
      else document.getElementsByClassName('StepContent')[this.state.step].appendChild(document.getElementById('step'))
      document.getElementById('StepContent').appendChild(document.getElementById('step'))
      document.getElementById('stepper').classList.add('MuiStepper-vertical');
      document.getElementById('stepper').classList.remove('MuiStepper-horizontal');
      for (let i = 0; i < connectors_root.length; i++) {
        connectors_root[i].classList.add('MuiStepConnector-vertical');
        connectors_root[i].classList.remove('MuiStepConnector-horizontal');
        lines[i].classList.add('MuiStepConnector-lineVertical');
        lines[i].classList.remove('MuiStepConnector-lineHorizontal');
      }
      for (let i = 0; i < MuiStep_root.length; i++) {
        MuiStep_root[i].classList.add('MuiStep-vertical');
        MuiStep_root[i].classList.remove('MuiStep-horizontal');
      }
    }
  }

  componentDidMount() {
    //use getCurrentPosition to get my location in map
    navigator.geolocation.getCurrentPosition((position) => {
      // console.log(position)
      this.setState({
        lat1: position.coords.latitude,
        lng1: position.coords.longitude
      })
    });
    this.screen_sizes();
    // to get contract start date and contract end date for a space 
    var today = new Date()
    var today2 = new Date()
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    today2.setDate(today.getDate() + 180)
    var date2 = today2.getFullYear() + '-' + (today2.getMonth() + 1) + '-' + today2.getDate();
    this.setState({
      spaceStartDate: date,
      spaceEndDate: date2
    })

    // to edit a space, this.props.location.state comes from propertyDetails component (file)
    if (this.props.location.state !== null && this.props.location.state !== undefined && this.props.location.state.step !== undefined) {
      this.setState({
        space_id2: this.props.location.state.space_id,
        prop_id: this.props.location.state.property_id
      })
      this.setState({ step: this.props.location.state.step, space_id: this.props.location.state.space_id, property_id: this.props.location.state.property_id })
      this.getEvent();
    }
    else this.setState({ request_type: "post" })
  }

  getEvent = async () => {
    //to get details of a space if i want to edit something
    await axios({
      method: 'GET',
      url: '/properties/' + this.props.location.state.property_id,
      header: {
        'Authorization': 'jwt ' + localStorage.getItem('token')
      }
    }).then(res => {
      this.setState({ propertyName: res.data.name, city: res.data.city_name, propertyFloor: res.data.floor })
    })
      .catch(e => { return '' });
    axios({
      method: 'GET',
      url: '/spaces/' + this.props.location.state.space_id,
      headers: {
        'Authorization': 'jwt ' + localStorage.getItem('token')
      }
    }).then(res => {
      this.ge_hours(res.data.avi_time)
      var media = res.data.media
      var images = []
      var content_type = []
      var images2 = []
      var mediaOrder = []
      var amenities_stats = []
      var mediaDict = {}
      var Features = [], feature_ids = [];
      for (let i = 0; i < res.data.available_amenities.length; i++)
        amenities_stats[i] = res.data.available_amenities[i].desc
      for (let i = 0; i < media.length; i++) {
        images[i] = media[i].media_url
        content_type[i] = media[i].media_type
        if (media[i].media_type === 'video') {
          this.setState({ spaceVideo: media[i].media_url,spaceVideo_old_value:media[i].media_url ,spaceVideo_old_value_id:media[i].id})
        }
        images2[i] = media[i].id
        mediaOrder = media[i].media_order
        mediaDict[i] = {
          'Id': media[i].id,
          'mediaOrder': media[i].media_order
        }
      }

      for (let i = 0; i < res.data.features.length; i++) {
        Features[i] = res.data.features[i].desc
        feature_ids[i] = res.data.features[i].id
      }

      this.setState({
        mediaId: images2,
        mediaOrder: mediaOrder,
        mediaDict: mediaDict
      })

      // to get the value of available days and then show it 
      let array = [], array2 = [], avi_days = res.data.avi_days, avi_time = res.data.avi_time
      for (let i = 0; i < avi_days.length; i++) {
        array.push(avi_days.charAt(i))
      }

      // to get the value of available hours and then show it
      for (let i = 0; i < avi_time.length; i++) {
        array2.push(avi_time.charAt(i))
      }

      // to check whether this space is created hourly or daily or both
      let Rent_type = this.state.Rent_type
      if (res.data.is_daily_avi === true)
        Rent_type[0] = true
      if (res.data.is_hourly_avi === true) {
        Rent_type[1] = true
      }

      // here to get the list of special rooms if the category is 3 then put the value of special_room_type just to show it when i want to update.
      if (res.data.category == 3) {
        let category = res.data.category
        let special_room_type = res.data.special_room_type
        let array2 = []
        axios({
          method: 'GET',
          url: '/special_room_type/'
        }).then(res => {
          let special_room_types = res.data.length
          for (let i = 0; i < special_room_types; i++) {
            array2.push(res.data[i])
          }

          this.setState({
            spaceTypes: array2,
            spaceCategory: category,
            spaceType: special_room_type,
          })
        }).catch()
      } else this.setState({ spaceCategory: res.data.category, spaceType: res.data.sub_category, spaceTypes: ['Open Area', 'Private Office'] })
      this.setState({
        spaceName: res.data.name, desc: res.data.desc, spaceCategory: res.data.category, currency_value: res.data.currnecy_code,
        spacePrice: res.data.price_hourly, spaceMaxCapacity: res.data.capacity, media_images: images, content_type: content_type, spacePictures: res.data.image_url, amenities_stats: amenities_stats,
        features: Features, request_type: "patch", feature_ids: feature_ids, spaceStartDate: res.data.contract_start_date, available_hours: array2, spaceEndDate: res.data.contract_end_date, week: array, det_features: res.data.features, spaceSize: res.data.space_size, Rent_type: Rent_type,
        daily_space_price: res.data.price_daily, bannarImageSelected: true
      })
      // when avaiable hours is false then all zeros of available hour field is like a value so i need to create a new array just to show all.
      if (res.data.is_hourly_avi === false) {
        this.setState({
          available_hours: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
        })
      }

      else {
        //here is to help represend how many hours available when updating
        for (let i = 0; i < this.state.available_hours.length; i++) {
          if (this.state.available_hours[i] === 'x') {
            this.setState({
              available_hours_calc: this.state.available_hours_calc - 1
            })
          }
        }
      }
      //convert time from 12 hours to 24 hours to show that correctly in calender
      let check_in_time = this.convert_time_12_to_24(res.data.check_in_time)
      // since the clock library repesented the value as object so i need to convert the value of checkin and checkout to an object then represent it
      let check_in_time_value = new Date()
      check_in_time_value.setHours(check_in_time.slice(0, 2))
      check_in_time_value.setMinutes(check_in_time.slice(3, 5))
      check_in_time_value.setMilliseconds("00")

      let check_out_time = this.convert_time_12_to_24(res.data.check_out_time)
      let check_out_time_value = new Date()
      check_out_time_value.setHours(check_out_time.slice(0, 2))
      check_out_time_value.setMinutes(check_out_time.slice(3, 5))
      check_out_time_value.setMilliseconds("00")
      this.setState({
        check_in_time: check_in_time_value,
        check_out_time: check_out_time_value
      })
    })
      .catch(e => {

      });
  }
  // convert time from 12 to 24
  convert_time_12_to_24 = (time) => {
    let check_in_time = time
    var PM = check_in_time.match('PM') ? true : false
    check_in_time = check_in_time.split(':')
    var min = check_in_time[1]
    min = min.replace('PM', '')
    min = min.replace('AM', '')
    if (PM) {
      var hour = 12 + parseInt(check_in_time[0], 10)
      if (hour == 24)
        hour = 12
      return hour + ':' + min
    } else {
      var hour = check_in_time[0]
      if (hour == '12')
        return '00' + ':' + min
      if (hour == '11' || hour == '10') {
        return hour + ':' + min
      }
      return 0 + hour + ':' + min

    }
  }
  //Move to the next step
  forwardStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
    this.screen_sizes(step + 1);

  }
  // to create or update the space
  last = () => {
    if (this.state.AddspecialRoomFlag == true && this.state.spaceCategory == 3) {
      axios({
        method: 'POST',
        url: '/special_room_type/',
        headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
        data: { 'title': this.state.specialRoomValue }
      }).then(res => {
        this.sendspace(res.data.id)
      }).catch()
    } else this.sendspace('no')
  }

  // send space details 
  sendspace = (data) => {
    var formData = new FormData();

    if (this.state.request_type === 'post') {
      if (this.state.spaceName !== null)
        formData.append("name", this.state.spaceName)

      if (this.state.spaceCategory !== null)
        formData.append("category", this.state.spaceCategory)

      if (this.state.spaceSize !== null)
        formData.append("space_size", this.state.spaceSize)

      if (this.state.spaceMaxCapacity !== null)
        formData.append("capacity", this.state.spaceMaxCapacity)

      if (typeof this.state.spacePictures !== 'string') {
        if (this.state.spacePictures) {
          formData.append("image_url", this.state.spacePictures)
        }
      }

      if (this.state.desc !== null)
        formData.append("desc", this.state.desc)

      if (this.state.spaceCategory == 3 && this.state.AddspecialRoomFlag == false) {
        if (this.state.spaceType !== '')
          formData.append("special_room_type", this.state.spaceType)
      }
      else {
        if (this.state.spaceType !== '' && this.state.spaceType !== -1)
          formData.append("sub_category", this.state.spaceType)
      }

      if (data !== 'no') {
        formData.append('special_room_type', data)
      }

      formData.append('contract_start_date', this.state.spaceStartDate)
      formData.append('contract_end_date', this.state.spaceEndDate)
    }






    if (this.state.currnecy_code2 !== null)
      formData.append('currnecy_code', this.state.currency_code2)

    var rent_type = this.state.Rent_type

    if (rent_type[0] === true && rent_type[1] === true) {
      formData.append("is_daily_avi", true)
      formData.append("is_hourly_avi", true)
    }

    if (rent_type[0] === true && rent_type[1] === false) {
      formData.append("is_daily_avi", true)
      formData.append("is_hourly_avi", false)
    }

    if (rent_type[1] === true && rent_type[0] === false) {
      formData.append("is_hourly_avi", true)
      formData.append("is_daily_avi", false)
    }

    if (rent_type[0] === true) {
      formData.append("price_daily", this.state.daily_space_price)
      if (this.state.check_in_time2 == '07:30' && this.state.request_type == 'patch') {
        let value = this.state.check_in_time.toString()
        let realvalue = value.slice(16, 21)
        let realtime = this.convert_time_24_to_12(realvalue)

        formData.append('check_in_time', realtime)
      } else if (this.state.request_type === 'post' && this.state.check_in_time2 == '07:30') {
        let value = this.state.check_in_time.toString()
        let realvalue = value.slice(16, 21)
        let realtime = this.convert_time_24_to_12(realvalue)
        formData.append('check_in_time', realtime)
      } else {
        formData.append('check_in_time', this.state.check_in_time2)

      }
      if (this.state.check_out_time2 == '07:30' && this.state.request_type == 'patch') {
        let value = this.state.check_out_time.toString()
        let realvalue = value.slice(16, 21)
        let realtime = this.convert_time_24_to_12(realvalue)
        formData.append('check_out_time', realtime)

      }
      else if (this.state.request_type === 'post' && this.state.check_out_time2 == '07:30') {
        let value = this.state.check_out_time.toString()
        let realvalue = value.slice(16, 21)
        let realtime = this.convert_time_24_to_12(realvalue)
        formData.append('check_out_time', realtime)
      } else {
        formData.append('check_out_time', this.state.check_out_time2)
      }
    }


    if (rent_type[1] === true) {
      formData.append("price_hourly", this.state.spacePrice)
      var avai_hours = this.state.available_hours.join('')
      formData.append('avi_time', avai_hours)
    }
    if (rent_type[1] === false && this.state.request_type === 'patch') {
      formData.append("price_hourly", 0.0)
      const avi_time = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']
      const final_avi_time = avi_time.join('')
      formData.append('avi_time', final_avi_time)
    } if (rent_type[0] === false && this.state.request_type === 'patch') {
      formData.append("price_daily", 0)
      formData.append('check_in_time', '')
      formData.append('check_out_time', '')
    }

    formData.append('property_id', parseInt(this.state.property_id))

    if (rent_type[0] === true) {
      var week = ['0', '0', '0', '0', '0', '0', '0']
      var avai_weekdays = week.join('')
      formData.append('avi_days', avai_weekdays)
    } else {
      var avai_weekdays = this.state.week.join('')
      formData.append('avi_days', avai_weekdays)
    }

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
    var end_point = "/spaces/"
    var number = ''
    if (this.state.request_type === 'patch')
      end_point = "/spaces/" + this.state.space_id

      setTimeout(()=>{ 
        axios({
          method: this.state.request_type, //should be put or not
          url: end_point,
          headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
          data: formData
        }).then(response => {
    
          number = response.data.id
          if (this.state.request_type === 'post')
            this.submit_media(response.data.id, this.state.spaceVideo)
    
          var features = this.state.new_features;
          if (features.length > 0) {
            var final_f = []
            var send_F = []
            for (let i = 0; i < features.length; i++) {
              if (features[i] !== null && features[i] !== '') {
                final_f.push(features[i])
    
              }
            }
            send_F = { "desc": final_f, "space_id": number }
            axios({
              url: "/features/",
              method: "post",
              headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
              data: send_F, AxiosConfig
            }).then(response => {
              // console.log('Features Response Data: ', response.data)
            }).catch(err => {
              // console.log('Features Error Resoponse', err.response)
            })
          }
    
          // Here if you have deleted features then you can send then throught an endpoint so check the length of removed_features if it' greater then send otherwise no
          if (this.state.removed_features.length > 0) {
            var feature = { 'features_ids': this.state.removed_features };
            // console.log('feature: ', feature)
            axios({
              url: "/features/",
              method: "delete",
              data: feature, AxiosConfig
            }).then(response => {
    
              // console.log("response: ", response.data)
            }).catch(err => {
    
            })
          } else {
            // console.log('No features are selected to be deleted')
          }
    
          this.setState({
            step: this.state.step + 1
          })
          // console.log(this.state.step)
    
        }).catch(err => {
          return swal.fire({
            icon: 'error',
            title: 'error',
            text: 'something went wrong with space details',
            showConfirmButton: false,
            timer: 1500
          })
        })


       }, 3000);

    
  };

  submit_media(space_id, spaceVideo) {
    

    if (spaceVideo.length !== 0) {
      var send_all = new FormData()
      send_all.append('media_url', spaceVideo)
      send_all.append('media_type', 'video')
      send_all.append('space_id', space_id)
      axios({
        url: '/media/',
        method: 'post',
        headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
        data: send_all, AxiosConfig
      }).then(response => {
        // console.log(response.data)
      }).catch(err => {
        return swal.fire({

          icon: 'error',
          title: 'error',
          text: 'something went wrong with media images',
          showConfirmButton: false,
          timer: 1500
        })
      })

    }
    var send_all = new FormData();
    var all_txt = true
    for (let i = 0; i < this.state.media_images.length; i++) {
      if (typeof this.state.media_images[i] !== 'string') {
        all_txt = false;
      }
    }
    if (this.state.media_images.length !== 0 && !all_txt) {
      var x = this.state.media_images
      for (let i = 0; i < x.length; i++) {
        if (typeof x[i] !== 'string') {
          send_all.append('media_url', x[i])
        }
      } send_all.append('space_id', space_id)
      var send_url = '/media/'
      axios({
        url: '/media/',
        method: 'post',
        headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
        data: send_all, AxiosConfig
      }).then(response => {
        // console.log(response.data)
      }).catch(err => {
        return swal.fire({

          icon: 'error',
          title: 'error',
          text: 'something went wrong with media images',
          showConfirmButton: false,
          timer: 1500
        })
      })
    } else return ''



    // this.props.history.push('/spaceDetails/'+response.data.id+'/'+this.state.property_id+'/0/')
  }

  submit_updated_media = (current_state) => {
    var send_all = new FormData();
    var all_txt = true
    for (let i = 0; i < current_state.length; i++) {
      if (typeof current_state[i] !== 'string') {
        all_txt = false;
      }
    }
    // console.log(current_state)
    if (current_state.length !== 0 && !all_txt) {
      // console.log(current_state)
      var x = current_state
      // console.log(x.length)
      for (let i = 0; i < x.length; i++) {
        if (typeof x[i] !== 'string') {
          send_all.append('media_url', x[i])
        }
      }
      send_all.append('space_id', this.state.space_id2)
    }
    axios({
      method: 'POST',
      url: '/media/',
      headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
      data: send_all, AxiosConfig
    }).then(response => {
      // console.log(response.data)
      this.jump_step()
      // this.props.move(true)
    }).catch(err => {
      // console.log(err.message)

    })
  }
  jump_step = () => {
    this.setState({
      step: 6
    })
  }
  properties_send_data = () => {
    // console.log('....,,,,,,...', this.state.request_type)
    // console.log('properyId', this.state.property_id)
    if (this.state.property_id === -1 || this.state.property_id === '') {
      var formData = new FormData();
      formData.append("name", this.state.propertyName)
      formData.append("poperty_num", 43)
      formData.append("floor", this.state.propertyFloor)
      formData.append("owner_id", localStorage.getItem('id'))
      formData.append("city_id", this.state.city)
      formData.append("lat", this.state.lat)
      formData.append("lon", this.state.lng)
      formData.append("prop_contact_num", this.state.phoneNumber)
      axios({
        url: "/properties/",
        method: "post",
        headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
        data: formData, AxiosConfig
      }).then(response => {
        // console.log("this is returned id", response.data.id);
        // console.log('property_id', this.state.property_id)
        if (this.state.property_id === -1 || this.state.property_id === '') {
          var amen = this.state.amenities
          var send_A = { "property_id": response.data.id, 'amenity_id': amen }
          // console.log('THIS IS send_A: ', send_A)
          axios({
            url: "/amenities/",
            method: "post",
            headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
            data: send_A,
          }).then(response => {
            // console.log('THE AMENTITES RESPONSE: ', response.data)
            this.setState({ property_id: send_A.property_id })
            this.last()
          }).catch(err => {
            return swal.fire({

              icon: 'error',
              title: 'error',
              text: 'something went wrong with adding amenity',
              showConfirmButton: false,
              timer: 1500
            })

          })

        }
      }).catch(err => {
        return swal.fire({

          icon: 'error',
          title: 'error',
          text: 'something went wrong with property details',
          showConfirmButton: false,
          timer: 1500
        })

      })
    }
    else {
      // console.log("no property added")
      this.last()
    }
  }

  //Move to the previous step
  backwardStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
    this.screen_sizes(step - 1);
  };


  remove_img_ = (x) => {
    var media = this.state.media_images
    media[x] = ''
    this.setState({ media_images: media })
  }

  remove_feature_ = (x, y) => {
    if (y) {
      // console.log("add properties x: ", x)
      // console.log("add properties y: ", y)
      var removed_features = this.state.removed_features;
      removed_features[removed_features.length] = x;
      this.setState({ removed_features: removed_features })
      // console.log('removed_features: ', this.state.removed_features)
    }
    else {
      // console.log('Y IS:  ', y)
      // console.log('before: THIS IS NEW FEATURE!!!!!! ', this.state.new_features)
      var new_features = this.state.new_features;
      new_features.splice(--x, 1);
      this.setState({ new_features: new_features })
      // console.log('after: THIS IS NEW FEATURE!!!!!! ', this.state.new_features)
    }
  }
  available_hours = (e) => {
    // console.log('available_hours e: ', e)
    if (this.state.spaceCategory === 2 || this.state.spaceCategory === 3 || this.state.spaceCategory === 4)
      var avai_hours = this.state.available_hours;

    var toggle = 'x'
    if (avai_hours[e] === 'x')
      toggle = '0'
    avai_hours[e] = toggle;
    this.setState({ available_hours: avai_hours })
    // console.log(avai_hours.join(''))
  }
  handle_amenities_change = (input, e, toggle) => {
    // console.log('no way handle_amenities_change: ', toggle)
    // console.log('input: ', input)
    var amenities = this.state.amenities;
    // console.log('amenitiesssssss', amenities)
    if (toggle)
      amenities.push(e);
    else {
      // console.log('unchecked amenities: ', amenities)
      // console.log('amenities.indexOf(' + e + '): ', amenities.indexOf(e))
      var index = amenities.indexOf(e);
      if (index > -1) {
        amenities.splice(index, 1);
      }
      // console.log('unchecked amenities done: ', amenities)
    }
    this.setState({ amenities })
    // console.log('all amenities selected: ', this.state.amenities)
  }
  handle_category_type = (type, input) => {

    if (input == 2 || input == 4) {
      this.setState({ AddspecialRoomFlag: false, specialRoomValue: '' })
    }
    if (type === 1)
      this.setState({ spaceCategory: input })
    this.setState({ spaceType: '' })
  }
  handle_week_Change = (index) => {
    // console.log('index: ', index)
    var week = this.state.week;
    if (week[index] === '0') {
      week[index] = 'x';
    } else week[index] = '0';
    this.setState({ week: week })
    // console.log('week: ', week)
  }
  handle_Rent_type = (value, daily_rent, hourly_rent) => {
    // console.log(value)
    // console.log(daily_rent)
    // console.log(hourly_rent)
    var Rent_type = this.state.Rent_type
    if (value === 0 && daily_rent === true) {
      Rent_type[0] = true
    } else if (value === 0 && daily_rent === false) {
      Rent_type[0] = false
    }
    if (value === 1 && hourly_rent === true) {
      Rent_type[1] = true
    } else if (value === 1 && hourly_rent === false) {
      Rent_type[1] = false
    }
    this.setState({
      Rent_type: Rent_type
    })

  }
  //Gets user input
  showProperty = e => {
    // console.log(e)
    axios({
      method: 'GET',
      url: '/properties/' + e,
      headers: {
        'Authorization': 'jwt ' + localStorage.getItem('token')
      }

    }).then(response => {
      var country_id = -1
      var currency = []
      // console.log(response.data)
      this.setState({
        propertyName2: response.data.name,
        propertyFloor2: response.data.floor,
        amenities2: response.data.amenities,
      })
      country_id = response.data.country_id
      this.setState({
        country: country_id
      })
      axios({
        method: 'GET',
        url: '/countries/'
      }).then(response => {
        this.setState({
          countries: response.data
        })
        for (var i = 0; i < response.data.length; i++) {
          if (country_id === response.data[i].id) {
            currency[i] = {
              'id': response.data[i].currency.id,
              'code': response.data[i].currency.code
            }
          }
        }
        this.setState({
          currency: currency
        })
      })


    }).catch(err => {
      // console.log(err.message)
    })
    // console.log(this.state.currency)
  }

  customRequest = ({ uid, file, send, action, headers, onProgress, onSuccess, onError }) => {
    return {
      abort() {
      }
    }
  }

  handleMediaImagesChange = (images) => {
    if (images && images.length > 0) {
      let imgsArr = images.map(img => img.file)
      imgsArr = [...this.state.media_images, ...imgsArr];

      let image = images.map(img => img.file)
      image = this.state.media_images;

      if (this.state.request_type === 'post') {
        this.setState({ media_images: imgsArr, }) // save current component
      } else {
        this.submit_updated_media(imgsArr)
      }
    }
  }

  // convert time from 24 to 12
  convert_time_24_to_12 = (time) => {
    var time = time
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    // console.log(time)
    return time[0] + time[1] + time[2] + time[5]
  }


  CheckspaceType = (type) => {
    if (type == 'office') {
      this.setState({ spaceTypes: ['Open Area', 'Private Office'] })
    } else
      this.setState({ spaceTypes: type })
  }

  // to check each value coming from children components
  onUserInputChange = (input, _2nd_param) => (e, w, i) => {
    // console.log('spaceType from the top...', this.state.spaceType)
    this.abledLastbuttonfunction()
    // console.log(input)
    // console.log(e)
    if (input !== "time_range" && input !== "spacePictures" && input !== "amenities" && input !== "feature1" && input !== "feature2" && input !== "feature3" &&
      input !== "desc" && input !== "media_images" && input !== 'spaceEndDate' && input !== 'spaceStartDate' && input !== 'spaceType' && input !== 'check_in_time' && input !== 'check_out_time') {
      this.setState({ [input]: e.target.value });
      // console.log("input :", e)
      if (input == 'property_id') {
        // console.log(e.target.value)
        if (e.target.value === -1) {
          this.setState({
            showProperty2: true
          })
        }
        if (e.target.value !== -1) {
          this.showProperty(e.target.value)
          this.setState({
            showProperty2: false
          })
        }
      }
      // console.log("input value :", e.target.value)
    }
    if (input === 'desc') {
      this.setState({
        desc: e.target.value
      })
    }
    if (input === "city") {
      // console.log(e.target.value)
      this.setState({
        city: e.target.value
      })
    }

    if (input === "propertyFloor") {

      this.setState({
        propertyFloor: e.target.value
      })
    }
    if (input === 'check_in_time') {
      if (e === null) {
        return ''
      }
      let value = e.toString()
      let realvalue = value.slice(16, 21)
      let realtime = this.convert_time_24_to_12(realvalue)
      // console.log(realtime)

      this.setState({
        check_in_time: e,
        check_in_time2: realtime
      })
    }
    if (input === 'check_out_time') {
      if (e === null) {
        return ''
      }

      // convert the value from object to string and then slice only the time
      let value = e.toString()
      let realvalue = value.slice(16, 21)
      let realtime = this.convert_time_24_to_12(realvalue)

      this.setState({
        check_out_time: e,
        check_out_time2: realtime
      })
    }

    if (input === 'spaceType') {
      if (e.target.value == -1) {
        this.setState({ AddspecialRoomFlag: true, spaceType: -1 })
      }
      else
        this.setState({
          spaceType: e.target.value,
          AddspecialRoomFlag: false,
          specialRoomValue: ''
        });
      // console.log('spaceType........', this.state.spaceType)
    }
    if (input === 'specialRoomValue') {
      this.setState({
        specialRoomValue: e.target.value
      })
    }


    if (input === 'spaceMaxCapacity') {
      this.setState({
        spaceMaxCapacity: e.target.value
      })
    }
    if (input === 'spaceSize') {
      this.setState({
        spaceSize: e.target.value
      })
    }

    if (input === 'daily_space_price') {
      this.setState({
        daily_space_price: e.target.value
      })
    }

    if (input === "media_images") {
      var x = this.state.media_images
      if (e.target.files.length > 1) {
        if (this.state.media_images.length > 0) {
          var y = this.state.media_images
          var counter = 0
          var g = y.length
          while (counter < e.target.files.length) {
            y[g] = e.target.files[counter]
            counter = counter + 1
            g = g + 1
          }
          this.setState({ media_images: y })

        } else {
          for (var i = 0; i < e.target.files.length; i++) {
            x[i] = e.target.files[i]
          }
          this.setState({ media_images: x });
        }
      } else {
        x[x.length] = e.target.files[0]
        this.setState({ media_images: x })
      }

      // console.log("this.state.media_images: ", this.state.media_images)
    }
    if (input === "x") {
      this.setState({ [input]: w });
    }
    if (input === "spacePictures") {
      if(this.state.request_type == 'patch')
      this.setState({updateSpaceImage:true})
      this.setState({ spacePictures: e.target.files[0], bannarImageSelected: true });
    }
    if (input === 'spaceVideo') {
    
      if (this.state.request_type == 'patch')
        this.setState({ updateSpaceVideo: true })
      this.setState({ spaceVideo: e.target.files[0] })
    }
    if (input === "amenities") {
      var amenities = this.state.amenities
      if (e.target.value === "1")
        amenities[0].value = e.target.checked
      if (e.target.value === "2")
        amenities[1].value = e.target.checked
      if (e.target.value === "4")
        amenities[2].value = e.target.checked
      if (e.target.value === "5")
        amenities[3].value = e.target.checked
      this.setState({ amenities: amenities })
    }
    var array = []
    var array2 = []
    if (input.includes("new_feature")) {
      var id = input.replace('new_feature', '');
      var new_features = this.state.new_features;
      new_features[id] = e.target.value;
      this.setState({ new_features: new_features })
      if (this.state.request_type === 'patch') {
        array = this.state.editedFeatures
        array2 = e.target.value
        array += array2
        this.setState({
          editedFeatures: array
        })
      }
    }

    if (input === 'country_id') {
      var cities = []
      var currency = []
      var countries = _2nd_param
      this.setState({
        country: e.target.value,
        countries: countries
      })
      if (e.target.value === countries[e.target.value - 1].id) {
        this.setState({
          dial_code: '+' + countries[e.target.value - 1].dial_code,

        })
      }

      for (var i = 0; i < countries.length; i++) {
        if (countries[i].id === e.target.value) {
          currency[i] = {
            'id': countries[i].currency.id,
            'code': countries[i].currency.code
          }
        }
      }
      this.setState({
        currency: currency
      })
      for (var i = 0; i < countries.length; i++) {
        if (countries[i].id === e.target.value) {
          for (var j = 0; j < countries[i].cities.length; j++) {
            cities[j] = {
              'id': countries[i].cities[j].id,
              'name': countries[i].cities[j].name
            }
          }
        }
      }
      // console.log('cities', cities)
      this.setState({
        cities: cities
      })
    }

    if (input === 'city_id') {
      var selectedCites = []
      var city_name = ''
      var selectedCites = _2nd_param

      for (var i = 0; i < selectedCites.length; i++) {
        if (selectedCites[i].id === e.target.value) {
          city_name = selectedCites[i].name
        }

      } if (this.state.city === e.target.value)
        this.setState({
          moveMap: true
        })
      else this.setState({ moveMap: false })
      this.setState({
        city: e.target.value,

      })
      this.extractlnglat(city_name)
    }
    if (input == "currency_id") {
      // console.log(_2nd_param)
      var currency = _2nd_param
      // console.log(_2nd_param)
      var currency_value = 0
      var currency_code = 0
      for (var i = 0; i < this.state.countries.length; i++) {
        if (this.state.country === this.state.countries[i].id) {
          // console.log('true true')
          currency_value = this.state.countries[i].currency.id
          currency_code = this.state.countries[i].currency.id
        }
      }

      // console.log(currency_value)
      // console.log(currency_code)
      this.setState({
        currency_value: currency_value,
        currnecy_code: currency_code,
        currency_code2: currency_code
      })
      // console.log(this.state.currency_code2)

    }
    if (input === "feature" + _2nd_param) {
      // console.log('feature1')
      var editedfeatures = this.state.editedFeatures
      // console.log("1 - this.state.features: ", this.state.features)
      var features = this.state.features

      _2nd_param = _2nd_param - 1
      if (this.state.request_type === 'patch') {
        editedfeatures[_2nd_param] = e.target.value
        this.setState({
          editedFeatures: editedfeatures
        })
        // console.log(this.state.det_features[0].desc)
      }

      // console.log('features[0]: ', features[0])
      // console.log("e.target.value: ", e.target.value)
      features[_2nd_param] = e.target.value
      // console.log("features!: ", features)
      this.setState({ features: features })
      // console.log("this.state.features: ", this.state.features)
    }
    if (input === 'codeNumber') {
      this.setState({
        codeNumber: e.target.value
      })

    }
    if (input === 'phoneNumber') {
      var reg = /^\d+$/;
      if (reg.test(e.target.value)) {
        this.setState({
          PhoneError: false
        })
      } else {
        this.setState({
          PhoneError: true
        })
      }
      this.setState({
        phoneNumber: e.target.value
      })
    }
  };

  // here is to extract lat and lng from the map
  extractlnglat = async (city_name) => {
    // console.log(city_name)
    await axios({
      method: 'GET',
      url: 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCJoRir4vSUFbV9TuA-c7mn24QWMV5Zq9w',
      params: {
        address: city_name,

      }
    }).then(response => {

      if (this.state.lat1 === 0 || this.state.lng1 === 0) {
        this.setState({
          lat: response.data.results[0].geometry.location.lat,
          lng: response.data.results[0].geometry.location.lng
        })

      } else {
        this.setState({
          lat: this.state.lat1,
          lng: this.state.lng1,
          zoom: 16
        })
      }
    }).catch(error => {
    })
  }

  getLatLng = (lat, lng, markerPosition) => {
    this.setState({
      lat: lat,
      lng: lng,
      markerPosition: markerPosition,
      check: true,
      moveMap: false
    })
  }


  ge_hours = (hours) => {
    var time = []
    for (var i = 0; i < hours.length; i++) {
      if (hours[i] === '0') {
        time[i] = { color: '#08D888', borderBottom: 'solid #08D888' }
      } else {
        time[i] = { borderBottom: 'solid lightgray', color: 'lightgray', marginBottom: 5, opacity: '70%' }
      }
      // console.log(time[i])
    }
    this.setState({
      time_c: time,

    })
  }

  uploadImages = event => {
    const imgFiles = Array.from(event.target.files[0]);
    this.setState({ spacePictures: imgFiles })
    // console.log(this.state));
  };
  time_c = (input, index) => {
    var time = this.state.time_c
    if (input === 'Clicked') {
      time[index] = { color: '#08D888', borderBottom: 'solid #08D888' }
    } else {
      time[index] = { borderBottom: 'solid lightgray', color: 'lightgray', marginBottom: 5, opacity: '70%' }
    }
    this.setState({
      time_c: time
    })
  }
  cal_available_hours = flag => {
    if (flag === 0) {
      this.setState({
        available_hours_calc: this.state.available_hours_calc - 1
      })
    } else {
      this.setState({
        available_hours_calc: this.state.available_hours_calc + 1
      })
    }
  }
  move = e => {
    if (e === true) {
      this.props.history.push('/spaceDetails/' + this.state.space_id2 + '/' + this.state.prop_id + '/')
    }
  }
  
  disabledLastbuttonfunction = () => {
    this.setState({
      disabledLastbutton: true
    })
  }
  abledLastbuttonfunction = () => {
    this.setState({
      disabledLastbutton: false
    })
  }
  move=()=>{
    this.props.history.push('/properties/')
  }
  
  render() {
    const propertyName2 = this.state.propertyName2
    const propertyFloor2 = this.state.propertyFloor2
    const amenities2 = this.state.amenities2
    const { step } = this.state;
    const {
      propertyName,
      propertyFloor,
      spaceType,
      spaceCategory,
      spaceName,
      spaceCapacity,
      spaceMaxCapacity,
      spacePrice,
      spaceStartDate,
      spaceEndDate,
      first,
      second,
      third,
      request_type,
      space_id2,
      prop_id,
      available_hours,
      week,
      time_c,
      editedFeatures,
      det_features,
      removed_features,
      showProperty2,
      city,
      dial_code,
      check,
      country,
      phoneNumber,
      spaceSize,
      mediaDict,
      cities,
      lat,
      lng,
      markerPosition,
      currency,
      currnecy_code,
      currency_value,
      PhoneError,
      moveMap,
      disabledLastbutton,
      Rent_type,
      daily_space_price,
      check_in_time,
      check_out_time,
      check_in_time2,
      check_out_time2,
      zoom,
      bannarImageSelected,
      available_hours_calc,
      AddspecialRoomFlag,
      specialRoomValue,
      spaceTypes,
      spaceVideo,
      content_type,
      updateSpaceVideo,
      updateSpaceImage,
      spaceVideo_old_value,
      spaceVideo_old_value_id

    } = this.state;
    const values = [
      propertyName,
      propertyFloor,
      spaceType,
      spaceCategory,
      spaceName,
      spaceCapacity,
      spaceMaxCapacity,
      spacePrice,
      spaceStartDate,
      spaceEndDate,
      first,
      second,
      third,
      request_type,
      available_hours,
      week,
      time_c,
      editedFeatures,
      det_features,
      removed_features,
      showProperty2,
      space_id2,
      prop_id,
      city,
      dial_code,
      check,
      country,
      phoneNumber,
      spaceSize,
      mediaDict,
      cities,
      lat,
      lng,
      markerPosition,
      currency,
      currnecy_code,
      currency_value,
      PhoneError,
      moveMap,
      disabledLastbutton,
      Rent_type,
      daily_space_price,
      check_in_time,
      check_out_time,
      check_in_time2,
      check_out_time2,
      zoom,
      bannarImageSelected,
      available_hours_calc,
      AddspecialRoomFlag,
      specialRoomValue,
      spaceTypes,
      spaceVideo,
      content_type,
      updateSpaceVideo,
      updateSpaceImage,
      spaceVideo_old_value,
      spaceVideo_old_value_id

    ];
    const getStepContent = () => {
      switch (step) {

        case 0:
          return (
            <PropertyForm
              nextStep={this.forwardStep}
              prevStep={this.backwardStep}
              handleChange={this.onUserInputChange}
              propertyInfo={this.state}
              amenities_change={this.handle_amenities_change}
              propertyName2={propertyName2}
              propertyFloor2={propertyFloor2}
              getLatLng={this.getLatLng}
              amenities2={amenities2}

            />
          );
        case 1:
          return (
            <SpaceForm
              nextStep={this.forwardStep}
              handle_category_type={this.handle_category_type}
              prevStep={this.backwardStep}
              handleChange={this.onUserInputChange}
              handleImageUpload={this.uploadImages}
              propertyInfo={this.state}
              CheckspaceType={this.CheckspaceType}

            />
          );
        case 2:
          return (
            <Space_info_form
              nextStep={this.forwardStep}
              prevStep={this.backwardStep}
              handleChange={this.onUserInputChange}
              move={this.move}
              jump_step={this.jump_step}
              propertyInfo={this.state}


            />
          );

        case 3:
          return (
            <Features_Form
              nextStep={this.forwardStep}
              prevStep={this.backwardStep}
              propertyInfo={this.state}
              handleChange={this.onUserInputChange}
              move={this.move}
              jump_step={this.jump_step}
              removed_features={this.remove_feature_}
            />);
        case 4:
          return (
            <MediaForm
              nextStep={this.forwardStep}
              prevStep={this.backwardStep}
              handleChange={this.onUserInputChange}
              jump_step={this.jump_step}
              remove_img={this.remove_img_}
              handleMediaImagesChange={this.handleMediaImagesChange}
              move={this.move}
              propertyInfo={this.state}
              abledLastbuttonfunction={this.abledLastbuttonfunction}
              customRequest={this.customRequest}
            />
          );
        case 5:
          return (
            <SpaceAvailabilityForm
              // nextStep={this.forwardStep}
              nextStep={this.properties_send_data}
              prevStep={this.backwardStep}
              handleChange={this.onUserInputChange}
              propertyInfo={this.state}
              avai_hours={this.available_hours}
              cal_available_hours={this.cal_available_hours}
              handle_week_Change={this.handle_week_Change}
              disabledLastbuttonfunction={this.disabledLastbuttonfunction}
              abledLastbuttonfunction={this.abledLastbuttonfunction}
              move={this.move}
              time_c={this.time_c}
              handle_Rent_type={this.handle_Rent_type}
            />
          );
        case 6:
          return (
            <ShowMessage
              propertyInfo={this.state}
              move={this.move}
            />
          );
        default:
          return null;
      }
    }
    function getSteps() {
      return ['Property information', 'Space general information', 'Name and description', 'Features', 'Media', 'Date and price', 'Done'];
    }
    const steps = getSteps();
    return (
      <div id='add_properties' style={{ marginTop: '110px', backgroundColor: '' }}>
        <Stepper
          id='stepper'
          orientation="horizontal"
          className='stepper'
          style={{ backgroundColor: 'transparent' }}
          activeStep={this.state.step}
          alternativeLabel={false}

        >
          {steps.map((label) => (

            <Step key={label} style={{ backgroundColor: '', marginLeft: '60px', color: '#08D888' }}>
              <StepLabel style={{ backgroundColor: '', color: '' }}>{label}</StepLabel>
              <StepContent className='StepContent' id='StepContent' style={{ backgroundColor: '', color: '' }} > </StepContent>
            </Step>
          ))}
        </Stepper>

        <div id='step'>{getStepContent(this.state.step)}</div>
      </div>
    )
  }
}

export default MyProperties;
