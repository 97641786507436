import Button1 from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Button } from 'react-bootstrap';
import swal from 'sweetalert2';
// import './index.scss';
import axios from '../../axios';
import './CreateNewHold';
import CreateNewHold from './CreateNewHold';
import './HoldSpace.css';
const mql = window.matchMedia(`(min-width: 980px)`)
export default class HoldSpace extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            space_id: 0,
            spaceName: '',
            mainDiv: {},
            open: false,
            display:'none',
            RentType: '',
            available_hours: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
            time_c: [],
            hold_details:[],
            hold_type:'',
            hold_id:'', 
            one_hold_details:{},
            request_type:'post',
            update:false,
            index:0,
            content:{},
           
            
        }
        this.screen_sizes = this.screen_sizes.bind(this)
    }
    componentDidMount() {
        this.setState({
            space_id: this.props.match.params.space_id,
            spaceName: this.props.location.state.spaceName
        })
       
        this.screen_sizes()
        this.getevent()

    }
    componentWillMount() {
        mql.addListener(this.screen_sizes)
    }
    componentWillUnmount() {
        mql.removeListener(this.screen_sizes)
    }
   
    screen_sizes() {
        if (mql.matches) {
            this.setState({
                mainDiv: {
                    display: 'grid',
                    gridTemplateColumns: '21.5rem',
                    marginTop: 120,
                },
                content:{
                    position:'relative',
                    width:'100%',
                    height:'100vh',
                    marginLeft:'380px',
                    backgroundColor:'white'
                },
            })
        } else {
            this.setState({
               mainDiv:{
                marginTop: 115
               },
               content:{
                   marginLeft:0
               }
            })
        }

    }
    getevent=()=>{
       
        axios({
            method:'GET',
            url:'/spaces/'+this.props.match.params.space_id+'/holding/',
            headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') }
        }).then(response=>{
            this.setState({
                hold_details:response.data
            })
        })


        
    }
    handleClose = () => {
        this.setState({
            open: false
        })
    }
   
    
    handleDelete = () => {
        swal.fire({
            title: 'Are you sure to delete this hold?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
              
            if (result.value == true) {
                axios({
                    method:'DELETE',
                    url:'/holding/'+this.state.one_hold_details.id+'/',
                    headers:{'Authorization':'jwt '+localStorage.getItem('token')}
                }).then(response=>{
                    this.getevent()
                    this.setState({
                        display:'none'
                    })
                    swal.fire(
                        'Deleted!',
                        'Your hold has been deleted.',
                        'success'
                      )
                })
            }
          })
        
    }
    get_hold_id=(hold_id,hold_type,index)=>{
        axios({
            method:'GET',
            url:'/holding/'+hold_id+'/',
            headers:{
                'Authorization':'jwt '+localStorage.getItem('token')
            }
        }).then(response=>{
          
            this.setState({
                one_hold_details:response.data,
                hold_id:hold_id,
                hold_type:hold_type,
                display:'block',
                index:index,
            })
            if(hold_type === 'Hourly Hold'){
                let available_hours = response.data.hold_time.split('')
                this.apply_hours(available_hours)
                this.setState({
                    available_hours:available_hours
                })
            } 
        })
    }
    apply_hours=(available_hours)=>{
        let time_c = this.state.time_c
        for(let i = 0 ; i < available_hours.length ; i++){
            if(available_hours[i] === '0'){
                time_c[i] = { color: '#08D888', borderBottom: 'solid #08D888' }
            }else
            time_c[i] = { borderBottom: 'solid #E02020', color: '#E02020', marginBottom: 5 }
            this.setState({
                time_c:time_c
            })
        }
    }

    //type,value,id
    updateComponent=()=>{
        this.setState({

            display:'none'
        })
        // let array = this.state.hold_details
        // let one_hold_details={}
        // if(type == 'Daily hold')
        // {
        //     array[this.state.index] = value
        //     one_hold_details = {
        //         hold_start_date: array[this.state.index].hold_start_date,
        //         hold_end_date: array[this.state.index].hold_end_date
        //     }
        // }else{
           
        //     array[this.state.index] = value
        //     one_hold_details = {
        //         hold_start_date: array[this.state.index].hold_start_date,
        //         hold_time:array[this.state.index].available_hours
        //     }
        //     let available_hours = array[this.state.index].available_hours.split('')
        //         this.apply_hours(available_hours)
        //         this.setState({
        //             available_hours:available_hours
        //         })
        // }
        // this.setState({
        //     one_hold_details:one_hold_details
        // })
    }
   
    render() {

        const Content = ()=>{
            if(this.state.hold_type === 'Daily Hold')
            return (
                <div style={{}}>
                    <header className="w3-container" style={{ fontSize: '18px' }} >
                       <strong>Daily Hold</strong>
                    </header>

                <div className="row">
                    <div className="col-12">
                        <strong style={{ fontSize: '17px', marginLeft: '17px' }}>Hold Start Date: {this.state.one_hold_details.hold_start_date}</strong>
                    </div>
                    <div className="col-12">
                        <strong style={{ fontSize: '17px', marginLeft: '17px' }}>Hold End Date: {this.state.one_hold_details.hold_end_date}</strong>
                    </div>

                </div>
            </div>
            )
            else 
            return (
                <div>
                    <header className="w3-container" style={{ fontSize: '18px' }} >
                        <strong>Hourly Rent</strong>
                    </header>
                <div>
                <div className="row">
                   <div className="col-12">
                       <strong style={{ fontSize: '17px', marginLeft: '17px' }}> {this.state.one_hold_details.hold_start_date}</strong>
                   </div>
               </div>
               <Grid container item direction="column" justify="center" style={{ marginTop: '60px', }} >
                   <Grid item className='time' lg="12" style={{ marginLeft: '25px' }}>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[0], fontSize: '15px' }}  >12 AM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[1], fontSize: '15px' }} >1 AM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[2], fontSize: '15px' }} >2 AM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[3], fontSize: '15px' }} >3 AM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[4], fontSize: '15px' }} >4 AM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[5], fontSize: '15px' }} >5 AM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[6], fontSize: '15px' }} >6 AM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[7], fontSize: '15px' }} >7 AM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[8], fontSize: '15px' }} >8 AM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[9], fontSize: '15px' }} >9 AM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[10], fontSize: '15px' }} >10 AM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[11], fontSize: '15px' }} >11 AM</Button1>
                   </Grid>
                   <br />
                   <Grid item className='time' lg="12" style={{ marginLeft: '25px' }}>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[12], fontSize: '15px' }} >12 PM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[13], fontSize: '15px' }} >1 PM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[14], fontSize: '15px' }} >2 PM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[15], fontSize: '15px' }} >3 PM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[16], fontSize: '15px' }} >4 PM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[17], fontSize: '15px' }} >5 PM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[18], fontSize: '15px' }} >6 PM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[19], fontSize: '15px' }} >7 PM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[20], fontSize: '15px' }} >8 PM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[21], fontSize: '15px' }} >9 PM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[22], fontSize: '15px' }} >10 PM</Button1>
                       <Button1 disabled={true} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[23], fontSize: '15px' }} >11 PM</Button1>
                   </Grid>

                   <Grid container item direction="column" style={{ marginTop: '25px', marginLeft: '25px' }}>
                       <Grid item style={{ color: 'black', fontSize: '15px' }} > <span style={{ backgroundColor: '#08D888', height: '10px', width: '10px', borderRadius: '50%', display: 'inline-block' }}></span>  Available hours </Grid>
                       <Grid item style={{ color: 'black', marginTop: '8px', fontSize: '15px' }}><span style={{ backgroundColor: '#E02020', height: '10px', width: '10px', borderRadius: '50%', display: 'inline-block' }}></span> Hold hours </Grid>

                   </Grid>
               </Grid>
           </div>
           </div>

            )
        }
       
        return (
            <div style={{ ...this.state.mainDiv, margin: 'auto' }}>
                <div style={{ gridColumn: 2 }}>
                    <Grid container >
                        <Grid container item direction="row"  >
                            <Grid item justify="flex-start" style={{position:'relative'}}>
                                <div className="sidebar"  id="sidebar" >
                                    <div className="row" style={{ borderBottom: '1px solid lightgray', paddingBottom: '5px' }}>
                                        <Button className="col-12" style={{ color: 'white', backgroundColor: 'rgb(57, 53, 78)', borderRadius: '50px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', fontSize: '16px', border: '1px solid white', marginTop: 'auto', width: '100%' }} onClick={() => this.setState({ open: true,request_type:'post' })} >+ New Hold</Button>
                                    </div>
                                    <div style={{}}>
                                        {this.state.hold_details.map((name,index)=>{
                                            let hold_type = 'Hourly Hold'
                                            if(this.state.hold_details[index].hold_type != 1)
                                            hold_type = 'Daily Hold'
                                                return <div>
                                                    <div style={{textAlign:'center',backgroundColor:'rgb(8 216 136)'}}>
                                                        <header style={{fontSize:'16px',fontFamily:'founders_bold',color:'white'}}>{hold_type}</header>
                                                    </div>
                                                    <div className="sidebarContent">
                                                        {hold_type == 'Hourly Hold' && 
                                                        <div style={{}} onClick={()=>this.get_hold_id(this.state.hold_details[index].id,'Hourly Hold',index)} >
                                                             <span style={{paddingLeft:'10px',fontSize:'16px',fontFamily:'founders_bold'}}>Hold Date: </span><span style={{paddingLeft:'50px'}}>{this.state.hold_details[index].hold_start_date}</span>
                                                        </div>
                                                        }
                                                        {hold_type == 'Daily Hold' && 
                                                        <div onClick={()=>this.get_hold_id(this.state.hold_details[index].id,'Daily Hold',index)}> 
                                                         <div style={{}} >
                                                            <span style={{paddingLeft:'10px',fontSize:'16px',fontFamily:'founders_bold'}}>Hold Start Date: </span><span style={{paddingLeft:'50px'}}>{this.state.hold_details[index].hold_start_date}</span>
                                                        </div>
                                                        <div>
                                                        <span style={{paddingLeft:'10px',fontSize:'16px',fontFamily:'founders_bold'}}>Hold End Date: </span><span style={{paddingLeft:'60px'}}>{this.state.hold_details[index].hold_end_date}</span>
                                                        </div>
                                                        </div>
                                                            }
                                                    </div>
                                                </div>                   
                                        })}

                                    </div>             
                                </div>
                             </Grid>
                            <Grid item justify="flex-start" className="content" style={{ ...this.state.content,display:this.state.display }}   >
                                <div style={{ width: '100%' }}>
                                    <div style={{ marginTop: '50px' }}>
                                        <Content />
                                    </div>  
                                    <Grid container item lg={8} direction="row" style={{}}>
                                        <Grid item lg={6} style={{padding:10}}>
                                            <Button style={{ color: '#E02020', backgroundColor: 'white', borderRadius: '50px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', fontSize: '15px', border: '1px solid #E02020', marginTop: 'auto', width: '100%' }} onClick={this.handleDelete}>Delete</Button>
                                        </Grid>
                                        <Grid item lg={6} style={{padding:10}}>
                                        <Button style={{ color: 'rgb(57, 53, 78)', backgroundColor: 'white', borderRadius: '50px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', fontSize: '15px', border: '1px solid white', marginTop: 'auto', width: '100%' }} onClick={() => this.setState({ open: true,request_type:'patch' })} >Edit</Button>

                                            </Grid>
                                    </Grid>                             
                                </div>
                            </Grid>     
                    </Grid>
                    </Grid>
                
            </div>
            {this.state.open === true &&
                    <CreateNewHold space_id={this.props.match.params.space_id} open={this.state.open} handleClose={this.handleClose} request_type={this.state.request_type} available_hours={this.state.available_hours} hold_type={this.state.hold_type} one_hold_details={this.state.one_hold_details} updateComponent={this.updateComponent} hold_id={this.state.hold_id} getevent={this.getevent}   />}

            </div >
        )
    }
}
