import React, { Component } from 'react';
import ReservationComponent from '../../components/ReservationsComponent/ReservationsComponent';

const mql = window.matchMedia(`(min-width: 30em)`);
export default class AdminReservations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainDiv: {
        display: 'grid',
        gridTemplateColumns: '250px auto auto',
       
       
      },
    };
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
  }
  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }
  componentDidMount() {
    if (mql.matches) {
      this.setState({
        mainDiv: {
          display: 'grid',
          gridTemplateColumns: '21.5rem',
          marginTop:120
         
         
        },
      });
    } else {
      this.setState({ mainDiv: {marginTop:105  } });
    }
    localStorage.setItem('RegisterOrder','reservations')
  }
  mediaQueryChanged() {
    if (mql.matches) {
      this.setState({
        mainDiv: {
          display: 'grid',
          gridTemplateColumns: '21.5rem',
          
          
        },
      });
    } else {
      this.setState({ mainDiv: { marginTop:105 } });
    }
  }
  render() {
    return (
      <>
        {/* <TopNav /> */}
        <div style={{ ...this.state.mainDiv, margin:"auto" }}>
          <div style={{ gridColumn: '2' }}>
            <ReservationComponent />
          </div>
        </div>
      </>
    );
  }
}
