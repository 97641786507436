import axios from 'axios';
import { getJwt } from '../helpers/jwt';

const AxiosConfig = axios.create({
    URL: "http://yourspace-dev.me-south-1.elasticbeanstalk.com/",
    headers: {
        'Access-Control-Origin': '*',
        'Access-Control-Allow-Policy': '*',
        'Content-Type': 'multipart/form-data;',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': getJwt,

    },
    post: {
        headers: {
            // 'Content-Type': 'multipart/form-data; boundary=--------------------------644471810150872642939746',
            'Content-Type': 'multipart/form-data;',
            'Accept-Encoding': '*',
        },

    }

});

AxiosConfig.interceptors.request.use((request) => {
    console.log('From Interceptor:' + request);
    return request;
}, (error) => {
        return Promise.reject(error);
});

AxiosConfig.interceptors.response.use((response) => {
    console.log('From Interceptor' + response);
    return response;
}, (error) => {
        return Promise.reject(error);
}
);


export default AxiosConfig;