import React from 'react'
import YourSpace from '../Landing_page/images/yourspace2.png'
import DowloadIcom from '../Landing_page/images/Downloadicon.svg'
import Navbar from 'react-bootstrap/Navbar'

const mql = window.matchMedia(`(min-width:50em)`)
class hostTemrs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showSpanone: 'none',
            showSpanTwo: 'none',
            showSpanThree: 'none',
            showSpanFour: 'none',
            showSpanFive: 'none',
            showSpanSix: 'none',
            showSpanSeven: 'none',
            showSpanEight: 'none',
            showSpanNine: 'none',
            footerWidth:{}
        }
        this.componentMediaQuery = this.componentMediaQuery.bind(this)
    }
    componentDidMount() {
        localStorage.setItem('RegisterOrder', 'hostTerms')
        this.componentMediaQuery()

    }
    componentWillMount(){   
        mql.addListener(this.componentMediaQuery)

    }
    componentWillUnmount(){
        mql.removeListener(this.componentMediaQuery)
    }
    componentMediaQuery(){
        if(mql.matches){
            this.setState({
                footerWidth:{
                    width:'35%'
                }
            })
        }else{
            this.setState({
                footerWidth:{
                    width:'90%'
                }
            })
        }
    }
    handleClick(event, number) {
        if (number === 1) {
            this.setState({ showSpanone: 'inline' })
        } else if (number === 2) {
            this.setState({ showSpanTwo: 'inline' })
        } else if (number === 3) {
            this.setState({ showSpanThree: 'inline' })
        } else if (number === 4) {
            this.setState({
                showSpanFour: 'inline'
            })
        } else if (number === 5) {
            this.setState({ showSpanFive: 'inline' })
        } else if (number === 6) {
            this.setState({ showSpanSix: 'inline' })
        } else if (number === 7) {
            this.setState({ showSpanSeven: 'inline' })
        } else if (number === 8) {
            this.setState({ showSpanEight: 'inline' })
        } else if (number === 9) {
            this.setState({ showSpanNine: 'inline' })
        }

    }
    render() {
        return (
            <div>
                <Navbar style={{ backgroundColor: '#08D888' }} variant="dark">
                    <Navbar.Brand href=""><img src={YourSpace} style={{ width: '11rem',cursor:'pointer' }} onClick={() => {
                        this.props.history.push('/host/register/')
                    }} /></Navbar.Brand>

                </Navbar>


                {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '60px' }} >
                    <button style={{ backgroundColor: '#08D888', color: 'white', width: '200px' }}>Print/Save</button>
                </div> */}

                <div className="container" style={{ marginTop: '70px', backgroundColor: '', textAlign: 'right' }} >
                    <h2 style={{ fontFamily: 'founders_bold', }}>الشروط والآحكام الخاصة بالمالك</h2>
                    <p style={{ fontSize: '25px', marginTop: '40px', fontFamily: 'founders', }}>المحتوى</p>
                    <ul style={{ marginLeft: '40px', textAlign: 'right', listStyleType: 'none' }}>
                        <li>
                            <a href="#terms_and_conditions" style={{ fontFamily: 'font_bold' }} >المقدمة -</a>
                        </li>
                        <li>
                            <a href="#payment_method" style={{ fontFamily: 'font_bold' }} > تعريفات -</a>
                        </li>
                        <li>
                            <a href="#amendment" style={{ fontFamily: 'font_bold' }}>الشروط والآحكام -</a>
                        </li>
                        <li>
                            <a href="#scope" style={{ fontFamily: 'font_bold' }} >طريقة الدفع -</a>
                        </li>

                        <li>
                            <a href="#limitation" style={{ fontFamily: 'font_bold' }}> تعديل الشروط والآحكام -</a>
                        </li>
                        <li>
                            <a href="#cancelation" style={{ fontFamily: 'font_bold' }}>  نطاق وطبيعة خدماتنا -</a>
                        </li>
                        <li>
                            <a href="#spaceaddition" style={{ fontFamily: 'font_bold' }}> القوة القاهرة -</a>

                        </li>
                        <li>
                            <a href="#checkidentity" style={{ fontFamily: 'font_bold' }}> ملفات تعريف الارتباط (الكوكيز) -</a>

                        </li>
                        <li>
                            <a href="#duration" style={{ fontFamily: 'font_bold' }}> حدودية المسؤولية -</a>

                        </li>
                        <li>
                            <a href="#upload" style={{ fontFamily: 'font_bold' }}> سیاسة الإلغاء واسترداد الرسوم الحجز والإلغاء -</a>

                        </li>

                        <li>
                            <a href="#intellectual" style={{ fontFamily: 'font_bold' }}> yourspace تقديم اضافة مساحة على منصات  -</a>

                        </li>
                        <li>
                        <a href="#ownercontractduration" style={{ fontFamily: 'font_bold' }}> مدة العقد مع المالك وتحويل العمولة له  - </a>
                        
                        </li>
                        <li>
                        <a href="#imagedownload" style={{ fontFamily: 'font_bold' }}>  تحميل الصور ومواقع المباني  - </a>
                        
                        </li>
                       
                        <li>
                            <a href="#violations" style={{ fontFamily: 'font_bold' }}> حقوق الملكیة الفكریة  - </a>

                        </li>
                        <li>
                            <a href="#prohibited" style={{ fontFamily: 'font_bold' }}> معالجة المخالفات والتجاوزات  -</a>

                        </li>
                        <li>
                            <a href="#rejection" style={{ fontFamily: 'font_bold' }}> النشاطات المحظورة  -</a>

                        </li>
                       
                        <li>
                            <a href="#noresponse" style={{ fontFamily: 'font_bold' }}>  اإلخالء من المسئولية  -</a>

                        </li>
                        <li>
                            <a href="#compensation" style={{ fontFamily: 'font_bold' }}> التعویض  -</a>

                        </li>
                        <li>
                            <a href="#dispute" style={{ fontFamily: 'font_bold' }}> قانون حل النزاع  -</a>

                        </li>
                        <li>
                            <a href="#generalrules" style={{ fontFamily: 'font_bold' }}>أحكام عامة  -</a>
                        </li>


                    </ul>
                </div>
                <div class="container" style={{direction:'rtl',textAlign:'right'}}>
                    <p id="terms_and_conditions" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }}>المقدمة : </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    تتيح منصة yourspace عبر موقعها عرض صور وكتالوجات
عقاراتكم وفقا لشروط محددة، ومهمتنا أن نفتح لك آفاقاً جديدة لتستكشف
العالم من حولك من خلال اتاحة الفرصة لكم لتقديم أفضل المواقع
والمساحات المميزة والمريحة في عالم العقار وأروع المعالم، فقط تمتع
بزيارتها بالطريقة الأمثل عبر منصة yourspace.
حيث نهتم لأمرك باستخدام هذه المنصة لتقديم خدمة العملاء بأكثر من


                      
                        <span style={{ fontFamily: 'founders_bold', display: this.state.showSpanSix === 'none' ? 'inline' : 'none' }} onClick={(e) => this.handleClick(e, 6)}> قراءة المزيد ....</span>
                        <span style={{ fontFamily: 'light', fontSize: 18, display: this.state.showSpanSix }}>حیث نهتم لأمرك باستخدام هذه المنصة لتقدیم خدمة العملاء بأكثر من طریقة عبر منصة yourspace 
                        طريقة عبر منصة yourspace الالكترونية، ولذلك نفتح لك الباب
أمام التجارب التي تناسب احتياجاتك من تسهيل عملية البحث
والاختيار وكذلك نسهل لك عملية الدفع مقابل رسوم الدخول على موقعنا
من خلال المنصة المتاحة بالإنترنت للبحث أو التأجير لمساحات أو
مكاتب ذات مساحات مميزة وواسعة من خلال منتجاتنا التي نوفرها
كملاك للعقارات ذات المواقع الرائدة والاطلالات الساحرة.
</span>

                    </span>

                    <p id="payment_method" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }}>تعريفات :</p>
                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>


                    1 .منصة yourspace أو مزود الخدمة: هي منصة متخصصة بعرض العقارات
والمساحات الخاصة بالملاك وترویجها بطرق حدیثة ومبتكرة عبر تطبیقاتها الإلكترونیة
والمواقع الخاصة بها وفق شروط وإجراءات معینة.
<br />2 .الملاك أو المالك: هم الأشخاص أو الشركات أصحاب العقارات الذین یقومون بعرض
وتسویق المساحات الخاصة بهم عبر منصة yourspace،
<br />3 .المستخدمین: هم الأشخاص أو الشركات الذین یستخدمون منصة yourspace للقیام
بحجوزات المكاتب أو المساحات الخاصة بهم والانتفاع بها خلال مدة الحجز.
<br />4 .الزائرین: هم الأشخاص الذین یقومون بزیارة الموقع وتصفحه واستعراض البیانات الخاصة
به عبر شبكات الانترنت.
            </span>
                    <p id="amendment" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }}>الشروط والآحكام :  </p>
                    <span style={{ fontFamily: 'light', fontSize: '18px', }}>

                    عزيزي مالك العقارات تشكل هذه الشروط واألحكام اتفاقية ملزمة قانونيا yourspace
في الوصول للمنصة واستخدامها بما في ذلك أي نطاقات فرعية منها، وأي مواقع ويب أخرى تتيح او تقدم
yourspace خدماتها وتطبيقات األجهزة المحمولة واألجهزة اللوحية وغيرها من األجهزة الذكية
وواجهات برنامج التطبيق )مجتمعة، "التطبيق"( وجميع الخدمات المرتبطة بموقع yourspace .يشار
فيما بعد إلى الموقع والتطبيق وخدمات yourspace مجتمعة باسم Platform Hads ."يتم تضمين
شروط وأحكام هذه االتفاقية أدناه.
                   
                     </span>

                    <p id="scope" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }}>طريقة الدفع :  </p>
                    <span style={{ fontFamily: 'light', fontSize: '18px', }}>
                    خدماتنا متوفرة على مدار (24/7) لمساعدتك عبر المنصة الإلكترونية
التي توفرها yourspace، على مدار سبعة أيام في الأسبوع، فقط
يتعين على العميل او الباحث او طالب الخدمة ان يتقدم بطلب السماح
له بالدخول و البحث أو الحجز وفق رمز تحقق يصله عبر جواله أو
أي تطبيق أخر يستخدمه أو بريده الالكتروني، كإشارة على قبول الطلب
، لكن من المقرر عزيزي المالك أن موقع العقار الخاص بك تقريبي
وغير دقيق بالنسبة للمستأجر ولا يتم معرفة موقع العقار إلا بعد استكمال
     
                        <span style={{ fontFamily: 'founders_bold', display: this.state.showSpanSeven === 'none' ? 'inline' : 'none' }} onClick={(e) => this.handleClick(e, 7)}> قراءة المزيد...</span>
                        <span style={{ display: this.state.showSpanSeven ,fontFamily: 'light', fontSize: '18px'}}>
                        عملية التسجيل والحجز، لذلك عند تقديم المستخدم طلب حجز او إذن
بالسماح له بالدخول وفق رمز التحقق المرسل إليه، عليه ابتداءً أن يوافق
على الشروط والأحكام التي تحكم عملية دخوله وتمتعه بالمزايا
والخصائص المتعلقة بخدمة الوصول إلى الموقع أو المواقع التي يرغب
بإتاحتها له بعد تأكيد الحجز وسوف تصله رسالة إلى جواله او بريده
الالكتروني بكافة تفاصيل الحجز، كإشارة منه على قبول الطلب مع
تزويده بالموقع الدقيق للعقار المستأجر.              
                        </span>

                    </span>


                    <p id="limitation" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >تعديل الشروط والآحكام :</p>
                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    من المفهوم أن هذه الشروط والأحكام يمكن أن تعدل من وقت لآخر
وفق معطيات السوق والظروف وهي تنطبق بشكل مباشر أو غير
مباشر على جميع خدماتنا المتاحة على موقعنا على شبكة الإنترنت أو
من خلال أي تطبيقات على جهاز محمول ومن خلال البريد الإلكتروني،
بالتالي إن استخدامكم لموقعنا أو أي من تطبيقاتنا من خلال أي منصة
(والمشار إليها مجتمعة باسم yourspace لتسهيل عملية البحث
والتصفح أو استكمال حجز المساحة أو المكتب من قبل العملاء، فإنكم
بذلك تقرّون وتوافقون على أنكم قد قرأتم وفهمتم ووافقتم على الشروط
والأحكام وتعديلاتها المبيّنة هنا بهذه الوثيقة والالتزام بأي تحديث يطرأ
عليها (بما في ذلك بيان الخصوصية التي تغطي الصور ويتم عرضها
وتداول ومحتواها بالموقع عبر الإنترنت (بأمان ).
                       {/* <span style={{ fontFamily: 'founders_bold', display: this.state.showSpanEight === 'none' ? 'inline' : 'none' }} onClick={(e) => this.handleClick(e, 8)}>  قراءة المزيد ...</span>
                        <span style={{ display: this.state.showSpanEight,fontFamily: 'light', fontSize: '18px' }}>
                        والأحكام وتعدیلاتها التي تنص علیها هذه الوثیقة الالكترونیة (بما في ذلك بیان
الخصوصیة التي تغطي بحثكم والصور التي تختارونها، ومحتواها، والبنیة التحتیة لها، وخدمة الحجز
عبر الإنترنت (بأمان ) ، وتخضع للشروط والأحكام المذكورة أدناه yourspace

                        </span>
  */}
                    </span>
                    <p id="cancelation" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >نطاق وطبيعة خدماتنا : </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    إن تعاملك كمالك عقار او عارض مساحات او مكاتب على موقع
البحث لدى منصة yourspace للعقار يجعلك تدخل في علاقة
مباشرة (ملزمة قانونياً ) كمالك عقار وذلك من خلال تسهيل التواصل
عبر المنصة مع ملاك العقارات الآخرين أو من خلال قيامكم بالبحث
و عرض صور العقار والمساحات على المنصة التي تجري فيها
الحجوزات، لذا فإن yourspace تتصرف فقط بمثابة وسيط
بين ملاك العقار أو اصحاب المكاتب والمساحات وبين الباحث أو
المستخدم من أجل تنفيذ عملية عبر موقع منصة yourspace ،
                 
    <span style={{ fontFamily: 'founders_bold', display: this.state.showSpanThree === 'none' ? 'inline' : 'none' }} onClick={(e) => this.handleClick(e, 3)}>   قراءة المزيد ...</span>
                        <span style={{ fontFamily: 'light', fontSize: '18px', display: this.state.showSpanThree }}>
                        عليه يتم الإجراء من خلال المنصة بموجب ارسال تفاصيل العقار أو
المساحة وحجمها وموقعها من خلال الصور التي تم مشاهدتها ذات
الصلة بحجز ك إلى أصحاب العقار من أجل اتمام عملية التأجير أو
العرض ومن ثم ترسل yourspace الى طالب الخدمة أو المنتج
رسالة تحقق وتأكيد بالبريد الالكتروني أو بجواله أو أي وسيط ميديا
متاحة بالمنصة نيابة عن صاحب العقار او المكتب والمساحة. لذا نشدد
على الالتزام بأي تعديل أو تحديث يتم على هذه الشروط والأحكام
للتمتع بالبحث والقيام بالحجز قبل قيامكم بعملية الحجز.
</span>

                    </span>
  


                    <p id="spaceaddition" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders', textDecoration:'underline'}} >القوة القاهرة :</p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    تعني القوة القاهرة أي حدث یقع خارج سیطرة الطرفین، ویجعل تنفیذ الالتزام مستحیًلا أو غیر مكتمل
لأي طرف بسبب أي من الحوادث التالیة:
أ. على سبیل المثال لا الحصر (في حالة قیام العمیل أو المستخدم باستئجار عقار وفجأة انقطع التیار
الكهربائي بسبب لا یرجع إلى مالك العقار، عندئذ یلتزم مالك العقار إما بتعویض العمیل أو المستخدم بیوم
آخر أو بإعادة قیمة الإیجار ولا تتحمل yourspace  أي تعویض في  هذا الشأن.<br />
ب.  ظهور الأمراض والأوبئة التي تستلزم قیام الدولة بإجراءات معینة مثل جائحة كرونا أو غیرها من 
الأمراض<br />
ج. ظهور أخطاء أو فیروسات تؤدي إلى تلف بیانات المستخدم أو العمیل لدرجة أنهم غیر قادرین على
استخدام الموقع الخاص بك.<br />
د. موت العمیل او المستخدم أو صاحب العقار.<br />
ه. الكوارث الطبیعیة مثل الزلازل والبراكین والفیضانات وغیرها.<br />
وتعتبر التعاقدات في مرحلة القوة القاهرة قابلة للإلغاء بدون أي رسوم إضافیة یتحملها المتعاقدین. ویحق
لجمیع الأطراف المتعاقدة اعتبار العقد كان لم یكن.
                    </span>

                    <p id="checkidentity" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} > ملفات تعريف الارتباط (الكوكيز) :  </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>

                    تحتفظ yourspace بملفات وحزم بيانات التي تمر عبر الرسائل
التي على الخادم الخاص بها من وإلى المتصفح والباحث الذي يقوم
بزيارة موقع المنصة. ومن ثم يخزن الموقع ما يقوم به المتصفح سواء
كان رسالة أو معلومات يصل إليها المستخدم ويعرضها في ملف صغير
يسمى ملف تعريف الارتباط، وتحتوي هذه الملفات عادة على معلومات
حول المتصفح الذي يزور صفحة منصة yourspace، بالإضافة
إلى أي معلومات يقوم المالك أو المستخدم بتعبئتها تلقائيًا، مثل اسمك
والبيانات او الصور او مواقع العقارات ومساحاتها. ثم يتم إرجاعه

بواسطة المستعرض أو المستخدم في كل مرة يقوم فيها بالوصول إلى
نفس الخادم من خلال هذه البيانات التي يستخدمها الخادم، بالتالي سيمكّن
هذا الملف من تحديد هوية الباحث أو طالب الخدمة أو المستخدم أو
تتبع وصوله أو وصوله إلى الخادم.
                            {/* <span style={{ fontFamily: 'founders_bold', display: this.state.showSpanFour === 'none' ? 'inline' : 'none' }} onClick={(e) => this.handleClick(e, 4)}>  قراءة المزيد ...</span>
                        <span style={{ fontFamily: 'light', fontSize: '18px', display: this.state.showSpanFour }}>
                           
بواسطة المستعرض أو المستخدم في كل مرة یقوم فیها بالوصول إلى نفس الخادم من خلال هذه البیانات
التي یستخدمها الخادم، بالتالي سیمكّن هذا الملف space Your من تحدید هویة الباحث او طالب
الخدمة أو المستخدم أو تتبع وصوله إلى الخادم.
                            </span> */}

                    </span>
                    <p id="duration" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >   حدودية المسؤولية : </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    أ. تقع مسئولية توفر المواقع والبيانات والصور والبروشورات وإرسالها في فيديو لمنصة yourspace
على أصحاب العقارات أو المكاتب أو المساحات وعلى yourspace التحقق منها قبل إضافتها للمنصة

)التطبيق الذي يسهل الوصول إلى هذه الفيديوهات(. فقط تتوسط وتمنح yourspace أصحاب العقارات
الذين يقومون بالتسويق وعرض منتجاتهم من مكاتب او مساحات على منصة yourspace ،ويتم منحهم
إذن الدخول إلى أنظمتنا ومن خالل ذلك يكون أصحاب العقارات هم المسؤولين بشكل كامل عن تحديث
جميع األسعار / الرسوم، والسياسات والشروط وغيرها من المعلومات ذات الصلة التي يتم عرضها على
منصة yourspace .على الرغم من أننا سنستخدم مهاراتنا وعنايتنا في أداء إجراءات خدمة عرض
العقارات من خالل المنصة، لكننا ال يمكننا )yourspace)أن نضمن جميع المعلومات إن كانت دقيقة
أو كاملة أو صحيحة للمتصفح او الباحث او الذي يقوم بالتأجير او البحث، وال يمكن أن نكون مسؤولين
لسنا مسؤولين عن أي انقطاع )سواء كان ذلك

عن أي أخطاء )بما في ذلك أخطاء بيانية ومطبعية(، أيضاً
بسبب أي انهيار أو إصالح أو ترقية أو صيانة المنصة لدينا سواء بشكل )مؤقت و / أو جزئي( أو غير
                  

    
<span style={{ fontFamily: 'founders_bold', display: this.state.showSpanFive === 'none' ? 'inline' : 'none' }} onClick={(e) => this.handleClick(e, 5)}>قراءة المزيد ...</span>
                        <span style={{ fontFamily: 'light', fontSize: '18px', display: this.state.showSpanFive }}> 
                        ذلك(،سواء كانت تلك المعلومات غير دقيقة أو مضللة أو غير صحيحة أو عدم تسليم المعلومات. يبقى كل
مالك مسؤو ًال في جميع األوقات عن دقة واكتمال وصحة المعلومات )الوصفية( )بما في ذلك األسعار /
الرسوم والسياسات والشروط وتوفر المعلومات والبيانات( المعروضة على منصتنا. وال تشكل المنصة
وال ينبغي اعتبارها أنها بمثابة توصية أو تأييداً الخاصة ب yourspace لمدى جودة أو مستوى الخدمة

أو موافقة على منتجاته )الرئيسية أو اإلضافية أو خدماته( باس

أو قبولها أو تصنيفا تثناء ما هو مبين بصراحة ً
أو منصوص عليه خالف ذلك.
ب. لذا فإن yourspace ال تملك أو تنشئ أو توفر أو تتحكم أو تدير أو تقدم أي قوائم أو خدمات تسويقية،
وال تعتبر yourspace هي الجهة المنظمة أو بائع أو مؤجر المباني أو المساحات أو المكاتب، حيث يعتبر
المالك وحدهم مسؤولين عن قوائمهم وعروض الصور والبيانات والمعلومات. وعندما يقوم المستخدمون
بإجراء الحجز أو قبوله، فإنهم يبرمون عقدًا مباش ًرا مع بعضهم البعض. بالتالي yourspace ليست طرفًا
أو مشار ًكا آخر في أي عالقة تعاقدية بين المالك وطالبي الخدمة أو المنتج وال يجب أن ينظر على yourspace
 باعتبارها سمسار عقارات أو شركة تأمين أو كوكيل بأي صفة، باستثناء ما هو محدد في الشروط
واالحكام.
ج. من المتفق عليه أن التحقق من هوية المستخدم / المالك على اإلنترنت ال تتحمل منصة yourspace
مسؤولية عن تأكيد هوية أي زائر أو مستخدم للمنصة أو من يقوم بالحجز أو تقديم طلب الحجز. وألغراض
الشفافية ومنع الغش وكما تسمح به القوانين المعمول بها يجوز لنا الرجوع الي ملف )الكوكيز( تخزين
البيانات لدى سيرفر منصة yourspace للتأكد من حقيقة الهويات، ولكن ليس علينا أي التزام بـ )1 )
مطالبة المستخدمين بتقديم شكل من أشكال الهوية الرسمية أو غيرها من المعلومات أو إجراء عمليات فحص
إضافية مصممة للمساعدة في التحقق من هويات المستخدمين او الزوار لموقع yourspace أو غيرهم.


هـ. من المفهوم للمالك إن منصة yourspace غير مسؤولة عن أي فقد أو ضياع او فوات أو فقدان
البيانات أو فقد النوايا الحسنة أو انقطاع الخدمة عن الكمبيوتر او تلف أو فشل النظام أو تحمل تكلفة
المنتجات أو الخدمات البديلة أو عن أي أضرار مادية أو فيما يتعلق بـهذه الشروط من استخدام أو عدم
القدرة على استخدام منصة yourspace بسبب أي اتصاالت أو تفاعالت أو اجتماعات مع مالك أو
مستخدمين آخرين أو أشخاص آخرين تتواصل معهم أو تتفاعل معهم أو تقابلهم نتيجة الستخدامكم لـ موقع
منصة yourspace ، أو من النشر أو حجز قائمة ، بما في ذلك توفير أو استخدام خدمات اخرى، سواء
أكان ذلك على أساس الضمان أو العقد أو الضرر )بما في ذلك اإلهمال( أو مسؤولية المنتج أو أي نظرية
قانونية أخرى تم إبالغ yourspace عنها و الخاصة بها أم ال بإمكانية حدوث مثل هذا الضرر. حيث
يجب على المالك التعامل مع الشكاوى أو المطالبات المتعلقة بالعقارات او المساحات المكتبية )بما في ذلك
ما يتعلق بسعر العرض )الخاص / الترويجي( أو السياسة أو الطلبات المحددة التي يقدمها العمالء( من ثم
تخلي yourspace التزامها وال تتحمل المسؤولية فيما يتعلق بهذه الشكاوى والمطالبات و )المنتج(. سواء
أكان مزود الخدمة العقارات )المالك( قد قاموا بخصم رسوم من العمالء مقابل الحجز او البحث أو إذا كانت
yourspace من قامت بتسهيل عملية دفع رسوم أو سعر الخدمة عبر أي من حساباتها ومن ثم تحويلها
إلى حساب المالك، لذا يتعين على المالك الموافقة واإلقرار بأن منصة yourspace هي من يكون مسؤول
في جميع األوقات عن تحصيل، تحويل، حجز أو دفع الضرائب المطبقة والمستحقة على المبلغ اإلجمالي
لسعر )المكتب / المساحة( أو رسوم السلطات الضريبية.
د. عزيزي المالك يحق لمنصة yourspace حذف التعليقات الموجودة على صفحات العقارات إذا كانت
مخالفة للذوق او خادشه للحياء وال يحق ذلك للمالك بإرادتهم المنفردة دون الرجوع إلى إدارة منصة yourspace
.
ف. يحق للمالك حذف المساحات الخاصة بهم في أي وقت بتقديم طلب للقائمين على منصة yourspace
كما يحق لمنصة yourspace أن تقوم بحذف المكاتب و المساحات الخاصة بالمالك في أي وقت وبدون
إبداء األسباب.
                       </span>
                    </span>
                    <p id="upload" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >سیاسة الإلغاء واسترداد الرسوم الحجز والإلغاء :  </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    عزيزي المالك يحق لك وضع شروط او قيود او سياسات خاصة
بالإلغاء واسترداد الرسوم المدفوعة وفقا للأوضاع علماً بإن سياسة
الاسترداد الخاصة بمنصة yourspace كالأتي:
إذا كان الالغاء او الاسترجاع بسبب يعود الى العميل، عندئذ يحق للعميل
استرجاع كامل المبلغ بالحالات التالية:
1. إذا تم الإلغاء قبل (48) ساعة من تاريخ او وقت إجراء
الحجز او التاجير،
2. بينما يحق للمستخدم أو العميل استرداد نصف المبلغ فقط إذا
كان الإلغاء او الاسترداد قبل (24) ساعة،
3. لكن لا يمكن للمستخدم أن يسترد المبلغ المطلوب إذا طالب
باسترداده في نفس يوم الحجز.
في كلا حالات الإلغاء والاسترجاع يتعين تقديم الوثائق اللازمة لإثبات
السبب، ولا تكون الإلغاءات بدون غرامات متاحة إلا للظروف المخففة
فقط التي تحدث قبل تاريخ الوصول الرسمي للحجز.. مثل المرض
الفجائي، او وفاة أحد افراد العائلة من الدرجة الاولي او اي حادث خارج
عن السيطرة مع ضرورة تقديم الوثائق التي تؤيد الوقائع في غضون
(14) يوما من الواقعة.


- يلتزم المالك بإعادة المبلغ المدفوع من المستخدم وارجاعه
إليه في حالة كان الإلغاء من قبل مالك العقار او المساحة او
المكتب حيث يتم دفع مبلغ بمثابة غرامة مالية (يكون هذا
المبلغ متغير على حسب الحجز وتاريخه والموقع والمساحة)
أو تعويض المستخدم أو العميل بموقع او مساحة أو مكتب
آخر على حساب المالك.

- يحق للمالك فرض أسعار ورسوم معينة وعروض خاصة
غير قابلة للإلغاء أو الاسترجاع أو التغيير. كما يحق لملاك
العقارات ومقدمي خدمات العقارات بخصم ضرائب الدولة
المطبقة في حالة الإلغاء غير المجاني أو في حال عدم
الحضور قد تختلف سياسات الدفع المسبق والإلغاء حسب كل
عقار أو مساحة أو مكتب معين منتج أو خدمة لكل مبنى او
وحدة مكتبية.
                    </span>
                    <p id="intellectual" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders', textDecoration:'underline'}} > yourspace تقديم اضافة مساحة على منصات  :      </p>
                   
                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    سوف تطرح منصة yourspace سياسات وشروط على الملاك
لمنح وتقديم مدة او مدد محدده بحدود قصوى او ادني وذلك بصدد
التثبت من صحة بيانات المساحات او المكاتب التي يقدمها الملاك
وتطابقها مع الصور او الكتالوجات المعروضة من قبلهم.
عزيزي المالك أنت تقر وتوافق باطلاعك وقبولك على هذه الشروط
والأحكام على أن (المالك) هو المعني هو المسؤول الوحيد ويتحمل كل
المسؤولية والالتزام فيما يتعلق بالخدمة (بما في ذلك أي ضمانات
وتعهدات يقدمها الملاك). لذا إن التعامل مع الشكاوى أو المطالبات
المتعلقة بالعقارات او المساحات المكتبية (بما في ذلك ما يتعلق بسعر
العرض (الخاص / الترويجي) أو السياسة أو الطلبات المحددة التي
يقدمها العملاء) مع ملاك العقارات. وعليكم اخلاء منصة yourspace
 من أي التزام أو مسؤولية بما يتعلق بهذه الشكاوى
والمطالبات إلا في الحدود الموضحة في هذه الاتفاقية.
                  
                    
                    </span>
                    <p id="ownercontractduration" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >مدة العقد مع المالك وتحويل العمولة له : </p>

                        <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                        يبرم عقد نصف سنوي بين كل من yourspace الوسيط ومقدم
الخدمة (المالك) قابل للتجديد لمدة او مدد بإشعار خطي للتمديد أو عدمه
مدته (ثلاثون) يوم، كما تقع مسئولية تحويل العمولة المستحقة للمالك
على منصة yourspace وفق ترتيبات ادارية وفنية يتفق عليها
الطرفان تشمل توفير تفاصيل رقم حساب بنكي حتى يمكن التحويل عليه
والتعامل من خلاله بين الطرفين على نحو شهري مع ارفاق بيان
بالمعاملات. كما يحق لمنصة yourspace خصم العمولات
الخاصة والمتفق عليها من قبل الطرفين
                        </span>
                        <p id="imagedownload" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >تحميل الصور ومواقع المباني : </p>

                            <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                            يبرم عقد نصف سنوي بين كل من yourspace الوسيط ومقدم
الخدمة (المالك) قابل للتجديد لمدة او مدد بإشعار خطي للتمديد أو عدمه
مدته (ثلاثون) يوم، كما تقع مسئولية تحويل العمولة المستحقة للمالك
على منصة yourspace وفق ترتيبات ادارية وفنية يتفق عليها
الطرفان تشمل توفير تفاصيل رقم حساب بنكي حتى يمكن التحويل عليه
والتعامل من خلاله بين الطرفين على نحو شهري مع ارفاق بيان
بالمعاملات. كما يحق لمنصة yourspace خصم العمولات
الخاصة والمتفق عليها من قبل الطرفين

على تحميل الصور (أو الصورة) المسؤولية القانونية والأخلاقية الكاملة
الناتجة عن جميع المطالبات القانونية التي يتم إجراؤها بواسطة أي
طرف ثالث (بما في ذلك، وليس على سبيل الحصر، أصحاب العقارات
او المساحات اذا كنت مستثمرا مثلا لتأجير العقارات والمساحات) وذلك
كنتيجة لاستخدام ونشر هذه الصور من قبل. دون أدنى مسئولية على
yourspace ، حيث انها لا تمتلك أو تصادق على الصور التي يتم
تحميلها. وتعتبر مصداقية الصور والحق في استخدامها مسؤولية المالك
الشخص الذي أشرف على تحميل الصور.
                            </span>
                    <p id="violations" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >حقوق الملكیة الفكریة : </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    ما لم ينص على خلاف ذلك، فإن البرمجيات والتطبيقات اللازمة
لخدماتنا أو المتاحة على منصتنا أو المستخدمة من قبل موقعنا وحقوق
الملكية الفكرية (بما في ذلك حقوق التأليف والنشر وعرض الصور)
لمحتويات منصتنا والمعلومات التي تفيد عنها والمواد المتوفرة عليها،
هي مملوكة من قبل أو مرخص باستخدامها من مورديها أو مزودي
خدمات عرض الصور والبيانات والبروشورات .تحتفظ yourspace
 بشكل حصري بملكية جميع الحقوق وملكية العنوان
والانتفاع بجميع حقوق الملكية الفكرية والشكل والفكرة (بما فيه البنية
الأساسية) لـِ المنصة التي تتاح عليها خدمة عرض العقارات (بما في
ذلك تعليقات المستخدمين المسموح لهم بالدخول بعد دفع رسوم البحث
على المنصة و ترجمة المحتوى) . غير مسموح او مخولاً للملاك القيام
بنسخ أو كشط أو استخدام الرابط (المتاح له الوصول به والدخول على
    yourspace  لـِ أو نشر أو ترويج أو تسويق أو دمج أو استخدام أو
جمع أو أي استخدام آخر للمحتوى (بما في ذلك أية ترجمات أو تعليقات
متعلقة بها) أو علامتنا التجارية دون الحصول على إذن خطي من
yourspace. بالقدر الذي يمكنكم من إستخدام أو الجمع (بشكل كلي
أو جزئي) للمحتوى (المترجم) الخاص بنا (بما فيه تعليقات المستخدمين
الآخرين أو يمكنك بطريقة أخرى من امتلاك أية حقوق للملكية الفكرية
في المنصة أو أي محتوى (مترجم) أو التعليقات للمستخدمين، تقوم
بموجبه أنت (كمالك) بتحديد ونقل وتعيين مثل هذه الحقوق للملكية
الفكرية. أي استخدام غير قانوني أو أي من الإجراءات المذكورة أو
السلوكيات المذكورة آنفًا مما يشكل انتهاك مادّي لحقوق الملكية الفكرية
للغير (بما فيه حقوق النشر وحقوق قاعدة البيانات الخاصة ب yourspace).
                    </span>

                    <p id="prohibited" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >معالجة المخالفات والتجاوزات :   </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>

                    يحق لـ yourspace فرض رسوم مالية على الملاك عند ارتكابهم
أو صدور أي مخالفات تتعلق بسوء استخدام المنصة من خلال الدعاية
والترويج والعرض أو بث التعليقات المسيئة أو غير اللائقة مثل
التعليقات العنصرية، الهوية الجنسية، والميول الشخصية أو الدين أو
العرق أو نشر صور فاضحة خلافاً لما خصصت له المنصة، يعاقب
بفرض جزاء مالي يقدر بـ (200) ريال او مايعادلها بالدولار او اليورو، أو حجبهم
كلياً او جزئياً عن استخدام منصة yourspace
كما يحق لـ yourspace فرض رسوم مالية على الملاك في حال
لم يتم تحديث بيانات التوافر والمساحات بشكل دوري ومستمر بفرض
جزاء مالي يقدر ب (200) ريال او مايعادلها بالدولار او اليورو,
                  
                    </span>
                    <p id="rejection" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >النشاطات المحظورة : </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    عزيزي المالك إن yourspace هي وحدها المسؤولة عن الامتثال
لجميع القوانين والقواعد واللوائح والالتزامات الضريبية التي قد تنطبق
على استخدامك لمنصة yourspace. فيما يتعلق باستخدامك لـ
منصة yourspace، ومع ذلك إن yourspace تؤكد على ان
لا يقوم المالك ولا يساعد الآخرين أو يمكنهم من: خرق أو التحايل
على أي قوانين أو لوائح أو اتفاقيات سارية مع أطراف ثالثة أو حقوق
طرف ثالث أو شروط أو سياسات أو معاييرنا ؛استخدام yourspace
 أو المحتوى الجماعي لأي أغراض تجارية أو أغراض
أخرى غير مسموح بها صراحةً بموجب هذه الشروط أو بطريقة تنطوي
على زيف تأييد yourspace أو الشراكة أو تضليل الآخرين
بطريقة أخرى أو نسخ أو تخزين أو الوصول إلى أو استخدام أي
معلومات ، بما في ذلك معلومات التعريف الشخصية عن أي عضو آخر
، المضمنة في yourspace بأية طريقة لا تتفق مع سياسة
خصوصية yourspace أو هذه الشروط أو التي تنتهك حقوق
خصوصية الأعضاء أو الغير.
                  
                    </span>
                    <p id="noresponse" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders', textDecoration:'underline'}} > اإلخالء من المسئولية : </p>

                        <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                        عزيزي المالك إذا اخترت استخدام خدماتنا عبر منصة yourspace
 فإنك تفعل ذلك طواعية وعلى مسؤوليتك وحدك. يتم توفير
منصة yourspace كما هي، دون أي ضمان أو تعهد من أي
نوع، سواء كانت صريحة أو ضمنية. لذا يخلي الملاك منصة yourspace
 من المسؤولية القانونية لجميع الصور والبيانات
والبروشورات المتعلقة بالمباني والمكاتب أو المساحات التي تم تحميلها
أو نشرها على منصة yourspace. حيث تقع هذه المسئولية على
عاتق الملاك والشخص القائم على تحميل الصور لذا عليهم ضمان
خلوها من أي فيروسات أو فيروس حصان طروادة أو الملفات المصابة،
وعليه أيضاً ضمان خلوها من أي مواد إباحية غير قانونية أو فاحشة أو
مهينة، ومكروهة أو غير لائقة وأي مواد أخرى تنتهك حقوق أي طرف
ثالث (حقوق الملكية الفكرية، أو حقوق الطبع والنشر أو الخصوصية).
حيث أن yourspace لن تقوم بنشر أي صور غير مستوفية
للمعايير المذكورة أعلاه. وتملك حق إزالتها وحذفها في أي وقت وبدون
أي إشعار مسبق. حيث أنك كنت توافق على أنه قد أتيحت لك أي فرصة
تراها ضرورية للتحقيق في خدمات yourspace وفقا للقوانين أو

القواعد أو اللوائح التي قد تنطبق على قوائمك و / أو الخدمات التي
تتلقاها وأنك لا تعتمد على أي بيان أو قانون أو حقيقة أدلى بها
موقع yourspace لتسهيل الوصول إلى المكاتب والمساحات
والصور والكتالوجات التي عرضها الملاك.
                        </span>
                    <p id="compensation" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders', textDecoration:'underline'}} > التعویض : </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    عزيزي المالك يمكن أن يكون هنالك تعويض إلى الحد الأقصى
المسموح به بموجب القانون المعمول به سواء للمستخدم او ادارة موقع
yourspace حسب كل حادثة او واقعة، لذلك فأنت توافق على
إبراء ذمة yourspace وشركاتها الفرعية والشركات التابعة لها
والدفاع عنها (وفقًا لخيار yourspace)، بما في ذلك على سبيل
المثال لا الحصر، إبراء yourspace عن أي مدفوعات ناشئة
وإعفائها ومسؤوليها ومديريها وموظفيها ووكلائها. عن أي مطالبات أو
دعاوى ، دون تسبيب أي ضرر و من أو ضد أي مطالبات ، مطلوبات ،
أضرار ، خسائر ، و نفقات بشأن صحة او دقة ما تقومون بعرضه على
منصة yourspace من عقارات او مكاتب او مساحات ، بما في
ذلك ، على سبيل المثال لا الحصر ، رسوم قانونية ومحاسبية معقولة ،
ناشئة عن أو مرتبطة بأي طريقة بـ (1) بانتهاككم لهذه الشروط أو
سياساتنا أو معاييرنا (2) استخدامك غير الصحيح لمنصة yourspace
 أو أي من خدمات yourspace ، (3) الدفاع عن
 yourspace وتعويضها عن أي إصابات أو خسائر أو أضرار
(سواء كانت تعويضية أو مباشرة أو عرضية أو تبعية أو غير ذلك) من
أي نوع ينشأ فيما يتعلق أو نتيجة لهذا التفاعل أو المشاركة أو الاستخدام
، (4) أو خرقك لأي قوانين أو لوائح أو حقوق للغير من حقوق ملكية
وخلافه.
                    </span>
                    <p id="dispute" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders', textDecoration:'underline'}} >قانون حل النزاع : </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    أي نزاع ينشأ بين yourspace عبر المفاوضات الودية، وفي حال فشل
                    الحل الودي يلجأ إلى التحكيم السعودي اذا كان الطرفين يقيمون بالمملكة العربية السعودية وفي حال كان
                    أحد األطراف خارج المملكة واختار و باالتفاق مع yourspace اللجوء للتحكيم الذي تباشره إحدى
                    أو يتفق المالك

                    الهيئات الخارجية يجب أن يكون التحكيم عن طريق محكم واحد يتفق األطراف عليه الحقاً
                    بشكل متبادل على أن أي نزاع أو مطالبة أو خالف ينشأ عن أو يتعلق بهذه الشروط أو قابلية التطبيق أو
                    االنتهاك أو اإلنهاء أو الصالحية أو التطبيق أو التفسير أو استخدام منصة yourspace أو سيتم تسوية
                    خدمة الدفع )يشار إليها مجتمعة "المنازعات"( عن طريق التحكيم الفردي الملزم )"ا تفاقية التحكيم"(. إذا
                    كان هناك خالف حول ما إذا كان يمكن تطبيق اتفاقية التحكيم هذه أو تطبيقها على نزاعنا ، فإنك توافق أنت
                    و ادارة yourspace على أن قرار التحكيم الذي يصدر من المحكم بشأن هذه المشكلة يعتبر نهائي
                    وملزم لألطراف .
                    قانون التحكيم المنطبق على النزاع : يطبق قانون التحكيم السعودي الساري المفعول بالمملكة العربية
                    السعودية.
                   
                    </span>



                        <p id="generalrules" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders', textDecoration:'underline'}} >احكام عامة : </p>

                            <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                            1 .باستثناء أي شروط اخرى قد يتم استكمالها بشروط وأحكام أو سياسات أو إرشادات أو
                            معايير إضافية، فإن هذه الشروط تشكل االتفاقية الكاملة بين yourspace وبين المالك
                            فيما يتعلق بالموضوع الوارد هنا، وتحل محل أي وجميع التفاهمات أو االتفاقات الشفوية أو
                            المكتوبة السابقة بين المالك و منصة yourspace فيما يتعلق بوصولكم إلى أو استخدامكم
                            منصة yourspace لعرض صور او كتالوجات عقاراتكم yourspace .
                            2 .ال يترتب على توقيعك على هذه الشروط واألحكام وجود أي عالقة عمل مشترك أو شراكة
                            أو وظيفة أو وكالة بينك وبين yourspace كنتيجة لهذه االتفاقية أو استخدامك لمنصة
                            .yourspace
                            3 .ال تهدف هذه الشروط وال يقصد منها منح أي حقوق أو تعويضات )إن وجدت( ألي شخص
                            آخر غير األطراف الموقعة والقابلة لها والتي قد أطلعت عليها.
                            4 .يعتبر أي بند من هذه الشروط غير صالح أو غير قابل للتنفيذ، فسيتم إلغاء هذا الحكم أو البند
                            ولن يؤثر على صالحية األحكام والبنود األخرى المتبقية وقابليتها للتنفيذ.
                            5 .إن فشل yourspace في إنفاذ أي حق أو حكم في هذه الشروط لن يشكل تناز ًال عن هذا
                            الحق أو الحكم ما ل م نقر بذلك ونوافق عليه كتابيًا. باستثناء ما هو منصوص عليه صراحة
                            في هذه الشروط، فإن ممارسة أي من الطرفين ومطالبته ألي من التعويضات بموجب هذه

                            الشروط ال يشكل إخالل بالمطالبة بأي تعويضات أخرى بموجب هذه الشروط أو بموجب أي
                            قانون آخر.


                            6 .يمكنك التنازل عن هذه االتفاقية حقوقك والتزاماتك أو نقلها أو تفويضها لواردة أدناه دون
                            موافقة كتابية مسبقة من yourspace .ويجوز ل yourspace ، دون قيود ، التنازل
                            عن هذه االتفاقية أو نقلها أو تفويضها أي حقوق والتزامات بموجب هذه االتفاقية ، وفقًا
                            لتقديرها الخاص ، مع إشعار مسبق بـ 30 يو ًما. يبقى حقك في إنهاء هذه االتفاقية في أي
                            وقت دون تغيير.
                            7 .ما لم ينص على خالف ذلك، سيتم تقديم أي إشعارات أو مراسالت أخرى إلى المالك
                            المطلوبة بموجب هذه االتفاقية، إلكترونيًا أو تُقدم عبر منصة yourspace او عبر البريد
                            اإللكتروني أو إشعار منصة yourspace أو خدمة الرسائل )بما في ذلك الرسائل النصية
                            القصيرة (.
                            8 .اذا اختلفت العبارات بين اللغة العربية واللغة االنجليزية فإن اللغة العربية هي اللغة المعتبرة
                            في هذا العقد.
                            9 .تف ّسر اإلشارة إلى أي نظام أو الئحة أو قرار أو تعليمات على أنها تشمل التعديالت التي تطرأ
                            عليها من حين آلخر.
                            10. .أق َّر كل طرف في هذه االتفاقية بالتفهم الكامل لبنودها واإلدراك التام لكافة ما ورد فيها بعد
                            االطالع عليها فيما يمتنع معه وبعد التوقيع عليها المعارضة بعدم اإلدراك أو الفهم الخاطئ أو
                            الغبن أو خالفه.
                            11 .يفسر أي التزام سلبي يُفرض على أي طرف بأنه التزام بعدم السماح بحدوث أثر ذلك الشيء
                            السلبي، كما يفسر أي التزام إيجابي يُفرض على أي طرف بأنه التزام بحدوث ذلك الفعل أو
                            األثر المعني حدوثه.

                            </span>




                </div>
                <footer style={{ ...this.state.footerWidth, margin: 'auto' }}>
                    <div className="row" style={{ marginTop: '3rem', paddingBottom: '1.2rem', paddingTop: '1rem', borderTop: '1px solid lightgrey' }}>
                        <span style={{ color: '#86868b', fontSize: '12px', lineHeight: 1.33337, fontWeight: 400, letterSpacing: '-.01em;', }} className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-6">© 2020 Copyright:
                        <span style={{ color: '#515154', fontSize: '12px', lineHeight: 1.33337, fontWeight: 400, letterSpacing: '-.01em;', }}><a style={{ color: '#707070', }} href="https://hads.sa/"> hads.sa</a></span>
                        </span>
                      
                        <span className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-6" style={{ color: '#707070', fontSize: '12px', lineHeight: 1.33337, fontWeight: 400, letterSpacing: '-.01em;', backgroundColor: '' }} ><span className="termsinLandingpage" style={{ color: '#707070', float: 'right',cursor:'pointer', }} onClick={() => this.props.history.push('/terms&agreements/')} >terms&agreements</span>  </span>

                    </div>







                </footer>

            </div>
        )
    }
}

export default hostTemrs