import React, { Component } from 'react';
import ReactPasswordStrength from 'react-password-strength';
import zxcvbn from 'zxcvbn';
import './PasswordStrengthMeter.css';
export default class passwordStrengthMeter extends Component {
  state = { progressPercentage: '0px', progressColor: '#F25F5C' };
  createPasswordLabel = result => {
    switch (result.score) {
      case 0:
        if (this.state.progressPercentage !== '0%')
          this.setState({ progressPercentage: '0%', progressColor: '#F25F5C' });
        return 'Weak';
        break;
      case 1:
        if (this.state.progressPercentage !== '25%')
          this.setState({
            progressPercentage: '25%',
            progressColor: '#F25F5C',
          });
        return 'Weak';
        break;
      case 2:
        if (this.state.progressPercentage !== '50%')
          this.setState({
            progressPercentage: '50%',
            progressColor: '#FFE066',
          });
        return 'Fair';
      case 3:
        if (this.state.progressPercentage !== '75%')
          this.setState({
            progressPercentage: '75%',
            progressColor: '#247BA0',
          });
        return 'Good';
      case 4:
        if (this.state.progressPercentage !== '100%')
          this.setState({
            progressPercentage: '100%',
            progressColor: '#70C1B3',
          });
        return 'Strong';
      default:
        if (this.state.progressPercentage !== '0%')
          this.setState({ progressPercentage: '0%', progressColor: '#F25F5C' });
        return 'Weak';
    }
  };
  render() {
    const { password } = this.props;
    const testedResult = zxcvbn(password);
    return (
      <div style={{ marginTop: '5px' }} className='password-strength-meter'>
        {/* <progress value={testedResult.score} max="4" style={{width:"100%"}} data-label={this.createPasswordLabel(testedResult)}
            className={`password-strength-meter-progress strength-${this.createPasswordLabel(testedResult)}`}/> */}
        {/* <label style={{width:"200px"}}>
            {password && (
            <>
              {this.createPasswordLabel(testedResult)}
            </>
          )}
            </label> */}
        <div
          className='progress'
          data-label={this.createPasswordLabel(testedResult)}>
          <span
            className='value'
            style={{
              width: this.state.progressPercentage,
              backgroundColor: this.state.progressColor,
            }}></span>
        </div>
        <span id='passState' style={{ display: 'none' }}>
          {this.createPasswordLabel(testedResult)}
        </span>
      </div>
    );
  }
}
