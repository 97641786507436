import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert2';
import axios from '../../axios';
import avatar from '../Navigation/Sidebar/images/avatar.jpg';
import '../ReservationsComponent/ReservationsComponent.css';
import './ReservationsComponent.css';


const mql = window.matchMedia(`(min-width: 1000px)`);
const mql2 = window.matchMedia(`(min-width:767px)`)
const styles = { transition: 'all 2s ease-out' }
class ReservationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renters: [],
      rent_time: [],
      rent_date: [],
      dict: [],
      listofrents2: [],
      SelectedProperty: '',
      SelectedDate: '',
      selectedRent: {},
      index: '',
      scale: [],
      open: false,
      BodyItem: {},
      result: '',
      openScan: false,
      textfield: '',
      textfield2: '',
      profile_image: '',
      content:{}


    };
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
  }
  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
    mql2.addListener(this.mediaQueryChanged)
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
    mql2.removeListener(this.mediaQueryChanged)
  }
  componentDidMount() {
    this.mediaQueryChanged()
    this.getEvent()

  }
  getEvent() {
    axios({
      method: 'GET',
      url: '/owners/' + localStorage.getItem('id') + "/reservations/",
      headers: {
        'Authorization': 'jwt ' + localStorage.getItem('token'),

      }

    }).then(response => {
      var date = []
      var counter = 0
      var rent_date = 0
      // console.log(response.data.rents)
      date[counter] = response.data.rents[counter].rent_date
      for (var i = 1; i < response.data.rents.length; i++) {
        if (date[counter] === response.data.rents[i].rent_date) {

        } else {
          counter = counter + 1
          date[counter] = response.data.rents[i].rent_date
        }
      }
      // console.log(date)
      this.setState({
        rent_date: date
      })


      this.setState({
        renters: response.data.rents
      })
      // console.log(this.state.renters)
      // console.log(date.length)

    }).catch(err => {
      // console.log(err.message)
    })
  }
  mediaQueryChanged() {
    if (mql.matches) {
      this.setState({
        BodyItem: {
          display: 'grid',
          gridTemplateColumns: '250px 250px 250px 250px'
        },
        content:{
          marginLeft:'460px',
          marginTop:'40px',
          position:'relative'
        }

      });
    } else {
      this.setState({
        BodyItem: {
          display: 'grid',
          gridTemplateColumns: 'auto'
        },
        content:{
          marginLeft:0
        }

      });
    }
  }
  calcTime = (rent_time, rent_time_length) => {
    var dict = []

    let temp = {}
    // console.log(rent_time_length.length)
    for (let i = 0; i < rent_time_length.length; i++) {
      if (rent_time_length[i] === 3) {
        temp[i] = rent_time[i]
        dict.push(temp)
        temp = {}
      }
    }

    // console.log('dict', dict[0])
    var first = false
    var second = false
    var third = false
    // console.log(dict.charAt(0))
    for (let i = 0; i < dict.length; i++) {
      if (dict[i].charAt(0) === 1)
        first = true
      if (dict[i].charAt(1) === 1)
        second = true
      if (dict[i].charAt(2) === 1)
        third = true

      this.getdict(dict[i], i, first, second, third)
      first = false
      second = false
      third = false
    }

  }
  getdict = (dict, i, first, second, third) => {
    let shift = ''

    if (first === true) {
      shift = 'First Shift'
    }
    if (second === true) {
      shift += "Second Shift"
    }
    if (third === true) {
      shift += 'Thrid Shift'
    }
    dict[i].push(shift)
    shift = ''
    if (i === dict.length) {
      this.setState({
        dict: dict
      })
    }
  }
  handleOpen = () => {
    this.setState({
      open: true
    })
  }
  handleOpenReport = () => {
    this.setState({
      openReport2: true
    })
  }
  handleCloseReport = () => {
    this.setState({
      openReport2: false
    })
  }
  handleClose = () => {
    this.setState({
      open: false,

    })
  }
  handleMoteToCalender = () => {
    var dict = []

    for (var i = 0; i < this.state.listofrents2.length; i++) {
      dict[i] = {
        flag: true
      }
    }
    for (var i = 0; i < this.state.listofrents2.length; i++) {
      if (i === 0) {
        dict[i] = {
          rent_date: this.state.listofrents2[i].rent_date,
          data: this.state.listofrents2[i],
          flag: false
        }
      } else {
        var first = new Date(this.state.listofrents2[i].rent_date)
        for (var j = i + 1; j < this.state.listofrents2.length; j++) {
          var rest = new Date(this.state.listofrents2[j].rent_date)
          if (first !== rest && dict[j].flag === true) {
            dict[j] = {
              rent_date: this.state.listofrents2[j].rent_date,
              data: this.state.listofrents2[j],
              flag: false
            }
          }
        }
      }
    }
    // console.log(dict)
    this.props.history.push({ pathname: '/calender/' + 280, state: { dict: this.state.listofrents2 } })
  }
  handleRentReview = (renters) => {

    // console.log(renters)
    axios({
      method: 'GET',
      url: '/rents/' + renters.rent_id,
      headers: {
        'Authorization': 'jwt ' + localStorage.getItem('token')
      }

    }).then(response => {

      // console.log(response.data)
      this.setState({
        selectedRent: response.data
      })
      if (response.data.user_profile_img === '') {
        this.setState({ profile_image: avatar })
      } else {
        this.setState({
          profile_image: response.data.user_profile_img
        })
      }

    }).catch(error => {
      // console.log(error)
    })


  }
  handleScan = () => {
    this.setState({
      openScan: true
    })
  }
  handleCloseScan = () => {
    this.setState({
      openScan: false
    })
  }
  handleScanReader = data => {
    if (data) {
      this.setState({
        result: data
      })
    }
  }
  handleErrorReader = err => {
    // console.error(err)
  }
  SendScanResult = () => {
    axios({
      method: 'POST',
      url: '/rents/' + this.state.selectedRent.id + '/check_in/',
      headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
      data: { 'conf_code': this.state.textfield2 }
    }).then(response => {

    }).catch(error => {
      return swal.fire({

        icon: 'error',
        title: 'error',
        text: 'something went wrong',
        showConfirmButton: false,
        timer: 1500
      })
    })
  }
  handleSendReport = () => {

    axios({
      method: 'POST',
      url: '/rents/' + this.state.selectedRent.id + '/report/',
      headers: {
        'Authorization': 'jwt ' + localStorage.getItem('token')
      },
      data: {
        'report_text': this.state.textfield
      }
    }).then(response => {


    }).catch(error => {
      return swal.fire({

        icon: 'error',
        title: 'error',
        text: 'something went wrong',
        showConfirmButton: false,
        timer: 1500
      })
    })
  }
  handleText = (e) => {

    this.setState({
      textfield: e.target.value
    })
  }


  render() {
    const listofrents = this.state.renters
    const handleClose = () => {
      this.setState({
        anchorEl: null
      })
    }
    const open = Boolean(this.state.anchorEl)
    const handleClick = (event) => {
      this.setState({
        anchorEl: event.target
      })
    };


    const handleCloseDialge = () => {
      this.setState({
        openDialoge: false
      })
    }
    const styles = (theme) => ({
      root: {
        margin: 0,
        padding: theme.spacing(2),
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
    });
    const ReservationHeader = () => {
      if (this.state.renters.length === 0) {
        return <strong style={{ fontFamily: 'founders_bold', fontSize: '30px' }}>No Reservation</strong>
      } else
        return <strong style={{ fontFamily: 'founders_bold', fontSize: '30px' }}>Reservation</strong>
    }


    var rent_date = ''
    const Header2 = (props) => {
      if (rent_date === '') {
        rent_date = props.renters.rent_start_date
        return <strong style={{ width: '100%' }}>{props.renters.rent_start_date}</strong>
      } else if (rent_date === props.renters.rent_start_date) {
        return ''
      } else {
        rent_date = props.renters.rent_start_date
        return <strong style={{ width: '100%' }}>{props.renters.rent_start_date}</strong>
      }
    }
    const BodyItem = (props) => {
      console.log(props.date)
      console.log(props.rent_date)
      console.log(props.index)

      if (props.date === props.rent_date) {
        return <div style={{}} onClick={(e) => this.handleRentReview(props.renters)}>
          <img className="card-img-top" src={this.state.renters[props.index].space_img_url} alt="Card image cap" style={{ height: 168, boxShadow: 'lightgrey 3px 3px' }} />
          <div className="card-body">
            <p className="card-text" style={{ fontFamily: 'font_bold', fontSize: '1.2rem', lineHeight: '0.5em' }}>{this.state.renters[props.index].space_name}</p>
            <p className="card-text" style={{ fontFamily: 'HelveticaNeue', fontSize: '1.2rem', lineHeight: '0.5em' }}>{this.state.renters[props.index].rent_end_date}</p>
            <p className="card-text" style={{ fontFamily: 'HelveticaNeue', fontSize: '1.2rem', lineHeight: '1.5em' }}>{this.getReservationsIntervals(this.state.renters[props.index].rent_time)}</p>
            <p className="card-text" style={{ fontFamily: 'HelveticaNeue', fontSize: '1.2rem', lineHeight: '0.5em' }}>{this.state.renters[props.index].user_name}</p>
          </div>

        </div>
      } else
        return ''

    }
    var reservationBackground = '#f8f9fa'
    if (this.state.renters.length === 0) {
      reservationBackground = 'transparent'
    } else {
      reservationBackground = '#F0F0F0'
    }


    return (
      <Grid container
        direction='column'
        style={{ backgroundColor: '' }}
        justify="space-between"
      >



        <Grid container item>
          <div className="leftCol" style={{ backgroundColor: reservationBackground }} >
            <Grid container item className="card" spacing={1} style={{ marginTop: '5px', backgroundColor: 'transparent' }}>
              <Grid container item direction="row"  >

                {this.state.renters.length === 0 ? 
                <strong style={{ fontFamily: 'founders_bold', fontSize: '30px', marginLeft: '53px',marginTop:'30px',color:'#39354E' }}>
                
                    No reservations for now
  
                  
                </strong> : ''}
                {this.state.renters.map((name, index) => {
                  return <React.Fragment >
                    <Grid item xl={12} lg={12} xs={12} md={12} sm={12} style={{ textAlign: 'center', backgroundColor: '#39354E', marginTop: '5px',color:'white' }} >
                      <Header2 renters={this.state.renters[index]} />
                    </Grid>

                    <Grid item xl={6} lg={6} xs={6} md={6} sm={6} style={{ cursor: 'pointer', marginTop: '0px', ...styles, transform: 'scale(' + this.state.scale[index] + ')', }} onClick={(e) => this.handleRentReview(this.state.renters[index])}   >
                      <img className="card-img-top" src={this.state.renters[index].space_img_url} alt="Card image cap" style={{ height: 140 }} />

                    </Grid>
                    <Grid item xl={6} lg={6} xs={6} md={6} sm={6} style={{ marginTop: '0px', backgroundColor: '#ffff', cursor: 'pointer' }} onClick={(e) => this.handleRentReview(this.state.renters[index])}>
                      <div className="card-body" id="reservedspace" >
                        <p className="card-text" style={{ fontFamily: 'font_bold', fontSize: '1.2rem', lineHeight: '1em' }}>{this.state.renters[index].space_name}</p>
                        <p className="card-text" style={{ fontFamily: 'HelveticaNeue', fontSize: '1.1rem', lineHeight: '0em' }}>Start date: {this.state.renters[index].rent_start_date}</p>


                        {this.state.renters[index].rent_type === 2 &&
                          <p className="card-text" style={{ fontFamily: 'HelveticaNeue', fontSize: '1.1rem', lineHeight: '1.3em' }}>End date: {this.state.renters[index].rent_end_date}</p>
                        }
                        {this.state.renters[index].rent_type === 1 &&
                          <p className="card-text" style={{ fontFamily: 'HelveticaNeue', fontSize: '1.1rem', lineHeight: '1.3em' }}>Time: {this.getReservationsIntervals(this.state.renters[index].rent_time)}</p>
                        }
                        <p className="card-text" style={{ fontFamily: 'HelveticaNeue', fontSize: '1.1rem', lineHeight: '0em' }}>{this.state.renters[index].user_name}</p>
                      </div>
                    </Grid>

                  </React.Fragment>
                })}

              </Grid>
            </Grid>

          </div>

        </Grid>

        <Grid container item style={{ ...this.state.content}} >
          <Grid item xl={5} lg={6} md={6} xs={6} sm={6} >
            {this.state.selectedRent.rent_start_date === undefined ? '' :
              <div style={{width:'100%'}}>

                <header className="w3-container w3-light-white">
                  {/* {this.state.selectedRent.user_profile_img === '' ? this.setState({profile_image:avatar})  :  this.setState({profile_image:this.state.selectedRent.user_profile_img})} */}

                  <img src={this.state.profile_image} alt="Avatar" className="w3-left w3-circle" style={{ height: '50px', width: '50px' }} />
                  <h3 style={{ marginTop: '10px', marginLeft: '20px' }}>{this.state.selectedRent.user_name}</h3>
                </header>
                <hr />

                <div className="w3-container">

                  <p style={{ fontSize: '1.3rem', fontFamily: 'HelveticaNeue', lineHeight: '0.5em',color:'#39354E' }} >Rent Start Date:<span style={{ fontSize: '1.1rem', }}> {this.state.selectedRent.rent_start_date}</span></p>
                  {this.state.selectedRent.rent_type === 1 && <p style={{ fontSize: '1.3rem', fontFamily: 'HelveticaNeue', lineHeight: '0.5em',color:'#39354E' }} >Rent Time:<span style={{ fontSize: '1.1rem', }}> {this.getReservationsIntervals(this.state.selectedRent.rent_time)}</span></p>}
                  {this.state.selectedRent.rent_type === 2 && <p style={{ fontSize: '1.3rem', fontFamily: 'HelveticaNeue', lineHeight: '0.5em',color:'#39354E' }} >Rent End Date:<span style={{ fontSize: '1.1rem', }}> {this.state.selectedRent.rent_end_date}</span></p>
                  }
                  {/* <p style={{ fontSize: '1.3rem', fontFamily: 'HelveticaNeue', lineHeight: '0.5em' }} >Price:<span style={{ fontSize: '1.2rem', }}> {this.state.selectedRent.price}</span></p> */}
                  <Grid container item spacing={1} style={{ marginBottom: '4px' }} >
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Button style={{ backgroundColor: '#39354E', width: '100%',color:'white', marginTop: '10px', borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)'}} onClick={this.handleScan} >check in</Button>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Button onClick={this.handleOpen} style={{ backgroundColor: 'white', width: '100%', marginTop: '10px', color: '#39354E',borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}>Report</Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            }

          </Grid>
        </Grid>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
        >

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Report
                   <textarea className="form-control rounded-0" id="exampleFormControlTextarea2" onChange={(e) => this.handleText(e)} rows="3" style={{ resize: 'none', marginTop: '5px' }} placeholder="type a report"></textarea>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} style={{ borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)',backgroundColor:'#39354E',color:'white'}}>
              Cancel
                </Button>
            <Button onClick={this.handleSendReport} autoFocus style={{ borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)',backgroundColor:'white',color:'#39354E' }}>
              Send
                </Button>
          </DialogActions>
        </Dialog>


        <Dialog
          open={this.state.openScan}
          onClose={this.handleCloseScan}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
        >

          <DialogContent>
            <QrReader
              delay={300}
              onError={this.handleErrorReader}
              onScan={this.handleScanReader}
              style={{ width: '100%' }}
            />
            <p>{this.state.result}</p>
            <input type="text" placeholder="Enter the code " onChange={(e) => this.handleChange(e)} />

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseScan} style={{ borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)',backgroundColor:'#39354E',color:'white' }}>
              Cancel
                </Button>
            {this.state.textfield2 === '' ? '' : <Button onClick={this.SendScanResult} autoFocus style={{ borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)',backgroundColor:'white',color:'#39354E' }}>
              Send
                </Button>}


          </DialogActions>
        </Dialog>







      </Grid >
    );
  }
  handleChange = (e) => {
    this.setState({
      textfield2: e.target.value
    })
  }
  getReservationsIntervals(timeStr) {
    if (timeStr.length == 3) {
      return this.getReservationForOffice(timeStr);
    } else {
      return this.getReservationForMeetingSpecial(timeStr);
    }
  }

  getReservationForOffice(timeStr) {
    let intervals = ''
    if (timeStr.charAt(0) == '1' && (timeStr.charAt(1) == '1' || timeStr.charAt(2) == '1'))
      intervals = ' 8AM to 12PM,'
    else
      return intervals = '8AM to 12PM.'

    if (timeStr.charAt(1) == '1' && (timeStr.charAt(0)) == '1' || timeStr.charAt(2) == '1')
      intervals += ' 12PM to 4PM,'
    else
      return intervals = '12PM to 4PM.'
    if (timeStr.charAt(2) == '1')
      intervals += ' 4PM to 8PM.'

    return intervals;
  }

  getReservationForMeetingSpecial(timeStr) {
    let intervals = '';
    let i = 0, j = 0;
    while (i < timeStr.length) {
      if (timeStr.charAt(i) == '1') {
        j = i;
        while (timeStr.charAt(j) == '1' && j < timeStr.length) {
          j++;
        }
        intervals += this.indiciesToString(i, j) + "\n\t";
        i = j;
      } else {
        i++;
      }
    }
    // console.log("======");
    // console.log(timeStr);
    // console.log(intervals);
    return intervals;
  }
  indiciesToString(i, j) {
    let start = this.indexToString(i);
    let end = this.indexToString(j);
    return ',from ' + start + " to " + end;
  }
  indexToString(i) {
    i = i % 24;
    if (i == 0)
      return "12AM";
    if (i >= 1 && i <= 11)
      return i + "AM";

    if (i == 12)
      return "12AM";

    return (i - 12) + "PM";
  }
}
export default withRouter(ReservationComponent)