import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import swal from 'sweetalert2';
import axios from '../../axios';
const mql = window.matchMedia(`(min-width: 50em)`);

export default class space_info_form extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            variant: '',
            minDev: {},
            textwidth: {},
            buttonWidth: {},
            get_cities: [],
            get_country: [],
            showAlert: false,
            GridContainer: {},
            space_name_flag: false,
            space_disc_flag: false
        }

        this.mediaQueryChanged = this.mediaQueryChanged.bind(this)
    }
    componentDidMount() {
        this.mediaQueryChanged()
    }
    componentWillMount() {
        mql.addListener(this.mediaQueryChanged);
    }
    componentWillUnmount() {
        mql.removeListener(this.mediaQueryChanged);
    }
    mediaQueryChanged() {
        if (mql.matches) {
            this.setState({
                minDev: {
                    display: 'grid',
                    gridTemplateColumns: '24rem',
                    marginTop: 40,
                },
                textwidth: {
                    width: '700px'
                },
                buttonWidth: {
                    width: '199px'
                },
                GridContainer: {
                    maxWidth: '70%'
                }
            })

        } else {
            this.setState({
                minDev: {

                    marginTop: 180
                },
                textwidth: {
                    width: '300px'
                },
                buttonWidth: {
                    width: '100%'
                },
                GridContainer: {
                    maxWidth: '100%'
                }
            })
        }
    }
    // Go to previous form
    formPrevious = e => {
        e.preventDefault();
        this.setState({
            open: false
        })
        this.props.prevStep();
    };

    // Go to next form
    formContinue = e => {
        if (this.props.propertyInfo.spaceName === '' || this.props.propertyInfo.spaceName === null || this.props.propertyInfo.spaceName.length === 0) {
            return this.setState({
                space_name_flag: true
            })

        } else {
            this.setState({
                open: false,
                space_name_flag: false,
                variant: 'success'
            })
        }
        if (this.props.propertyInfo.desc === '' || this.props.propertyInfo.desc === null || this.props.propertyInfo.desc.length === 0) {
            return this.setState({
                space_disc_flag: true
            })
        } else {
            this.setState({
                desc: false
            })
        }
        e.preventDefault();
        this.props.nextStep();

    };


    // to send patch request to edit 
    SaveChanges = () => {
        if (this.props.propertyInfo.spaceName === '' || this.props.propertyInfo.spaceName === null || this.props.propertyInfo.spaceName.length === 0) {
            return this.setState({
                space_name_flag: true
            })

        } else {
            this.setState({
                open: false,
                space_name_flag: false,
                variant: 'success'
            })
        }

        if (this.props.propertyInfo.desc === '' || this.props.propertyInfo.desc === null || this.props.propertyInfo.desc.length === 0) {
            return this.setState({
                space_disc_flag: true
            })
        } else {
            this.setState({
                desc: false
            })
        }
        var formData = new FormData();
        formData.append('name', this.props.propertyInfo.spaceName)
        formData.append('desc', this.props.propertyInfo.desc)
        formData.append('capacity', this.props.propertyInfo.spaceMaxCapacity)
        if (this.props.propertyInfo.spaceCategory == 4) {
            formData.append('sub_category', this.props.propertyInfo.spaceType)
        } else formData.append('special_room_type', this.props.propertyInfo.spaceType)
        axios({
            method: 'PATCH',
            url: '/spaces/' + this.props.propertyInfo.space_id2,
            headers: {
                'Authorization': 'jwt ' + localStorage.getItem('token')
            },
            data: formData
        }).then(res => {
            // console.log(res.data)

            this.props.jump_step()
        }).catch(err => {
            // console.log(err.message)
            return swal.fire({

                icon: 'error',
                title: 'error',
                text: 'something went wrong with space update',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }
    render() {
        return (
            <div style={{ ...this.state.minDev, margin: 'auto' }}>
                <div style={{ gridColumn: '2' }}>

                    <form  >
                        <Grid
                            container
                            direction='column'
                        >
                            <Grid container item direction="column" justify="flex-start" style={{ backgroundColor: '' }} >

                                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} >
                                    <FormControl style={{ width: '100%' }}>
                                        <TextField
                                            id='space-name'
                                            value={this.props.propertyInfo.spaceName}
                                            onChange={this.props.handleChange('spaceName')}
                                            placeholder='Enter your space name'
                                            label={<p style={{ fontSize: '20px', fontFamily: 'founders' }}>Space Name</p>}
                                            error={this.state.space_name_flag}
                                            inputProps={{
                                                maxLength: 40,
                                                minLength: 10,
                                            }}
                                            style={{ width: '100%' }}
                                            margin='normal'
                                            variant='outlined'
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xl={7} lg={7} md={9} sm={12} xs={12}>
                                    <FormControl style={{ width: '100%' }}>
                                        <TextField
                                            id='space-desc'
                                            value={this.props.propertyInfo.desc}
                                            onChange={this.props.handleChange('desc')}
                                            placeholder='Enter your space description'
                                            label={<p style={{ fontFamily: 'founders', fontSize: 20 }}>Space Description</p>}
                                            error={this.state.space_disc_flag}
                                            margin='normal'
                                            variant='outlined'
                                            inputProps={{
                                                maxLength: 1000,
                                            }}
                                            multiline
                                            rows="9"
                                            style={{ width: '100%' }}
                                            multiline
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid container item justify="space-between" xl={7} lg={7} md={9} sm={12} xs={12}  >
                                    <Grid item style={{ marginTop: 86, }}    >
                                        <Button
                                            variant='contained'
                                            label='Back'
                                            id="back"
                                            style={{ float: 'right' }}
                                            size='large'
                                            style={{ backgroundColor: '#39354E', color: 'white', height: 44, fontSize: 20, ...this.state.buttonWidth, fontFamily: 'HelveticaNeue',borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}
                                            onClick={this.formPrevious}>
                                            Back
                                         </Button>
                                    </Grid>

                                    <Grid item style={{ marginTop: 86, }} >
                                        {this.props.propertyInfo.request_type === 'post' &&
                                            <Button
                                                label="Continue"
                                                variant='contained'
                                                size='large'
                                                id="continue"
                                                style={{ backgroundColor: 'white', color: '#39354E', height: 44, fontSize: 20, ...this.state.buttonWidth, fontFamily: 'HelveticaNeue',borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}
                                                onClick={this.formContinue}
                                            >
                                                Continue
                                             </Button>
                                        }
                                        {this.props.propertyInfo.request_type === 'patch' &&
                                            <Button
                                                label="Save Changes"
                                                variant='contained'
                                                size='large'
                                                id="continue"
                                                style={{ backgroundColor: 'white', color: '#39354E', height: 44, fontSize: 20, ...this.state.buttonWidth, fontFamily: 'HelveticaNeue',borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}
                                                onClick={this.SaveChanges}
                                            >
                                                Save Changes
                                                </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        )
    }
}
