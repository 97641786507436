import React, { Component } from 'react'
import avatar from "../../images/avatar.jpg"
import { Form } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { fadeInRightBig, fadeOutLeft, fadeOutRight, fadeInLeftBig} from 'react-animations';
import Radium, {StyleRoot} from 'radium';
const mql = window.matchMedia(`(min-width: 1060px)`);

const styles = {
    fadeInRightBig: {
      animation: 'x .3s',
      animationName: Radium.keyframes(fadeInRightBig, 'fadeInRightBig')
    },
    fadeOutLeft: {
        animation: 'x .3s',
        animationName: Radium.keyframes(fadeOutLeft, 'fadeOutLeft')
      },
      fadeOutRight: {
        animation: 'x .3s',
        animationName: Radium.keyframes(fadeOutRight, 'fadeOutRight')
      },
      fadeInLeftBig: {
        animation: 'x .3s',
        animationName: Radium.keyframes(fadeInLeftBig, 'fadeInLeftBig')
      },
  }
export default class InboxComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            align:{}, big_grid:{display:"grid", gridTemplateColumns: "350px auto"}, back:"none", right_animation:{}, fadeOutLeft:{}, 
            back_clicked:false, chat_preview_Click:false, convo:[], bottomBorder:"solid thin lightgrey", msg_width:"500px"
        };
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.chat_preview_Click = this.chat_preview_Click.bind(this);
      }
    componentWillMount() {
        mql.addListener(this.mediaQueryChanged);
    }
    
    componentWillUnmount() {
        mql.removeListener(this.mediaQueryChanged);
    }
    componentDidMount(){
        this.screen_sizes();
        var elem = document.getElementById('convo');
        elem.scrollTop = elem.scrollHeight;
      }
      mediaQueryChanged() {
        this.screen_sizes();
      }
      screen_sizes(){
        if(mql.matches){
            this.setState({ align:{}, big_grid:{display:"grid", gridTemplateColumns: "350px auto"}, back:"none",  right_animation: {}, 
            bottomBorder:"solid thin lightgrey", msg_width:"500px"})
            document.getElementById("chat").style.display="block";
            document.getElementById("user_chats").style.display="block";
        }
        else {
            this.setState({ align:{textAlign:"center"}, big_grid:{}, back:"none", bottomBorder:"", msg_width:"300px"})
            document.getElementById("chat").style.display="none";
        }
      }
      chat_preview_Click(notif){
        if(!mql.matches){
            
            this.setState({ back:"block", chat_preview_Click:true })

            
            this.setState({
                fadeOutLeft: styles.fadeOutLeft })
        } if(mql.matches)
            document.getElementById("chat").style.display="block";
        document.getElementById(notif).style.display = "none"
      }
      back_button = e => {
        
        this.setState({back_clicked:true, back:"none", right_animation: styles.fadeOutRight, })
      }

      send_message(message){

        if(message != ""){
           var convo = this.state.convo;
          var msg = <div key={convo.length} style={{display:"grid", gridTemplateColumns: "auto 45px"}}>               
          <div style={{ gridColumn: '1',overflowX:'auto'}}>
              <div style={{backgroundColor:"white", width:"75%", margin:"10px", padding:"10px",
          borderRadius:"5px", float:"right" }}>
                  <span style={{verticalAlign:"middle",  wordWrap: 'break-word'}}>{message} </span>
              </div>

          </div>
          <div style={{gridColumn: '2'}}>
              <img style={{width:"45px", borderRadius:"50%",marginTop:"15px"}} src={avatar}/>
          </div>
      </div>

      convo[convo.length] = msg;

      this.setState({convo:convo})

      document.getElementById("msg_input").value = ""

      this.scroll_func();
        }
    }

    scroll_func(){
        setTimeout(function() {
            var elem = document.getElementById('convo');
            elem.scrollTop = elem.scrollHeight;
          }, 1);
    }

    render() {
        const img_style = {width:"45px", borderRadius:"50%", margin:"0 10px 0"}
        const chat_preview_style = {borderBottom:"solid thin grey", cursor:"pointer", padding:"10px 0"}
        const last_message_preview = {fontSize:"13px", color:"grey", paddingTop:"5px", float:"left", marginBottom:"0px",
        width:"70%", overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis"}
        const title = {paddingBottom:"10px", ...this.state.align}
        const unread_style = { color:"white", backgroundColor:"orange",float:"right", width:"20px",
                                lineHeight: '20px', textAlign:"center", borderRadius:"50%", fontSize:"13px"}
        const borders = {height:"500px", border:"solid thin lightgrey", width:"100%", ...this.state.big_grid, border:this.state.bottomBorder}
        const username_style = { overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis", width:"50%", float:"left", marginBottom:"0"}
        return (
            <>


            <button id="back_button" style={{ display: this.state.back, position:"absolute", marginLeft:"10px"}} onClick={this.back_button}>back</button>
            <h3 style={title}>Inbox</h3>
            <div style={borders}>
            <StyleRoot>
                <div id="user_chats" style={{ overflowY:"scroll", height:"100%", gridColumn: '1', ...this.state.fadeOutLeft}} 
                onAnimationEnd={() => {
                    if(this.state.chat_preview_Click){
                        this.setState({ chat_preview_Click:false, fadeOutLeft:{},})
                document.getElementById("user_chats").style.display = "none";
                document.getElementById("chat").style.display = "block"; 
                this.setState({ right_animation: styles.fadeInRightBig,})}
             if(this.state.back_clicked) this.setState({back_clicked:false})
            }
                }>
                    <div style={chat_preview_style} onClick ={ (e) => this.chat_preview_Click("notif_1")}>
                        <div style={{float:"left"}}>
                            <img style={img_style} src={avatar}/>
                        </div>
                        <div style={{paddingLeft:"60px", paddingRight:"10px"}}>
                            <p style={username_style}>username #1 is long</p>
                            <span style={{float:"right", fontSize:"13px"}}>13 Oct at 1AM</span>
                            <br/>
                            <p style={last_message_preview}>this is the last message that i sent</p>
                            
                            <span id="notif_1" style={unread_style}>2</span>
                            <br/>
                        </div>
                    </div>
                    <div style={chat_preview_style} onClick ={ (e) => this.chat_preview_Click("notif_2")}>
                        <div style={{float:"left"}}>
                            <img style={img_style} src={avatar}/>
                        </div>
                        <div style={{paddingLeft:"60px", paddingRight:"10px"}}>
                            <p style={username_style}>username #2</p>
                            <span style={{float:"right", fontSize:"13px"}}>13 Oct at 1AM</span>
                            <br/>
                            <p style={last_message_preview}>this is the last message that i sent</p>
                            
                            <span id="notif_2" style={unread_style}>1</span>
                            <br/>
                        </div>
                    </div>
                </div>
                </StyleRoot>
                <StyleRoot>
                <div id="chat" style={{ height:"500px", gridColumn: '2', backgroundColor:"lightgrey", ...this.state.right_animation}} 
                onAnimationEnd={() => {if(this.state.back_clicked){
                    document.getElementById("chat").style.display="none";
                    document.getElementById("user_chats").style.display="block";

                    this.setState({fadeOutLeft: styles.fadeInLeftBig})
                    
                }
                this.scroll_func();

                // if(this.state.chat_preview_Click)this.setState({ chat_preview_Click:false })
                
                }}>
                    <div id="convo" style={{ height:"400px", padding:"0 20px 20px 20px", overflowY:"scroll"}}>
                        <div style={{display:"grid", gridTemplateColumns: "45px auto"}}>
                            <div style={{gridColumn: '1'}}>
                                <img style={{width:"45px", borderRadius:"50%",marginTop:"15px"}} src={avatar}/>
                            </div>
                                
                            <div style={{backgroundColor:"white", width:"75%", margin:"10px", gridColumn: '2', padding:"10px",
                            borderRadius:"5px"}}>
                                <span style={{verticalAlign:"middle"}}>
                                    message 1 is a very long message, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                                </span>
                            </div>
                        </div>

                        <div style={{display:"grid", gridTemplateColumns: "auto 45px "}}>

                                
                            <div style={{ gridColumn: '1',}}>
                                <div style={{backgroundColor:"white", width:"75%", margin:"10px", padding:"10px",
                            borderRadius:"5px", float:"right" }}>
                                    <span style={{verticalAlign:"middle"}}>
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                                    </span>
                                </div>

                            </div>
                            <div style={{gridColumn: '2'}}>
                                <img style={{width:"45px", borderRadius:"50%",marginTop:"15px"}} src={avatar}/>
                            </div>
                        </div>

                        {/* <span style={{backgroundColor:"white",padding:"5px 50px" }}>
                            message 1 is a very long message, hellow swedrty uiop;olkjhfvbnk lkjhbvc ebhfjkvlfr
                        </span> */}
                        {this.state.convo}
                    </div>
                    <div style={{backgroundColor:"WhiteSmoke", height:"100px", padding:"10px", display:"grid", gridTemplateColumns: "auto auto auto"}}>

                        <div style={{height:"50px",gridColumn:"2", display:"grid",  gridTemplateColumns: "auto 60px"}}>
                            <div style={{ gridColumn:"1"}}>
                                <Form.Control id="msg_input" 
                                onKeyUp={(e) => {if(e.keyCode === 13) document.getElementById("send_button").click();}} type="text" 
                                ref={(ref) => {this.chat_message = ref}} placeholder="Send a message" 
                                style={{ margin:"5px 5px 0 0", width:"95%", height:"80%", paddingLeft:"5px", fontSize:"17px", borderRadius:"5px"}}/>
                                
                            </div>
                            <div style={{ gridColumn:"2", verticalAlign:"middle"}}>
                                <Button id="send_button" style={{height:"80%", marginTop:"5px"}} 
                                onClick = { (e) => this.send_message(this.chat_message.value)} variant="outlined">send</Button>
                            </div>
                        </div>
                    {/* <input type="text"/> */}
                    </div>
                    {/* <div style={{display: 'flex'}}>
                        <span style={{ verticalAlign:"text-bottom", alignSelf: 'flex-end'}}>Price amount: SR300</span>
                    </div> */}
                   
                </div>
                </StyleRoot>
            </div>
            </>
        )
    }
}
