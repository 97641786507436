import React from 'react';
import {
  GoogleMap,
  Marker, withGoogleMap, withScriptjs
} from 'react-google-maps';
import { compose, withProps } from 'recompose';

const MyMapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCJoRir4vSUFbV9TuA-c7mn24QWMV5Zq9w&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(props => (
  <GoogleMap defaultZoom={15} defaultCenter={{ lat:props.lat, lng: props.lng}} position={{ lat: props.lat, lng: props.lng }}>
    {props.isMarkerShown && (
      <Marker
        position={{ lat: props.lat, lng: props.lng }}
        onClick={props.onMarkerClick}
        draggable={false}
        
      />
    )}
  </GoogleMap>
));

export default class Map extends React.PureComponent {
  constructor(props){
    super(props)
    this.state = {
      isMarkerShown: false,
      lat:24.713552,
      lng:46.675297
    };
  }
  

  componentDidMount() {
    // console.log(this.props.lat)
    // console.log(this.props.lng)
    
    this.delayedShowMarker();
  }

  delayedShowMarker = () => {
    setTimeout(() => {
      this.setState({ isMarkerShown: true });
    }, 0);
  };

  handleMarkerClick = () => {
    this.setState({ isMarkerShown: false });
    this.delayedShowMarker();
  };

  render() {
    return (
      <MyMapComponent
        isMarkerShown={this.state.isMarkerShown}
        lat={this.props.lat}
        lng={this.props.lng}
        onMarkerClick={this.handleMarkerClick}
        
      />
    );
  }
}
