import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import RemoveIcon from '@material-ui/icons/Remove';
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert2';
import axios from '../../axios';
const mql = window.matchMedia(`(min-width: 50em)`);
export default class Features_form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      features_preview: [],
      features: [],
      loaded: false,
      features_ui: [],
      f0: '',
      remove_f: false,
      removed_F: [],
      new_features_num: 0,
      didMount_features: 0,
      minDev: {},
      editedfeatures: [],
      buttonWidth: {},
      textFieldWidth: {},
      FabStyle: {},
      GridContainer: {}
    }
    this.componentQueryChanged = this.componentQueryChanged.bind(this)
  }

  // Go to previous form
  formPrevious = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  // Go to next form
  formContinue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  componentDidMount() {
    if (mql.matches) {
      this.setState({
        minDev: {
          display: 'grid',
          gridTemplateColumns: '24rem',
          marginTop: '40px'
        },
        buttonWidth: {
          width: '199px'
        },
        textFieldWidth: {
          width: '700px'
        },
        GridContainer: {
          maxWidth: '70%'
        }
      })
      // console.log('./././././', this.props.propertyInfo.request_type)

    } else {
      this.setState({
        minDev: {
          display: 'grid',
          gridTemplateColumns: '0rem',
          marginTop: '40px  '
        },
        buttonWidth: {
          width: '100%'
        },
        textFieldWidth: {
          width: 300
        },
        GridContainer: {
          maxWidth: '100%'
        }
      })
      if (this.props.propertyInfo.request_type === 'patch') {
        this.setState({
          editedfeatures: this.props.propertyInfo.editedFeatures
        })
      }
    }
    // console.log('this.props.propertyInfo.new_features.length: ', this.props.propertyInfo.new_features.length)
    // console.log('his.props.propertyInfo.features.length:', this.props.propertyInfo.features.length)
    this.setState({ didMount_features: this.props.propertyInfo.new_features.length })
    localStorage.setItem('step', 'Features')
  }
  componentWillMount() {
    mql.addListener(this.componentQueryChanged)
  }
  componentWillUnmount() {
    mql.removeListener(this.componentQueryChanged)
  }
  componentQueryChanged() {
    if (mql.matches) {
      this.setState({
        minDev: {
          display: 'grid',
          gridTemplateColumns: '24rem',
          marginTop: '100px'

        },
        buttonWidth: {
          width: '199px'
        },
        textFieldWidth: {
          width: '700px'
        },
        GridContainer: {
          maxWidth: '70%'
        }
      })
    } else {
      this.setState({
        minDev: {
          display: 'grid',
          gridTemplateColumns: '0rem',
          marginTop: '180px'
        },
        buttonWidth: {
          width: '100%'
        },
        textFieldWidth: {
          width: 300
        },
        GridContainer: {
          maxWidth: '100%'
        }
      })
    }

  }
  addFeature = e => {
    // console.log('features_preview.length: ', this.props.propertyInfo.features.length);
    // console.log(this.props.propertyInfo.features)
    var features_preview = this.state.features_preview
    var new_features_num = this.state.new_features_num
    var new_feature = 'new_feature' + new_features_num++;
    var new_ = <div> <br /><TextField
      id={'feature' + (this.props.propertyInfo.features.length + new_features_num)}
      value={this.props.propertyInfo.features[this.props.propertyInfo.features.length]}
      onChange={this.props.handleChange('new_feature' + new_features_num)}
      key={this.props.propertyInfo.features.length + new_features_num}
      placeholder={'Enter feature ' + (this.props.propertyInfo.features.length + new_features_num)}
      label={'Feature ' + (this.props.propertyInfo.features.length + new_features_num)}
      margin='normal'
      variant='outlined'
    />
      <Fab color="secondary" aria-label="add" size="small" style={{ marginTop: "10%" }} onClick={(e) => this.remove_new_feature(new_features_num)}>
        <RemoveIcon />
      </Fab>
    </div>
    features_preview[features_preview.length] = new_;
    // console.log('features_preview: ', features_preview)
    this.setState({ features_preview: features_preview, new_features_num: new_features_num })
  }
  remove_feature = (x) => {
    var removed_F = this.state.removed_F;
    removed_F[removed_F.length] = x

    this.setState({ remove_f: true, removed_F: removed_F, })
    // console.log('remove feature: ', this.state.remove_f)
    // console.log('feature_ids ', x)
    // console.log('this.state.removed_F; ', this.state.removed_F);
    this.props.removed_features(x, true)
    document.getElementById("feature_preview" + x).remove();
  }
  remove_new_feature(x) {
    // console.log('remove_new_feature: ', x)
    this.props.removed_features(x, false)
    var new_features_num = this.state.new_features_num;
    // console.log('features_preview length: ', this.state.features_preview.length)
    var features_preview = this.state.features_preview;
    var w = --x;
    features_preview.splice(w, 1);
    --new_features_num;
    // console.log('THIS IS new_features_num: ', new_features_num)
    // console.log('THIS IS features_preview: ', features_preview)
    this.setState({ new_features_num: new_features_num, features_preview: features_preview })
  }
  SaveChanges = () => {
    var array = []
    var array2 = []
    var flag = false
    var array3 = []
    // console.log('.,.,.,', this.props.propertyInfo.editedFeatures.length)
    // console.log('.....', this.props.propertyInfo.det_features)
    for (var i = 0; i < this.props.propertyInfo.editedFeatures.length; i++) {
      if (this.props.propertyInfo.editedFeatures.length > this.props.propertyInfo.det_features.length) {
        flag = true
        array3[i] = this.props.propertyInfo.editedFeatures[i]
      } else {
        if (this.props.propertyInfo.editedFeatures[i] !== this.props.propertyInfo.det_features[i].desc) {
          array[i] = this.props.propertyInfo.det_features[i].id
          array2[i] = this.props.propertyInfo.editedFeatures[i]
        }
      }
    }

    if (array.length !== 0) {
      

        // console.log(array2[j])
      for (var j = 0; j < array.length; j++) {
        axios({
          method: "patch",
          url: '/features/' + array[j] + '/',
          headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
          data: {
            'desc': array2[j],
          }
        }).then(response => {
          // console.log(response.data)
          this.props.jump_step()
        }).catch(err => {
          return swal.fire({

            icon: 'error',
            title: 'error',
            text: 'something went wrong',
            showConfirmButton: false,
            timer: 1500
        })
        })
      }
    }
    if (flag) {
      axios({
        method: 'post',
        url: '/features/',
        headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
        data: {
          'desc': this.props.propertyInfo.new_features,
          'space_id': this.props.propertyInfo.space_id
        }
      }).then(response => {
          this.props.jump_step()
      }).catch(err => {
        
        return swal.fire({

          icon: 'error',
          title: 'error',
          text: 'something went wrong',
          showConfirmButton: false,
          timer: 1500
      })
      })
    }
    // console.log(this.state.removed_F.length)
    if (this.state.removed_F.length !== 0) {
      for (var i = 0; i < this.state.removed_F.length; i++) {
        axios({
          method: 'delete',
          url: '/features/',
          headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
          data: {
            'features_ids': this.state.removed_F
          }
        }).then(response => {
          this.props.jump_step()
         
        }).catch(err => {
          return swal.fire({

            icon: 'error',
            title: 'error',
            text: 'something went wrong',
            showConfirmButton: false,
            timer: 1500
        })
        })
      }
    }

  }
  render() {
    const features_preview = this.state.features_preview
    const features = this.props.propertyInfo.features
    const the_new_features = this.props.propertyInfo.new_features
    return (
      <div style={{ ...this.state.minDev, margin: 'auto' }}>
        <div style={{ gridColumn: '2' }}>
          <Grid
            container
            direction='column'

          >
            <Grid contanier item direction="column" justify="flex-start">
              <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                {features.map((x, i) => {
                  return (
                    <div key={i} id={'feature_preview' + this.props.propertyInfo.feature_ids[i]} style={{ backgroundColor: '', }}>
                      <TextField
                        id={'feature' + (i + 1)}
                        // defaultValue={features[i]}
                        value={this.props.propertyInfo.features[i] || ''}
                        // value = {this.state.f0 }
                        key={i}
                        onChange={this.props.handleChange('feature' + (i + 1), i + 1, i - 1)}
                        // onChange= {this.handle0}
                        placeholder={'Enter feature 1'}
                        label={'Feature ' + (i + 1)}
                        margin='normal'
                        variant='outlined'
                        style={{ width: '80%', height: 47.27}}
                      />
                      <Fab color="secondary" aria-label="add" size="small" style={{ marginTop: '2.5%', marginLeft: '12px', backgroundColor: 'white', color: 'red', border: '1px solid red' }} onClick={(e) => this.remove_feature(this.props.propertyInfo.feature_ids[i])}>
                        <RemoveIcon />
                      </Fab>
                    </div>
                  )
                })}

              </Grid>
              <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                {the_new_features.map((data, index) => {
                  if (index < this.state.didMount_features) {
                    return (
                      <div style={{ backgroundColor: '', }}>
                        <br />
                        <TextField
                          id={'feature' + (this.props.propertyInfo.features.length + index)}
                          value={the_new_features[index]}
                          onChange={this.props.handleChange('new_feature' + index)}
                          style={{ width: '80%', height: 47.27 }}
                          key={the_new_features.length + index}
                          placeholder={'Enter feature ' + (this.props.propertyInfo.features.length + index + 1)}
                          label={'Feature ' + (this.props.propertyInfo.features.length + index + 1)}
                          margin='normal'
                          variant='outlined'
                        />
                        <Fab aria-label="add" size="small" style={{ marginTop: '2.5%', marginLeft: '12px', backgroundColor: 'white', color: 'red', border: '1px solid red' }} onClick={(e) => this.remove_new_feature(this.state.new_features_num)}>
                          <RemoveIcon />
                        </Fab>
                      </div>

                    )
                  }
                })}

              </Grid>
              <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                {features_preview.map((data, index) => {
                  return (
                   

                    <div style={{ backgroundColor: '', }}>

                      <TextField
                        id={'feature' + (this.props.propertyInfo.features.length + index + this.state.didMount_features)}
                        value={this.props.propertyInfo.features[this.props.propertyInfo.features.length + index + this.state.didMount_features]}
                        // onKeyUpCapture={this.addFeature}
                        onChange={this.props.handleChange('new_feature' + (index + this.state.didMount_features))}
                        key={this.props.propertyInfo.features.length + index + this.state.didMount_features}
                        style={{ width: '80%', height: 47.27 }}
                        placeholder={'Enter feature ' + (this.props.propertyInfo.features.length + index + 1 + this.state.didMount_features)}
                        label={'Feature ' + (this.props.propertyInfo.features.length + index + 1 + this.state.didMount_features)}
                        margin='normal'
                        variant='outlined'
                      />
                      <Fab aria-label="add" size="small" style={{ marginTop: '2.5%', marginLeft: '12px', backgroundColor: 'white', color: 'red', border: '1px solid red' }} onClick={(e) => this.remove_new_feature(this.state.new_features_num)}>
                        <RemoveIcon />
                      </Fab>
                    </div>
                  )
                })}

              </Grid>
              <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{marginTop:'10px'}}>
                <Button size='small' aria-label="add" style={{  marginTop: '20px', color: '#39354E', backgroundColor: 'white', fontSize: '14px', fontFamily: 'HelveticaNeue', margin: 'auto',borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)',border:'1px solid transparent' }} onClick={this.addFeature} >
                  + Add New Feature
            </Button>

              </Grid>
              <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{marginBottom:'30px'}}>

                {this.props.propertyInfo.request_type === 'post' &&
                  <Grid container item justify="space-between" style={{ width: '100%', backgroundColor: '' }} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item >
                      <Button
                        variant='contained'
                        label='Back'
                        color='secondary'
                        size='large'

                        style={{ marginTop: 86, backgroundColor: '#39354E', color: 'white', height: 44, fontSize: 20, ...this.state.buttonWidth, fontFamily: 'HelveticaNeue' ,borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)'}}

                        onClick={this.formPrevious}>
                        Back
                        </Button>
                    </Grid>
                    <Grid item >
                      <Button
                        variant='contained'
                        label='Continue'
                        color='primary'
                        size='large'

                        style={{ marginTop: 86, backgroundColor: 'white', color: '#39354E', height: 44, fontSize: 20, ...this.state.buttonWidth, fontFamily: 'HelveticaNeue',borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}

                        onClick={this.formContinue}>
                        Continue
                        </Button>
                    </Grid>


                  </Grid>
                }
                {this.props.propertyInfo.request_type === 'patch' &&
                  <Grid container item direction='column'  lg="6" style={{  backgroundColor: '' }}>
                    <Button
                      variant='contained'
                      label='Back'
                      color='secondary'
                      size='large'

                      style={{ marginTop: 86, backgroundColor: '#39354E', color: '#FFFFFF', height: 44, fontSize: 20, ...this.state.buttonWidth, fontFamily: 'HelveticaNeue',borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)'}}

                      onClick={this.SaveChanges}>
                      Save Changes
                    </Button>

                  </Grid>
                }

              </Grid>


            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}
