import { FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import swal from 'sweetalert2';
import axios from '../../axios';


const mql = window.matchMedia(`(min-width: 30em)`)

export default class Property_form extends Component {

  constructor(props) {
    super(props)
    this.state = {
      propertyInfo: {},
      amenities_types_stats: [],
      amenities_types: [],
      loaded: [false, false],
      name: '',
      floor: 0,
      city_name: '',
      open: false,
      new_amenities: [],
      amenities_del: [],
      property_name: '',
      loadedAmenties: [],
      flag: true,
      check: true,
      midDev: {},
      EditProperty: {},
      margin: {},
      marginauto: {}

    }
    this.componentQueryChanged = this.componentQueryChanged.bind(this)
  }

  componentDidMount() {
    this.componentQueryChanged()
    this.getEvent();
  }
  componentWillMount() {
    mql.addListener(this.componentQueryChanged)
  }
  componentWillUnmount() {
    mql.removeListener(this.componentQueryChanged)
  }
  componentQueryChanged() {
    if (mql.matches) {
      this.setState({
        midDev: {
          display: 'grid',
          gridTemplateColumns: '24rem ',

          marginTop: 140
        },

        span: {
          float: 'left',
          marginLeft: '30px'
        },
        btn_width: {
          width: 199
        },
        EditProperty: {
          marginLeft: '30px'
        },
        margin: {

        },

        marginauto: {

        }
      })
    } else {
      this.setState({
        midDev: {
          backgroundColor: '',
          marginTop: '140px'
        },


        btn_width: {
          width: 189
        },
        EditProperty: {

        },
        margin: {
          marginLeft: '28%'
        },

        marginauto: {
          margin: 'auto'
        }

      })
    }

  }

  // used to call info of each prop and it's amenities
  getEvent = async () => {

    await axios({
      method: 'GET',
      url: '/properties/' + this.props.match.params.property_id,
      headers: {
        'Authorization': 'jwt ' + localStorage.getItem('token')
      }
    }).then(res => {
      // console.log(res.data)
      this.setState({
        property_name: res.data.name
      })
      var loaded = this.state.loaded;
      loaded[0] = true;
      // console.log(res.data.prop_contact_num)
      this.setState({ propertyInfo: res.data, loaded: loaded, name: res.data.name, floor: res.data.floor, city_name: res.data.city_name, flag: false, prop_contact_num: res.data.prop_contact_num })
      // console.log(this.state.prop_contact_num)
      if (loaded[1])
        this.load_amenities();

    })
      .catch(e => {
        // console.log(e.response)
      });

    let amenities = await axios.get("/amenities_types/"
    )
      .then(res => {
        var am = []

        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].desc === "Wifi")
            am[0] = res.data[i]
        }
        // console.log(am)
        this.setState({ amenities_types: am })
        var amenities_types = am
        var amenities_types2 = am

        var amenities_types_stats = [];
        // console.log(amenities_types)
        for (let i = 0; i < amenities_types.length; i++) {
          if (amenities_types[i].desc === "Wifi")
            amenities_types_stats[0] = {
              id: amenities_types[i].id,
              value: false,
              desc: amenities_types[i].desc,
            }
        }
        var amenities_types_stats2 = [];
        for (let i = 0; i < amenities_types2.length; i++) {
          amenities_types_stats2.push(
            {
              id: amenities_types2[i].id,
              value: false,
              desc: amenities_types2[i].desc,
            })
        }
        this.setState({ loadedAmenties: amenities_types_stats2 })
        this.setState({ amenities_types_stats: amenities_types_stats })

        var loaded = this.state.loaded;
        loaded[1] = true;
        this.setState({ loaded: loaded, check: true })
        if (loaded[0])
          this.load_amenities();

      })
      .catch(e => {
        //  console.log(e)
      });
  }


  load_amenities() {


    var amenities_types_stats = this.state.amenities_types_stats;
    var amenities_types = this.state.amenities_types
    var amenities_types_filtered = [];
    var amenities = this.state.propertyInfo.amenities;
    var am = []
    for (var i = 0; i < amenities.length; i++) {
      am[i] = {
        id: amenities[i].amenity_id,
        value: true,
        desc: amenities[i].desc
      }
    }

    for (let i = 0; i < amenities_types.length; i++) {

      amenities_types_filtered.push(amenities_types[i].desc)
    }


    for (let i = 0; i < amenities.length; i++) {
      amenities_types_stats[amenities_types_filtered.indexOf(amenities[i].desc)].value = true
    }
    this.setState({ amenities_types_stats: amenities_types_stats })
    this.setState({ loadedAmenties: am })
  }
  amenities_change = i => {
    var amenities_types_stats = this.state.amenities_types_stats;
    amenities_types_stats[i].value = !amenities_types_stats[i].value;
    this.setState({ amenities_types_stats: amenities_types_stats })
  }

  sortarray(array = []) {
    for (var i = 0; i < array.length; i++) {
      if (array[i + 1] == null || array[i] == '')
        break
      if (array[i] > array[i + 1]) {
        var temp = array[i]
        array[i] = array[i + 1]
        array[i + 1] = temp
      }
    }
  }
  submitForm = () => {
    var e = "";
    var amenities = [], amenities_del = [], amenities_to_delete = [];
    var ame = this.state.loadedAmenties
    var amenities_types_stats = this.state.amenities_types_stats;
    var flag = this.state.flag
    axios({
      method: "patch",
      url: "/properties/" + this.props.match.params.property_id,
      headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
      data: {
        name: this.state.name,
        floor: this.state.floor
      }
    }).then(res => {
      this.showalert("property_name", 1)
    }).catch(err => {
      return swal.fire({

        icon: 'error',
        title: 'error',
        text: 'something went wrong with property update',
        showConfirmButton: false,
        timer: 1500
      })
    })

    axios({
      method: "get",
      url: "/properties/" + this.props.match.params.property_id + "/amenities/"

    }).then(response => {
      e = response.data
      var checklist = []
      var count = 0
      for (var i = 0; i < ame.length; i++) {
        if (ame[i].value === true) {
          checklist[count] = ame[i].id
          count = count + 1
        }
      }
      for (let i = 0; i < amenities_types_stats.length; i++) {
        if (amenities_types_stats[i].value === true) {
          amenities.push(amenities_types_stats[i].id)
        }
        else amenities_del.push(amenities_types_stats[i].id)
      }
      this.sortarray(checklist)
      var filtered_amenties = []

      for (let i = 0; i < amenities.length; i++) {
        if (!checklist.includes(amenities[i])) {
          filtered_amenties.push(amenities[i])
        }
      }



      // console.log('filtered amenties ',filtered_amenties)
      for (let i = 0; i < amenities_del.length; i++) {
        for (let j = 0; j < e.length; j++) {
          if (amenities_del[i] == e[j].amenity_id) {
            amenities_to_delete.push(e[j].id)
          }
        }
      }
      if (amenities_to_delete.length !== 0) {
        for (var i = 0; i < amenities_to_delete.length; i++) {
          axios({
            url: '/amenities/' + amenities_to_delete[i] + '/',
            method: 'DELETE',
            headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
            //data : this.props.match.params.property_id
          }).then(response => {
            this.props.history.push('/properties/')
          }).catch(err => {

          })
        }
      }
      if (amenities.length !== 0) {
        axios({
          url: '/amenities/',
          method: 'post',
          headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
          data: {
            property_id: this.props.match.params.property_id,
            amenity_id: filtered_amenties
          }
        }).then(response => {
          this.props.history.push('/properties/')
        }).catch(err => {
          return swal.fire({
            icon: 'error',
            title: 'error',
            text: 'something went wrong with create amenities',
            showConfirmButton: false,
            timer: 1500
          })
        })
      }
    }
    )
  }
  handleChange = (e, input) => {

    this.setState({ [input]: e.target.value })
    var name = this.state.name
    var name2 = this.state.property_name

    if (name === name2) {
      this.setState({
        flag: false
      })
    } else {
      this.setState({
        flag: true
      })
    }
  }
  showalert = (input, flag) => {
    if (input === "property_name") {

    }
    this.props.history.push('/properties/')
  }
  handleChange2 = (e) => {
    this.setState({
      floor: e.target.value
    })
  }
  render() {
    const amenities_types_stats = this.state.amenities_types_stats;
    const amenities_types = this.state.amenities_types
    return (
      <div style={{ ...this.state.midDev, margin: 'auto' }}>
        <div style={{ gridColumn: 2 }}>
          <Grid container style={{ backgroundColor: '', width: '100%' }} fluid={true}>
            <Grid container item direction="column" style={{ backgroundColor: '' }}>
              <div style={{}}>
                <Grid item xl={6} lg={6} md={6} xs={11} sm={12} style={{ ...this.state.marginauto }}>
                  <span style={{ fontFamily: 'founders_bold', fontSize: 30, }}>Edit Property</span>
                </Grid>
                <Grid item xl={6} lg={6} md={6} xs={11} sm={12} style={{ backgroundColor: '', ...this.state.marginauto }} >
                  <FormControl style={{ width: '100%' }}  >
                    <InputGroup style={{ borderColor: "lightgrey" }}>
                      <TextField
                        id='property-name'
                        label={<span style={{ fontFamily: 'founders', fontSize: '20px' }}>Property Name</span>}
                        value={this.state.name}
                        onChange={(e) => this.handleChange(e, 'name')}
                        style={{ width: '100%' }}
                        error={this.state.error1}
                        placeholder='Enter your property name'
                        type='text'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin='normal'
                      />
                    </InputGroup>
                  </FormControl>
                </Grid>
                <Grid item xl={6} lg={6} md={6} xs={11} sm={12} style={{ ...this.state.marginauto }}>
                  <FormControl style={{ width: '100%' }}  >
                    <InputGroup style={{ borderColor: "lightgrey" }}>
                      <TextField
                        id="demo-simple-select-outlined"
                        label={<p style={{ fontFamily: 'founders', fontSize: '20px' }}>City</p>}
                        value={this.state.city_name}
                        onChange={(e) => this.handleChange(e, 'city_name')}
                        disabled
                        style={{ width: '100%', }}
                        error={this.state.error1}
                        placeholder='Enter your property city'
                        type='text'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin='normal'
                      />
                    </InputGroup>
                  </FormControl>
                </Grid>
                <Grid item xl={6} lg={6} md={6} xs={11} sm={12} style={{ ...this.state.marginauto }}>
                  <FormControl style={{ width: '100%' }}  >
                    <InputGroup style={{ borderColor: "lightgrey" }}>
                      <TextField
                        id='floor-number'
                        label={<p style={{ fontFamily: 'founders', fontSize: '20px' }}>Floor</p>}
                        value={this.state.floor}
                        onChange={(e) => this.handleChange2(e)}
                        style={{ width: '100%' }}
                        type='text'
                        placeholder='Enter Floor'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin='normal'
                      />
                    </InputGroup>
                  </FormControl>
                </Grid>

                <Grid item xl={6} lg={6} md={6} xs={11} sm={12} style={{ ...this.state.marginauto }}>
                  <FormControl style={{ width: '100%' }}  >
                    <InputGroup style={{ borderColor: "lightgrey" }}>
                      <TextField
                        id='phoneNumber'
                        label={<p style={{ fontFamily: 'founders', fontSize: '20px' }}>Phone Number</p>}
                        value={this.state.prop_contact_num}
                        disabled
                        style={{ width: '100%' }}
                        type='text'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin='normal'
                      />
                    </InputGroup>
                  </FormControl>
                </Grid>


                <Grid item xl={6} lg={6} md={6} xs={11} sm={12} style={{ ...this.state.marginauto }} >
                  <FormControl component="fieldset" style={{}} >
                    <FormLabel component="legend" style={{}} > <span style={{}}>Amenities</span></FormLabel>
                    <FormGroup style={{}}>
                      {amenities_types_stats.length > 0 &&
                        <FormControlLabel
                          key={1}
                          control={<Checkbox
                            // checked={gilad} 
                            // onChange={this.props.handleChange('amenities')} 
                            onChange={(e) => this.amenities_change(0)}
                            style={{ color: '#39354E' }}
                            // checked={this.state.amen_test}
                            // checked={this.state.amen_states[i]}
                            // defaultChecked = {this.state.amen_states[i]}
                            checked={amenities_types_stats[0].value}
                            // id={amenities_types[i].desc}
                            id={'amen_' + amenities_types[0].id}
                            value={amenities_types[0].id} />}
                          label={amenities_types[0].desc}
                        />
                      }
                    </FormGroup>
                  </FormControl>
                </Grid>
              </div>
            </Grid>


            <Grid itemxl={6} lg={6} md={6} xs={11} sm={12} style={{ ...this.state.marginauto }} >
              <Button
                variant='contained'
                label='Submit Changes'
                color='primary'
                size='large'
                style={{  backgroundColor: 'white', color: '#39354E', ...this.state.btn_width, height: 44, fontSize: 15, marginTop: '6px',borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}
                onClick={this.submitForm}
                className='submit_btn'
              >
                Submit Changes
            </Button>

            </Grid>
          </Grid>

        </div>
      </div>
    )

  }
}



