import React from 'react';
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
    return (
        <div className='privacy-wrapper'>
            <h1> Privacy Policy Preview</h1>

            <p> Engage with us in other related ways ― including any sales, marketing, or events
                In this privacy notice, if we refer to:</p>

            <p> "Website," we are referring to any website of ours that references or links to this policy
                "App," we are referring to any application of ours that references or links to this policy, including any listed above</p>
            <p>  "Services," we are referring to our Website, App, and other related services, including any sales, marketing, or events</p>
            <p> The purpose of this privacy notice is to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.</p>

            <p> Please read this privacy notice carefully, as it will help you understand what we do with the information that we collect.</p>

            <h3> TABLE OF CONTENTS</h3>
            <ul>
                <li>WHAT INFORMATION DO WE COLLECT?</li>
                <li>HOW DO WE USE YOUR INFORMATION?</li>
                <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
                <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
                <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
                <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
                <li> DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
                <li> DO WE MAKE UPDATES TO THIS NOTICE?</li>
                <li> HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
                <li> HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</li>
            </ul>
            <h4>  1. WHAT INFORMATION DO WE COLLECT?</h4>
            <h5>Personal information you disclose to us</h5>

            <p> In Short:  We collect personal information that you provide to us.</p>

            <p> We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services (such as by posting messages in our online forums or entering competitions, contests or giveaways) or otherwise when you contact us.</p>

            <p> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect may include the following:</p>

            <p> Personal Information Provided by You. We collect names; phone numbers; email addresses; job titles; passwords; and other similar information.</p>

            <p> Payment Data. We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Payfort. You may find their privacy notice link(s) here: https://m.media-amazon.com/images/G/01/APS/website/Payfort_Privacy_policy.pdf.
                All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</p>

            <h5> Information automatically collected</h5>

            <p> In Short:  Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</p>

            <p> We automatically collect certain information when you visit, use or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>

            <h5> The information we collect includes:</h5>

            <p> Log and Usage Data. Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).</p>
            <h5> Information collected through our App</h5>

            <p> In Short:  We collect information regarding your geolocation, mobile device, push notifications, when you use our App.</p>

            <p> If you use our App, we also collect the following information:</p>

            <p>Geolocation Information. We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using our App, to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.
                Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device's camera, and other features. If you wish to change our access or permissions, you may do so in your device's settings.
                Mobile Device Data. We automatically collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model, Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our App, we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID and information about the features of our App you accessed.
                Push Notifications. We may request to send you push notifications regarding your account or certain features of the App. If you wish to opt-out from receiving these types of communications, you may turn them off in your device's settings.
                This information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.</p>

            <h4> 2. HOW DO WE USE YOUR INFORMATION?</h4>

            <p>In Short:  We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</p>

            <p> We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</p>

            <h4>  We use the information we collect or receive:</h4>
            <p> To send you marketing and promotional communications. We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. For example, when expressing an interest in obtaining information about us or our Services, subscribing to marketing or otherwise contacting us, we will collect personal information from you. You can opt-out of our marketing emails at any time (see the "WHAT ARE YOUR PRIVACY RIGHTS?" below).
                Deliver targeted advertising to you. We may use your information to develop and display personalized content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.</p>
            <h4> 3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h4>

            <p> In Short:  We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>

            <h4>  We may process or share your data that we hold based on the following legal basis:</h4>

            <p> Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.
                Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.
                Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
                Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
                Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
                More specifically, we may need to process your data or share your personal information in the following situations:</p>

            <p> Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>
            <p> Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third-party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Services, which will enable them to collect data on our behalf about how you interact with our Services over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content, pages or features, and better understand online activity. Unless described in this notice, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.</p>
            <p>  Business Partners. We may share your information with our business partners to offer you certain products, services or promotions.
                Other Users. When you share personal information (for example, by posting comments, contributions or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.</p>
            <h4> 4. WHO WILL YOUR INFORMATION BE SHARED WITH?  </h4>

            <p> In Short:  We only share information with the following third parties.</p>

            <p> We only share and disclose your information with the following third parties. If we have processed your data based on your consent and you wish to revoke your consent, please contact us using the contact details provided in the section below titled "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?".</p>
            <p> Advertising, Direct Marketing, and Lead Generation
                Google AdSense and Facebook Audience Network</p>

            <p> Web and Mobile Analytics
                Google Analytics for Firebase, Facebook Ads conversion tracking and Adjust</p>

            <h4> 5. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>

            <p>  In Short:  We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>

            <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>

            <p> When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>

            <h4>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>

            <p> In Short:  We aim to protect your personal information through a system of organizational and technical security measures.</p>

            <p> We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>

            <h4> 7. DO WE COLLECT INFORMATION FROM MINORS?</h4>

            <p> In Short:  We do not knowingly collect data from or market to children under 18 years of age.</p>

            <p> We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at info@hads.sa.</p>

            <h4>8. WHAT ARE YOUR PRIVACY RIGHTS?</h4>

            <p> In Short:  You may review, change, or terminate your account at any time.</p>



            <p>  If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</p>
            <p>  If you are a resident in Switzerland, the contact details for the data protection authorities are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.</p>
            <h5> Account Information</h5>

            <p> If you would at any time like to review or change the information in your account or terminate your account, you can:</p>

            <p>  Contact us using the contact information provided.</p>
            <p> Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.</p>

            <p> Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list — however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:</p>

            <p> Contact us using the contact information provided.</p>
            <h4> 9. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>

            <p> Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice. </p>

            <h4>   10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4>

            <p>In Short:  Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</p>

            <p>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>

            <p>  If you are under 18 years of age, reside in California, and have a registered account with a Service, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).</p>

            <h4>11. DO WE MAKE UPDATES TO THIS NOTICE?  </h4>

            <p> In Short:  Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>

            <p> We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>

            <h4> 12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?  </h4>

            <p>  If you have questions or comments about this notice, you may email us at info@hads.sa or by post to:

                hads

                6057 Al Ulaya - Al Muruj Dist.

                Riyadh, Riyadh 12281

                Saudia Arabia</p>

            <h4> 13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?   </h4>

            <p> Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form by clicking here.</p>
        </div>
    );
}

export default PrivacyPolicy;