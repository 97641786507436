import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import InfiniteCalendar, {
  Calendar,
  withRange
} from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';
import axios from '../../axios';
import { API_URL } from "../../config";
import "./SpacePayments.css";


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  rangeWrapper: {
    marginBottom: "10px",
    fontSize: "20px",
  },
  dateChip: {
    padding: "10px 35px",
    background: "#ccc",
    borderRadius: "60px"
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose} className={props.classes.closeButton}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const columns = [
  { field: 'id', headerName: 'ID', width: 90, editable: false },
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
    editable: false,
  },
  {
    field: 'no_of_rents',
    headerName: 'No of Rents',
    width: 150,
    editable: false,
  },
  {
    field: 'total_money_earned',
    headerName: 'Total Money Earned',
    type: 'number',
    width: 300,
    editable: false,
  },

];


const mql = window.matchMedia(`(min-width: 30em)`);
const SpacePayment = (props) => {
  const [mainDiv, setMainDiv] = useState({});
  const CalendarWithRange = withRange(Calendar);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('month'));
  const [calendarData, setCalendarData] = useState(null);
  const [data, setData] = useState([
    // {
    //   id: 1,
    //   name: "Name",
    //   no_of_rents: "No of Rents",
    //   total_money_earned: "Total Money Earned",
    // },
    // {
    //   id: 2,
    //   name: "Name",
    //   no_of_rents: "No of Rents",
    //   total_money_earned: "Total Money Earned",
    // },
    // {
    //   id: 1,
    //   name: "Name",
    //   no_of_rents: "No of Rents",
    //   total_money_earned: "Total Money Earned",
    // },
  ]);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    if (calendarData) {
      setStartDate(moment(calendarData.start));
      setEndDate(moment(calendarData.end));
    }
    setOpen(false);
  };


  useEffect(() => {
    if (mql.matches) {
      setMainDiv({
        display: 'grid',
        gridTemplateColumns: '22rem',
        marginTop: '150px'
      });
    } else {
      setMainDiv({
        marginTop: '150px'
      });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "get",
      url: `${API_URL}/get_spaces_rents_information/?start-date=${startDate.format()}&end-date=${endDate.format()}`,
      headers: {
        Authorization: `JWT  ${localStorage.getItem('token')}`
      }
    }).then(response => {
      setData(response.data);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    })
  }, [startDate, endDate]);

  return (
    <div style={{ ...mainDiv, margin: 'auto' }}>
      <div style={{ gridColumn: 2 }}>
        <Grid container justify="center" style={{ backgroundColor: '' }} direction="column"  >
          <div className={props.classes.rangeWrapper}>
            <span className={props.classes.dateChip} >{startDate.format("MMMM Do, YYYY")}</span> - <span className={props.classes.dateChip}>{endDate.format("MMMM Do, YYYY")}</span> <br /><br />
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
              Select Custom Range
            </Button>
            <br />
          </div>
          <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="md">
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Select Range
              </DialogTitle>
              <DialogContent dividers>
                <InfiniteCalendar
                  width={800}
                  height={300}
                  Component={CalendarWithRange}
                  selected={{
                    start: startDate,
                    end: endDate,
                  }}
                  locale={{
                    headerFormat: 'MMM Do, YYYY',
                  }}
                  onSelect={(event) => {
                    if (event.eventType === 3) {
                      setStartDate(moment(event?.start));
                      setEndDate(moment(event?.end));
                      setCalendarData({
                        start: event?.start,
                        end: event?.end,
                      });
                    }
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                  Save changes
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">ID</TableCell>
                  <TableCell align="right">Name</TableCell>
                  <TableCell align="right">No of Rents</TableCell>
                  <TableCell align="right">Total Money Earned</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell align="right">{row.id}</TableCell>
                    <TableCell align="right">{row.name}</TableCell>
                    <TableCell align="right">{row.no_of_rents}</TableCell>
                    <TableCell align="right">{row.total_money_earned}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </div>
    </div>
  );
}

export default withStyles(styles)(SpacePayment);