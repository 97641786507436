import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import React from 'react';
import { Button } from 'react-bootstrap';
import swal from 'sweetalert2';
import axios from '../../axios';
import { API_URL } from "../../config";
import Map from '../MapComponent/Map';

const mql = window.matchMedia(`(min-width: 50em)`)
const mql2 = window.matchMedia(`(min-width:286px)`)
class PropertyForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      properties: [],
      amen_states: [false, false, false, false],
      amen_loaded: false,
      amenities_types: [],
      amenities_preview: [],
      amenities_types_stats: [],
      propertyName: '',
      amen_test: false,
      flag: false,
      check: false,
      check2: false,
      firstButton: true,
      secondButton: true,
      cancel: true,
      index: '',
      labelWidth: 0,
      minDev: {
        display: 'grid',
        gridTemplateColumns: '250px auto auto',
      },
      checkResponse: false,
      CreateNewProperty: false,
      textwidth: {},
      marginTop: 0,
      buttonWidth: {},
      lastmarging: {},
      codeNumber: 0,
      phoneNumber: 0,
      lat: 24.713552,
      lng: 46.675297,
      markerPosition: null,
      get_country: [],
      get_cities: [],
      get_city: [],
      get_cities1: [],
      get_dial_code: [],
      get_cities_check: false,
      showAlert1: false,
      showAlert2: false,
      showAlert3: false,
      showAlert4: false,
      showAlert5: false,
      showAlert6: false,
      showAlert7: false,
      count: 0,
      GridContainer: {},
      gridColumn: {},
      property_name_flag: false,
      country_flag: false,
      city_flag: false,
      floor_flag: false

    }
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this)
    this.setState({
      lat: this.props.lat,
      lng: this.props.lng
    })
  }
  // Go to the next form
  formContinue = e => {
    this.getLenghOfPhone()
    // console.log('property', this.props.propertyInfo.showProperty2)
    // console.log('name', this.props.propertyInfo.propertyName)
    // console.log('floor', this.props.propertyInfo.propertyName)
    if (this.props.propertyInfo.showProperty2 === true) {
      if (this.props.propertyInfo.propertyName === '' || this.props.propertyInfo.propertyName === null) {
        return this.setState({
          property_name_flag: true
        })


      }
      else if (this.props.propertyInfo.country === '') {
        return this.setState({
          property_name_flag: false,
          country_flag: true
        })

      } else if (this.props.propertyInfo.city === '') {
        return this.setState({
          property_name_flag: false,
          country_flag: false,
          city_flag: true
        });

      }
      if (this.props.propertyInfo.propertyFloor === '' || this.props.propertyInfo.propertyFloor === null) {
        return this.setState({
          property_name_flag: false,
          country_flag: false,
          city_flag: false,
          floor_flag: true
        })


      }
      if (this.props.propertyInfo.propertyFloor != parseInt(this.props.propertyInfo.propertyFloor, 10)) {
        this.setState({
          floor_flag: true
        })
        return swal.fire({
          icon: 'error',
          title: 'error',
          text: 'floor input value should be integer',
          showConfirmButton: false,
          timer: 1500
        })
      }
      else
        this.setState({
          floor_flag: false

        })

        if (this.props.propertyInfo.propertyFloor < 0 ) {
          this.setState({
            floor_flag: true
          })
          return swal.fire({
            icon: 'error',
            title: 'error',
            text: 'floor input value should be positive',
            showConfirmButton: false,
            timer: 1500
          })
        }
        else
          this.setState({
            floor_flag: false
  
          })
    }
    if (this.props.propertyInfo.property_id === '') {
      if (this.props.propertyInfo.propertyName === '' || this.props.propertyInfo.propertyName === null) {
        return this.setState({
          property_name_flag: true
        })

      }
      else if (this.props.propertyInfo.country === '') {
        return this.setState({
          property_name_flag: false,
          floor_flag: false,
          country_flag: true
        })
      }
      else if (this.props.propertyInfo.city === '') {
        return this.setState({
          property_name_flag: false,
          floor_flag: false,
          country_flag: false,
          city_flag: true

        })
      }
      else if (this.props.propertyInfo.propertyFloor === '' || this.props.propertyInfo.propertyName === null) {
        return this.setState({
          property_name_flag: false,
          floor_flag: true
        })
      }
      if (this.props.propertyInfo.propertyFloor != parseInt(this.props.propertyInfo.propertyFloor, 10)) {
        this.setState({
          floor_flag: true
        })
        return swal.fire({
          icon: 'error',
          title: 'error',
          text: 'floor input should be integer',
          showConfirmButton: false,
          timer: 1500
        })
      }

      else
        this.setState({
          floor_flag: false
        })
    }
    this.setState({
      property_name_flag: false,
      country_flag: false,
      city_flag: false,
      floor_flag: false
    })
    e.preventDefault();
    this.props.nextStep();
  };

  componentDidMount() {
    if (mql2.matches) {
      this.setState({
        gridColumn: {
          gridColumn: 2
        }
      })
    } else {
      this.setState({
        gridColumn: {
          gridColumn: 1
        }
      })
    }
    if (mql.matches) {
      this.setState({
        minDev: {
          display: 'grid',
          gridTemplateColumns: '24rem',
          marginTop: 55,
        },

        buttonWidth: {
          width: '40%'
        },
        lastmarging: {

        },
        GridContainer: {
          maxWidth: '80%',
        }
      })

    } else {
      this.setState({
        minDev: {
          marginTop: 180,
        },

        buttonWidth: {
          width: '100%'
        },
        lastmarging: {

        },
        GridContainer: {
          width: '100%'
        }
      })
    }
    localStorage.setItem('step', 'Property information')
    this.getEvent();
    // console.log('.........', this.state.properties.length)

  }
  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
    mql2.addListener(this.mediaQueryChanged)
  }
  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
    mql2.removeListener(this.mediaQueryChanged)
  }
  mediaQueryChanged() {
    if (mql2.matches) {
      this.setState({
        gridColumn: {
          gridColumn: 2
        }
      })
    } else {
      this.setState({
        gridColumn: {
          gridColumn: 1
        }
      })
    }
    if (mql.matches) {
      this.setState({
        minDev: {
          display: 'grid',
          gridTemplateColumns: '24rem',
          marginTop: 55,
        },
        buttonWidth: {
          width: '70%'
        }, lastmarging: {

        },
        GridContainer: {
          maxWidth: '80%',
        }
      })
    } else {
      this.setState({
        minDev: {
          marginTop: 180
        },

        buttonWidth: {
          width: '100%'
        },
        lastmarging: {

        },
        GridContainer: {
          width: '100%'
        }
      })
    }
  }

  componentDidUpdate() {
    if (this.props.propertyInfo.amenities_stats.length !== 0 && this.state.amen_loaded === false) {
      var amen_states1 = [false, false, false, false]
      // if(amen.desc === "Garden")
      var amen = this.props.propertyInfo.amenities_stats
      for (let i = 0; i < this.props.propertyInfo.amenities.length; i++) {
        if (amen[i] === 'Gym' || amen[i] === 'Parking' || amen[i] === 'Garden' || amen[i] === 'Wifi')
          amen_states1[i] = true;
      }
      this.setState({ amen_states: amen_states1, amen_loaded: true })
      // console.log("amen_states: ", amen_states1)
      // console.log(this.state.amen_states, 'loaded!!')
    } else return ''

  }

  getLenghOfPhone = () => {
    let phoneNumber = this.props.propertyInfo.PhoneNumber
    let count = 0
    if (phoneNumber >= 1)++count;

    while (phoneNumber / 10 >= 1) {
      phoneNumber /= 10;
      ++count;
    }

    this.setState({ count: count })
  }
  getEvent = async () => {
    await axios({
      method: "GET",
      url: `${API_URL}/users/` + localStorage.getItem('id') + '/properties/',
      headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') }
    })
      .then(res => {
        this.setState({
          flag: false
        })

        if (res.data.length === 0) {
          this.setState({
            checkResponse: true
          })

        } else {
          this.setState({
            checkResponse: false,
            CreateNewProperty: false,
          })
        }

        this.setState({ properties: res.data })
        // console.log('........', this.state.properties)
      })
      .catch(e => {
        // console.log(e.message)

      });
    await axios({
      method: 'GET',
      url: '/countries/',
    }).then(response => {
      this.setState({
        get_cities: response.data
      })
      var get_country = [{}]
      for (var i = 0; i < this.state.get_cities.length; i++) {
        get_country[i] = {
          'id': this.state.get_cities[i].id,
          'name': this.state.get_cities[i].name
        }
      }
      this.setState({
        get_country: get_country
      })
      // console.log(get_country)

    })

    let amenities = await axios.get("/amenities_types/"
    )
      .then(res => {
        var am = []

        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].desc === "Wifi") {
            am[0] = res.data[i]
          }
        }

        this.setState({ amenities_types: am })

        var amenities_types = am

        // console.log(res.data)
        // console.log('this.state.amenities_types: ', this.state.amenities_types)
        var amenities_types_stats = [];
        var counter = 0
        // console.log(amenities_types)
        for (let i = 0; i < amenities_types.length; i++) {
          if (amenities_types[i].desc === "Wifi") {
            amenities_types_stats[counter] = { id: amenities_types[i].id, value: false }
          }
        }
        // console.log(amenities_types_stats)
        this.setState({ amenities_types_stats: amenities_types_stats })
        // amenities_preview
        var amenities_preview = [];
        for (let i = 0; i < amenities_types.length; i++) {
          amenities_preview[i] =
            <FormControlLabel
              control={<Checkbox

                onChange={this.amenities_change}
                checked={this.state.amen_test}

                id={'amen_' + amenities_types[i].id}
                value={amenities_types[i].id} />}
              label={amenities_types[i].desc}
            />
        }
        this.setState({ amenities_preview: amenities_preview })
        // console.log("amenities_preview: ", amenities_preview)
      })
      .catch(e => { });
  }

  amenities_change = e => {
    // console.log(this.state.amenities_types_stats)
   
    var val = parseInt(e.target.value);
    // console.log('val: ', val)
    var toggle = document.getElementById('amen_' + val).checked;
    // console.log('....................', toggle)
    this.props.amenities_change('amenities', e.target.value, toggle)
    // console.log('before: amen_test: ', this.state.amen_test)
    this.setState({ amen_test: true })
    // console.log('after: amen_test: ', this.state.amen_test)
    // console.log('amenities_types_stats: ', this.state.amenities_types_stats)


    // console.log('!document.getElementById(amen_+ val).checked: ', document.getElementById('amen_' + val).checked)
    var amenities_types_stats = this.state.amenities_types_stats
    // console.log(this.state.amenities_types_stats)
    for (let i = 0; i < amenities_types_stats.length; i++) {
      if (amenities_types_stats[i].id === val) {
        amenities_types_stats[i].value = toggle;
      }
    }
    this.setState({ amenities_types_stats: amenities_types_stats })

  }
  handlefirstButton = () => {
    this.setState({
      check: true,
      check2: false,
      firstButton: false,
      secondButton: false
    })
  }
  handlesecondButton = () => {
    this.setState({
      check: false,
      check2: true,
      firstButton: false,
      secondButton: false
    })
  }
  handleCancel = () => {
    this.setState({
      cancel: false
    })
    return (
      <div>
        {this.props.initilizealltoNone,
          this.setState({
            check: false,
            check2: false,
            firstButton: true,
            secondButton: true
          })}

      </div>
    )

  }

  createNewProperty = () => {
    // console.log('truetruetrue')
    this.setState({
      CreateNewProperty: true,
      marginTop: 20

    })
  }

  printLatLng = (lat, lng, markerPosition) => {

    this.setState({
      lat: lat,
      lng: lng
    })
    // console.log(this.state.lat)
    // console.log(this.state.lng)
    this.props.getLatLng(this.state.lat, this.state.lng, markerPosition)


  }

  render() {
    
    const ShowProperty =
      <div>
        {this.props.propertyInfo.property_id !== '' &&
          <div>
            <h4>PropertyName: <p></p>{this.state.propertyName}</h4>
          </div>
        }
      </div>
    const properties_list = this.state.properties
    const countries = this.state.get_country
    const dial_code = this.state.get_dial_code
    const cities = this.props.propertyInfo.cities
    const amenities_types = this.state.amenities_types;
    const amenities_types_stats = this.state.amenities_types_stats;
    const Ame = () => {
      for (var i = 0; i < amenities_types_stats.length; i++) {
        return <FormControlLabel
          key={1}
          style={{}}
          control={
            <Checkbox
              onChange={this.amenities_change}
              checked={amenities_types_stats[i].value}
              id={'amen_' + amenities_types[i].id}
              style={{ color: '#08D888' }}
              value={amenities_types[i].id} />
          }
          label={amenities_types[i].desc}


        />
      }

    }
    return (
      <div style={{ ...this.state.minDev, margin: 'auto', backgroundColor: '' }}>
        <div style={{ ...this.state.gridColumn }}>

          <Grid
            container
            direction='column'


            style={{}}
          >
            <Grid container item justify="flex-start" >
              
              {this.state.checkResponse === false &&
                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} >
                  <FormControl style={{ width: '100%' }}   >
                    <InputLabel ref={this.props.propertyInfo.property_id} style={{ marginBottom: '20px', marginLeft: '10px', width: '100%' }} htmlFor="outlined-age-native-simple">
                      Choose Property
                      </InputLabel>
                    <Select
                      variant="outlined"
                      value={this.props.propertyInfo.property_id}
                      style={{ width: '100%' }}
                      onClick={this.handleCountry}
                      onChange={this.props.handleChange('property_id')}
                      labelWidth={120}
                    >
                      {properties_list.map(function (name, index) {
                        return <MenuItem key={index} id="SelectedProperty" value={properties_list[index].id}>{properties_list[index].name}</MenuItem>;
                      })}
                      <MenuItem key={-1} id="createproperty" value={-1} style={{}} onClick={this.createNewProperty}>-- Create a property --</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              }
              {this.state.checkResponse === true &&
                <Grid item xl={7} lg={7} md={9} sm={12} xs={12}>
                  <p style={{ marginTop: 30, fontSize: 20, fontFamily: 'founders' }}>
                    Create New Property
              </p>
                </Grid>
              }
              {/* {this.state.checkResponse === true  &&  */}
              {this.props.propertyInfo.showProperty2 === true &&
                <Grid item xl={7} lg={7} md={9} sm={12} xs={12}>
                  <p style={{ marginTop: 30, fontSize: 20, fontFamily: 'founders' }}>
                    Create New Property
              </p>
                </Grid>
              }
              {this.props.propertyInfo.showProperty2 === true &&
                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{ ...this.state.marginTop }}>
                  <TextField
                    id='property-name'
                    value={this.props.propertyInfo.propertyName}
                    onChange={this.props.handleChange('propertyName')}
                    style={{ width: '100%' }}
                    error={this.state.property_name_flag}
                    placeholder='Enter your property name'

                    label='Property Name'
                    margin='normal'
                    variant='outlined'
                  />

                </Grid>
              }
              {this.props.propertyInfo.showProperty2 === true &&

                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{ ...this.state.marginTop }}>
                  <FormControl style={{ marginTop: '10px', marginBottom: 15, width: '100%' }}  >

                    <InputLabel ref={this.props.propertyInfo.country} style={{ marginLeft: '15px', marginBottom: '20px' }} >
                      Choose country
                </InputLabel>
                    <Select
                      ref={this.props.propertyInfo.country}
                      variant="outlined"
                      value={this.props.propertyInfo.country}
                      style={{ width: '100%' }}
                      error={this.state.country_flag}
                      onChange={this.props.handleChange('country_id', this.state.get_cities)}
                      labelWidth={115}
                    >
                      {countries.map(function (name, index) {
                        return <MenuItem key={index} id="select_country" value={countries[index].id}>{countries[index].name}</MenuItem>;
                      })}

                    </Select>
                  </FormControl>
                </Grid>
              }

              {this.props.propertyInfo.showProperty2 === true &&
                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{ ...this.state.marginTop, }}>
                  <FormControl style={{ marginTop: '5px', marginBottom: 15, width: '100%' }}   >
                    <InputLabel ref={this.props.propertyInfo.city} style={{ marginLeft: '15px', marginBottom: '20px' }} >
                      Choose city
                  </InputLabel>
                    <Select
                      ref={this.props.propertyInfo.city}
                      variant="outlined"
                      value={this.props.propertyInfo.city}
                      style={{ width: '100%' }}
                      onChange={this.props.handleChange('city_id', cities)}
                      error={this.state.city_flag}
                      //onClick={this.handleCity}
                      labelWidth={90}
                    >
                      {cities.map(function (name, index) {
                        return <MenuItem key={index} id="select_city" value={cities[index].id}>{cities[index].name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              }

              {this.props.propertyInfo.showProperty2 === true && this.props.propertyInfo.lat !== 0 &&

                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{ ...this.state.marginTop, }}>
                  <Map
                    lat={this.props.propertyInfo.lat}
                    lng={this.props.propertyInfo.lng}
                    moveMap={this.props.propertyInfo.moveMap}
                    markerPosition={this.props.markerPosition}
                    zoom={this.props.propertyInfo.zoom}
                    printLatLng={this.printLatLng} />

                  <p style={{ marginTop: '5px', fontSize: '16px', fontFamily: 'founders_bold' }}>  <RoomOutlinedIcon style={{ color: '08D888', fontSize: '25px' }} /> Drag marker and Drop it</p>

                </Grid>
              }
              {this.props.propertyInfo.showProperty2 === true &&
                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{ ...this.state.marginTop, }}>
                  <TextField

                    id='floor-number'
                    label={<p style={{ fontSize: 25, fontFamily: 'founders' }}>Floor Number </p>}
                    value={this.props.propertyInfo.propertyFloor}
                    onChange={this.props.handleChange('propertyFloor')}
                    error={this.state.floor_flag}
                    style={{ width: '100%' }}
                    type="number"
                    InputLabelProps={{
                      shrink: true
                    }}

                    margin='normal'
                  />
                </Grid>
              }
              {this.props.propertyInfo.showProperty2 === true &&

                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{ ...this.state.marginTop, backgroundColor: '', }}>

                  <Grid container item direction="row" spacing={2} style={{ backgroundColor: '', width: '100%' }}>
                    <Grid item style={{ backgroundColor: '', width: '100%' }} lg={2} sm={2} xs={4} md={2} xl={2}>
                      <TextField

                        id='dial_code'
                        label={<p style={{ fontSize: 25, fontFamily: 'founders' }}>Code </p>}
                        //label={<p style={{ fontSize: 25, fontFamily: 'founders' }}>Phone Number </p>}
                        value={this.props.propertyInfo.dial_code}
                        //onChange={this.props.handleChange('phoneNumber')}

                        style={{ width: '100%' }}

                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin='normal'
                      />
                    </Grid>



                    <Grid item lg={10} sm={8} xs={8} md={10} xl={10} style={{ width: '100%' }}>
                      <TextField
                        id='phone-number'

                        label={<p style={{ fontSize: 25, fontFamily: 'founders' }}>Phone Number </p>}
                        value={this.props.propertyInfo.phoneNumber}
                        onChange={this.props.handleChange('phoneNumber')}
                        style={{ width: '100%' }}
                        type="number"
                        // inputProps={{
                        //   maxLength: 9
                        // }}
                        error={this.props.propertyInfo.PhoneError}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin='normal'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              }

              {/* {this.state.CreateNewProperty === true  && */}
              {this.props.propertyInfo.showProperty2 === true &&
                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{ marginTop: 25, ...this.state.marginTop }} >
                  {this.props.propertyInfo.request_type === "post" &&
                    <FormControl component="fieldset"  >
                      <FormLabel component="legend"><span style={{ fontSize: 20, fontFamily: 'founders' }}>Amenities</span> </FormLabel>
                      <FormGroup style={{ display: 'inline', width: '100%' }}>
                        {/* {this.state.amenities_preview} */}
                        {amenities_types_stats.length > 0 &&
                       
                       <FormControlLabel
                         key={1}
                         style={{}}
                         control={
                           <Checkbox
                             onChange={this.amenities_change}
                             checked={amenities_types_stats[0].value}
                             
                             id={'amen_' + amenities_types[0].id}
                             style={{ color: 'rgb(57, 53, 78)' }}
                             value={amenities_types[0].id} />
                         }
                         label={amenities_types[0].desc}
                       />
                     }

                      </FormGroup>
                    </FormControl>
                  }
                </Grid>
              }

              {this.state.checkResponse === true &&

                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{}}>
                  <TextField
                    id='property-name'
                    value={this.props.propertyInfo.propertyName}
                    onChange={this.props.handleChange('propertyName')}
                    style={{ width: '100%' }}
                    error={this.state.property_name_flag}
                    placeholder='Enter your property name'
                    label='Property Name'
                    margin='normal'
                    variant='outlined'
                  />

                </Grid>
              }
              {this.state.checkResponse === true &&
                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{ ...this.state.marginTop, }}>
                  <FormControl style={{ marginTop: '10px', marginBottom: 15, width: '100%' }}  >

                    <InputLabel ref={this.props.propertyInfo.country} style={{ marginLeft: '15px', marginBottom: '20px' }} >
                      Choose country
                </InputLabel>
                    <Select
                      ref={this.props.propertyInfo.country}
                      variant="outlined"
                      value={this.props.propertyInfo.country}
                      error={this.state.country_flag}
                      style={{ width: '100%' }}
                      onChange={this.props.handleChange('country_id', this.state.get_cities)}
                      labelWidth={115}
                    >
                      {countries.map(function (name, index) {
                        return <MenuItem key={index} id="select_country" value={countries[index].id}>{countries[index].name}</MenuItem>;
                      })}

                    </Select>
                  </FormControl>
                </Grid>
              }
              {this.state.checkResponse === true &&




                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{ ...this.state.marginTop, }}>
                  <FormControl style={{ marginTop: '5px', marginBottom: 15, width: '100%' }}   >
                    <InputLabel ref={this.props.propertyInfo.city} style={{ marginLeft: '15px', marginBottom: '20px' }} >
                      Choose city
                  </InputLabel>
                    <Select
                      ref={this.props.propertyInfo.city}
                      variant="outlined"
                      value={this.props.propertyInfo.city}
                      style={{ width: '100%' }}
                      error={this.state.city_flag}
                      onChange={this.props.handleChange('city_id', cities)}
                      //onClick={this.handleCity}
                      labelWidth={90}
                    >
                      {cities.map(function (name, index) {
                        return <MenuItem key={index} id="select_city" value={cities[index].id}>{cities[index].name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              }
              {this.state.checkResponse === true && this.props.propertyInfo.lat !== 0 &&
                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{ ...this.state.marginTop, backgroundColor: '' }}>
                  <Map
                    lat={this.props.propertyInfo.lat}
                    lng={this.props.propertyInfo.lng}
                    moveMap={this.props.propertyInfo.moveMap}
                    markerPosition={this.state.markerPosition}
                    printLatLng={this.printLatLng}
                    zoom={this.props.propertyInfo.zoom}
                  />
                  <p style={{ marginTop: '5px', fontSize: '16px', fontFamily: 'founders_bold' }}>  <RoomOutlinedIcon style={{ color: '08D888', fontSize: '25px' }} /> Drag marker and Drop it</p>

                </Grid>
              }
              {this.state.checkResponse === true &&
                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{}}>
                  <TextField
                    id='floor-number'
                    label={<p style={{ fontSize: 25, fontFamily: 'founders' }}>Floor Number </p>}
                    value={this.props.propertyInfo.propertyFloor}
                    onChange={this.props.handleChange('propertyFloor')}
                    style={{ width: '100%' }}
                    type="number"
                    error={this.state.floor_flag}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='normal'
                  />
                </Grid>
              }
              {this.state.checkResponse === true &&
                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{ ...this.state.marginTop, }}>
                  <Grid container item direction="row" spacing={2} style={{ backgroundColor: '', width: '100%' }}>
                    <Grid item style={{ backgroundColor: '', width: '100%' }} lg={2} sm="2" xs={2} md={2} xl={2}>
                      <TextField

                        id='dial_code'
                        label={<p style={{ fontSize: 25, fontFamily: 'founders' }}>Code </p>}
                        //label={<p style={{ fontSize: 25, fontFamily: 'founders' }}>Phone Number </p>}
                        value={this.props.propertyInfo.dial_code}
                        //onChange={this.props.handleChange('phoneNumber')}

                        style={{ width: '100%' }}

                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin='normal'
                      />
                    </Grid>
                    <Grid item lg={10} sm={10} xs={10} md={10} xl={10} style={{ width: '100%' }}>
                      <TextField
                        id='phone-number'
                        label={<p style={{ fontSize: 25, fontFamily: 'founders' }}>Phone Number </p>}
                        value={this.props.propertyInfo.phoneNumber}
                        onChange={this.props.handleChange('phoneNumber')}
                        style={{ width: '100%' }}
                        type="number"
                        // inputProps={{
                        //   maxLength: 9
                        // }}
                        // error={this.props.propertyInfo.PhoneError}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin='normal'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              }

              {this.state.checkResponse === true &&
                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{ marginTop: 25 }} >
                  {this.props.propertyInfo.request_type === "post" &&
                    <FormControl component="fieldset"  >
                      <FormLabel component="legend"><span style={{ fontSize: 20, fontFamily: 'founders' }}>Amenities</span> </FormLabel>
                      <FormGroup style={{ display: 'inline', width: '100%' }}>
                        {/* {this.state.amenities_preview} */}
                        {/* {amenities_types_stats.map((index, name) => {
                          console.log(amenities_types_stats)
                          console.log(amenities_types)
                          return <FormControlLabel
                            key={1}
                            style={{}}
                            control={
                              <Checkbox

                                onChange={this.amenities_change}
                                checked={amenities_types_stats[0].value}
                                id={'amen_' + amenities_types[0].id}
                                style={{ color: '#08D888' }}
                                value={amenities_types[index].id} />
                            }
                            label={amenities_types[index].desc}
                          />
                        })} */}
                        {amenities_types_stats.length > 0 &&
                       
                          <FormControlLabel
                            key={1}
                            style={{}}
                            control={
                              <Checkbox
                                onChange={this.amenities_change}
                                checked={amenities_types_stats[0].value}
                                
                                id={'amen_' + amenities_types[0].id}
                                style={{ color: 'rgb(57, 53, 78)' }}
                                value={amenities_types[0].id} />
                            }
                            label={amenities_types[0].desc}
                          />
                        }
                      </FormGroup>
                    </FormControl>
                  }
                </Grid>
              }
            </Grid>
            <Grid container direction='row' justify="flex-start" style={{ marginTop: 20, }}>
              <Grid item xl={6} lg={6} md={9} sm={12} xs={12} style={{ backgroundColor: '' }}>
                <Button
                  label="Continue"
                  variant='contained'
                  size='large'
                  style={{ marginTop: '10%', backgroundColor: 'white', color: '#39354E', ...this.state.buttonWidth, height: 44, fontSize: 20, fontFamily: 'HelveticaNeue', marginBottom: '20%',borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}
                  onClick={this.formContinue}

                >
                  Continue
             </Button>
              </Grid>
            </Grid>

          </Grid>
        </div>
      </div>
    );
  }
}
export default PropertyForm

