import React, { Component } from 'react';

export default class NotFound extends Component {
  render() {
    return (
      <div>
        <h1 style={{ textAlign: 'center', marginTop: '100px' }}>Page not found</h1>
      </div>
    );
  }
}
