import React, { Component, PureComponent } from 'react';
import { Marker } from 'react-google-maps'
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps"
import { compose, withProps } from "recompose"

export default class Map extends Component {
	constructor(props) {
		super(props)
		this.state = {
			fields: {},
			map: null,
			mapDict: {},
			lat: 0,
			lng: 0,
			lat1:0,
			lng1:0,
			check:false
		}
		this.markerPosition = null;
		this.inputRef = React.createRef()
	}
	async componentDidMount() {
		var lat = 0 ,lng=0

		

		// console.log(this.props.propertyName)
	}
	
	// to prevent component form being rerendring.
	shouldComponentUpdate() {
		if(this.props.moveMap === false){
			return false
		}
		if (this.props.lat === this.state.lat && this.props.lng === this.state.lng)
			return false
		
		
		
		
		return true
	}
	componentDidUpdate() {
		// console.log(this.props.lat)
		// console.log(this.props.lng)
			

		
	}
	
	addMarker = (location, map) => {
		this.setState(prev => ({
			fields: {
				...prev.fields,
				location
			}
		}));
		map.panTo(location);
	}
	delayedShowMarker = () => {
		setTimeout(() => {
			this.setState({ isMarkerShown: true })
		}, 3000)
	}

	handleMarkerClick = () => {
		this.setState({ isMarkerShown: true })
		this.delayedShowMarker()
	}
	mapMoved() {
		// console.log('mapMoved')

		// console.log(JSON.stringify(this.state.map.getCenter()));

	}
	mapLocated(map) {
		// console.log('mapLocated', map)
		//   const mapp = map.props.defaultCenter
		if (this.state.map != null) return;
		this.setState({ map: map });
	}
	getLatLngBymoving = () => {
		var markerPosition = this.props.markerPosition
		// console.log(markerPosition)
		var lat = this.state.markerPosition.lat()
		var lng = this.state.markerPosition.lng()
		// console.log('markerPosition', this.state.markerPosition)
		
		this.setState({
			lat: lat,
			lng: lng
		})

		this.props.printLatLng(this.state.lat, this.state.lng,this.state.markerPosition)
	}
	getLatLngByclicking = () => {
		var lat = this.state.markerPosition.lat()
		var lng = this.state.markerPosition.lng()
		// console.log('markerPosition', this.state.markerPosition)
		this.setState({
			lat: lat,
			lng: lng
		})

		this.props.printLatLng(this.state.lat, this.state.lng)

	}

	render() {

		const MyMapComponent = compose(
			withProps({
				googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCJoRir4vSUFbV9TuA-c7mn24QWMV5Zq9w&v=3.exp&libraries=geometry,drawing,places",
				loadingElement: <div style={{ height: `100%` }} />,
				containerElement: <div style={{ height: `400px` }} />,
				mapElement: <div style={{ height: `100%`, width: '100%' }} />,
			}),
			withScriptjs,
			withGoogleMap
		)((props) =>
			<GoogleMap
				ref={this.mapLocated.bind(this)}
				onClick={(event) => {
					this.setState({ markerPosition: event.latLng })
				}
				}
				onDragEnd={this.mapMoved.bind(this)}
				defaultZoom={this.props.zoom}
				//defaultCenter={this.state.markerPosition?{lat:this.props.lat,lng:this.props.lng}:  { lat: 24.713511, lng: 46.675297 }}
				defaultCenter={{ lat: this.props.lat, lng: this.props.lng }}
				position={{ lat: this.props.lat, lng: this.props.lng }}
			>
				<Marker onDragEnd={(event => {
					this.setState({
						markerPosition: event.latLng
					})
					this.props.printLatLng(this.state.markerPosition.lat(),this.state.markerPosition.lng(),this.state.markerPosition)
					// console.log(this.state.markerPosition.lat())
					// console.log(this.state.markerPosition.lng())
					//this.getLatLngBymoving()
				})
				} draggable={true}
					onChange={(e) => this.props.handleMap(e)}
					position={{ lat: this.props.lat, lng: this.props.lng }}
					defaultCenter={{ lat: this.props.lat, lng: this.props.lng }} />
			</GoogleMap>
		)
		return (
			<div style={{ margin: 'auto', backgroundColor: '', }}>

				<MyMapComponent
					// google={this.props.google}
					// initialCenter={this.state.fields.location}
					// center={this.state.fields.location}
					// zoom={14}
					//onClick={(t, map, c) => this.addMarker(c.latLng, map)}
				>
					<Marker position={this.state.fields.location} />
				</MyMapComponent>
			</div>
		)
	}

}

