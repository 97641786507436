
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import axios from '../../axios';

//controll the image and change it then link it to the button
const mql = window.matchMedia(`(min-width: 50em)`)
const mql2 = window.matchMedia(`(min-width: 600px)`)
const acceptedFiles = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'

export default class Editing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: localStorage.getItem('first_name'),
            secondName: localStorage.getItem('last_name'),
            email: localStorage.getItem('email'),
            profile_image: "",
            disabled: true,
            error1: false,
            error2: false,
            minDev: {},
            PersonalInfo: {},
            textWidth: {},
            secondDivmar: {},
            thirdDivmar: {},
            buttonWidth: {},
            thumbnailH: 'fit-content',

            file: null,
            first: {},

            up_buttonClicked: false,
            profile: '',
            marginContentLeft: {},
            image: '',
            image2:'',
            Thumbnail: '',
            image_Change:false,
            url: ''

        }
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this)
    }
    componentDidMount() {
        this.mediaQueryChanged()
        localStorage.setItem('RegisterOrder', 'true4')
        this.getEvent()

    }

    getEvent = () => {
        // console.log('welcome to getEvent')
        axios({
            method: 'GET',
            url: '/users/' + localStorage.getItem('id') + '/',
            headers: {
                'Authorization': 'jwt ' + localStorage.getItem('token')
            }
        }).then(response => {
            var Thumbnail = ''
            // console.log(response.data.profile_image)
            if (response.data.profile_image !== '') {
                var x = response.data.profile_image
                this.setState({ url: response.data.profile_image })
                Thumbnail = <span><img key='0' id={'Did_thumbnail_'} style={{ width: '200px', height: '200px', borderRadius: '50%' }} src={x} alt='' /></span>
            }
            this.setState({
                Thumbnail: Thumbnail
            })

            if (response.data.profile_image === "") {
                this.setState({
                    image: ""
                })
            } else {

            }
        })
    }
    componentWillMount() {
        mql.addListener(this.mediaQueryChanged)

    }
    componentWillUnmount() {
        mql.removeListener(this.mediaQueryChanged)

    }
    mediaQueryChanged() {
        if (mql.matches) {
            this.setState({
                minDev: {
                    display: 'grid',
                    gridTemplateColumns: '22rem',
                    marginTop: 150
                },
                first: {
                },
                textWidth: {
                    width: '550px',

                },
                thirdDivmar: {
                    marginTop: 100,
                    marginLeft: '21%',
                    backgroundColor: 'red'
                },
                secondDivmar: {
                    marginLeft: '21%'
                },
                buttonWidth: {
                    width: 199
                },
                image: {
                    // marginLeft: '25px'
                }, image2: {
                    marginTop: 10,
                    marginRight: 227
                },
                marginContentLeft: {

                }
            })
        } else {
            this.setState({
                minDev: {
                    display: 'grid',
                    gridTemplateColumns: '2rem',
                    marginTop: 150,
                },
                first: {
                    marginLeft: '10%'
                },
                secondDivmar: {


                },
                textWidth:
                {
                    width: 300
                },
                buttonWidth: {
                    width: 129
                },
                thirdDivmar: {
                    marginTop: '70px',
                    width: '60%',
                    marginLeft: '18%'
                },
                marginContentLeft: {
                    marginLeft: '15%'
                }
            })
        }


    }


    async handleImage (e)  {
        this.setState({

            image:e.target.files[0],
            image_Change:true
        })

        // var preview = document.querySelector('#image');
        // var files = e.target.files[0]
        // var reader = new FileReader();
        // var percentage = 0.75;
        // var canvas = document.getElementById("canvas");
        // reader.addEventListener("load", function () {
        //     preview.src = reader.result;
        //     preview.onload = function () {
        //         var ctx = canvas.getContext("2d");
        //         canvas.height = canvas.width * (preview.height / preview.width);
        //         var oc = document.createElement('canvas'), octx = oc.getContext('2d');
        //         oc.width = preview.width * percentage;
        //         oc.height = preview.height * percentage;
        //         canvas.width = oc.width;
        //         canvas.height = oc.height;
        //         octx.drawImage(preview, 0, 0, oc.width, oc.height);
        //         octx.drawImage(oc, 0, 0, oc.width, oc.height);
        //         ctx.drawImage(oc, 0, 0, oc.width, oc.height, 0, 0, canvas.width, canvas.height);
        //     }
        // }, false);
        // if (files) {
        //     var r = reader.readAsDataURL(files);
        //     var c = canvas.toDataURL("image/jpeg", 0.7)
        //     const res = await fetch(c);
        //     const blob = await res.blob();
        //     const mainfile = new File([blob], files.name, { type: 'image/png' });
        //     this.setState({
        //         image:mainfile
        //     })
        //     console.log(mainfile)
        //     return mainfile
            // var arr = c.split(','),
            
            //     mime = arr[0].match(/:(.*?);/)[1],
            //     bstr = atob(arr[1]),
            //     n = bstr.length,
            //     u8arr = new Uint8Array(n);
            //     console.log(u8arr)
            // while (n--) {
            //     u8arr[n] = bstr.charCodeAt(n);
            // }
            // let mainfile = new File([u8arr], files.name, { type:mime });
            // // mainfile.lastModifiedDate = files.lastModifiedDate
            // console.log(mainfile)
            // console.log(e.target.files[0])
            // console.log(typeof(mainfile))
            // console.log(typeof(files))
            // console.log(arr)
            // if(mainfile === files){
            //     console.log("same same")
            // }
            // this.setState({
            //     image:files
            // })
           
            // console.log('....')

           
        // }
        // console.log('.......')
        // this.setState({
        //     image:mainfile
        // })


        // var c = canvas.toDataURL("image/jpeg", 0.7)
        // var blobBin = atob(c.split(',')[1])
        // var array = [];
        // for (var i = 0; i < blobBin.length; i++) {
        //     array.push(blobBin.charCodeAt(i));
        // }
        // var file = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
        // console.log(array)
        // console.log(file)
        // // var r = reader.readAsDataURL(file);

        // console.log(c)
        // file.lastModifiedDate = files.lastModifiedDate;
        // file.lastModified = files.lastModified
        // file.webkitRelativePath = files.webkitRelativePath
        // file.name = files.name;
        // const mainfile = new File([file], files.name,{
        //     type: "image/jpeg",
        //   });

        //     const options = {
        //         maxSizeMB: 0,
        //         useWebWorker: true,

        //     }
        // imageCompression(mainfile, options)
        // .then((compressedFile)=> {

        // console.log(mainfile)
        // compressedFile.lastModifiedDate=mainfile.lastModifiedDate
        // console.log(compressedFile)
        // const mainfile2 = new File([compressedFile], mainfile.name,{
        //     type: "image/jpeg",
        //   });

        // this.setState({
        //     image:mainfile2
        // })
        // })
        // .catch(function (error) {
        // console.log(error.message);
        // });





    }
    Tumbnail_preview = (e) => {
        if (e.target.files[0] !== undefined) {
            var z = window.URL.createObjectURL(e.target.files[0])
            this.setState({ url: z })
            var Thumbnail = <span><img id='Did_thumbnail_' style={{ width: '200px', borderRadius: '50%', height: '200px' }} src={z} alt='' />
            </span>
            this.setState({ Thumbnail: Thumbnail })
        }
    }
    HandleFirstNameChange = (e, x) => {
        if (x === 0) {
            return this.setState({
                disabled: false
            })

        }
        else if (x == 1) {
            this.setState({ firstName: e.target.value })
            if (e.target.value === localStorage.getItem('first_name')) {
                return (
                    <div>
                        {
                            this.setState({
                                disabled: true
                            })
                        }
                    </div>
                )
            }
            if (e.target.value.length === 0 || e.target.value.length === 1 || e.target.value.length === 2) {

                return (

                    <div>
                        {this.setState({
                            error1: true,
                            disabled: true
                        })};
                    </div>
                )


            } else if (this.state.secondName.length > 2) {

                return (
                    <div>
                        {this.setState({
                            error1: false,
                            disabled: false,

                        })}
                    </div>
                )

            }
            if (e.target.value.length > 2) {
                return (
                    <div>
                        {this.setState({ error1: false, })}
                    </div>
                )
            }

        } else if (x == 2) {

            this.setState({ secondName: e.target.value })
            if (e.target.value === localStorage.getItem('last_name')) {
                return (
                    <div>
                        {
                            this.setState({
                                disabled: true
                            })
                        }
                    </div>
                )
            }
            if (e.target.value.length === 0 || e.target.value.length === 1 || e.target.value.length === 2) {
                return (
                    <div>
                        {this.setState({
                            error2: true,
                            disabled: true
                        })}
                    </div>
                )

            } else if (this.state.firstName.length > 2) {
                return (
                    <div>
                        {this.setState({
                            error2: false,
                            disabled: false,
                        })}

                    </div>
                )

            }
            if (e.target.value.length > 2) {
                return (
                    <div>
                        {this.setState({
                            error2: false,
                        })}
                    </div>
                )

            }

        }
    }

    HandleClick = () => {
        
        var data = new FormData();
        // console.log(this.state.firstName)
        // console.log(this.state.secondName)
        // console.log(this.state.image)
        localStorage.setItem('profile_image', this.state.url)
        data.append('first_name', this.state.firstName)
        data.append('last_name', this.state.secondName)

        if(this.state.image_Change === true)
        data.append('profile_image', this.state.image)
        // console.log('this.state.firstname', this.state.firstName)
        // console.log('this.state.last_name', this.state.secondName)
        // console.log('localStorage.getItem(firstname)', localStorage.getItem('first_name'))
        // console.log('localStorage.getItem(lastname)', localStorage.getItem('last_name'))
        // console.log(this.state.profile_image)
        axios({
            url: '/users/' + localStorage.getItem('id') + '/',
            method: 'PATCH',
            headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
            data: data
        }).then(response => {
            // console.log("\nthis is the response : \n", response.data)
            // console.log(response.data.profile_image)
            localStorage.setItem('first_name', response.data['first_name']);
            localStorage.setItem('last_name', response.data['last_name']);
            localStorage.setItem('profile_image',response.data.profile_image)

            this.props.history.push('/settings/')

        }).catch(error => {
            // console.log(error)
        })
    }
    render() {

        return (
            <div style={{ ...this.state.minDev, margin: 'auto' }}>
                <div style={{ gridColumn: '2', backgroundColor: '', }} >


                    <Container style={{ backgroundColor: '', width: '100%',}} fluid={true}>
                        <Row style={{ backgroundColor: '', marginTop:'1px',}}>
                            <Col style={{ backgroundColor: '', }} >
                                <strong style={{ fontFamily: "founders_bold", fontSize: '30px',paddingBottom: '20px',color:'#39354E' }}> Personal Information</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: '9', offset: '0' }} sm={{ offset: '3', span: '9' }} md={{ offset: '4', span: '6' }}>
                                <img src="" alt="" id="image" style={{ display: 'none' }} />
                                <canvas id="canvas" style={{ height: '500px', width: '500px', display: 'none' }} ></canvas>
                                <div style={{ backgroundColor: 'transparent', margin: 'auto', marginTop: '30px' }}>
                                    <div style={{ width: '100%', backgroundColor: "transparent", padding: "25px", backgroundColor: '', width: '50%', }}>
                                        {this.state.Thumbnail}
                                    </div>

                                </div>
                                <input type="file" id='contained-button-file' onInput={this.Tumbnail_preview} accept={acceptedFiles} multiple={false} onChange={(e) => this.handleImage(e)} style={{ display: 'none' }} />
                                <label htmlFor='contained-button-file' style={{ fontSize: '20px', fontFamily: 'founders', color: '#39354E', marginLeft: '4rem' }}>
                                    Upload  image
                        <Button
                                        variant='contained'
                                        style={{ marginLeft: 50, backgroundColor: 'white', color: 'black', border: '1px solid black', display: 'none' }}
                                        component='span'
                                    >
                                        Upload
                        </Button>
                                </label>
                            </Col>
                        </Row>
                        <Row >
                            <Col lg={{ span: '9', offset: '0' }} sm={{ offset: '3', span: '9' }} md={{ offset: '4', span: '6' }} >
                                <TextField
                                    id='first-name'
                                    label={<p style={{ fontFamily: 'founders', fontSize: '20px' }}>First Name</p>}
                                    value={this.state.firstName}
                                    onChange={(e) => this.HandleFirstNameChange(e, 1)}
                                    style={{ width: '100%' }}
                                    error={this.state.error1}

                                    type='text'

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin='normal'
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={{ span: '9', offset: '0' }} sm={{ offset: '3', span: '9' }} md={{ offset: '4', span: '6' }} >
                                <TextField
                                    id='second-name'
                                    label={<p style={{ fontFamily: 'founders', fontSize: '20px' }}>Last Name</p>}
                                    value={this.state.secondName}
                                    onChange={(e) => this.HandleFirstNameChange(e, 2)}
                                    style={{ width: '100%' }}
                                    error={this.state.error2}

                                    type='text'

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin='normal'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: '9', offset: '0' }} sm={{ offset: '3', span: '9' }} md={{ offset: '4', span: '6' }} >
                                <TextField
                                    id='Email'
                                    label={<p style={{ fontFamily: 'founders', fontSize: '20px' }}>Email</p>}
                                    value={this.state.email}
                                    style={{ width: '100%' }}
                                    type='email'
                                    disabled
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin='normal'
                                />
                            </Col>
                        </Row>
                        <Row >

                            <Col style={{ backgroundColor: '', marginTop: '60px',marginBottom:'20px' }} lg={{ span: '9', offset: '0' }} sm={{ offset: '3', span: '9' }} md={{ offset: '4', span: '6' }}>
                                <Button variant="contained" onClick={this.HandleClick}
                                    style={{ backgroundColor: 'white', color: '#39354E', width: '199px', height: 44, fontSize: 17,borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </div>



        )
    }



}





// export default Editing;