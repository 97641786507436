import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import 'bootstrap/dist/css/bootstrap.css';
import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import swal from 'sweetalert2';
import axios from '../../axios';
import { API_URL } from "../../config";
import PasswordStrengthMeter from '../PasswordStrengthBar/PasswordStrengthBar';
import MountainImg from './images/forall.jpg';
import yourspace from './yourspace.svg';
const mql = window.matchMedia(`(min-width: 950px)`);


export default class AdminRegister extends Component {
  constructor(props){
    super(props)
    this.state={
      no_match:"",
      match:"",
      passState:"",
      submitButton:false,
      password:'',
      confirmPass:'',
      first_name:'',
      last_name:'',
      beginValidation:false, 
      buttonWidth:{},
      formcontrolwidth:{},
      imgSize:{},
      thirddivSize:{},
      lasttext:{},
      buttonDisabled:true
    }
    this.componentQueryChanged = this.componentQueryChanged.bind(this)
  }
  componentDidMount() {
    if(mql.matches){
      this.setState({
        buttonWidth:{
          width:180
        },
        formcontrolwidth:{
          width:'500px'
        },
        imgSize:{
          width:500
        },
        thirddivSize:{
          
          height:'auto',
          margin:'2% 23%',
         opacity:0.8,
          backgroundColor:'white'
        },
        lasttext:{
          color:'black'
        }
      })
    }else{
      this.setState({
        buttonWidth:{
          width:129
        },
        formcontrolwidth:{
          width:300
        },
        imgSize:{
          width:250
        },
        thirddivSize:{

        },
        lasttext:{
          color:'white'
        }
      })
    }
    
    this.setState({
      submitButton:true
    })
    localStorage.setItem('RegisterOrder','true')
    
  }
  componentWillMount(){
    mql.addListener(this.componentQueryChanged)
  }
  componentWillUnmount(){
    mql.removeListener(this.componentQueryChanged)
  }
  componentQueryChanged(){
    if(mql.matches){
      this.setState({
        buttonWidth:{
          width:180
        },
        formcontrolwidth:{
          width:'500px'
        },
        imgSize:{
          width:500
        },
        thirddivSize:{
          margin:'2% 23%',         
           height:'auto',
         opacity:0.8,
          backgroundColor:'white'
        },
        lasttext:{
          color:'black'
        }
      })
    }else{
      this.setState({
        buttonWidth:{
          width:129
        },
        formcontrolwidth:{
          width:300
        },
        imgSize:{
          width:250
        },
        thirddivSize:{

        },
        lasttext:{
          color:'white'
        }
      })
    }
    

  }
  

  handlePasswordChange = event => {
    this.setState({
      password: event.target.value,
    });

    if (this.state.beginValidation === true)
      this.validate(event.target.value, this.state.confirmPass);
  };
  confirmPass = e => {
    this.setState({
      confirmPass: e.target.value,
      beginValidation: true,
    });
    this.validate(e.target.value, this.state.password);
  };

  validate(value1, value2) {
    if (value1 === value2) {
      
      this.setState({
        no_match:"none",
        match:"block",

      })
    
      if (document.getElementById('passState').innerHTML !== 'Weak')
        
        this.setState({
          submitButton:false
        })
      
      document.getElementById('password').classList.remove('is-invalid');
      document.getElementById('confirm_password').classList.remove('is-invalid');
      document.getElementById('password').classList.add('is-valid');
      document.getElementById('confirm_password').classList.add('is-valid');
    } else {
      this.setState({
        match:"none",
        no_match:"block",
        submitButton:true
      })
      
      // console.log('passState', document.getElementById('passState').innerHTML);
      document.getElementById('password').classList.add('is-invalid');
      document.getElementById('confirm_password').classList.add('is-invalid');
    }
  }
  validate_email() {
    var email = document.getElementById('email').value;
    // console.log(email);
    
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)){
      
      document.getElementById('email').classList.add('is-invalid');
      
    }
    else {
      var emails_list = ['haifa@hads.sa', 'm@hads.sa'],
        exist = false;
      for (let i = 0; i < emails_list.length; i++)
        if (emails_list[i] === email) {
          document.getElementById('email').classList.add('is-invalid');
          exist = true;
        }
      if (!exist)
        document.getElementById('email').classList.remove('is-invalid');
      document.getElementById('email').classList.add('is-valid');
    }
  }

  send_info(e) {
    if(this.state.password !== this.state.confirmPass){
      return swal.fire({
        icon: 'error',
        title: 'error',
        text: 'new password and confirmation password should be the same',
        showConfirmButton: false,
        timer: 1500
    }) 
    }
    
    if(this.state.submitButton === true){
      return swal.fire({
        icon: 'error',
        title: 'error',
        text: 'your password is weak ',
        showConfirmButton: false,
        timer: 1500
    }) 
    }
    var email = document.getElementById('email').value;
    var pass = document.getElementById('password').value;
    if (this.state.first_name.length == 0 || this.state.last_name.length == 0 ){
      return  ''
       
      
    }
    axios
      .post(
        '/sign_up/',
        {
          first_name:this.state.first_name,
          last_name:this.state.last_name,
          email: email,
          password: pass,
          user_type: 2,
        },
        {
          'Access-Control-Allow-Origin': '*',
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      )
      .then(response => {
        // console.log(response);
        localStorage.setItem('first_name',this.state.first_name)
        localStorage.setItem('last_name',this.state.last_name)
        //localStorage.setItem('profile_image',this.state.)
        localStorage.setItem('email',document.getElementById('email').value)
        //localStorage.setItem('RegisterOrder','true')
        localStorage.setItem('RegisterOrder','true2') 
        // console.log(email)
        // console.log(this.state.password) 
        axios.post(
          `${API_URL}/login/`,
          {
            email:email,
            password:this.state.password
          },
          {
            'Access-Control-Allow-Origin': '*',
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
        ).then(response =>
          {
            if(response.data['user_info'].user_type != 2){
              return('')
          }    
            localStorage.setItem('token',response.data.token); 
            // console.log(response.data.token)  

            axios({
              method:"GET",
              url: `${API_URL}/users/` + response.data['user_info'].user_id + "/",
              headers:{
                'Authorization' : 'jwt '+response.data.token
              }
            }).then(response=>{
              localStorage.setItem('first_name',response.data.first_name)
              localStorage.setItem('last_name',response.data.last_name)
              localStorage.setItem('email',response.data.email)
              localStorage.setItem('profile_image',response.data.profile_image)
              localStorage.setItem('id',response.data.id)       
              this.props.history.push('/properties/')
              
            }).catch(err =>{
              return swal.fire({
                icon: 'error',
                title: 'error',
                text: 'something went wrong ',
                showConfirmButton: false,
                timer: 1500
            })
            })
          })
          .catch(error =>{
            // console.log(error.message)
            if(error.message == "Network Error"){
              return swal.fire({
  
                icon: 'error',
                title: 'error',
                text: 'Network Error',
                showConfirmButton: false,
                timer: 1500
            })
              
            }
           
          })
   
        
      })
      .catch(err => {
        // console.log(err)
        return  swal.fire({
          icon: 'error',
          title: 'error',
          text: 'Email Adress is already Exsits',
          showConfirmButton: false,
          timer: 1500
        })
      });
      e.preventDefault()
    
  }
  handleTextsChanges = (e,value)=>{
    if(value === 1 ){
      this.setState({
        first_name:e.target.value
      })
    }else
    this.setState({
      last_name:e.target.value
    })
  }
  handleClick = (e)=>{
    this.props.history.push('/host/login/')
    e.preventDefault()

  }
  handleChange=(e)=>{
    if(this.state.first_name === '' || this.state.last_name === ''){
      return this.setState({
        buttonDisabled:true
      })
    }
    if(document.getElementById('email').value === ''){
      return this.setState({
        buttonDisabled:true
      })
     
    }
    if(this.state.password === '' || this.state.confirmPass === ''){
      return this.setState({
        buttonDisabled:true
      })
    }
    var flag = !this.state.buttonDisabled
    this.setState({
      buttonDisabled:flag
    })
  }
  render() {
    return (
      <div style={{backgroundImage:`url(${MountainImg})`,backgroundRepeat:'no-repeat', backgroundSize:'cover',backgroundPosition:'center',height:'100%',backgroundAttachment:'scroll',position:'absolute',width:'100%'}}>
        <div >
       <div style={{...this.state.thirddivSize ,padding:'5px',}}>

       
      <div style={{display:'grid',placeContent:'center'}}>
      <img src={yourspace} style={{...this.state.imgSize,marginTop:"5%",cursor:'pointer'}} onClick={()=>this.props.history.push('/host/')}/>
      </div>
      <div style={{margin:"5% auto",backgroundColor:'',...this.state.formcontrolwidth,}}>
        
        <Form action='/adminDashboard'>
          <Form.Group controlId='formBasicEmail'>
          <Form.Label>First Name:</Form.Label>
            <Form.Control
              id='first_name'
              // isInvalid='false'
              // isValid='true'
              required
              type='text'
              
              placeholder='Enter your first name'
              onChange={(e)=>this.handleTextsChanges(e,1)}
            />
            <Form.Label style={{marginTop:"2%"}}>Last Name:</Form.Label>
            <Form.Control
              id='last_name'
              required
              type='text'
              
              placeholder='Enter your last name'
              onChange={(e)=>this.handleTextsChanges(e,2)}
            />
            <Form.Label style={{marginTop:"2%"}}>Email address:</Form.Label>
            <Form.Control
              id='email'
              // isInvalid='false'
              // isValid='true'
              required
              type='email'
              placeholder='Enter email'
              onChange={this.validate_email}
            />
          </Form.Group>
          

          <Form.Group controlId='formBasicPassword'>
            <Form.Label for='password' style={{marginTop:"1%"}}>Password:</Form.Label>
            <Form.Control
              minLength='6'
              id='password'
              controlId='password'
              required
              onChange={this.handlePasswordChange}
              type='password'
              placeholder='Password'
            />
            {/* </Form.Group> */}
            {/* <Form.Group> */}
            <PasswordStrengthMeter password={this.state.password} />
          </Form.Group>
          <Form.Group controlId='formConfirmBasicPassword'>
            <Form.Label for='confirm_password' style={{marginTop:"2%"}}>Confirm password:</Form.Label>
            <Form.Control
              id='confirm_password'
              controlId='confirm_password'
              required
              onChange={this.confirmPass}
              type='password'
              placeholder='Confirm password'
            />
          </Form.Group>
          <div style={{backgroundColor:''}}>


          <label><input type="checkbox" name="checkbox" value="value" onClick={this.handleChange}/> I agree upon terms and agreements <button style={{display:'inline',color:'#08D888',border:'0px',backgroundColor:'transparent'}} onClick={()=>this.props.history.push('/host/terms&agreements/')} >read more</button></label>

                        
                            
                        
            </div>
          <Form.Group id='result' style={{ height: '30px', width: '50%' }}>
            <small style={{ display: 'none' }} id={this.state.match}>
              <CheckIcon style={{ color: 'green' }} /> Passwords match
            </small>
            <small style={{ display: 'none' }} id={this.state.no_match}>
              <ClearIcon style={{ color: 'red' }} /> Passwords do not match
            </small>
          </Form.Group>
         

          <Button 
            //id={this.state.submitButton}
            disabled={this.state.buttonDisabled}
            variant='primary'
           
            style={{marginLeft:"34%",marginTop:'3%',backgroundColor:'#08D888',color:'#FFFFFF',...this.state.buttonWidth,height:44,fontSize:20,fontFamily:'HelveticaNeue',border:'1px solid white'}}

            onClick={e => this.send_info(e)}>
            Sign up
            
          </Button>
        </Form>
        <Typography style={{marginTop:"30px",fontFamily:'founders',...this.state.lasttext}}>
          if you already have an account please <a onClick={this.handleClick} href="/host/login/" style={{color:'#08D888'   }}>log in </a> here
        </Typography>
      </div>
      </div>
      </div>
      </div>
    );
  }
}
