import React from 'react'
import {Grid,Button,Typography} from '@material-ui/core'

const mql = window.matchMedia(`(min-width: 50em)`);

export default class ShowMessage extends React.Component{
    constructor(props){
        super(props)
        this.state={
            mainDiv:{}
        }
        this.componentMediaQueryChanged = this.componentMediaQueryChanged.bind(this)
    }
    componentDidMount(){
        this.componentMediaQueryChanged()
    }
    componentWillMount(){
        mql.addListener(this.componentMediaQueryChanged)
    }
    componentWillUnmount(){
        mql.removeListener(this.componentMediaQueryChanged)
    }
    componentMediaQueryChanged(){
        if(mql.matches){
            this.setState({
                mainDiv:{
                    display:'grid',
                    gridTemplateColumns:'24rem',
                    marginTop:40
                }
            })
        }else {
            this.setState({
                mainDiv:{
                    marginTop:160
                }
            })
        }
    }

    handleClick=()=>{
        this.props.move()
    }
   
  
    render(){
        const Message=()=>{
            if(this.props.propertyInfo.request_type === 'post'){
                return <div>
                    <strong style={{display:'block',color:'#39354E'}}>
                        Done!
                    </strong>
                    <strong>yourspace team will review your request within 24 hours</strong>
                    </div>
            }else {
                return <div>
                <strong style={{display:'block',color:'#39354E'}}>
                    Done!
                </strong>
                <strong>yourspace team will review your request within 24 hours</strong>
                </div>
            }
        }
        return (
            <div style={{...this.state.mainDiv,margin:'auto'}}>
                <div style={{gridColumn:2}}>
                    <Grid container>
                        <Grid container item direction="column">
                            <Grid item>
                                <Typography style={{fontFamily:'font_bold',fontSize:'24px'}}>
                                    <Message />
                                </Typography>
                            </Grid>
                            <Grid item style={{marginTop:'2rem'}}>
                                <Button style={{ backgroundColor: 'white', color: '#39354E', width:'199px',  fontSize: 16, fontFamily: 'HelveticaNeue',borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)'}} onClick={this.handleClick}>Back to the main page</Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </div>
                

            </div>
        )
    }

}