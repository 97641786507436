import axios from "axios";

const instance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// if (typeof window !== "undefined") {
//     const token = window?.localStorage?.getItem("bearerToken");
//     if (token) instance.defaults.headers.common["authorization"] = `Bearer ${token}`;;
// }

export default instance;
