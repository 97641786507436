import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React from 'react';
//import Container from '@material-ui/core/Container';
import { Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import swal from 'sweetalert2';
import axios from '../../axios';
import Icon from './IBANicon.png';
const mql = window.matchMedia(`(min-width: 30em)`);
const mql1 = window.matchMedia(`(max-width: 968px)`);

export default class Payment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            swiftCode: ' ',
            Iban: ' ',
            fullname: '',
            Country: ' ',
            City: ' ',
            postCode: ' ',
            Street: ' ',
            BuildingNumber: ' ',
            phoneNumber: ' ',
            Email: ' ',
            midDiv: {},
            error1: false,
            error2: true,
            gridContainer: {},
            ButtonWidth: {},
            error3: false,
            text1: {},
            text2: {},
            text3: {},
            transWidth: {},
            payment: {},
            FirstName: '',
            SecondName: '',
            Iban2: '',
            marginTop: {},
            ContanerWidth: {},
            marginLeft: {},
            display: {},
            display2: {},
            fullname2: ''
        }
        this.componentMediaQuery = this.componentMediaQuery.bind(this)
    }
    componentDidMount() {
        localStorage.setItem('RegisterOrder', 'payment')
        this.componentMediaQuery()
        this.getEvent()

    }
    componentMediaQuery() {
        if (mql.matches) {
            this.setState({
                midDiv: {
                    display: 'grid',
                    gridTemplateColumns: '22rem auto ',
                    marginTop: '150px'
                },
                gridContainer: {
                    width: '52%'
                },
                ButtonWidth: {
                    width: 199
                },
                text2: {
                    width: '100%'
                },
                marginTop: {

                },
                ContanerWidth: {
                    width: '60%'
                },
                marginLeft: {
                    marginLeft: "80px"
                },
                text1: {
                    width: '50%'
                }
            })
        } else {
            this.setState({
                midDiv: {
                    marginTop: 150
                },
                ButtonWidth: {
                    width: 129
                },
                marginTop: {
                    marginTop: '30px'
                },
                ContanerWidth: {
                    width: '35%'
                },
                marginLeft: {
                    marginLeft: "50px"
                },
                text1: {
                    width: '100%'
                }
            })
        }


    }
    getEvent = () => {
        axios({
            method: 'GET',
            url: '/users/' + localStorage.getItem('id') + '/owner_profile/',
            headers: {
                'Authorization': 'jwt ' + localStorage.getItem('token')
            },

        }).then(response => {
            this.setState({
                fullname2: response.data.full_name,
                Iban2: response.data.IBAN
            })
            if (this.state.Iban2 === '') {
                this.setState({
                    display: {
                        display: 'inline',
                    },
                    display2: {
                        display: 'none'
                    }
                })
            } else {
                this.setState({
                    display: {
                        display: 'none'
                    },
                    display2: {
                        display: 'inline'
                    }
                })
            }
            // console.log(this.state.SecondName)
        }).catch(err => {
            // console.log(err.message)
        })

    }
    componentWillMount() {

        mql.addListener(this.componentMediaQuery)
    }
    componentWillUnmount() {
        mql.removeListener(this.componentMediaQuery)
    }

    handleIban = (value, e) => {
        var re = /^[A-Za-z]+$/;
        // console.log(this.state.Iban.length)
        // console.log(this.state.fullname.length)

        if (value === 1) {
            this.setState({
                fullname: e.target.value,
            })
        } else if (value === 3) {
            this.setState({
                Iban: e.target.value
            })
        }
        if(this.state.fullname.length === 0 || this.state.Iban.length === 0){
            return this.setState({
                error2:true
            })
        }else this.setState({error2:false})

    }
    handleClick = () => {
        // console.log(this.state.fullname)
        // console.log(this.state.Iban)
        // console.log(this.state.Iban)
        axios({
            method: 'PATCH',
            url: '/users/' + localStorage.getItem('id') + '/owner_profile/',
            headers: {
                'Authorization': 'jwt ' + localStorage.getItem('token')
            },
            data: {
                'full_name': this.state.fullname,
                'IBAN': this.state.Iban
            }
        }).then(response => {
            // console.log(response.data)
            this.getEvent()
        }).catch(err => {
            swal.fire({

                icon: 'error',
                title: 'error',
                text: 'fill all the blanks',
                showConfirmButton: false,
                timer: 1500
            })


        })

    }
    render() {
        const img = <img src={Icon} style={{ width: '5%', height: '5%' }} />
        const display = this.state.display
        const display2 = this.state.display2

        return (
            <div style={{ ...this.state.midDiv, margin: 'auto', backgroundColor: '' }} >



                <div style={{ gridColumn: '2' }}>


                    <Grid container justify="center" style={{ backgroundColor: '', }} direction="column" style={{ backgroundColor: '' }} >
                        <Container fluid={true} style={{}}>
                            <Row>
                                <Col lg={{ span: '4', offset: '0' }} sm={{ offset: '3', span: '6' }} md={{ offset: '0', span: '6' }}>
                                    <strong style={{ paddingBottom: '20px', fontFamily: 'founders_bold', fontSize: '30px', backgroundColor: '' }}>Bank Information</strong>

                                </Col>
                            </Row>

                        </Container>
                        {this.state.Iban2 !== '' ? '' : <Container fluid={true} style={{}}>
                            <Row>
                                <Col lg={{ span: '4', offset: '0' }} sm={{ offset: '3', span: '6' }} md={{ offset: '0', span: '6' }}>
                                    <p style={{ fontSize: '25px', fontFamily: 'font_bold', marginTop: '30px',color:'#39354E' }}>Transaction:</p>
                                </Col>
                            </Row>
                            <Grid container item direction="column" >
                                <Grid item lg={6} xl={6} sm={6} xs={12} md={6}>
                                    <TextField
                                        label={"full name"}
                                        id="iban"
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        onChange={(e) => this.handleIban(1, e)}

                                        inputProps={{
                                            maxLength: 24,
                                        }}
                                    />

                                </Grid>

                                <Grid item lg={6} xl={6} sm={6} xs={12} md={6} style={{ marginTop: '15px' }}>
                                    <TextField
                                        label={"IBAN"}
                                        id="iban"
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        onChange={(e) => this.handleIban(3, e)}

                                        inputProps={{
                                            maxLength: 24,
                                        }}
                                    />

                                </Grid>

                            </Grid>
                            <Row style={{ marginTop: '20px' }}>
                                <Col lg={{ span: '4', offset: '0' }} sm={{ offset: '3', span: '6' }} md={{ offset: '0', span: '6' }}>
                                    <Button id="button" disabled={this.state.error2} onClick={this.handleClick} style={{  fontSize: '20px', ...this.state.ButtonWidth, border: '1px solid white', marginBottom: '40px',borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)',backgroundColor:'white',color:'#39354E' }}>
                                        Save
                                </Button>
                                </Col>
                            </Row>
                        </Container>


                        }



                        <Container style={{ marginTop: '2%', backgroundColor: '', }} fluid={true}>
                            <Row style={{ backgroundColor: '' }}>
                                <Col lg={{ span: '4', offset: '0' }} sm={{ offset: '3', span: '6' }} md={{ offset: '0', span: '6' }}>
                                    <p style={{ fontSize: '25px', fontFamily: 'font_bold',color:'#39354E' }}>Transaction Information:</p>

                                </Col>
                            </Row>
                        </Container>

                        <Container fluid={true} style={{}}>
                            <Grid container item direction="column">
                                <Grid item lg={6} xl={6} sm={6} xs={12} md={6}>
                                    <div>
                                        <p style={{ fontSize: '22px', fontFamily: 'founders',color:'#39354E' }}>Full name: <p style={{ display: 'inline', fontFamily: 'light' }}>{this.state.fullname2}</p></p>
                                    </div>
                                </Grid>

                                <Grid item lg={6} xl={6} sm={6} xs={12} md={6} style={{ marginTop: '15px' }}>
                                    <div>
                                        <p style={{ fontSize: '22px', fontFamily: 'founders',color:'#39354E' }}>IBAN: <p style={{ display: 'inline', fontFamily: 'light' }}>{this.state.Iban2}</p></p>
                                    </div>
                                    {/* <TextField
                                        label={"IBAN"}
                                        id="iban"
                                        variant="outlined"
                                        value={this.state.Iban2}
                                        style={{ width: '100%' }}
                                        onChange={(e) => this.handleIban(e)}

                                        inputProps={{
                                            maxLength: 24,
                                        }}
                                    /> */}
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </div>
            </div>
        )
    }
}