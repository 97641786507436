import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import React, { Component } from 'react';
import Button1 from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import axios from '../../axios';
import './PropertyList.css';
const mql = window.matchMedia(`(min-width: 767px)`);
export default class propertyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked: mql.matches,
      sidebarOpen: false,
      LeftM: "25px",
      LeftM2: "250px",
      grid_div: { display: "grid", gridTemplateColumns: "auto auto auto" },
      sub_grid_div: { gridColumn: '2' },
      img_width: "265px",
      textA: "left",
      btn_center: {},
      filter_btn: {},
      properties: [],
      spaces: [],
      spaces2: [],
      table_display: {},
      msg: "null",
      open: false,
      toggleCollapse: false,
      collapse_child: [],
      toggleCollapse_txt: 'Collapse all',
      main_char: '-',
      property_space: [],
      showReviews1: false,
      openDialoge: false,
      space_reveiw: [],
      text_decoration:'none',
      space_reveiw_length:0

    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }
  UNSAFE_componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }
  getEvent = async () => {
    var array = []
    localStorage.setItem('RegisterOrder','true22')
    await axios({
      method: "GET",
      url: '/users/' + localStorage.getItem('id') + '/properties/',
      headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') }
    }).then(res => {
      // console.log(res.data[0].spaces.length)
      var counter = 0
      // console.log(res.data.length)
      for (var j = 0; j < res.data.length; j++) {
        for (var i = 0; i < res.data[j].spaces.length; i++) {

          array[counter] = {
            'id': res.data[j].spaces[i]['id'],
            'contract_start_date': res.data[j].spaces[i]['contract_start_date'],
            'contract_end_date': res.data[j].spaces[i]['contract_end_date'],
            'avi_time': res.data[j].spaces[i]['avi_time'],
            'name': res.data[j].spaces[i]['name'],
            'category': res.data[j].spaces[i]['category'],
            'sub_category': res.data[j].spaces[i]['sub_category'],
            'desc': res.data[j].spaces[i]['desc'],
            'capacity': res.data[j].spaces[i]['capacity'],
            'price_hourly': res.data[j].spaces[i]['price_hourly'],
            'image_url': res.data[j].spaces[i]['image_url'],
            'status': res.data[j].spaces[i]['status'],
            'rating': res.data[j].spaces[i]['rating'],
            'avi_days': res.data[j].spaces[i]['avi_days'],
            'currnecy_code': res.data[j].spaces[i]['currnecy_code'],
            'property_id': res.data[j].spaces[i]['property_id'],
            'approval_status':res.data[j].spaces[i]['approval_status'],
            'space_size':res.data[j].spaces[i]['space_size'],
            'price_daily':res.data[j].spaces[i]['price_daily'],
            'check_in_time':res.data[j].spaces[i]['check_in_time'],
            'check_out_time':res.data[j].spaces[i]['check_out_time'],
            'is_hourly_avi':res.data[j].spaces[i]['is_hourly_avi'],
            'is_daily_avi':res.data[j].spaces[i]['is_daily_avi'],

          }
          counter = counter + 1
        }

      }
      // console.log(array)
      this.setState({
        property_space: array
      })

      var collapse_child = []
      for (let i = 0; i < res.data.length; i++)
        collapse_child[i] = true;
      this.setState({ properties: res.data, collapse_child: collapse_child })




    }).catch(e => {
      // console.log(e.response)
    })

  }
  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }
  componentDidMount() {
    localStorage.setItem('RegisterOrder','true22')
    this.getEvent()
    this.screen_sizes();
    if (this.props.location.state !== null && this.props.location.state !== undefined && this.props.location.state.msg !== undefined) {
      // console.log("msg: ", this.props.location.state.msg)

      this.setState({ msg: this.props.location.state.msg, open: true })
      setTimeout(() => { this.setState({ open: false }) }, 10000)
    }
  }
  handleClose = () => {
    this.setState({ open: false });
  };
  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
    // console.log("mql.matches: ",mql.matches)

    this.screen_sizes();

  }
  screen_sizes() {
    if (mql.matches) {
      this.setState({
        grid_div: { display: "inline" }, sub_grid_div: {}, img_width: "265px", LeftM: "25px", textA: "left", LeftM2: "340px", btn_center: { display: "inline-block" },
        filter_btn: { float: "right", marginRight: "20px" }, table_display: { display: "table" }
      })
    }
    else {
      this.setState({
        grid_div: { display: "grid", gridTemplateColumns: "auto auto auto" }, sub_grid_div: { gridColumn: '2' }, img_width: "inherit",
        LeftM2: "0px", LeftM: "0px", textA: "center", btn_center: { textAlign: "center", marginBottom: "20px", },
        filter_btn: { textAlign: "center", }, table_display: {}
      })
    }
    // console.log("table_display", this.state.table_display)
    // console.log("leftM2",this.state.LeftM2)
  }
  filters(state) {
    if (state === "occ") {
      var available_state = "none";
      var occ_state = "inline-block"
    }
    else if (state === "avai") {
      var available_state = "inline-block";
      var occ_state = "none"
    }
    else if (state = "all") {
      var available_state = "inline-block";
      var occ_state = "inline-block"
    }
    var available = document.getElementsByClassName('available');
    var occupied = document.getElementsByClassName("red");
    for (var i = 0; i < available.length; i += 1) {
      available[i].style.display = available_state;
    }
    for (var i = 0; i < occupied.length; i += 1) {
      occupied[i].style.display = occ_state;
    }
  }
  toggleExpand = (index) => {
    // console.log('clicked ', index)

    if (this.state.collapse_child[index]) {
      var collapse_child = this.state.collapse_child
      collapse_child[index] = false
      this.setState({ collapse_child: collapse_child })
      document.getElementById("char_" + index).innerText = "+"
    }
    else {
      // if(mql.matches){
      var collapse_child = this.state.collapse_child
      collapse_child[index] = true
      this.setState({ collapse_child: collapse_child })

      document.getElementById("char_" + index).innerText = "-"
    }

  }
  expand_collapse_all(val) {
    var collapse_child = this.state.collapse_child;
    for (let i = 0; i < collapse_child.length; i++) {
      collapse_child[i] = val;
      // console.log("char_", i)
      // console.log('collapse_child.length-10 = ', collapse_child.length - 10)
      if (val) document.getElementById("char_" + i).innerText = "-"
      else document.getElementById("char_" + i).innerText = "+"

    }
    this.setState({ collapse_child: collapse_child })
  }
  showReviews = (space_id) => {
    this.setState({
      showReviews1: true
    })
    this.props.history.push({ pathname: '/space_review/' + space_id, state: { showReviews1: this.state.showReviews1 } })
    

  }
  MouseEnter=()=>{
    this.setState({
      text_decoration:'underline'
    })
  }
  render() {
    const styles = (theme) => ({
      root: {
        margin: 0,
        padding: theme.spacing(2),
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
    });
    const DialogTitle = withStyles(styles)((props) => {
      const { children, classes, onClose, ...other } = props;
      return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
          <Typography variant="h6">{children}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      );
    });
    const DialogContent = withStyles((theme) => ({
      root: {
        padding: theme.spacing(8),
      },
    }))(MuiDialogContent);
    const DialogActions = withStyles((theme) => ({
      root: {
        margin: 0,
        padding: theme.spacing(1),
      },
    }))(MuiDialogActions);

    const content_style = {
      marginTop: "50px",
      overflow: 'auto'
    }
    const contentStyle = { marginTop: "145px", marginLeft: this.state.LeftM2,backgroundColor:''}
    const list_style = {
      margin: "30px 20px",

      width: this.state.img_width,
      display: 'inline-block',
      height: 'auto',

    }
    const btn_center = this.state.btn_center;
    const stars = { color: "gold", fontSize: "15px", float: 'right' }
    const img_style = { width: this.state.img_width, height: "190px", backgroundSize: "cover", marginBottom: "15px", backgroundPosition: 'center' }
    const name_style = {
      marginLeft: "15px", fontSize: "18px", display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical', overflow: 'hidden',
      wordBreak: 'break-word', fontFamily: "founders_bold",lineHeight:'1em'
    }
    const filter_style = { padding: "5px 15px", borderRadius: "2px", color: "white", cursor: "pointer", marginRight: '15px' }
    const sub_grid_div = this.state.sub_grid_div
    const leftM = this.state.leftM
    const properties = this.state.properties;
    // const spaces = [this.state.spaces[0], this.state.spaces[1],];
    // var x =this.state.spaces
    const spaces = this.state.property_space
    const textA = this.state.textA;
    const grid_div = this.state.grid_div;
    var num = 0;
    const to_datails = (id, p_id, flag) => {
      if (flag === false)
        this.props.history.push('/spacedetails/' + id + '/' + p_id + '/')
      else
        this.props.history.push({ pathname: '/space_review/' + id, state: { showReviews1: true } })
    }
    const add = (e) => {
      this.props.history.push('/addproperties/')
    }
    const handleClose2 = () => {
      this.setState({
        openDialoge: false
      })
    }
    const handleClick = (space) => {
      // console.log(space.id)
      axios({
        method: 'GET',
        url: '/spaces/' + space.id + '/reviews/'
      }).then(response => {
        // console.log(response.data)
        
        this.setState({
          space_reveiw: response.data,
          openDialoge: true
        })
      }).catch(error => {
        // console.log(error.message)
      })
      // console.log(this.state.space_reveiw)
    }
    const calCReviews=(id)=>{
      axios({
        method: 'GET',
        url: 'spaces/' + 192 + '/reviews/'
      }).then(response => {
        // console.log(response.data)
        this.setState({
          space_reveiw_length: response.data.length,
        })
      }).catch(error => {
        // console.log(error.message)
      })

    }
    const space_reveiw_length = this.state.space_reveiw_length

    //////////
    const toggleCollapse_stat = this.state.toggleCollapse;
    const toggleCollapse = () => {
      // console.log('toggleCollapse!! ', properties.length)

      var toggleCollapse_btn = '+',
        toggleCollapse_txt = 'Expand all'

      if (toggleCollapse_stat) {
        toggleCollapse_btn = "-";
        toggleCollapse_txt = 'Collapse all';
      }
      this.setState({
        main_char: toggleCollapse_btn,
        toggleCollapse_txt: toggleCollapse_txt
      })
      //document.getElementById("main_char").innerText = toggleCollapse_btn
      //document.getElementById("toggleCollapse_txt").innerText = toggleCollapse_txt
      // console.log('toggleCollapse_stat: ', toggleCollapse_stat)
      this.setState({ toggleCollapse: !toggleCollapse_stat })
      // for(let i=0; i< properties.length; i++) {toggleExpand (i);}

      this.expand_collapse_all(toggleCollapse_stat)

    }
    const text_decoration = this.state.text_decoration
    const collapse_child = this.state.collapse_child
    const toggleExpand = this.toggleExpand
    const openDialoge = this.state.openDialoge
    const CheckReviews = ()=>{
      if(this.state.space_reveiw.length === 0){
        return <div style={{textAlign:'center'}}>
          <strong style={{fontSize:'25px'}}>No Comments</strong>
        </div>
      }
      return ''
    }
    const space_reveiw =
      <Dialog onClose={handleClose2} aria-labelledby="customized-dialog-title" open={openDialoge} fullWidth={true}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose2}>
          Space Reviews
    </DialogTitle>
        <DialogContent dividers>
          <CheckReviews />
          {this.state.space_reveiw.map((name, index) => {
            var clean_rating = [], accurate = [],calm=[],pv=[]
            
            for (let i = 0; i < this.state.space_reveiw[index].clean; i++) {
              clean_rating[i] = <Rating name="read-only" readOnly value="1" max="5" defaultValue="1" style={{ color: '#08D888', width: '16px' }} size="large" key={i} />
            }
            for (let i = 0; i < this.state.space_reveiw[index].accurate; i++) {
              accurate[i] = <Rating name="read-only" readOnly value="1" max="5" defaultValue="1" style={{ color: '#08D888', width: '16px' }} size="large" key={i} />
            }
            for (let i = 0; i < this.state.space_reveiw[index].accurate; i++) {
              calm[i] = <Rating name="read-only" readOnly value="1" max="5" defaultValue="1" style={{ color: '#08D888', width: '16px' }} size="large" key={i} />
            }
            for (let i = 0; i < this.state.space_reveiw[index].accurate; i++) {
              pv[i] = <Rating name="read-only" readOnly value="1" max="5" defaultValue="1" style={{ color: '#08D888', width: '16px' }} size="large" key={i} />
            }
            return <div key={index} style={{ borderBottom: '1px solid lightgrey', marginTop: '30px' }}>
              <div>
                <p style={{ fontSize: '22px', fontFamily: 'font_bold' }}>- {this.state.space_reveiw[index].user_name}  <p style={{ color: '#1c1e21',fontSize:'18px',fontFamily:'HelveticaNeue' }} >{this.state.space_reveiw[index].review}</p></p>
                {/* <span onMouseEnter={() => alert("clean")}> {clean_rating} -- </span>
                <span onMouseEnter={() => alert("clean")}>    -- {accurate} -- </span>
                <span onMouseEnter={() => alert("clean")}> -- {calm} -- </span>
                <span onMouseEnter={() => alert("clean")}> {pv}</span> */}
              </div>

            </div>

          })}
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose2} color="primary">
            Save changes
      </Button>
        </DialogActions> */}
      </Dialog>
    const all_ =
      <div>
        {/* <h4 style={{ textAlign: textA, cursor: "pointer", fontSize: "30px", outline: "0", paddingTop: '40px' }}
          onClick={toggleCollapse}
        >  <Button id={`main_char`} size='small' style={{ width: "5px", marginRight: "5px", fontSize: "35px", outline: "0", fontFamily: 'Regular' }}>{this.state.main_char}</Button>
          <p id='toggleCollapse_txt' style={{ display: 'inline', fontSize: '24x', fontFamily: 'Regular' }}>{this.state.toggleCollapse_txt}</p></h4> */}
        {
          properties.map(function (name, index) {
            return (
              <div key={index} id="me?" style={content_style}>
                {/* <div style={{paddingLeft: leftM, marginLeft:leftM}}></div> */}
                <div>
                  {/* <h4 style={{ textAlign: textA, cursor: "pointer", fontSize: "25px", outline: "0", display: 'inline-block' }}
                  onClick={(e) => toggleExpand(index)}
                > */}
                  {/* Property #1 */}
                  {/* <span><span id={`char+_${index}`} style={{display:"none"}}> + </span> 
                      <span id={`char-_${index}`}> - </span></span> */}
                  {/* <Button id={`char_${index}`}
                    style={{ width: "5px", marginRight: "5px", fontSize: "30px", outline: "0", width: '18px', fontFamily: 'founders' }}>
                    - </Button> */}

                  <p style={{ fontFamily: 'founders', fontSize: '38px', display: 'inline',marginLeft:'30px',color:"#39354E"}}>
                    {properties[index].name} in ({properties[index].city_name})
                      </p>


                  {/* </span> */}
                {/* </h4> */}
                  <p style={{ ...btn_center, marginLeft: '30px',}}>
                    <Link to={'/editproperty/' + properties[index].id}>
                      <Button1 variant="outlined" style={{ border: '0px solid black', color: '#39354E', fontFamily: 'HelveticaNeue', fontSize: '12px',backgroundColor:'white',borderRadius:'50px',boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.06)',marginBottom:'10px' }}>Edit</Button1>
                    </Link>
                  </p>
                </div>
                <div id={`child_${index}`} style={{ ...grid_div, backgroundColor: '',marginLeft:'30px',marginRight:'30px' }}>
                  <Collapse in={collapse_child[index]}>

                    {spaces.map(function (name, index1) {
                      var returned, rating = []
                      for (let i = 0; i < spaces[index1].rating; i++) {
                        spaces[index1].rating = 1
                        rating[i] = <Rating name="read-only" readOnly value="1" max="2" defaultValue="1" style={{ color: '#08D888', width: '26px' }} size="large" key={i} />
                      }
                      var approval_statusvalue='red'
                      // console.log(spaces[index1].approval_status)
                      if(spaces[index1].approval_status === 0){
                        approval_statusvalue='green'
                      }else if (spaces[index1].approval_status === 1) {
                        approval_statusvalue='yellow'
                      }

                      if (properties[index].id === spaces[index1].property_id) {
                        var category = "office"

                        if (spaces[index1].category === 2)
                          category = "meeting room"
                        if (spaces[index1].category === 3)
                          category = "special room"
                        // console.log("properties[index].name: ", properties[index].name)
                        //  console.log("spaces[index1].property_name: ", spaces[index1].property_name)
                        return <div  key={index1} className="available"
                          style={{ ...list_style, ...sub_grid_div, cursor: "pointer", backgroundColor: 'white',borderRadius:'10%',height:'290px',  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 8px 5px 0 rgba(3, 3, 3, 0.03)',}}>

                          <div
                          // style={{width:"250px"}}
                          >
                            <div style={{ backgroundImage: `url(${spaces[index1].image_url})`, ...img_style, borderTopLeftRadius:'10%',borderTopRightRadius:'10%' }} onClick={(e) => to_datails(spaces[index1].id, properties[index].id, false)} />
                            <div style={{  height: '20px', position: 'relative',height:'0em',marginTop:'4px' }}>
                           
                              <strong style={name_style}>
                                
                                {spaces[index1].name}
                              </strong>
                            </div>

                            <div style={{ marginTop: "0px",backgroundColor:'',marginLeft:'15px',marginBottom:'22px',position:'relative'}}>
                              {/* <p style={{ float: "right", fontSize: '16px', fontFamily: "founders" }}>Price: {spaces[index1].currnecy_code} {spaces[index1].price_hourly}</p> */}
                              <div style={{color: "white", fontSize: '12px', fontFamily: 'founders',backgroundColor:'#39354E',borderRadius:'20px',textAlign:'center',position:'absolute',marginTop:'35px',height:'21px',paddingBottom:'9px',paddingLeft:'9px',paddingRight:'9px',paddingTop:'3px'}}><p style={{margin:'auto',textAlign:'center',backgroundColor:''}}>{category}</p> </div>

                              <span style={{backgroundColor:approval_statusvalue,marginLeft:'200px',marginTop:'40px',fontSize:'60px',position:'absolute',height:'20px',padding:'10px',borderRadius:'50%'}}></span>


                            </div>
                            {/* <div>
                              <div onClick={(e) => handleClick(spaces[index1])} style={{}} >
                                {rating}
                              </div>


                            </div> */}

                          </div>
                        </div>

                      }

                    }
                    )}
                  </Collapse>

                </div>

              </div>
            );
          })}</div>

    return (
      <div style={contentStyle}>
        <div style={this.state.btn_center}>
          <Button1 onClick={add} style={{ color: "#39354E", backgroundColor: "white", borderRadius:'50px', width: '170px', marginLeft: '23px', fontSize: '20px', fontFamily: 'HelveticaNeue',border:'1px solid white',  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.10)'}}>+ Add New Space</Button1>
        </div>
      
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          // key={`${vertical:'top'},${horizontal}`}
          open={this.state.open}
          onClose={this.handleClose}
          // ContentProps={{
          //   'aria-describedby': 'message-id',
          // }}
          message={<span id="message-id">{this.state.msg}</span>}
        />
      
        {all_}
       
        {space_reveiw}
        {/* <div className="footer sticky-navbar-fixed-bottom" style={{position: 'relative',bottom: 0,}}>
        <Footer />
        </div> */}
       
      </div>
    )
  }
}

