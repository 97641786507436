import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import RemoveIcon from '@material-ui/icons/Remove';
import React, { Component } from 'react';
import Button1 from 'react-bootstrap/Button';
import StackGrid from 'react-stack-grid';
import RUG, { DragArea, DropArea } from 'react-upload-gallery';
import 'react-upload-gallery/dist/style.css';
import swal from 'sweetalert2';
import axios from '../../axios';
import AxiosConfig from '../../axios/axios';
import './Forms.css';

const mql = window.matchMedia(`(min-width: 50em)`);
export default class Media_form extends Component {
    constructor(props) {
        super(props)
        this.state = {
            img_preview: [],
            thumbnailH: "fit-content",
            up_buttonClicked: false,
            Thumbnail: '',
            Thumbnail_video: [],
            thumbnailW: '',
            thumbnail_img_W: '100%',
            marginLeft: '0',
            deletedmedia: [],
            StackGrid_st: {},
            btnwidth: {},
            spaceCategory: '',
            current_media_images: [],
            bigDiv: {},
            mainDiv: {},
            showAlert: false,
            sub_div_media_form: {},
            GridContainer: {},
            imagehight: {},
            uploadColor: {},
            open: false,
            dropGrid: {},
            arr: [],
            result: false,
            updated_media_images: [],
        }
        this.screen_sizes = this.screen_sizes.bind(this);
    }
    componentWillMount() {
        mql.addListener(this.screen_sizes);
    }
    componentWillUnmount() {
        mql.removeListener(this.screen_sizes);
    }
    formContinue = e => {
        e.preventDefault();
        if (this.props.propertyInfo.spacePictures === null || this.props.propertyInfo.spacePictures === '' || this.props.propertyInfo.spacePictures.length === 0) {
            return this.setState({
                uploadColor: {
                    color: 'red'
                }
            })
        } else this.setState({
            uploadColor: {
                color: 'black'
            }
        })

        //here is to save images in the parent component addproperty
        this.props.handleMediaImagesChange(this.state.current_media_images);
        this.props.nextStep();
    };
    // Go Back to the previous form
    formPrevious = e => {
        e.preventDefault();
        this.props.handleMediaImagesChange(this.state.current_media_images);
        this.props.prevStep();
    };

    componentDidMount() {
        this.props.abledLastbuttonfunction()
        // applying media query 
        this.screen_sizes();
        if (this.props.propertyInfo.spaceCategory === 4) {
            this.setState({
                spaceCategory: 'office'
            })
        } else if (this.props.propertyInfo.spaceCategory === 2) {
            this.setState({
                spaceCategory: 'meeting room'
            })
        } else {
            this.setState({
                spaceCategory: 'special room'
            })
        }
        var imagehight = '38vh'
        if (mql.matches) {
            imagehight = '38vh'
        } else imagehight = '30vh'
        if (this.props.propertyInfo.spaceVideo.length !== 0 && this.props.propertyInfo.request_type == 'post') {
            var z = URL.createObjectURL(this.props.propertyInfo.spaceVideo)
            var Thumbnail = <div style={{ display: "inline-block", }}>
                <span>
                    <video autoplay="" loop="" muted="true" playsinline="" style={{ ...this.state.imagehight, objectFit: 'cover', width: '100%', objectPosition: 'center center', display: 'block', maxWidth: '100%' }} controls>
                        <source src={z} type="video/mp4" />
                        <source src={z} type="video/ogg" />
                        Your browser does not support the video tag.
                    </video>

                </span>
            </div>
            this.setState({ thumbnailH1: "auto", Thumbnail_video: Thumbnail })
        } else if (this.props.propertyInfo.spaceVideo.length !== 0 && this.props.propertyInfo.request_type == 'patch') {
            var Thumbnail = <div style={{ display: "inline-block", }}>
                <span>
                    <video autoplay="" loop="" muted="true" playsinline="" style={{ ...this.state.imagehight, objectFit: 'cover', width: '100%', objectPosition: 'center center', display: 'block', maxWidth: '100%' }} controls>
                        <source src={this.props.propertyInfo.spaceVideo} type="video/mp4" />
                        <source src={this.props.propertyInfo.spaceVideo} type="video/ogg" />
                        Your browser does not support the video tag.
                    </video>
                </span>
            </div>
            this.setState({ thumbnailH1: "auto", Thumbnail_video: Thumbnail })
        }

        if (this.props.propertyInfo.spacePictures.length !== 0 && this.props.propertyInfo.request_type !== 'patch') {
            var x = this.props.propertyInfo.spacePictures;
            var z = URL.createObjectURL(x)
            var Thumbnail = <span><img key='0' id={'Did_thumbnail_'} style={{ ...this.state.imagehight, width: '100%', objectFit: 'cover', objectPosition: 'center center', maxWidth: '100%', display: 'block', maxWidth: '100%' }} src={z} alt='' /></span>
            this.setState({ Thumbnail: Thumbnail, thumbnailH: "400px" })
        }
        else {
            var Thumbnail = <span><img key='0' id={'Did_thumbnail_'} style={{ ...this.state.imagehight, width: '100%', objectFit: 'cover', objectPosition: 'center center', maxWidth: '100%', display: 'block', maxWidth: '100%' }} src={this.props.propertyInfo.spacePictures} alt='' /></span>
            this.setState({ Thumbnail: Thumbnail, thumbnailH: "400px" })
        }
        if (this.props.propertyInfo.media_images.length !== 0 && this.props.propertyInfo.request_type !== 'patch') {
            var x = this.props.propertyInfo.media_images;
            var arr = [], media_images = [];
            for (let i = 0; i < x.length; i++) {
                if (x[i] !== '') {
                    arr[i] = URL.createObjectURL(x[i])
                    media_images[i] =
                        <div id={'img_' + i} key={i} style={{ display: "inline-block", }}>
                            <Fab color="secondary" aria-label="add" size="small" style={{ verticalAlign: "top", position: "absolute" }} onClick={(e) => this.remove_img(i + '')}>
                                <RemoveIcon />
                            </Fab>
                            <img key={i} style={{ width: "100%", height: '200px', display: 'inline-block' }} src={arr[i]} alt='' />
                        </div>
                }
            }
            this.setState({ img_preview: media_images, arr: arr });
        }
        else {
            var x = this.props.propertyInfo.media_images;
            var content_type = this.props.propertyInfo.content_type
            var arr = [], media_images = [];
            for (let i = 0; i < x.length; i++) {
                if (x[i] !== '' && content_type[i] !== 'video') {
                    media_images[i] =
                        <div id={'img_' + i} key={i} style={{ display: "inline-block" }}>
                            <Fab color="secondary" aria-label="add" size="small" style={{ verticalAlign: "top", position: "absolute" }} onClick={(e) => this.remove_img(i + '')}>
                                <RemoveIcon />
                            </Fab>
                            <img key={i} style={{ width: "100%", height: '200px', display: 'inline-block' }} src={x[i]} alt='' />
                        </div>
                }
            }
            this.setState({ img_preview: media_images });
        }
        setTimeout(() => {
            this.setState({ StackGrid_st: { maxWidth: '100%' } });
        }, 10);

    }
    w() {
        if (document.getElementById('Did_thumbnail_') !== null) {
            var x = '400px'
            if (mql.matches)
                document.getElementById('Did_thumbnail_').style.width = '100%'
            else {
                document.getElementById('Did_thumbnail_').style.width = '100%'
                x = '200px'
            }
            this.setState({ thumbnail_img_W: x })
        }
    }

    screen_sizes() {
        if (mql.matches) {
            this.setState({ mainDiv: { display: 'grid', gridTemplateColumns: '24rem', marginTop: 20 }, thumbnailW: "750px", bigDiv: { width: '100%' }, thumbnail_img_W: "700px", marginLeft: "0px", btnwidth: { width: 199 }, sub_div_media_form: { marginTop: 200 }, GridContainer: { width: '70%' }, imagehight: { maxHeight: '38vh', width: '100%', height: 'auto' }, dropGrid: { display: 'grid', gridTemplateColumns: '300px 300px', margin: 'auto' } })
        } else {
            this.setState({ mainDiv: { marginTop: 180 }, thumbnailW: "350px", thumbnail_img_W: "300px", bigDiv: { width: '100%' }, marginLeft: '0', btnwidth: 129, sub_div_media_form: '180px', GridContainer: { width: '100%' }, imagehight: { maxHeight: '30vh', width: '100%', height: 'auto' }, dropGrid: { display: 'grid', gridTemplateColumns: 'auto' } })
        }
        if (document.getElementById('Did_thumbnail_') !== null)
            document.getElementById('Did_thumbnail_').style.width = this.state.thumbnail_img_W;
    }

    remove_img(prop, e) {
        var index = prop
        index = parseInt(index)
        var x = this.state.img_preview
        x[index] = "";
        var array = this.state.deletedmedia
        var f = this.state.deletedmedia.length
        array[f] = prop
        this.setState({ deletedmedia: array })
        this.setState({ img_preview: x })
        this.props.remove_img(index)
    }
    remove_media = deletedmedia => {
        var x = this.props.propertyInfo.mediaDict
        for (var i = 0; i < deletedmedia.length; i++) {
            if (x[deletedmedia[i]] !== undefined) {
                axios({
                    method: "delete",
                    url: "/media/" + x[deletedmedia[i]].Id + "/",
                    headers: {
                        'Authorization': 'jwt ' + localStorage.getItem('token')
                    }
                }).then(response => {
                    this.props.jump_step()

                }).catch(e => {
                })
            } else
                return ''
        }
    }

    thumbnail_preview = e => {
        if (e.target.files[0] !== undefined) {
            var z = URL.createObjectURL(e.target.files[0])
            var Thumbnail = <span><img key='0' id={'Did_thumbnail_'} style={{ ...this.state.imagehight, objectFit: 'cover', width: '100%', objectPosition: 'center center', display: 'block', maxWidth: '100%' }} src={z} alt='' /></span>
            this.setState({ thumbnailH: "400px", Thumbnail: Thumbnail })
        }
    }
    thumbnail_preview_video = e => {
       
        if (e.target.files[0] !== undefined) {
            var z = URL.createObjectURL(e.target.files[0])
            var Thumbnail = <div style={{ display: "inline-block", }}>
                <span>
                    <video   autoplay="" loop="" muted="true" playsinline="" key='0' id={'Did_thumbnail_id'} style={{ ...this.state.imagehight, objectFit: 'cover', width: '100%', objectPosition: 'center center', display: 'block', maxWidth: '100%' }} controls >
                   
                        <source src={z} type="video/mp4" />
                        <source src={z} type="video/ogg" />
                    </video>
                </span>
            </div>
            this.setState({ thumbnailH1: "auto", Thumbnail_video: Thumbnail })
        }
    }

    //this commented code might help in choosing media images without using library to order them, it is fixed so by this code you cant order images.


    // img_previews = e => {

    //     var x = this.state.img_preview
    //     var str = []; let index_remove = 0;
    //     for (let i = 0; i < e.target.files.length; i++) {
    //         if (e.target.files[i] !== undefined) {
    //             var z = URL.createObjectURL(e.target.files[i])
    //             str[i] = (x.length)
    //             index_remove = x.length
    //             var y =
    //                 <div id={'img_' + x.length} key={x.length}
    //                 >
    //                     <div id={'remove_btn_' + str[i]} className={'remove_btn_' + str[i]} onClick={(e) => this.remove_img(str[i], e.target.innerText)}>
    //                         <span>
    //                             {/* {'img '+str[i]} */}
    //                         </span>
    //                         <Fab color="secondary" aria-label="add" size="small" style={{ verticalAlign: "top", position: "absolute" }} >
    //                             <RemoveIcon />
    //                             <span style={{ visibility: 'hidden' }}>
    //                                 {str[i]}
    //                             </span>
    //                         </Fab>
    //                     </div>
    //                     <img key={x.length} style={{ width: "100%", height: '200px', display: 'inline-block' }} src={z} alt='' />
    //                     {/* </Grow> */}
    //                 </div>
    //             x[x.length] = y
    //             var Timeout = 100;
    //             if (i > 0) Timeout = 5000;
    //             setTimeout(() => {
    //                 this.setState({ img_preview: x })
    //             }, Timeout);

    //         }
    //     }

    // }

    submitChanges = () => {
        if (this.props.propertyInfo.updateSpaceImage == true) {
            var sendSpacePic = new FormData();
            sendSpacePic.append('image_url', this.props.propertyInfo.spacePictures)
            axios({
                method: 'PATCH',
                url: '/spaces/' + this.props.propertyInfo.space_id2,
                headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
                data: sendSpacePic
            }).then(response => {
                // console.log(response.data)


            }).catch(err => {
            })
        }
        if (this.props.propertyInfo.updateSpaceVideo == true) {
            var send_all = new FormData()
            send_all.append('media_url', this.props.propertyInfo.spaceVideo)
            send_all.append('media_type', 'video')
            send_all.append('space_id', this.props.propertyInfo.space_id2)

            if (this.props.propertyInfo.spaceVideo_old_value.length !== 0 ) {
                axios({
                    url: '/media/' + this.props.propertyInfo.spaceVideo_old_value_id + '/',
                    method: 'delete',
                    headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') }
                }).then(res => {

                }).catch(error => {
                    return swal.fire({
                        icon: 'error',
                        title: 'error',
                        text: 'something went wrong with deleting spave video',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })


            }

            axios({
                url: '/media/',
                method: 'post',
                headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
                data: send_all, AxiosConfig
            }).then(response => {
                // console.log(response.data)
            }).catch(err => {
                return swal.fire({

                    icon: 'error',
                    title: 'error',
                    text: 'something went wrong with media images',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
        }
        this.props.handleMediaImagesChange(this.state.updated_media_images)
        if (this.state.deletedmedia.length !== 0)
            this.remove_media(this.state.deletedmedia)


        this.props.jump_step()
    }

    render() {
        const style = {
            content: {
                border: '3px dashed rgb(61, 72, 82)',
                minHeight: 250
            },
            header: {
                display: 'flex'
            },
            button: {
                backgroundColor: 'white',
                borderRadius: '3px',
                padding: '7px 12px',
                fontSize: '12px',
                color: '#39354E',
                textAlign: 'center',
                cursor: 'pointer',
                minWidth: '150px',
                outline: 'none',
                borderRadius: '20px', boxShadow:
                    '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)',
                border: '1px solid transparent'
            },

            totalProgress: {
                height: 30,
                lineHeight: '30px',

                background: 'transparent',
                transition: 'width 1000ms ease',
                textAlign: 'center',
                color: 'black',
                fontSize: 18,

                marginBottom: '5px',

                borderRadius: '3px'
            },

            dropAreaText: {
                textAlign: 'center',
                margin: '60px 0',
                fontFamily: 'Helvetica Neue',
                fontWeight: '300',
                fontSize: 50
            },

            imageArea: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
            },

            image: {
                width: 250,
                height: 300,
                fontFamily: 'Helvetica Neue',
                position: 'relative',
                overflow: 'hidden'
            },

            controls: {
                display: 'flex'
            },

            selectBTN: {
                flex: 1,
                background: 'green',
                color: '#fff',
                padding: '6px 0'
            },
            removeBTN: {
                flex: 1,
                background: 'red',
                color: '#fff',
                padding: '6px 0'
            },

            progress: {
                height: 3,
                background: 'rgb(0, 122, 255)',
                transition: 'width 500ms ease',
                borderRadius: '3px'
            },

            error: {
                width: 50,
                height: 50,
                background: 'red',
                borderRadius: 25,
                position: 'absolute',
                top: 'calc(50% - 25px)',
                left: 'calc(50% - 25px)',
            },

            done: {
                width: 50,
                height: 50,
                background: 'green',
                borderRadius: 25,
                position: 'absolute',
                top: 'calc(50% - 25px)',
                left: 'calc(50% - 25px)',
            },

            selected: {
                border: '2px solid green'
            }
        }

        const Image = ({
            uid,
            name,
            size,
            done,
            abort,
            click,
            error,
            remove,
            select,
            source,
            upload,
            refresh,
            progress,
            selected,
            uploading
        }) => (<div style={{
            width: 300,
            height: 190,
            fontFamily: 'Helvetica Neue',
            position: 'relative',
            overflow: 'hidden'
            , ...(selected ? style.selected : {})
        }}>

            <img src={source} alt="" style={{ padding: 10, width: 300, height: 200 }} />

        </div>)
        const elements = this.state.img_preview
        const limit = 12
        return (
            <div style={{ ...this.state.mainDiv, margin: 'auto', backgroundColor: '' }}>
                <div style={{ gridColumn: '2', backgroundColor: '' }}>
                    <Grid
                        container
                        direction='column'
                        style={{ backgroundColor: '' }}
                    >
                        <div id='sub_div_media_form' style={{ display: 'grid', justifyContent: 'flex-start' }}>
                            <Grid item direction='row' style={{ marginTop: "20px", backgroundColor: '' }} lg={12} xl={12} md={12} sm={12} xs={12}  >
                                <input
                                    accept='image/*'
                                    style={{ display: 'none' }}
                                    id='contained-button-file'
                                    // id="file"
                                    // onChange={ this.checkFileDetails}
                                    // value={this.props.propertyInfo.spacePictures}

                                    onChange={this.props.handleChange('spacePictures')}
                                    onInput={this.thumbnail_preview}
                                    onClick={(e) => this.setState({ up_buttonClicked: true })}
                                    // multiple
                                    type='file'
                                />

                                <label htmlFor='contained-button-file' style={{ fontSize: '20px', fontFamily: 'founders', marginRight: '200px', backgroundColor: '' }}>
                                    Upload {this.state.spaceCategory} image
                        <Button
                                        variant='contained'
                                        style={{ marginLeft: 0, backgroundColor: 'white', color: 'black', border: '1px solid black', marginLeft: '20px', ...this.state.uploadColor, ...style.button }}
                                        component='span'
                                    >
                                        Upload
                        </Button>
                                </label>

                            </Grid>

                            <Grid item style={{ backgroundColor: '' }}>
                                <div style={{ backgroundColor: '#D8D8D8', maxWidth: '100%', maxHeight: '50%' }}>
                                    <div style={{ width: this.state.thumbnailW, minHeight: this.state.thumbnailH, padding: "25px", backgroundColor: '#D8D8D8' }}>
                                        {this.state.Thumbnail}
                                    </div>

                                </div>

                            </Grid>
                            <Grid item style={{ backgroundColor: '' }}>
                                <div style={{ maxWidth: '100%', maxHeight: '50%' }}>
                                    <div style={{ width: this.state.thumbnailW, padding: "25px", }}>

                                        <Grid item direction='row' style={{ marginTop: "20px", backgroundColor: '' }} lg={12} xl={12} md={12} sm={12} xs={12}  >
                                            <input
                                                accept="video/mp4,video/x-m4v,video/*"
                                                style={{ display: 'none' }}
                                                id='video'
                                                onChange={this.props.handleChange('spaceVideo')}
                                                onInput={this.thumbnail_preview_video}
                                                onClick={(e) => this.setState({ up_buttonClicked: true })}
                                                // multiple
                                                type='file'
                                            />

                                            <label htmlFor='video' style={{ fontSize: '20px', fontFamily: 'founders', marginRight: '200px', backgroundColor: '' }}>

                                                <Button
                                                    variant='contained'
                                                    style={style.button}
                                                    component='span'
                                                >
                                                    Upload video
                                                                        </Button>
                                            </label>
                                        </Grid>
                                        <Grid item style={{ backgroundColor: '' }}>
                                            <div style={{ border: '1px solid lightgray' }}>
                                                <div style={{ width: '100%', minHeight: 'auto', padding: "25px", }}>
                                                    {this.state.Thumbnail_video}
                                                </div>
                                            </div>

                                        </Grid>

                                    </div>

                                </div>

                            </Grid>






                            {this.props.propertyInfo.bannarImageSelected == true &&
                                <div style={{ height: "fit-content", marginBottom: "30px", marginTop: '30px' }}>
                                    {/* <h6 style={{ display: 'inline-block' }}>More images:</h6> */}
                                    {/* <Fab color="primary" aria-label="add" size="small" style={{float:"right"}} onClick={(e) => document.getElementById("contained-button-file").click()}>
                        <AddIcon />
                    </Fab> */}
                                    <Grid item direction='row' style={{ marginTop: "20px", backgroundColor: '' }}  >
                                        <Grid item lg={12} xs={12} xl={12} md={12} sm={12} style={{}} >
                                            <button
                                                style={style.button}
                                                onClick={() => {
                                                    this.setState({
                                                        result: true,
                                                        overflow: 'hidden',
                                                        display: 'none',
                                                        marginBottom: '20px'
                                                    })
                                                }}>
                                                + Add more images
                                            </button>

                                            {this.state.result === true &&
                                                <div>



                                                    <RUG header={false} rules={{ limit }} style={{ marginTop: '20px' }}
                                                        customRequest={this.props.customRequest} onChange={
                                                            (images) => {
                                                                if (this.props.propertyInfo.request_type === 'patch')
                                                                    this.setState({ updated_media_images: images })
                                                                else this.setState({ current_media_images: images });
                                                            }}
                                                        // onConfirmDelete={() => window.confirm('Are you sure you want to delete?')}
                                                        onDeleted={(deletedImage, images) => {
                                                            // console.log(deletedImage, images)
                                                            if (deletedImage.selected && images.length) {
                                                                images[0].select()
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            (images, { openDialogue }) => {
                                                                const length = images.length
                                                                return <DropArea style={{ backgroundColor: '' }}>
                                                                    {
                                                                        isDrag => <div style={{ ...style.content, ...(isDrag ? { borderColor: 'rgb(0, 122, 255)', backgroundColor: '' } : {}) }}>
                                                                            <div style={style.header, { backgroundColor: '' }}>
                                                                                <button
                                                                                    style={style.button}
                                                                                    onClick={openDialogue}>
                                                                                    +
                                                                            </button>
                                                                                <div style={{ flex: 1, margin: 7 }}>
                                                                                    <div style={{
                                                                                        ...style.totalProgress,
                                                                                        width: '100%'
                                                                                    }}>
                                                                                        <span style={{ marginRight: 10 }}>
                                                                                            {length > 0 && `${length} / ${limit}`}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <DragArea className="rug-items __card" style={{ ...this.state.dropGrid }}>
                                                                                {image => <div style={{ border: `1px solid ${image.selected ? 'green' : 'transparent'}`, position: 'relative', zIndex: 2 }}>
                                                                                    <Fab color="secondary" aria-label="add" size="small" style={{ verticalAlign: "top", position: "absolute", zIndex: 1 }} onClick={image.remove}>
                                                                                        <RemoveIcon />
                                                                                    </Fab>
                                                                                    <Image {...image} style={{ padding: '5px' }} />
                                                                                </div>}
                                                                            </DragArea>
                                                                        </div>
                                                                    }
                                                                </DropArea>
                                                            }
                                                        }
                                                    </RUG>
                                                </div>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>

                            }
                            <div style={{
                                // overflow:'auto',
                                // maxWidth:"600px",

                            }}>

                                <StackGrid id='StackGrid' columnWidth={300} style={this.state.StackGrid_st}>

                                    {this.state.img_preview.map(function (x, index) {

                                        var x = []
                                        x = elements[index]
                                        return (x)
                                    })
                                    }</StackGrid></div>
                            <Grid container item justify="space-between" xl={12} lg={12} md={12} sm={12} xs={12} style={{ backgroundColor: '', marginBottom: '40px' }}>
                                {this.props.propertyInfo.request_type === 'post' &&
                                    <Grid container item justify="space-between" xl={12} lg={12} md={12} sm={12} xs={12} >
                                        <Button1
                                            variant='contained'
                                            label='Back'
                                            color='secondary'
                                            size='large'
                                            style={{ marginTop: 80, marginRight: 30, backgroundColor: '#39354E', color: 'white', ...this.state.btnwidth, height: 44, fontSize: 20, fontFamily: 'HelveticaNeue', borderRadius: '20px', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}

                                            onClick={this.formPrevious}>
                                            Back
                                     </Button1>

                                        <Button1
                                            variant='contained'
                                            label='Continue'
                                            color='primary'
                                            size='large'
                                            style={{ marginTop: 80, backgroundColor: 'white', color: '#39354E', ...this.state.btnwidth, height: 44, fontSize: 20, fontFamily: 'HelveticaNeue', borderRadius: '20px', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}
                                            onClick={this.formContinue}>
                                            Continue
                             </Button1>
                                    </Grid>

                                }
                                {this.props.propertyInfo.request_type === 'patch' &&
                                    <Button1
                                        variant='contained'
                                        label='Save Changes'
                                        color='primary'
                                        size='large'
                                        style={{
                                            marginTop: 80, backgroundColor: '#39354E', color: 'white', width: 199, height: 44, fontSize: 20, fontFamily: 'HelveticaNeue', borderRadius: '20px', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)',
                                            border: '1px solid transparent'
                                        }}
                                        onClick={this.submitChanges}
                                    >
                                        Save Changes
                         </Button1>
                                }
                            </Grid>
                        </div>
                    </Grid>
                </div>
            </div>
        )
    }
}
