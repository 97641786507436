import Typography from '@material-ui/core/Typography';
import 'bootstrap/dist/css/bootstrap.css';
import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert2';
import axios from '../../axios';
import { API_URL } from "../../config";
import MountainImg from './images/forall.jpg';
import yourspace from './yourspace.svg';

const mql = window.matchMedia(`(min-width: 950px)`);
class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      minDev: {},
      formcontrolwidth: {},
      textwidth: {},
      buttonWidth: {},
      imgSize: {},
      thirddivSize: {},
      lasttextcolor: {}
    }
    this.componentQueryChange = this.componentQueryChange.bind(this)
  }

  componentDidMount() {
    localStorage.setItem('RegisterOrder', "false11")
    if (mql.matches) {
      this.setState({
        formcontrolwidth: {
          width: 500
        },
        textwidth: {
          marginLeft: '170px',
          marginTop: '40px'
        },
        buttonWidth:
        {
          width: 180
        },
        imgSize: {
          width: 500
        },
        thirddivSize: {
          width: '780px',
          height: 'auto',
          opacity: 0.8,
          backgroundColor: 'white'
        },
        lasttextcolor: {
          color: 'black'
        }

      })

    } else {
      this.setState({
        formcontrolwidth: {
          width: 300
        },
        textwidth: {

        }, buttonWidth:
        {
          width: 129
        },
        imgSize: {
          width: 250
        },
        thirddivSize: {

        },
        lasttextcolor: {
          color: 'white'
        }
      })
    }

  }
  componentWillMount() {
    mql.addListener(this.componentQueryChange)

  }
  componentWillUnmount() {
    mql.removeListener(this.componentQueryChange)

  }
  componentQueryChange() {
    if (mql.matches) {
      this.setState({
        formcontrolwidth: {
          width: 500
        },
        textwidth: {
          marginLeft: '170px',
          marginTop: '40px'
        }, buttonWidth:
        {
          width: 180
        },
        imgSize: {
          width: 500
        },
        thirddivSize: {
          width: '780px',
          height: 'auto',
          opacity: 0.8,
          backgroundColor: 'white'
        },
        lasttextcolor: {
          color: 'black'
        }
      })
    } else {
      this.setState({
        formcontrolwidth: {
          width: 300
        },
        textwidth: {

        }, buttonWidth:
        {
          width: 129
        },
        imgSize: {
          width: 250
        },
        thirddivSize: {

        },
        lasttextcolor: {
          color: 'white'
        }
      })
    }
  }

  handleChanges = (e, value) => {
    if (value === 1) {
      this.setState({
        email: e.target.value
      })
      // console.log(this.state.email)
    }
    else {
      this.setState({
        password: e.target.value
      })
      // console.log(this.state.password)
    }

  }
  handleClick = (e) => {
    localStorage.setItem('RegisterOrder', 'true')
    this.props.history.push('/host/register/')
    e.preventDefault()
  }

  send_info() {
    axios.post(
      `${API_URL}/login/`,
      {
        email: this.state.email,
        password: this.state.password
      },
      {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
    ).then(response => {
      if (response.data['user_info'].user_type !== 2) {
        return ('')
      }
      localStorage.setItem('token', response.data.token);
      axios({
        method: "get",
        url: `${API_URL}/users/` + response.data['user_info'].user_id + "/",
        headers: {
          'Authorization': 'jwt ' + localStorage.getItem('token')
        }
      }).then(response => {
        localStorage.setItem('first_name', response.data.first_name)
        localStorage.setItem('last_name', response.data.last_name)
        localStorage.setItem('email', response.data.email)
        localStorage.setItem('profile_image', response.data.profile_image)
        localStorage.setItem('id', response.data.id)
        this.props.history.push('/properties/')

      }).catch(err => {
        return swal.fire({
          icon: 'error',
          title: 'error',
          text: 'something went wrong ',
          showConfirmButton: false,
          timer: 1500
        })
      })
    })
      .catch(error => {
        // console.log(error.message)
        if (error.message == "Network Error") {
          return swal.fire({

            icon: 'error',
            title: 'error',
            text: 'Network Error',
            showConfirmButton: false,
            timer: 1500
          })

        }
        if (this.state.email.length === 0 || this.state.password.length === 0) {
          return swal.fire({
            icon: 'error',
            title: 'error',
            text: 'Empty TextField',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          return swal.fire({
            icon: 'error',
            title: 'error',
            text: 'Wrong email or password',
            showConfirmButton: false,
            timer: 1500
          })
        }
      })




  }
  render() {
    return (
      <div style={{}}>


        <div style={{ backgroundImage: `url(${MountainImg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh', backgroundAttachment: 'scroll', position: 'fixed', width: '100%' }}>
          <div style={{ ...this.state.thirddivSize, margin: '2% auto', padding: '5px' }}>


            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <img src={yourspace} style={{ ...this.state.imgSize, marginTop: "5%", cursor: 'pointer' }} onClick={() => this.props.history.push('/host/')} />
            </div>
            <div style={{ width: "30%", margin: "10% auto", ...this.state.formcontrolwidth }}>

              <Form action='/adminDashboard' style={{ ...this.state.formcontrolwidth, margin: 'auto auto', }}>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Label style={{}}>Email address:</Form.Label>
                  <Form.Control
                    required
                    id='email'
                    type='email'
                    value={this.state.email}
                    style={{}}
                    placeholder='Enter email'
                    onChange={(e) => this.handleChanges(e, 1)}
                  />
                </Form.Group>
                <Form.Group controlId='formBasicPassword'>
                  <Form.Label style={{}}>Password:</Form.Label>
                  <Form.Control
                    required
                    id='password'
                    type='password'
                    placeholder='Password'
                    style={{}}
                    value={this.state.password}
                    onChange={(e) => this.handleChanges(e, 2)}
                  />
                </Form.Group>
                <Typography>
                  <a href="http://127.0.0.1:8000/users/forgot_password_page/" style={{ color: '#08D888', fontFamily: 'founders' }}>Forget Password</a>
                </Typography>
                {/* <Form.Group controlId='formBasicCheckbox'>
            <Form.Check type='checkbox' label='Remember me' />
          </Form.Group> */}
              </Form>
              <Button variant='primary'
                style={{ marginLeft: "32%", marginTop: '5%', backgroundColor: '#08D888', color: '#FFFFFF', ...this.state.buttonWidth, height: 44, fontSize: 20, fontFamily: 'HelveticaNeue', border: '1px solid white' }}
                onClick={e => this.send_info()}>
                {' '}
                login{' '}
              </Button>

              <Typography style={{ marginTop: 40, fontFamily: 'founders', ...this.state.lasttextcolor }}>
                if you dont have an account please register <a onClick={this.handleClick} href="/host/register/" style={{ color: '#08D888' }}>here</a>
              </Typography>


            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(AdminLogin);
