import React from 'react';
import 'date-fns';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button1 from 'react-bootstrap/Button';
import Button from '@material-ui/core/Button'
import DateFnsUtils from '@date-io/date-fns';
import './Forms.css'
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { MenuItem, InputLabel, Select, Input } from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert2'
const mql = window.matchMedia(`(min-width: 50em)`)
class SpaceAvailabilityForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      time_range: [0, 24],
      time_c: [],
      num_of_available_hours: 0,
      week: [true, true, true, true, true, true, true],
      buttonWidth: {},
      hour_color_btn: { color: 'orange' },
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checked: false,
      sun: 'sun',
      minDev: {},
      buttonWidh: {},
      buttonHoursWidth: {},
      marginContentRight: {},
      avai_hours: {},
      available_hours_calc: 24,
      errorWidth: {},
      GridContainer: {},
      Rent_type: [],
      hourly_rent_flag: false,
      daily_rent_falg: false,
      textSar: {},
      space_price_flag: false,
      currency_flag: false,
      check_in_time_flag: false,
      check_out_time_flag: false,
      daily_space_price_flag: false,
      GridWidth: {},
      DaysGrid: {},

      display:'none',
      displayFinish:'block'
    }
    this.componentMediaQuery = this.componentMediaQuery.bind(this)
  }
  componentDidMount() {
    this.componentMediaQuery()

  }
  componentWillMount() {
    mql.addListener(this.componentMediaQuery)
  }
  componentWillUnmount() {
    mql.removeListener(this.componentMediaQuery)
  }
  componentMediaQuery() {
    if (mql.matches) {
      this.setState({
        minDev: {
          display: 'grid',
          gridTemplateColumns: '24rem',
          marginTop: '40px',
          backgroundColor: ''
        },
        buttonWidh: {
          width: 199
        },
        buttonHoursWidth: {
          width: 100
        },
        marginContentRight: {

        },
        avai_hours: {
          width: 750
        },
        errorWidth: {
          width: '700px'
        },
        GridContainer: {
          width: '70%'
        },
        textSar: {
          marginTop: '7%'
        },
        GridWidth: {
          width: '80%',
          border: '2px solid lightgrey',
          padding: '10px'
        },
        DaysGrid: {
          display: 'grid',
          gridTemplateColumns: 'auto auto auto'
        }
      })
    } else {
      this.setState({
        minDev: {
          marginTop: '160px'
        },
        buttonWidh: {
          width: '100%'
        },
        buttonHoursWidth: {
          width: 50
        },
        marginContentRight: {
          marginRight: '10%'
        },
        avai_hours: {
          width: 350
        },
        errorWidth: {
          width: '300px'
        },
        GridContainer: {
          width: '100%'
        },
        textSar: {
          marginTop: '16%'
        },
        GridWidth: {
          width: '100%',
          border: '2px solid lightgrey',
          padding: '10px'
        },
        DaysGrid: {
          display: 'grid',
          gridTemplateColumns: 'auto auto'
        }
      })
    }
  }
  formContinue = e => {
    this.props.disabledLastbuttonfunction()
    if (this.props.propertyInfo.Rent_type[0] == false && this.props.propertyInfo.Rent_type[1] == false) {
      return swal.fire({
        icon: 'error',
        title: 'error',
        text: 'you should check at least one of rent type choices',
        showConfirmButton: false,
        timer: 1500
      })
    }
    this.setState({
      display:'block',
      displayFinish:'none'
    })
    var flag = false
    var flag2 = false
    if (this.props.propertyInfo.currency_value === '') {
      return this.setState({
        currency_flag: true
      })
    } else
      this.setState({
        currency_flag: false
      })
    if (this.props.propertyInfo.daily_space_price === '' && this.props.propertyInfo.Rent_type[0] == true) {
      return this.setState({
        daily_space_price_flag: true
      })
    } else this.setState({ daily_space_price_flag: false })

    if (this.props.propertyInfo.check_in_time === '' && this.props.propertyInfo.Rent_type[0] == true) {
      return this.setState({ check_in_time_flag: true })
    } else this.setState({ check_in_time_flag: false })

    if (this.props.propertyInfo.check_out_time === '' && this.props.propertyInfo.Rent_type[0] == true) {
      return this.setState({ check_out_time_flag: true })
    } else this.setState({ check_out_time_flag: false })

    if (this.props.propertyInfo.spacePrice === '' && this.props.propertyInfo.Rent_type[1] == true) {
      return this.setState({
        space_price_flag: true
      })
    }
    else { this.setState({ space_price_flag: false }) }

    var available_hours = []
    var count = 0

    for (var i = 0; i < this.props.propertyInfo.available_hours.length; i++) {
      if (this.props.propertyInfo.available_hours[i] == '0') {
        flag = true
        break

      } else {
        available_hours[count] = this.props.propertyInfo.available_hours[i]
        count = count + 1
        flag = false
      }
    }
    if (available_hours.length == 24)
      return swal.fire({
        icon: 'error',
        title: 'error',
        text: 'you should have at least one available hour',
        showConfirmButton: false,
        timer: 1500
      })

    var available_week = []
    var week_count = 0
    for (var i = 0; i < this.props.propertyInfo.week.length; i++) {
      if (this.props.propertyInfo.week[i] == '0') {
        flag2 = true
        break

      } else
        available_week[week_count] = this.props.propertyInfo.week[i]
      week_count = week_count + 1
    }
    if (available_week.length == 7) {
      return swal.fire({
        icon: 'error',
        title: 'error',
        text: 'you should have at least one available day',
        showConfirmButton: false,
        timer: 1500
      })
    }


    e.preventDefault();
    this.props.nextStep();
  };

  // Go Back to the previous form
  formPrevious = e => {
    e.preventDefault();
    this.props.prevStep();
  };


  timeC(index) {
    this.props.abledLastbuttonfunction()
    var time_c = this.state.time_c, num_of_available_hours = this.state.num_of_available_hours;
    if (this.props.propertyInfo.available_hours[index] === '0') {
      this.setState({
        checked: true
      })
      time_c[index] = { borderBottom: 'solid orange', color: 'orange', marginBottom: 5 }
      this.props.cal_available_hours(0)
      this.props.time_c('notClicked', index)
      ++num_of_available_hours;

    }
    else if (this.props.propertyInfo.available_hours[index] === 'x') {
      this.setState({
        checked: false
      })
      time_c[index] = {}
      --num_of_available_hours;
      this.props.cal_available_hours(1)
      this.props.time_c('Clicked', index)
    }
    this.setState({ time_c: time_c, num_of_available_hours: num_of_available_hours })
    this.props.avai_hours(index)
  }
  handle_week_Change(index) {
    this.props.abledLastbuttonfunction()
    var week = this.state.week;
    week[index] = !week[index]
    this.setState({ week: week })
    this.props.handle_week_Change(index)
  }
  handle_Rent_type_change = (value, e) => {

    if (value === 0 && this.state.daily_rent_falg === false) {
      this.setState({
        daily_rent_falg: true
      })
    } else if (value === 0 && this.state.daily_rent_falg === true) {
      this.setState({
        daily_rent_falg: false
      })
    }
    if (value === 1 && this.state.hourly_rent_flag === false) {
      this.setState({
        hourly_rent_flag: true
      })
    } else if (value === 1 && this.state.hourly_rent_flag === true) {
      this.setState({
        hourly_rent_flag: false
      })

    }
    this.props.handle_Rent_type(value, this.state.daily_rent_falg, this.state.hourly_rent_flag)
  }

  render() {
    const currency = this.props.propertyInfo.currency.length === 0 ? [] : this.props.propertyInfo.currency
    return (
      <div style={{ ...this.state.minDev, margin: 'auto' }}>
        <div style={{ gridColumn: '2', backgroundColor: '' }}>
          <Grid
            container
            direction='column'
            justify="flex-start"
            id='sub_time_form'>
            {/* {this.props.propertyInfo.request_type === 'post' &&
          <FormControl component="fieldset" style={{backgroundColor:'',width:'400px'}}>
            <FormLabel component="legend">Contract duration</FormLabel>
            <RadioGroup aria-label="gender" defaultValue="three_months" name="customized-radios" style={{ display: 'inline' }}>
              <FormControlLabel value="three_months" control={
                <Radio
                  checked={this.props.propertyInfo.checkedvalue}
                  value='a'
                  disableRipple
                  style={{ color: '#08D888' }}
                />
              }
                onClick={this.props.handleChange('three_months')}
                
                label="three months" />
              <FormControlLabel value="six_months"

                control={
                  <Radio
                    disableRipple
                    checked={this.props.propertyInfo.checkedvalue1}
                    value='b'
                    style={{ color: '#08D888' }}
                  />
                }
                onClick={this.props.handleChange('six_months')}

                
                label="six months" />
              <FormControlLabel value="one_year" control={
                <Radio
                  checked={this.props.propertyInfo.checkedvalue2}
                  value='c'
                  disableRipple
                  style={{ color: '#08D888' }}
                />
              }
                onClick={this.props.handleChange('one_year')}
                label="one year" />
            </RadioGroup>
          </FormControl>

        } */}



            {/* {this.props.propertyInfo.request_type === 'post' &&
          <p style={{color:'black',backgroundColor:'',fontFamily:"founders"}}> Start from {this.props.propertyInfo.spaceStartDate} End {this.props.propertyInfo.spaceEndDate}</p>
        }
        {this.props.propertyInfo.request_type === 'patch' &&
          <span style={{fontFamily:'founders',fontSize:'20px'}}><span style={{ fontSize: '18px', color: 'black', textDecoration: 'underline',backgroundColor:'',fontFamily:'founders' }}>Contract duration</span> start from {this.props.propertyInfo.spaceStartDate} End {this.props.propertyInfo.spaceEndDate}</span>
        } */}
            <Grid container item justify="flex-start" style={{ backgroundColor: '' }}>
              <FormControl component="fieldset" style={{ marginTop: '20px' }}>
                <FormLabel component="legend" style={{ fontFamily: 'founders' }}>Choose Rent Type</FormLabel>
                <FormGroup style={{ display: 'grid', gridTemplateColumns: 'auto auto', backgroundColor: '' }}>
                  <div><FormControlLabel control={<Checkbox checked={this.state.Rent_type[0]} style={{ color: '#39354E' }} onChange={(e) => this.handle_Rent_type_change(0, e)} checked={this.props.propertyInfo.Rent_type[0] === true ? true : false} value='Daily Rent' />} label="Daily Rent" /></div>
                  <br />
                  <div><FormControlLabel control={<Checkbox checked={this.state.Rent_type[1]} style={{ color: '#39354E' }} onChange={(e) => this.handle_Rent_type_change(1, e)} checked={this.props.propertyInfo.Rent_type[1] === true ? true : false} value="Hourly Rent" />} label="Hourly Rent" /></div>
                </FormGroup>
              </FormControl>
            </Grid>


            {this.props.propertyInfo.Rent_type[0] === true &&
              <Grid container style={{ marginTop: '30px', ...this.state.GridWidth }}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <p style={{ fontSize: '24px', fontFamily: 'font_bold', color: '#39354E' }}>Daily Rent Details</p>
                </Grid>
                <Grid container item direction="row" justify="space-between" style={{}} >
                  <Grid item xl={7} lg={7} md={9} sm={12} xs={12}>


                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center' }} >
                      {this.props.propertyInfo.request_type === "post" &&


                        <Grid item style={{ backgroundColor: '' }} lg={2} sm={2} xs={2} md={2} xl={2}>
                          <FormControl style={{ width: '100%' }} >
                            <InputLabel htmlFor="currency_label">currency</InputLabel>
                            <Select
                              id="space_currency"

                              variant="standard"

                              id="space-currency"
                              style={{ width: '90%', backgroundColor: '' }}
                              value={this.props.propertyInfo.currency_value}
                              error={this.state.currency_flag}

                              //onClick={this.handleCurrency}
                              onChange={this.props.handleChange('currency_id', currency)}
                            >
                              {currency.map(function (name, index) {
                                return <MenuItem key={index} id="selectcity" value={currency[index].id}>{currency[index].code}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      }
                      {this.props.propertyInfo.request_type === "patch" &&
                        <Grid item lg={2} sm={2} xs={2} md={2} xl={2} style={{}}>
                          <FormControl style={{ width: '100%', backgroundColor: '' }} >
                            <InputLabel htmlFor="component-simple">currency</InputLabel>
                            <Input id="component-simple"
                              variant="standard"
                              error={this.state.currency_flag}


                              style={{ width: '90%' }}
                              value={this.props.propertyInfo.currency_value}
                            />
                          </FormControl>
                        </Grid>

                      }
                      <Grid item lg={10} sm={10} xs={10} md={10} xl={10} style={{}}>
                        <FormControl style={{ width: '100%', backgroundColor: '' }}  >
                          <InputLabel htmlFor="daily-price">daily price (including vat)</InputLabel>
                          <Input
                            id="daily-price"
                            value={this.props.propertyInfo.daily_space_price}
                            onChange={this.props.handleChange('daily_space_price')}
                            error={this.state.daily_space_price_flag}
                            type='number'
                            style={{ width: '100%' }}
                          />
                         
                        </FormControl>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>


                <Grid container item direction="row" spacing={1} xl={7} lg={7} md={9} sm={12} xs={12} style={{ marginTop: '5px' }} >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        error={this.state.check_in_time_flag}
                        value={this.props.propertyInfo.check_in_time}
                        onChange={this.props.handleChange('check_in_time')}

                        label="check in time"
                        style={{ width: '100%' }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />




                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <FormControl style={{ width: '100%' }}>
                        <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          error={this.state.check_out_time_flag}
                          value={this.props.propertyInfo.check_out_time}
                          label="check out time"
                          style={{ width: '100%' }}
                          onChange={this.props.handleChange('check_out_time')}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                        />

                      </FormControl>


                    </Grid>
                  </MuiPickersUtilsProvider>

                </Grid>



              </Grid>
            }





            {this.props.propertyInfo.Rent_type[1] === true &&
              <Grid container style={{ marginTop: '40px', ...this.state.GridWidth }}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <p style={{ fontSize: '24px', fontFamily: 'font_bold', color: '#39354E' }}>Hourly Rent Details</p>
                </Grid>

                <Grid container item direction="row" justify="space-between" style={{}}  >
                  <Grid item xl={7} lg={7} md={9} sm={12} xs={12}>


                    <div style={{ width: '100%', display: 'flex', alignContent: 'center', alignItems: 'center', alignSelf: 'center ' }} >
                      {this.props.propertyInfo.request_type === "post" &&


                        <Grid item style={{ backgroundColor: '' }} lg={2} sm={2} xs={2} md={2} xl={2}>
                          <FormControl style={{ width: '100%' }} >
                            <InputLabel htmlFor="currency_label">currency</InputLabel>
                            <Select
                              id="currecy_label"

                              variant="standard"


                              style={{ width: '90%', backgroundColor: '' }}
                              value={this.props.propertyInfo.currency_value}
                              error={this.state.currency_flag}

                              //onClick={this.handleCurrency}
                              onChange={this.props.handleChange('currency_id', currency)}
                            >
                              {currency.map(function (name, index) {
                                return <MenuItem key={index} id="selectcity" value={currency[index].id}>{currency[index].code}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      }
                      {this.props.propertyInfo.request_type === "patch" &&
                        <Grid item lg={2} sm={2} xs={2} md={2} xl={2} style={{}}>
                          <FormControl style={{ width: '100%' }} >
                            <InputLabel htmlFor="currency_hourly">currency</InputLabel>
                            <Input
                              id="currency_hourly"
                              error={this.state.currency_flag}
                              style={{ width: '90%' }}
                              value={this.props.propertyInfo.currency_value}
                            />
                          </FormControl>
                        </Grid>

                      }
                      <Grid item lg={10} sm={10} xs={10} md={10} xl={10} >
                        <FormControl style={{ width: '100%', backgroundColor: '', }}  >
                          <InputLabel htmlFor="hourly-price">hourly price (including vat)</InputLabel>
                          <Input
                            value={this.props.propertyInfo.spacePrice}
                            onChange={this.props.handleChange('spacePrice')}
                            error={this.state.space_price_flag}
                            
                            min={0}
                            type='number'
                            style={{ width: '100%' }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin='normal'
                          />


                        </FormControl>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Grid item >
                  <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{ marginTop: '30px' }}>
                    <FormControl component="fieldset" style={{ marginTop: '20px', ...this.state.avai_hours, backgroundColor: '' }}>
                      <FormLabel component="legend" style={{ fontFamily: 'founders' }}>Choose <span style={{ color: 'rgb(8, 216, 136)', textDecoration: 'underline', fontFamily: 'founders' }}>available</span> hours for rent</FormLabel></FormControl>

                  </Grid>
                  <br />
                </Grid>
                {/* <Grid item xl={7} lg={7} md={9} sm={12} xs={12} className='time' style={{}}>
                  {this.props.propertyInfo.spaceCategory == 1 &&
                    <Grid container item >
                      <Button onClick={(e) => this.timeC(0)} value={this.props.propertyInfo.available_shifts[0]} checked={{ ...this.props.propertyInfo.time_c[0] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[0] }}  >1st shift: 8AM - 12PM</Button>
                      <Button onClick={(e) => this.timeC(1)} value={this.props.propertyInfo.available_shifts[1]} checked={{ ...this.props.propertyInfo.time_c[1] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[1] }}>2nd shift: 12PM - 4PM</Button>
                      <Button onClick={(e) => this.timeC(2)} value={this.props.propertyInfo.available_shifts[2]} checked={{ ...this.props.propertyInfo.time_c[2] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[2] }}>3rd shift: 4PM - 8PM</Button>
                    </Grid>
                  }
                </Grid> */}


                <Grid container item>
                  <Grid item className='time' style={{}}>


                    <Grid container item >
                      <Button onClick={(e) => this.timeC(0)} checked={{ ...this.props.propertyInfo.time_c[0] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[0] }}>12 AM</Button>
                      <Button onClick={(e) => this.timeC(1)} checked={{ ...this.props.propertyInfo.time_c[1] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[1] }}>1 AM</Button>
                      <Button onClick={(e) => this.timeC(2)} checked={{ ...this.props.propertyInfo.time_c[2] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[2] }}>2 AM</Button>
                      <Button onClick={(e) => this.timeC(3)} checked={{ ...this.props.propertyInfo.time_c[3] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[3] }}>3 AM</Button>
                      <Button onClick={(e) => this.timeC(4)} checked={{ ...this.props.propertyInfo.time_c[4] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[4] }}>4 AM</Button>
                      <Button onClick={(e) => this.timeC(5)} checked={{ ...this.props.propertyInfo.time_c[5] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[5] }}>5 AM</Button>
                      <Button onClick={(e) => this.timeC(6)} checked={{ ...this.props.propertyInfo.time_c[6] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[6] }}>6 AM</Button>
                      <Button onClick={(e) => this.timeC(7)} checked={{ ...this.props.propertyInfo.time_c[7] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[7] }}>7 AM</Button>
                      <Button onClick={(e) => this.timeC(8)} checked={{ ...this.props.propertyInfo.time_c[8] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[8] }}>8 AM</Button>
                      <Button onClick={(e) => this.timeC(9)} checked={{ ...this.props.propertyInfo.time_c[9] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[9] }}>9 AM</Button>
                      <Button onClick={(e) => this.timeC(10)} checked={{ ...this.props.propertyInfo.time_c[10] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[10] }}>10 AM</Button>
                      <Button onClick={(e) => this.timeC(11)} checked={{ ...this.props.propertyInfo.time_c[11] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[11] }}>11 AM</Button>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid item className='time' style={{}}>
                    <Grid container item  >
                      <Button onClick={(e) => this.timeC(12)} checked={{ ...this.props.propertyInfo.time_c[12] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[12] }}>12 PM</Button>
                      <Button onClick={(e) => this.timeC(13)} checked={{ ...this.props.propertyInfo.time_c[13] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[13] }}>1 PM</Button>
                      <Button onClick={(e) => this.timeC(14)} checked={{ ...this.props.propertyInfo.time_c[14] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[14] }}>2 PM</Button>
                      <Button onClick={(e) => this.timeC(15)} checked={{ ...this.props.propertyInfo.time_c[15] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[15] }}>3 PM</Button>
                      <Button onClick={(e) => this.timeC(16)} checked={{ ...this.props.propertyInfo.time_c[16] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[16] }}>4 PM</Button>
                      <Button onClick={(e) => this.timeC(17)} checked={{ ...this.props.propertyInfo.time_c[17] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[17] }}>5 PM</Button>
                      <Button onClick={(e) => this.timeC(18)} checked={{ ...this.props.propertyInfo.time_c[18] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[18] }}>6 PM</Button>
                      <Button onClick={(e) => this.timeC(19)} checked={{ ...this.props.propertyInfo.time_c[19] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[19] }}>7 PM</Button>
                      <Button onClick={(e) => this.timeC(20)} checked={{ ...this.props.propertyInfo.time_c[20] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[20] }}>8 PM</Button>
                      <Button onClick={(e) => this.timeC(21)} checked={{ ...this.props.propertyInfo.time_c[21] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[21] }}>9 PM</Button>
                      <Button onClick={(e) => this.timeC(22)} checked={{ ...this.props.propertyInfo.time_c[22] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[22] }}>10 PM</Button>
                      <Button onClick={(e) => this.timeC(23)} checked={{ ...this.props.propertyInfo.time_c[23] }} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.props.propertyInfo.time_c[23] }}>11 PM</Button>
                    </Grid>
                  </Grid>

                </Grid>




                <br />





                <Grid container item >
                  {this.props.propertyInfo.spaceCategory !== 1 &&
                    <div style={{ marginTop: 5 }}>

                      <Typography style={{ fontFamily: 'founders', color: 'black' }}>Available hours for rent:
                    <span style={{ fontFamily: 'founders', color: '#08D888', }}> {this.props.propertyInfo.available_hours_calc} </span>hours</Typography>

                    </div>
                  }


                </Grid>


                <div>

                </div>
                {this.props.propertyInfo.Rent_type[0] === true &&
                  <Grid container item style={{ marginTop: '20px' }}  >


                    <FormControl component="fieldset" style={{ marginTop: '20px' }}>
                      <FormLabel component="legend" style={{ fontFamily: 'founders', color: 'black', fontSize: '17px' }}> <span style={{ color: 'red', fontSize: '23px' }}>*</span> Minimum daily rent is 7 days</FormLabel>

                      <FormGroup style={{ backgroundColor: '', ...this.state.DaysGrid }}>

                        <div><FormControlLabel control={<Checkbox checked={this.state.week[0]} style={{ color: 'grey' }} checked={true} value='sun' />} label="SUNDAY" /></div>

                        <div><FormControlLabel control={<Checkbox checked={this.state.week[1]} style={{ color: 'grey' }} checked={true} value="mon" />} label="MONDAY" /></div>

                        <div><FormControlLabel control={<Checkbox checked={this.state.week[2]} style={{ color: 'grey' }} checked={true} value="tues" />} label="TUESDAY" /></div>

                        <div><FormControlLabel control={<Checkbox checked={this.state.week[3]} style={{ color: 'grey' }} checked={true} value="wed" />} label="WEDNESDAY" /></div>

                        <div><FormControlLabel control={<Checkbox checked={this.state.week[4]} style={{ color: 'grey' }} checked={true} value='thur' />} label="THRUSDAY" /></div>

                        <div><FormControlLabel control={<Checkbox checked={this.state.week[5]} style={{ color: 'grey' }} checked={true} value='fri' />} label="FRIDAY" /></div>

                        <div><FormControlLabel control={<Checkbox checked={this.state.week[6]} style={{ color: 'grey' }} checked={true} value='sat' />} label="SATURDAY" /></div>

                      </FormGroup>


                    </FormControl>
                  </Grid>
                }

                {this.props.propertyInfo.Rent_type[0] !== true &&
                  <Grid container item style={{ marginTop: '20px' }}  >
                    <FormControl component="fieldset" style={{ marginTop: '20px' }}>
                      <FormLabel component="legend" style={{ fontFamily: 'founders' }}>Choose <span style={{ color: 'rgb(8, 216, 136)', textDecoration: 'underline', fontFamily: 'founders' }}>available</span> days for rent</FormLabel>

                      <FormGroup style={{ ...this.state.DaysGrid, backgroundColor: '' }}>

                        <div><FormControlLabel control={<Checkbox checked={this.state.week[0]} style={{ color: 'rgb(57, 53, 78)' }} onChange={() => this.handle_week_Change(0)} checked={this.props.propertyInfo.week[0] === '0' ? true : false} value='sun' />} label="SUNDAY" /></div>

                        <div><FormControlLabel control={<Checkbox checked={this.state.week[1]} style={{ color: 'rgb(57, 53, 78)' }} onChange={() => this.handle_week_Change(1)} checked={this.props.propertyInfo.week[1] === '0' ? true : false} value="mon" />} label="MONDAY" /></div>

                        <div><FormControlLabel control={<Checkbox checked={this.state.week[2]} style={{ color: 'rgb(57, 53, 78)' }} onChange={() => this.handle_week_Change(2)} checked={this.props.propertyInfo.week[2] === '0' ? true : false} value="tues" />} label="TUESDAY" /></div>

                        <div><FormControlLabel control={<Checkbox checked={this.state.week[3]} style={{ color: 'rgb(57, 53, 78)' }} onChange={() => this.handle_week_Change(3)} checked={this.props.propertyInfo.week[3] === '0' ? true : false} value="wed" />} label="WEDNESDAY" /></div>

                        <div><FormControlLabel control={<Checkbox checked={this.state.week[4]} style={{ color: 'rgb(57, 53, 78)' }} onChange={() => this.handle_week_Change(4)} checked={this.props.propertyInfo.week[4] === '0' ? true : false} value='thur' />} label="THURSDAY" /></div>

                        <div><FormControlLabel control={<Checkbox checked={this.state.week[5]} style={{ color: 'rgb(57, 53, 78)' }} onChange={() => this.handle_week_Change(5)} checked={this.props.propertyInfo.week[5] === '0' ? true : false} value='fri' />} label="FRIDAY" /></div>

                        <div><FormControlLabel control={<Checkbox checked={this.state.week[6]} style={{ color: 'rgb(57, 53, 78)' }} onChange={() => this.handle_week_Change(6)} checked={this.props.propertyInfo.week[6] === '0' ? true : false} value='sat' />} label="SATURDAY" /></div>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                }
              </Grid>
            }
            {/* <p>{this.props.propertyInfo.time_range[1] - this.props.propertyInfo.time_range[0]} hours</p> */}
            <Grid container item justify="space-between" style={{ backgroundColor: '', marginBottom: '40px' }} xl={10} lg={10} md={10} sm={12} xs={12}  >
              {this.props.propertyInfo.request_type === 'post' &&
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Button1
                    variant='contained'
                    label='Back'
                    color='secondary'
                    size='large'
                    style={{ marginTop: 80, marginRight: 30, backgroundColor: '#39354E', color: 'white', width: '100%', height: 44, fontSize: 20, fontFamily: 'HelveticaNeue', ...this.state.buttonWidh, borderRadius: '20px', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}
                    onClick={this.formPrevious}>
                    Back
          </Button1>
                </Grid>
              }
              {this.props.propertyInfo.request_type === 'patch' &&
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Button1
                    variant='contained'
                    label='Back'
                    color='secondary'
                    size='large'
                    style={{ marginTop: 80, marginRight: 30, backgroundColor: '#39354E', color: 'white', width: '100%', height: 44, fontSize: 20, fontFamily: 'HelveticaNeue', lineHeight: '1em', ...this.state.buttonWidh, borderRadius: '20px', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}
                    onClick={this.formPrevious}>
                    Edit Images
          </Button1>
                </Grid>
              }
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Button1
                  variant='contained'
                  label='Continue'
                  
                  color='primary'
                  size='large'
                  disabled={this.props.propertyInfo.disabledLastbutton}
                  style={{ marginTop: 80, backgroundColor: 'white', color: '#39354E', width: '100%', height: 44, fontSize: 20, fontFamily: 'HelveticaNeue', ...this.state.buttonWidh, borderRadius: '20px', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}
                  onClick={this.formContinue}>
                    <span style={{display:this.state.display}} className="fa fa-circle-o-notch fa-spin" ></span>
                    <span style={{display:this.state.displayFinish}}>Finish</span>
                  
          </Button1>

              </Grid>

            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default SpaceAvailabilityForm;
