import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import HomeIcon from '@material-ui/icons/Home';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MenuIcon from '@material-ui/icons/Menu';
import PaymentIcon from '@material-ui/icons/Payment';
import SettingsIcon from '@material-ui/icons/Settings';
import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink, Route, useLocation } from 'react-router-dom';
import Login from '../../AdminLogin/AdminLogin';
import office from '../../AdminLogin/yourspace.svg';
import img2 from './images/avatar.jpg';
import './side_style.css';



const drawerWidth = 323;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  root: {
    display: 'flex',
    color: 'black'
  },
  drawer: {
    [theme.breakpoints.up(767)]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    color: {
      co: 'black'
    }
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(767)]: {
      // display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: 'white',

    color: '#FFFFFF',
    overflowX: 600,
  },
  content: {
    flexGrow: 1,
    border: '1px blue solid',
    padding: theme.spacing(3),
  },
}));
function ResponsiveDrawer(props) {
  localStorage.setItem('RegisterOrder', 'Sidebar')
  React.useEffect(() => {
    if (window.location.href.toLowerCase().includes("".toLowerCase()) === '/')
      return ''
    listenerFunc()
  }, []);

  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [desktopOpen, setDesktopOpen] = React.useState(false);
  const [profile_image2, set_profile_image] = React.useState(null)

  var greenColor = ''
  if (window.location.href.toLowerCase().includes("properties".toLowerCase()))
    greenColor = '#08D888'
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const mql = window.matchMedia(`(max-width: 766px)`);
  const listenerFunc = () => {
    // Listening logic goes here
    if (!mql.matches) {
      // console.log('!mql.matches ', mql.matches)
      setDesktopOpen(true)
    } else {
      setDesktopOpen(false);
      //  console.log('mql.matches ', mql.matches)
    }
  };

  mql.addListener(listenerFunc);
  const handleLogOut = () => {
    localStorage.removeItem('first_name')
    localStorage.removeItem('last_name')
    localStorage.removeItem('id')
    localStorage.removeItem('email')
    localStorage.removeItem('token')
    localStorage.removeItem('profile_image')
    localStorage.removeItem('user_id')
    localStorage.removeItem('propertyName')
    localStorage.removeItem('propertyFloor');


    return <Route path="/host/login" component={Login} />
  }

 
  const { pathname } = useLocation();
  const drawer = (
    <div style={{ backgroundColor: "", }}>

      <div className={classes.toolbar} style={{ backgroundImage: `url(${office})`, backgroundColor: 'white', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center center', width: '11rem', margin: 'auto', height: '118px', }} />
      <Divider variant='fullWidth' light={true} />
      <List component='nav' style={{ backgroundColor: 'white', height: '100%', width: '300px', borderTop: '1px white solid', borderBottom: '0px solid transparent' }} >
        <ListItem style={{ marginTop: 40, width: '100%', position: 'relative' }}>
        
          <NavLink
            to='/properties/'
            style={{
              textDecoration: 'none',
              color: '#8C929B',
              fontSize: '24px',
              textAlign: 'center',
              width: '100%',
              position: 'absolute',
              height: '50px'
            }}
           
            isActive={() => ['/properties/', '/addproperties/', '/editproperty/:property_id'].includes(pathname)}
            activeStyle={{ color: 'rgb(8, 216, 136)', borderLeft: '3px solid blue', height: '50px', color: '#292735', backgroundColor: '', width: '100%', }}
          >
            
            <HomeIcon fontSize="large" color="inherit" to="/properties/" style={{marginRight:'25px'}} />

            <span style={{ marginRight: '70px' }}>Spaces</span>

          </NavLink>
        </ListItem>
        <ListItem style={{ marginTop: 40, width: '100%', position: 'relative' }}>
          
          <NavLink
            to='/reservations/'
            style={{
              textDecoration: 'none',
              color: '#8C929B',
              fontSize: '24px',
              textAlign: 'center',
              width: '100%',
              height: '50px',
            }}
            activeStyle={{ color: 'rgb(8, 216, 136)', borderLeft: '3px solid blue', height: '50px', color: '#292735' }}
          >
            <ListAltIcon fontSize="large" color="inherit" to="/properties/" style={{marginLeft:'25px'}} />
            <span style={{ marginLeft: 30 }}>Reservations</span>
          </NavLink>
        </ListItem>
        <ListItem style={{ marginTop: 40 }}>
         
          <NavLink
            to='/bank_info/'
            style={{
              textDecoration: 'none',
              color: '#8C929B',
              fontSize: '24px',
              textAlign: 'center',
              position: 'absolute',
              width: '100%',
              height: '50px',
            }}
            activeStyle={{ color: 'rgb(8, 216, 136)', borderLeft: '3px solid blue', height: '50px', color: '#292735' }}
          >
            <AccountBalanceIcon fontSize="large" color="inherit" to="/properties/" style={{marginRight:'25px'}} />

            <span style={{ marginRight: '42px' }}>Bank Info</span>
          </NavLink>
        </ListItem>
        <ListItem style={{  position: 'relative',marginTop:60 }}>
         
          <NavLink
            to='/payment/'
            style={{
              textDecoration: 'none',
              color: '#8C929B',
              fontSize: '24px',
              textAlign: 'center',
              position: 'absolute',
              width: '100%',
              height: '50px',
             
            }}

            activeStyle={{ color: 'rgb(8, 216, 136)', borderLeft: '3px solid blue', height: '50px', color: '#292735' }}

          >
            <PaymentIcon fontSize="large" color="inherit" to="/properties/" style={{marginRight:'25px'}}  />
            <span style={{ marginRight: '50px' }}>Payment</span>

          </NavLink>
        </ListItem>
        <ListItem style={{ marginTop: 60 }}>
         
          <NavLink
            to='/settings/'
            style={{
              textDecoration: 'none',
              color: '#8C929B',
              fontSize: '24px',
              textAlign: 'center',
              position: 'absolute',
              width: '100%',
              height: '50px',
              marginRight: '70px',
            

            }}
            activeStyle={{ color: 'rgb(8, 216, 136)', borderLeft: '3px solid blue', height: '50px', color: '#292735' }}

          >
            <SettingsIcon fontSize="large" color="inherit" to="/properties/" style={{marginRight:'25px'}} />
            <span style={{ marginRight: '55px' }}>Settings</span>

          </NavLink>
        </ListItem>
      </List>

      <Divider />
    </div>
  );

  const Container1 = () => {
    return <Container className="d-flex justify-content-end" style={{}} fluid={true} >
      <Row className="" >
        <Col style={{ backgroundColor: '', }}   >
          <Dropdown style={{}}>
            <Dropdown.Toggle variant="transparent" id="dropdown-basic" style={{ border: '0px solid' }}>
              {localStorage.getItem('profile_image') !== '' ? set_profile_image(localStorage.getItem('profile_image')) : set_profile_image(img2)}

              <Image src={profile_image2} roundedCircle style={{ width: '30px', height: '30px', marginRight: '5px' }} />
              <span style={{ paddingLeft: '6px', marginRight: '10px' }}>{localStorage.getItem('first_name')}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu >
              <Dropdown.Item href="/settings/">Account Settings</Dropdown.Item>
              <Dropdown.Item href="/host/login" onClick={() => {
                localStorage.removeItem('first_name')
                localStorage.removeItem('last_name')
                localStorage.removeItem('id')
                localStorage.removeItem('email')
                localStorage.removeItem('token')
                localStorage.removeItem('profile_image')
                localStorage.removeItem('user_id')
                localStorage.removeItem('propertyName')
                localStorage.removeItem('propertyFloor');



              }}>Log out</Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>

          {/* <Button onMouseEnter={handleClickOpen} style={{ backgroundColor: '' }} >
        <Image src={img2} roundedCircle style={{ width: '30px', height: '30px' }} />
        {localStorage.getItem('first_name')}
      </Button> */}

        </Col>

      </Row>
    </Container>
  }
  return (
    <div id="sideBar" className={classes.root} style={{ backgroundColor: '', width: '100%' }}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} style={{ border: '1px solid white', width: '100%' }}>

        <Toolbar style={{ backgroundColor: '#FFFFFF', border: '1px trasparent solid', width: '100%', height: '118px', zIndex: 0 }}>
          <Hidden only={['sm', 'xs']} >
            <div className={classes.toolbar} style={{ backgroundImage: `url(${office})`, backgroundColor: 'white', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center center', width: '12rem', margin: 'auto', height: '118px', marginLeft: '40px' }} />


          </Hidden>


          <Hidden only={['xl', 'lg', 'md']} implementation='css'>
            <IconButton
              color="rgb(57 53 78)"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>


          </Hidden>

          {/* <Typography variant="h6" noWrap>
            Responsive drawer
          </Typography> */}

          <Container1 />


        </Toolbar>
      </AppBar>




      <nav id="list" className={classes.drawer} aria-label='mailbox folders' style={{ backgroundColor: '' }}>

        <Hidden smUp implementation='css'>
          <Drawer
            container={container}

            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            style={{ backgroundColor: 'white' }}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}>

            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation='css'>
          <Drawer

            classes={{
              paper: classes.drawerPaper,
            }}
            variant='persistent'
            open={desktopOpen}
            style={{ backgroundColor: '' }}
          >

            {drawer}
          </Drawer>
        </Hidden>
      </nav>

    </div>
  );
}

export default ResponsiveDrawer;