import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import AdminLogin from './components/AdminLogin/AdminLogin';
import AdminRegister from './components/AdminRegister/AdminRegister';
import Property_form from './components/Forms/Property_form';
import HoldSpace from './components/HoldSpace/HoldSpace';
import landingpage from './components/Landing_page/LandingPage2';
import MainPage from './components/MainPage/MainPage';
import Sidebar from './components/Navigation/Sidebar/Sidebar';
import NotFound from './components/NotFound/NotFound';
import PorpertyList from './components/PropertyList/PropertyList';
import Suggestions from './components/Suggestions/Suggestions';
import hostTemrs from './components/Terms&conditions/host_terms_conditions';
import Terms from './components/Terms&conditions/Terms&conditions.js';
import AddProperties from './containers/AddProperties/AddProperties';
import AdminReservations from './containers/AdminReservation/AdminReservation';
import Inbox from './containers/Inbox/Inbox';
import Payment from './containers/Payment/Payment';
import PrivacyPolicy from './containers/PrivacyPolicy';
import PropertyDetails from './containers/PropertyDetails/PropertyDetails';
import EditingForm from './containers/Settings/EditForm';
import SpacePayments from "./containers/SpacePayments";
// axios.defaults.baseURL = "http://127.0.0.1:8000/";
class App extends React.Component {


  componentDidMount() {
    if (window.location.pathname === "/privacy-policy") {
      return;
    }


    const jwt = localStorage.getItem('token');
    console.log(jwt, localStorage.getItem('RegisterOrder'));

    if (!jwt && !localStorage.getItem('RegisterOrder')) {
      this.props.history.push('/')
    } 
    else if (!jwt && localStorage.getItem('RegisterOrder') === 'landingpage') {
      return this.props.history.push('/')
    } 
    else if (localStorage.getItem('RegisterOrder') == 'true1' && !jwt) {
      this.props.history.push('/host/forgetpassword')
    } 
    else if (localStorage.getItem('RegisterOrder') == 'terms' && !jwt) {
      return this.props.history.push('/terms&agreements/')
    }
    else if(localStorage.getItem('RegisterOrder') === 'hostTerms' && !jwt){
      return this.props.history.push('/host/terms&agreements/')
    } 
    else if (localStorage.getItem('RegisterOrder') == 'firsttrue' && !jwt) {
      return this.props.history.push('/host/')
    } 
    else if (localStorage.getItem('RegisterOrder') === 'true2' && !jwt) {
      return this.props.history.push('/host/policies&privacies/')
    }
   else if (!jwt && localStorage.getItem('RegisterOrder') == "false11") {
      return this.props.history.push("/host/login/")
    }
    else if (!jwt && localStorage.getItem('RegisterOrder') === 'true') {
      return this.props.history.push('/host/register/')
    }else if(!jwt){
      return this.props.history.push('/')
    }
    else if (localStorage.getItem('RegisterOrder') === 'true4' && jwt) {
      this.props.history.push('/settings/')
    } 
    else if (localStorage.getItem('RegisterOrder') === 'reservations' && jwt) {
      this.props.history.push('/reservations/')
    } 
    else if (localStorage.getItem('RegisterOrder') === 'payment' && jwt) {
      this.props.history.push('/bank_info/')
    }
    else if (jwt)
      return this.props.history.push('/properties/')
  }


  render() {
    console.log("Here");

    return (
      <div className='App'>
        {(
          !window.location.href.toLocaleLowerCase().includes("spacedetails".toLowerCase()) &&
          window.location.pathname != "/host/login" &&
          window.location.pathname != "/host/login/" &&
          window.location.pathname != "/host/register" &&
          window.location.pathname != "/host/register/" &&
          window.location.pathname != "/" &&
          window.location.pathname != "" &&
          window.location.pathname != "/host/" &&
          window.location.pathname != "/host" &&
          window.location.pathname != "/host/forgetpassword" &&
          window.location.pathname != "/terms&agreements" &&
          window.location.pathname != "/terms&agreements/" &&
          window.location.pathname != "/host/terms&agreements" &&
          window.location.pathname != "/host/terms&agreements/" &&
          window.location.pathname != "/host/policies&privacies" &&
          window.location.pathname != "/host/policies&privacies/" &&
          window.location.pathname != "/privacy-policy"
        )
          ? <Sidebar /> : ''}
        <Switch>
          <Route path={'/privacy-policy/'} component={PrivacyPolicy} />
          <Route path={'/'} exact={true} component={landingpage} />
          {/* { window.location.href.toLowerCase().includes("".toLowerCase())  ? '' : <Sidebar /> } */}
          <Route path={'/host/login/'} component={AdminLogin} />
          <Route path={'/host/register/'} component={AdminRegister} />
          <Route path={'/reservations/'} component={AdminReservations} />
          <Route path={'/spacedetails/:id_name/:p_id/'} component={PropertyDetails} />
          <Route path={'/properties/'} component={PorpertyList} />
          <Route path={'/addproperties'} component={AddProperties} />
          <Route path={'/inbox'} component={Inbox} />
          <Route path={'/settings/'} component={EditingForm} />
          <Route path={'/editproperty/:property_id'} component={Property_form} />
          <Route path={'/space/hold/:space_id'} component={HoldSpace}/>
          <Route path={'/EditingForm'} component={EditingForm} />
          <Route path={'/payment/'} component={SpacePayments} />
          <Route path={'/bank_info/'} component={Payment} />
          <Route path={'/terms&agreements/'} component={Terms} />
          <Route path={'/host/terms&agreements/'} component={hostTemrs}/>
          <Route path={'/host/'} component={MainPage} />
          <Route path={'/suggestions'} component={Suggestions} />
          <Route component={NotFound} />
        </Switch>
        {/* </Router> */}
      </div>
    );
  }
}

export default withRouter(App);
