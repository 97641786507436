import DateFnsUtils from '@date-io/date-fns'
import Button1 from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React from 'react'
import { Button } from 'react-bootstrap'
import swal from 'sweetalert2'
import axios from '../../axios'

export default class CreateNewHold extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            space_data: {},
            hold_value:'',
            spaceStartDate:'',
            spaceStartDate2:'',
            spaceEndDate:'',
            spaceEndDate2:'',
            hold_hourly_date:'',
            spacerealvalueendDate:'',
            hold_hourly_daterealDate:'',
            spacerealvalueendDate:'',
            available_hours: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
            time_c: [],
            array:[],
            hold_id:''

        }
    }
    componentDidMount() {
        

        if(this.props.request_type === 'post'){
            axios({
                method: 'GET',
                url: '/spaces/' + this.props.space_id,
                headers: {
                    'Authorization': 'jwt ' + localStorage.getItem('token')
                }
            }).then(res => {
                    if (res.data.is_daily_avi === true && res.data.is_hourly_avi === false)
                    this.setState({ hold_value: 'Daily hold' })
                    else if (res.data.is_daily_avi === false && res.data.is_hourly_avi === true)
                        this.setState({ hold_value: 'Hourly hold' })
                    else this.setState({ hold_value: 'Daily hold' })  
                this.setState({
                    space_data:res.data,
                    spaceStartDate:res.data.contract_start_date,
                    spaceEndDate:res.data.contract_end_date,
                    spaceStartDate2: res.data.contract_start_date,
                    spaceEndDate2: res.data.contract_end_date,
                    hold_hourly_date: res.data.contract_start_date,
                    spacerealvaluestartDate: res.data.contract_start_date,
                    hold_hourly_daterealDate: res.data.contract_start_date,
                    spacerealvalueendDate: res.data.contract_end_date,
                  })
            })
        }else {
            if(this.props.hold_type === 'Hourly Hold'){ 
                 this.setState({available_hours:this.props.available_hours, hold_value: 'Hourly hold',hold_hourly_date:this.props.one_hold_details.hold_start_date,hold_hourly_daterealDate:this.props.one_hold_details.hold_start_date,space_data:{is_hourly_avi:true,is_daily_avi:false} })
            }else 
                this.setState({ hold_value: 'Daily hold',spaceStartDate:this.props.one_hold_details.hold_start_date,spaceEndDate:this.props.one_hold_details.hold_end_date,spaceStartDate2:this.props.one_hold_details.hold_start_date,spaceEndDate2:this.props.one_hold_details.hold_end_date,spacerealvaluestartDate:this.props.one_hold_details.hold_start_date,spacerealvalueendDate:this.props.one_hold_details.hold_end_date,space_data:{is_hourly_avi:false,is_daily_avi:true}})
                this.apply_hours(this.props.available_hours)
                this.setState({
                    hold_id:this.props.hold_id
                })
        } 
        
    }
    apply_hours=(available_hours)=>{
        let time_c = this.state.time_c
        for(let i = 0 ; i < available_hours.length ; i++){
            if(available_hours[i] === '0'){
                time_c[i] = { color: '#08D888', borderBottom: 'solid #08D888' }
            }else
            time_c[i] = { borderBottom: 'solid red', color: 'red', marginBottom: 5 }
            this.setState({
                time_c:time_c
            })
        }
    }
    handleDateChange = (data, value) => {
        let v = data.toString()
        let realvalue = v.slice(3, 15)
        let date = [
          { 'month': 'Jan', 'value': 1 }, { 'month': 'Fab', 'value': 2 }, { 'month': 'Mar', 'value': 3 },
          { 'month': 'Apr', 'value': 4 }, { 'month': 'May', 'value': 5 }, { 'month': 'Jun', 'value': 6 },
          { 'month': 'Jul', 'value': 7 }, { 'month': 'Aug', 'value': 8 }, { 'month': 'Sep', 'value': 9 },
          { 'month': 'Oct', 'value': 10 }, { 'month': 'Nov', 'value': 11 }, { 'month': 'Dec', 'value': 12 }
        ]
        let realvalue_month = realvalue.slice(1, 4)
        let get_month = ''
        for (var i = 0; i < date.length; i++) {
          if (realvalue_month === date[i].month)
            get_month = date[i].value
        }
        let realvalue_day = realvalue.slice(5, 7)
        let realvalue_year = realvalue.slice(8, 12)
        let date_sent = realvalue_year + '-' + parseInt(get_month) + '-' + realvalue_day
        // console.log(date_sent)
        // console.log(realvalue_day , "/" , get_month , "/" , realvalue_year)
        if (value === 1)
    
          this.setState({ spaceStartDate2: data, spacerealvaluestartDate: date_sent })
        else if (value === 2)
          this.setState({ spaceEndDate2: data, spacerealvalueendDate: date_sent })
        else this.setState({ hold_hourly_date: data, hold_hourly_daterealDate: date_sent })
      };
      timeC(index) {

        var av_hours = this.state.available_hours
        console.log(this.state.available_hours)
        var time_c = this.state.time_c
        if (av_hours[index] == '0') {
          av_hours[index] = '1'
          time_c[index] = { borderBottom: 'solid red', color: 'red', marginBottom: 5 }
          this.setState({
            available_hours: av_hours
          })
        }
        else if (av_hours[index] === '1') {
          time_c[index] = { color: '#08D888', borderBottom: 'solid #08D888' }
          av_hours[index] = '0'
          this.setState({
            available_hours: av_hours
          })
        }
        this.setState({ time_c: time_c, })
      }
      SubmitHold = () => {
        if (this.state.hold_value === 'Daily hold') {
          this.sendHoldRequest('Daily',this.props.hold_id)
        } else {
          this.sendHoldRequest('Hourly',this.props.hold_id)
        }
      }
      sendHoldRequest = (HoldType,hold_id) => {
        var formData = new FormData();
        if (HoldType == 'Daily' && this.props.request_type == 'post') {
          formData.append('hold_type', '2')
          formData.append('hold_start_date', this.state.spacerealvaluestartDate)
          formData.append('hold_end_date', this.state.spacerealvalueendDate)
        }else if(HoldType == 'Daily' && this.props.request_type == 'patch'){
            formData.append('hold_type', '2')
            formData.append('new_hold_start_date', this.state.spacerealvaluestartDate)
            formData.append('new_hold_end_date', this.state.spacerealvalueendDate) 
            // let array ={
            //     hold_start_date:this.state.spacerealvaluestartDate,
            //     hold_end_date:this.state.spacerealvalueendDate
            // }
            // this.setState({
            //     array:array
            // })
        }else if(HoldType == 'Hourly' && this.props.request_type == 'post') {
          formData.append('hold_type', '1')
          formData.append('hold_start_date', this.state.hold_hourly_daterealDate)
          var avai_hours = this.state.available_hours.join('')
          formData.append('hold_time', avai_hours)
        }else{
            formData.append('hold_type', '1')
            formData.append('new_hold_start_date', this.state.hold_hourly_daterealDate)
            var avai_hours = this.state.available_hours.join('')
            formData.append('new_hold_time', avai_hours)
            // let array={
            //     hold_start_date:this.state.hold_hourly_daterealDate,
            //     available_hours:avai_hours
            // }
            // this.setState({
            //     array:array
            // })
        }
        
        if(this.props.request_type == 'post'){
            axios({
                method: this.props.request_type,
                url: '/spaces/' + this.props.space_id + '/holding/',
                headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
                data: formData
              }).then(response => {
                 
                this.props.handleClose()
                if (response.data.error)
                  return swal.fire({
                    icon: 'error',
                    title: 'error',
                    text: response.data.error,
                    showCancelButton: true,
                    // timer: 3500
                  })
                this.props.getevent()
                return swal.fire({
                  icon: 'success',
                  title: 'success',
                  text: 'Holding Successfully',
                  showCancelButton: true,
                  // timer: 3500
                })
            })
        }else{
            axios({
                method:this.props.request_type,
                url:'/holding/'+hold_id+'/',
                headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
                data: formData
            }).then(response=>{
                
                this.props.handleClose()
                if (response.data.error){

                    return swal.fire({
                        icon: 'error',
                        title: 'error',
                        text: response.data.error,
                        showCancelButton: true,
                        // timer: 3500
                      })
                }
                

            this.props.getevent()
        
            //this.state.hold_value,this.state.array,this.props.one_hold_details.id
            this.props.updateComponent()
              return swal.fire({
                icon: 'success',
                title: 'success',
                text: response.data.msg,
                showCancelButton: true,
                // timer: 3500
              })
            })
        }
      }
    render() {
        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.handleClose} maxWidth="md" aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <Grid container item justify="flex-start" style={{ backgroundColor: '' }}>
                            <FormControl component="fieldset" style={{ marginTop: '20px' }}>
                                <RadioGroup aria-label="gender" name="holding" value={this.state.hold_value} onChange={e => this.setState({ hold_value: e.target.value })}  >
                                    {this.state.space_data.is_daily_avi === true && this.state.space_data.is_hourly_avi == false && this.props.request_type=='post'&&
                                        <FormControlLabel value="Daily hold" control={<Radio style={{ color: 'rgb(57, 53, 78)' }} />} label="Daily hold" />
                                    }
                                    {this.state.space_data.is_daily_avi === false && this.state.space_data.is_hourly_avi == true && this.props.request_type =='post'&&
                                        <FormControlLabel value="Hourly hold" control={<Radio style={{ color: 'rgb(57, 53, 78)' }} />} label="Hourly hold" />
                                    }
                                    {this.state.space_data.is_hourly_avi === true && this.state.space_data.is_daily_avi === true && this.props.request_type == 'post'&&
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <FormControlLabel value="Daily hold" control={<Radio style={{ color: 'rgb(57, 53, 78)' }} />} label="Daily hold" />
                                            <FormControlLabel value="Hourly hold" control={<Radio style={{ color: 'rgb(57, 53, 78)' }} />} label="Hourly hold" />
                                        </div>
                                    }

                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {this.state.space_data.is_daily_avi === true && this.state.hold_value == 'Daily hold' &&
                            <Grid container justify="center" direction="column" alignContent>
                                <Grid container item direction="column">
                                    <Grid item style={{}}>
                                        <Grid container item direction="column" justify="space-around">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Grid item lg="12" sm="12" xs="12">
                                                    <KeyboardDatePicker
                                                        inputVariant="outlined"
                                                        format="dd/MM/yyyy"
                                                        margin="normal"
                                                        id="start-date"
                                                        style={{ width: '100%' }}
                                                        label="Start date"
                                                        value={this.state.spaceStartDate2}
                                                        // minDate={this.state.spaceStartDate}
                                                        // maxDate={this.state.spaceEndDate2}
                                                        onChange={(e) => this.handleDateChange(e, 1)}

                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item lg="12" sm="12" xs="12">
                                                    <KeyboardDatePicker
                                                        inputVariant="outlined"
                                                        format="dd/MM/yyyy"
                                                        margin="normal"
                                                        id="end-date"
                                                        style={{ width: '100%' }}
                                                        label="End date"
                                                        value={this.state.spaceEndDate2}
                                                        // minDate={this.state.spaceStartDate2}
                                                        // maxDate={this.state.spaceEndDate}
                                                        onChange={(e) => this.handleDateChange(e, 2)}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {this.state.space_data.is_hourly_avi == true && this.state.hold_value === 'Hourly hold' &&
                            <div>
                                {this.state.space_data.is_hourly_avi == true && this.state.hold_value === 'Hourly hold' &&
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid item lg="12" sm="12" xs="12">
                                            <KeyboardDatePicker
                                                inputVariant="outlined"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                style={{ width: '100%' }}

                                                // minDate={this.state.hold_hourly_date}
                                                // maxDate={this.state.spaceEndDate}
                                                label="Select Date"
                                                value={this.state.hold_hourly_date}
                                                onChange={(e) => this.handleDateChange(e, 3)}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                }
                                <Grid container item direction="column" justify="center" style={{ marginTop: '20px' }} >
                                    <Grid item className='time' lg="12" style={{}}>
                                        <Button1 onClick={(e) => this.timeC(0)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[0] }}  >12 AM</Button1>
                                        <Button1 onClick={(e) => this.timeC(1)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[1] }} >1 AM</Button1>
                                        <Button1 onClick={(e) => this.timeC(2)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[2] }} >2 AM</Button1>
                                        <Button1 onClick={(e) => this.timeC(3)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[3] }} >3 AM</Button1>
                                        <Button1 onClick={(e) => this.timeC(4)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[4] }} >4 AM</Button1>
                                        <Button1 onClick={(e) => this.timeC(5)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[5] }} >5 AM</Button1>
                                        <Button1 onClick={(e) => this.timeC(6)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[6] }} >6 AM</Button1>
                                        <Button1 onClick={(e) => this.timeC(7)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[7] }} >7 AM</Button1>
                                        <Button1 onClick={(e) => this.timeC(8)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[8] }} >8 AM</Button1>
                                        <Button1 onClick={(e) => this.timeC(9)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[9] }} >9 AM</Button1>
                                        <Button1 onClick={(e) => this.timeC(10)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[10] }} >10 AM</Button1>
                                        <Button1 onClick={(e) => this.timeC(11)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[11] }} >11 AM</Button1>
                                    </Grid>
                                    <br />
                                    <Grid item className='time' lg="12" style={{}}>
                                        <Button1 onClick={(e) => this.timeC(12)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[12] }} >12 PM</Button1>
                                        <Button1 onClick={(e) => this.timeC(13)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[13] }} >1 PM</Button1>
                                        <Button1 onClick={(e) => this.timeC(14)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[14] }} >2 PM</Button1>
                                        <Button1 onClick={(e) => this.timeC(15)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[15] }} >3 PM</Button1>
                                        <Button1 onClick={(e) => this.timeC(16)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[16] }} >4 PM</Button1>
                                        <Button1 onClick={(e) => this.timeC(17)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[17] }} >5 PM</Button1>
                                        <Button1 onClick={(e) => this.timeC(18)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[18] }} >6 PM</Button1>
                                        <Button1 onClick={(e) => this.timeC(19)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[19] }} >7 PM</Button1>
                                        <Button1 onClick={(e) => this.timeC(20)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[20] }} >8 PM</Button1>
                                        <Button1 onClick={(e) => this.timeC(21)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[21] }} >9 PM</Button1>
                                        <Button1 onClick={(e) => this.timeC(22)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[22] }} >10 PM</Button1>
                                        <Button1 onClick={(e) => this.timeC(23)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[23] }} >11 PM</Button1>
                                    </Grid>

                                    <Grid container item direction="column" style={{ marginTop: '25px' }}>
                                        <Grid item style={{ color: 'black', }} > <span style={{ backgroundColor: '#08D888', height: '10px', width: '10px', borderRadius: '50%', display: 'inline-block' }}></span>  Available hours </Grid>
                                        <Grid item style={{ color: 'black', marginTop: '8px' }}><span style={{ backgroundColor: 'red', height: '10px', width: '10px', borderRadius: '50%', display: 'inline-block' }}></span> Hold hours </Grid>

                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </DialogContent>
                    <Grid container justify="space-around" alignContent="center" alignItems="center" style={{ marginTop: '90px', paddingBottom: '10px' }}>
                        <Grid container item style={{ width: '70%' }}>

                            <Grid item lg="6" xl="6" md="6" sm="6" xs="6" style={{ paddingLeft: '6px' }}>
                                <Button onClick={this.props.handleClose} style={{ backgroundColor: 'rgb(57, 53, 78)', width: '100%', color: 'white', borderRadius: '50px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', border: '1px solid white' }}>
                                    Cancel
                 </Button>
                            </Grid>
                            <Grid item lg="6" xl="6" md="6" sm="6" xs="6" style={{ paddingRight: '6px' }}>
                                <Button onClick={()=>this.SubmitHold()} style={{ backgroundColor: 'white', width: '100%', color: 'rgb(57, 53, 78)', borderRadius: '50px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 8px 4px 0 rgba(0, 0, 0, 0.09)', border: '1px solid white' }}>
                                    Send
               </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Dialog>

            </div>
        )
    }
}