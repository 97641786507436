import React, { Component } from 'react';
import InboxComponent from '../../components/InboxComponent/InboxComponent';
import Inbox from '../../components/InboxComponent/inbox'
import ChatComponent from '../../components/ChatComponent/ChatComponent';
const mql = window.matchMedia(`(min-width: 767px)`);
export default class AdminInboxPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainDiv: {
        display: 'grid',
        gridTemplateColumns: '10% auto 10%',
        marginTop: '90px',
      },
    };
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
  }
  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }
  componentDidMount() {
    if (mql.matches) {
      this.setState({
        mainDiv: {
          display: 'grid',
          gridTemplateColumns: '300px auto 10%',
          marginTop: '40px',
        },
      });
    } else {
      this.setState({ mainDiv: { marginTop: '30px' } });
    }
  }
  mediaQueryChanged() {
    if (mql.matches) {
      this.setState({
        mainDiv: {
          display: 'grid',
          gridTemplateColumns: '300px auto 10%',
          marginTop: '40px',
        },
      });
    } else {
      this.setState({ mainDiv: { marginTop: '30px' } });
    }
  }
  render() {
    return (
      <>
        {/* <TopNav/> */}
        <div style={{ ...this.state.mainDiv }}>
          <div style={{ gridColumn: '2' }}>
            <Inbox />
            {/* <ChatComponent/> */}
          </div>
        </div>
      </>
    );
  }
}
