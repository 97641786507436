import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { Button } from 'react-bootstrap';
import axios from '../../axios';

const mql = window.matchMedia(`(min-width: 50em)`);
class SpaceForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      spaceTypes: [],
      currency: 'SAR',
      loaded: false,
      f0: '',
      show_type: true,
      minDiv: {},
      buttonWidth: {},
      get_cities: [],
      get_country: [],
      get_currency: [],
      marginTextTop: {},
      flag: true,
      currency_flag: false,
      space_price_flag: false,
      space_max_capacity_flag: false,
      space_size_flag: false,
      listofSpecialRoom: [],
      spaceTypeFlag: false,
      specialRoomValueFlag: false
    };
  }
  // Go to next form
  formContinue = e => {
    if (this.props.propertyInfo.spaceCategory === 4 || this.props.propertyInfo.spaceCategory === 3) {
      if (this.props.propertyInfo.spaceType === '' && this.props.propertyInfo.AddspecialRoomFlag !== true) {
        return this.setState({ spaceTypeFlag: true })
      } this.setState({ spaceTypeFlag: false })
    } if (this.props.propertyInfo.AddspecialRoomFlag == true && this.props.propertyInfo.specialRoomValue == '') {
      return this.setState({ specialRoomValueFlag: true })
    }
    if (this.props.propertyInfo.spaceMaxCapacity === '') {
      return this.setState({
        currency_flag: false,
        space_price_flag: false,
        specialRoomValueFlag: false,
        space_max_capacity_flag: true
      })
    } else if (this.props.propertyInfo.spaceSize === '') {
      return this.setState({
        currency_flag: false,
        space_price_flag: false,
        space_max_capacity_flag: false,
        specialRoomValueFlag: false,
        space_size_flag: true
      })
    }
    if (this.props.propertyInfo.spacePrice < 0) {
      return this.setState({
        currency_flag: false,
        space_price_flag: true
      })
    } else if (this.props.propertyInfo.spaceMaxCapacity < 0) {
      return this.setState({
        currency_flag: false,
        space_price_flag: false,
        space_max_capacity_flag: true
      })
    } else if (this.props.propertyInfo.spaceSize < 0) {
      return this.setState({
        currency_flag: false,
        space_price_flag: false,
        space_max_capacity_flag: false,
        space_size_flag: true
      })
    }
    this.setState({
      currency_flag: false,
      space_price_flag: false,
      space_size_flag: false,
      space_max_capacity_flag: false
    })
    e.preventDefault();
    this.props.nextStep();
  };

  // Go to previous form
  formPrevious = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  componentDidMount() {
    this.mediaQueryChanged()
    let array = []
    axios({
      method: 'GET',
      url: '/special_room_type/'
    }).then(res => {
      for (let i = 0; i < res.data.length; i++) {
        array.push(res.data[i])
      }
      this.setState({
        listofSpecialRoom: array,
      })
    }).catch()

    if (this.props.propertyInfo.spaceCategory == 4 && (this.props.propertyInfo.request_type == 'post' || this.props.propertyInfo.request_type == 'patch')) {
      this.setState({
        spaceTypes: ['Open Area', 'Private Office']
      })
    } else if ((this.props.propertyInfo.spaceCategory == 3) && (this.props.propertyInfo.request_type == 'post' || this.props.propertyInfo.request_type == 'patch')) {
      this.setState({
        spaceTypes: array
      })
    }
    this.getEvent()
  }
  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }
  mediaQueryChanged = () => {
    if (mql.matches) {
      this.setState({
        minDiv: {
          display: 'grid',
          gridTemplateColumns: '24rem',
          marginTop: 55,
        },
        buttonWidth: {
          width: '199px'
        },
        marginTextTop: {
          marginBottom: ''
        },

      });
    } else {
      this.setState({
        minDiv:
        {
          display: 'grid',
          gridTemplateColumns: '0rem',
          marginTop: '180px',
        },
        buttonWidth:
        {
          width: '100%'
        },
        marginTextTop:
        {
          marginBottom: '3%'
        },
      });
    }
  }

  getEvent = async () => {
    await axios({
      method: 'GET',
      url: '/countries/',
    }).then(response => {
      this.setState({
        get_cities: response.data
      })
      var get_country = [{}]
      for (var i = 0; i < this.state.get_cities.length; i++) {
        get_country[i] = {
          'id': this.state.get_cities[i].id,
          'name': this.state.get_cities[i].name
        }
      }
      this.setState({
        get_country: get_country
      })
      // console.log(get_country)

    })
  }
  
  spaceTypes = (cate) => {
    if (cate === 4) {

      // control choosing the lists for each selected category,
      this.props.CheckspaceType('office')
      return this.setState({ flag: true })
    }
    else if (cate === 3) {
      // control choosing the lists for each selected category,
      this.props.CheckspaceType(this.state.listofSpecialRoom)
      return this.setState({ flag: false })
    }
  }

  // to controll what is selected from space category
  category = e => {
    this.setState({ show_type: true })
    this.props.handle_category_type(1, e.target.value);
    this.spaceTypes(e.target.value);
  }

  render() {
    // here to control showing space category and space type when display is none then it will not appear
    const SpaceCategory = () => {
      let display = 'none', show = false
      if (this.props.propertyInfo.request_type === "patch") {
        display = 'block'
        show = true
      } else {
        display = 'block'
        show = false
      }
      return <Category display={display} show={show} />
    }

    const Category = (props) => {
      let display = props.display
      let show = props.show
      return (
        <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{ backgroundColor: '' }}>
          <FormControl variant="outlined" id="formcontrol2" style={{ width: '100%' }}>
            <InputLabel ref={this.props.propertyInfo.spaceCategories} htmlFor="spaceCategory" style={{ display: display }}>Space Category</InputLabel>
            <Select
              labelId="spaceCategory"
              id="spaceCategory"
              value={this.props.propertyInfo.spaceCategory}
              style={{ width: '100%', display: display }}
              disabled={show}
              labelWidth={115}
              onChange={this.category}>
              <MenuItem value={4} style={{ fontSize: '20px', fontFamily: 'founders' }}>Office</MenuItem>
              <MenuItem value={2} style={{ fontSize: '20px', fontFamily: 'founders' }}>Meeting room</MenuItem>
              <MenuItem value={3} style={{ fontSize: '20px', fontFamily: 'founders' }}>Special room</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )
    }

    return (
      <div style={{ ...this.state.minDiv, margin: 'auto' }}>
        <div style={{ gridColumn: '2' }}>
          <Grid
            container
            direction='column'
          >
            <Grid container item direction="column" justify="flex-start" >
              <SpaceCategory />
              {this.props.propertyInfo.spaceCategory !== 2 && this.state.show_type === true && this.props.propertyInfo.request_type ==='post'&&
                <Grid item xl={7} lg={7} md={9} sm={12} xs={12} style={{ marginTop: '2%' }}>
                  <FormControl variant="outlined" id='formcontrol' style={{ width: '100%' }} >
                    <InputLabel ref={this.props.propertyInfo.spaceType} htmlFor="spaceType">
                      Space Type
                      </InputLabel>
                    <Select
                      labelId="spaceType"
                      id="spaceType"
                      value={this.props.propertyInfo.spaceType}
                      label={<p style={{ fontSize: '20px', fontFamily: 'founders' }}>Space Type</p>}
                      onChange={this.props.handleChange('spaceType')}
                      error={this.state.spaceTypeFlag}
                      style={{ width: '100%',disabled:true }}
                      placeholder='Space Type'
                      labelWidth='10px'
                      labelWidth={80}
                    >
                      {this.props.propertyInfo.spaceCategory == 3 &&
                        this.props.propertyInfo.spaceTypes.map((type, i) => {
                          return <MenuItem key={type.id} value={type.id} style={{ fontSize: '20px', fontFamily: 'founders' }}>{type.title}</MenuItem>
                        })
                      }
                      {this.props.propertyInfo.spaceCategory == 3 && this.props.propertyInfo.request_type == 'post' &&
                        <MenuItem key={-1} id="createspecialroom" value={-1} style={{}} >--  Add a special room  --</MenuItem>
                      }
                      {this.props.propertyInfo.spaceCategory == 4 &&
                        this.props.propertyInfo.spaceTypes.map((type, i) => {
                          return <MenuItem key={i} value={type} style={{ fontSize: '20px', fontFamily: 'founders' }}>{type}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
              }
              <Grid item xl={7} lg={7} md={9} sm={12} xs={12}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ backgroundColor: '', marginTop: '10px' }}>
                  {this.props.propertyInfo.AddspecialRoomFlag == true && this.props.propertyInfo.request_type == 'post' &&
                    <TextField
                      id="specialRoom"
                      label={<span style={{ fontFamily: 'founders', fontSize: 25, ...this.state.marginTextTop }}>Special Room Type</span>}
                      min='1'
                      value={this.props.propertyInfo.specialRoomValue}
                      error={this.state.specialRoomValueFlag}
                      onChange={this.props.handleChange('specialRoomValue')}
                      style={{ fontFamily: 'Times New Roman', width: '100%' }}
                      helperText={<span style={{ color: 'rgb(8 216 136)' }}>Admin may change special room type</span>}
                      inputProps={{
                        maxLength: 30,
                        minLength: 10,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin='normal'
                    />
                  }
                  <TextField
                    id="space-capacity"
                    label={<span style={{ fontFamily: 'founders', fontSize: 25, ...this.state.marginTextTop }}>Space Capacity <span style={{ fontFamily: 'founders', fontSize: 16 }}>(Number of Chairs)</span> </span>}
                    min='1'
                    value={this.props.propertyInfo.spaceMaxCapacity}
                    error={this.state.space_max_capacity_flag}
                    onChange={this.props.handleChange('spaceMaxCapacity')}
                    style={{ fontFamily: 'Times New Roman', width: '100%', marginTop: '20px' }}
                    type='number'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='normal'
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    id="space-size"
                    label={<span style={{ fontFamily: 'founders', fontSize: 25, ...this.state.marginTextTop }}>Space Size <span style={{ fontFamily: 'founders', fontSize: 17 }}>(m²)</span></span>}
                    min='1'
                    value={this.props.propertyInfo.spaceSize}
                    error={this.state.space_size_flag}
                    onChange={this.props.handleChange('spaceSize')}
                    style={{ fontFamily: 'Times New Roman', width: '100%', marginTop: '20px' }}
                    type='number'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='normal'
                  />
                </Grid>
                <Grid container item justify="space-between" xl={12} lg={12} md={12} sm={12} xs={12} >
                  {this.props.propertyInfo.request_type === "post" &&
                    <Grid item >
                      <Button
                        variant='contained'
                        label='Back'
                        size='large'
                        style={{ marginTop: 86, backgroundColor: '#39354E', color: 'white',  ...this.state.buttonWidth, height: 44, fontSize: 20, fontFamily: 'HelveticaNeue', float: 'left',borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}
                        onClick={this.formPrevious}>
                        Back
                 </Button>
                    </Grid>}
                  <Grid item   >
                    <Button
                      label="Continue"
                      variant='contained'
                      size='large'
                      style={{ marginTop: 86, backgroundColor: 'white', color: '#39354E', ...this.state.buttonWidth, height: 44, fontSize: 20, fontFamily: 'HelveticaNeue',borderRadius:'20px',boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}
                      onClick={this.formContinue}
                    >
                      Continue
             </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default SpaceForm;
