import React, { Component } from 'react'
import Niceoffice from './images/office6.jpg'
import Yourspace from '../Navigation/Sidebar/images/yourspace.svg'
import GetStarted from './images/mounatin2.jpg'
import Meeting from './images/meeting.jpg'
import Special from './images/recording.jpg'
import OfficeLong from './images/wideOffice.jpg'
import Image from './images/image.jpg'
import Typical from 'react-typical'
import Aos from 'aos'
import "aos/dist/aos.css"
import gsap from 'gsap'
import Payment from './images/payment.jpeg'
import Slider from "react-slick";
import Audio from './images/Audio.jpg'
import Lecture from './images/lecture.jpg'
import Micro from './images/micro.jpg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './MainPage.css'
import OFFICE from '../Landing_page/images/MeetingRoom.jpg'
import MEETINGROOM from '../Landing_page/images/OpenOffice2.jpg'
import Button from 'react-bootstrap/Button'


const mql = window.matchMedia(`(min-width: 989px)`)
const styles = {
    transition: 'all 2s ease-out'
}
const settingsa = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    draggable: true


};

export default class MainPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            yourspaceimg: {},
            index: 1,
            yourspacediv: {
            },
            reasonofyourspace: {},
            showimagesdiv: {},
            category: {},
            parallax: {},
            scrolly: '',
            thirdDiv: {},
            divInside: {},
            officeImage: {},
            data_aos: '',
            data_aos_offset: "",
            data_aos_easing: "",
            data_aos_duration: "",
            list_of_categories: [
                {
                    type: 'office',
                    image: OfficeLong
                },
                {
                    type: 'meeting room',
                    image: Meeting
                },
                {
                    type: 'special room',
                    image: Special
                }
            ],
            imgsize: {},
            height: '',
            imgDict: {},
            scale1: 1,
            scale2: 1,
            scale3: 1,
            shape_to_left: {},
            sliderimagesize: {},
            footerWidth: {},
            marginRight: {},
            paddingGetStarted: {},
            growmakefontsize: {},
            marginTop: {},
            Hight: {},
            WIDTH: {},
            growyourwork: {},
            DiscimageBottom: {},
            TopimageBottom: {},
            marginTopfroTop: {}

        }
        this.screenSize = this.screenSize.bind(this)
        this.citybackground = React.createRef(null)
    }
    componentDidMount() {
        localStorage.setItem('RegisterOrder', 'firsttrue')
        window.addEventListener('scroll', this.handleScroll);
        Aos.init({ duration: 2000 })
        this.screenSize()
        // document.addEventListener('DOMContentLoaded', function () {
        //     var elems = document.querySelectorAll('.carousel');
        //     var instances = M.Carousel.init(elems, {});
        // });

    }
    handleScroll = () => {
        this.setState({
            scrolly: window.scrollY
        })
        this.setState({
            parallax: {
                backgroundPositionY: this.state.scrolly * 0.4 + "px"
            }
        })
    }
    componentWillMount() {
        mql.addListener(this.screenSize)
    }
    componentWillUnmount() {
        mql.removeListener(this.screenSize)
    }
    screenSize() {
        if (mql.matches) {
            this.setState({
                yourspaceimg: {
                    width: '11rem'
                },
                reasonofyourspace: {
                    display: 'flex',
                    flex: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: 'auto',
                    width: '70%',
                    marginTop: '60px'

                },
                showimagesdiv: {
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    gridGap: '10px',
                    justifyContent: 'center',
                    alignItems: 'center'
                },
                category: {
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    marginTop: '70px',
                    borderTop: '1px solid lightgray',
                    gridGap: 8
                },
                thirdDiv: {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit,minmax(10rem,1fr))',
                    justifyContent: 'center',
                    gridGap: '25px',
                    textAlign: 'center',
                    paddingTop: '2rem',
                    width: '90%',
                    margin: 'auto',

                },
                divInside: {

                },
                officeImage: {
                    height: '60vh',
                    width: '60vh'
                },
                data_aos: 'fade-righ',
                data_aos_offset: '300',
                data_aos_easing: 'ease-in-sine',
                data_aos_duration: "3000",
                imgsize: {
                    height: '300px',
                    width: '600px'
                },
                height: '300px',
                imgDict: {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit,minmax(15rem,1fr))'
                },
                sliderimagesize: {
                    height: '400px',
                    width: '45%'


                },
                footerWidth: {
                    maxWidth: '35%'
                },
                marginRight: {
                    marginRight: '20%'
                },
                paddingGetStarted: {
                    padding: '75px'
                },
                growmakefontsize: {
                    fontSize: '7rem'
                },
                marginTop: {

                },
                Hight: {
                    height: '25vw',
                    width: '25vw'
                },
                WIDTH: {
                    width: '80%'
                },
                growyourwork: {

                },
                DiscimageBottom: {
                    fontSize: '2.3rem'
                },
                TopimageBottom: {
                    fontSize: '2.2rem'
                },
                marginTopfroTop: {

                }

                // shape_to_left:{
                //     position:'absolute',

                //     bottom:'10rem',
                //     right:'-10rem'
                // }
            })
        } else {
            this.setState({
                yourspaceimg: {
                    width: '11rem'
                },
                category: {

                },
                thirdDiv: {
                    textAlign: 'center',
                    marginTop: '40px'
                },
                divInside: {
                    marginTop: '80px'
                },
                officeImage: {
                    height: '330px',
                    width: '400px'
                },

                data_aos_offset: '',
                data_aos_easing: '',
                data_aos: "fade-up",
                data_aos_duration: "3000",
                imgsize: {
                    height: '70px',
                    width: '50px'
                },
                height: '600px',
                imgDict: {

                },
                sliderimagesize: {
                    height: '200px',
                    width: '47%'


                },
                footerWidth: {
                    maxWidth: '90%'
                },
                marginRight: {
                    marginRight: '4%'
                },
                paddingGetStarted: {
                    padding: '40px'
                },
                growmakefontsize: {
                    fontSize: '6rem'
                },
                marginTop: {
                    marginTop: '60px'
                },
                Hight: {
                    height: '85vw',
                    width: '85vw'
                },
                WIDTH: {
                    width: '100%'
                },
                growyourwork: {
                    marginTop: '50px'
                },
                DiscimageBottom: {
                    fontSize: '2rem'
                },
                TopimageBottom: {
                    fontSize: '1.9rem'
                },
                marginTopfroTop: {

                }

            })
        }

    }
    handleSelect = (selectedIndex) => {
        this.setState({
            index: selectedIndex
        })
    }
    handleClick = () => {
        localStorage.setItem("RegisterOrder", 'false11')
        this.props.history.push("/host/login/")
    }
    handleMouseEnter = (value) => {
        if (value === 1) {
            this.setState({
                scale1: 1.03
            })
        } else if (value === 2) {
            this.setState({
                scale2: 1.03
            })
        } else {
            this.setState({
                scale3: 1.03
            })
        }
    }
    handleMouseLeave = (value) => {
        if (value === 1) {
            this.setState({
                scale1: 1
            })
        } else if (value === 2) {
            this.setState({
                scale2: 1
            })
        } else {
            this.setState({
                scale3: 1
            })
        }
    }
    render() {
        const data_aos = this.state.data_aos
        const list_of_categories = this.state.list_of_categories
        const handleCity = (city) => {
            gsap.to(this.citybackground, {
                duration: 0,
                background: `url(${city}) cente center`
            });
            gsap.to(this.citybackground, {
                duration: '0.4',
                opacity: 1,
                ease: 'power3.inOut'
            });
            gsap.from(this.citybackground, {
                duration: 0.4,
                skewY: 2,
                transformOrigin: 'right top'
            })
        }
        const handleCityReturn = () => {
            gsap.to(this.citybackground, {
                duration: 0.4,
                opacity: 0
            })
        }
        return (
            <div>

                <nav className="navbar navbar-default navbar-fixed-top justify-content-between" style={{ backgroundColor: '#08D888', position: 'fixed', zIndex: '1030', width: '100%', left: 0, right: 0, borderRadius: 0 }}>
                    <a className="navbar-brand" style={{ cursor: 'pointer' }} onClick={() => {
                        this.props.history.push('/')
                    }}>
                        <img src={Yourspace} alt="yourspace" className="yourspace" style={{ cursor: 'pointer' }} />


                    </a>
                    <sapn style={{ backgroundColor: 'transparent', color: 'white', border: '0px', fontSize: '1rem', cursor: 'pointer' }} onClick={() => {
                        localStorage.setItem('RegisterOrder', 'false11')
                        this.props.history.push('/host/login/')
                    }}>GET STARTED</sapn>
                    {/* <Button style={{  backgroundColor: 'transparent', color: 'white',border:'0px',fontSize:'1rem' }} onClick={() => {
                                localStorage.setItem('RegisterOrder', 'false11')
                                this.props.history.push('/host/login/')
                            }}>GET STARTED</Button> */}

                </nav>




                {/* <div>
                    <nav className="navbar sticky-navbar-light  sticky-top" style={{ backgroundColor: '#08D888', }}>
                        <a className="navbar-brand"><img src={Yourspace} style={{ ...this.state.yourspaceimg }} /></a>
                        <form className="form-inline">
                            <button className="btn  my-2 my-sm-0" type="submit" style={{ color: 'white', border: '1px solid #08D888' }} onClick={this.handleClick}>GET STARTED</button>
                        </form>
                    </nav>
                </div> */}
                <div style={{ height: '70vh', backgroundImage: `url(${Niceoffice})`, backgroundPosition: 'ceneter center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', ...this.state.parallax, opacity: '0.7', position: 'relative' }}>
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>

                        <div style={{ backgroundColor: 'white', opacity: 0.8, borderRadius: '5px', ...this.state.paddingGetStarted }}>
                            <p style={{ ...this.state.growmakefontsize, lineHeight: '0.2em', color: 'rgb(0, 0, 255)', fontFamily: 'font_bold' }}>GROW</p>
                            <p style={{ ...this.state.growmakefontsize, color: '#08D888', fontFamily: 'font_bold', lineHeight: '1.3em' }}>MAKE</p>
                            <Button style={{ width: '100%', backgroundColor: '#08D888', color: 'white', borderRadius: '5px', border: '0px' }} onClick={() => {
                                localStorage.setItem('RegisterOrder', 'false11')
                                this.props.history.push('/host/login/')
                            }}>GET STARTED</Button>
                        </div>


                    </div>
                </div>





                <div className="container" style={{ marginTop: '130px' }}>
                    <div className="row">
                        <div className="col" >
                            {/* data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" */}
                            <p style={{ fontSize: '35px', textAlign: 'center', fontFamily: 'founders_bold' }}>
                                - you can host your space in easy steps -
                            </p>
                        </div>
                    </div>
                    {/* data-aos="fade-up" data-aos-anchor-placement="top-center" */}
                    <div className="row" style={{marginTop:'20px'}}>
                        <div id="div" className="col" style={{}}>
                            <p id="p" style={{ fontSize: '20px', fontFamily: 'font_bold' }}>Add or create your property</p>
                            <p id="p" style={{ fontSize: '20px', fontFamily: 'font_bold' }}>Select your space category </p>
                            <p id="p" style={{ fontSize: '20px', fontFamily: 'font_bold' }}>Add your space information</p>
                            <p id="p" style={{ fontSize: '20px', fontFamily: 'font_bold' }}>Add your space features</p> 
                            <p id="p" style={{ fontSize: '20px', fontFamily: 'font_bold' }}>Add your space images </p>
                            <p id="p" style={{ fontSize: '20px', fontFamily: 'font_bold' }}>Select pricing, available hours and available days</p>
                        </div>


                    </div>
                </div>

                <div className="container" style={{ marginTop: '180px' }}>
                    <div style={{}}> <p style={{ fontFamily: 'founders_bold', fontSize: '35px', textAlign: 'center' }}>- Why you should choose yourspace? -</p> </div>
                </div>
                <div style={{ ...this.state.thirdDiv, marginTop: '40px' }}>
                    <div style={{ maxWidth: '90%', textAlign: 'center', margin: 'auto' }} data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500">
                        <p style={{ fontSize: '25px', fontFamily: 'font_bold', textAlign: 'center' }}>Make some opportunities, make some networing, make some business and make some money.  </p>




                    </div>
                    <div style={{ maxWidth: '90%', textAlign: 'center', margin: 'auto', ...this.state.growyourwork }} data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500" >
                        {/* <i class="fa fa-hand-o-right" style={{ fontSize: 36 }}></i> <h3 style={{ display: 'inline', fontFamily: 'font_bold', fontSize: '30px' }}>security</h3> */}
                        <p style={{ fontSize: '25px', fontFamily: 'font_bold' }}>Grow your work space, grow your network, grow your business and grow your own way. </p>
                    </div>

                </div>




                <div className="container" style={{ marginTop: '180px' }}>

                    <div className="container" style={{ marginTop: '180px' }}>
                        <div style={{}}> <p style={{ fontFamily: 'founders_bold', fontSize: '35px', textAlign: 'center' }}> - Our Categories - </p> </div>




                        <div style={{ ...this.state.imgDict, marginTop: 120, }}>
                            <div style={{ backgroundImage: `url(${MEETINGROOM})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', ...this.state.Hight, borderRadius: '50%', }}>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginLeft: '10px', ...this.state.marginTop }}>
                                <div style={{}}>
                                    <section style={{}}>
                                        <div style={{ backgroundColor: '', textAlign: 'center', }}>
                                            <strong style={{ fontSize: '2.5rem' }}>
                                                Offices
                                                    </strong>
                                            <h3 style={{ marginTop: '30px' }}>
                                                An unused space can be a source of revenue
                                                    </h3>
                                        </div>
                                        <div style={{}}>
                                            <p style={{}}>
                                                {/* bla bla bla bla bla */}
                                            </p>
                                        </div>

                                    </section>
                                </div>
                            </div>
                        </div>

                        <div style={{ borderTop: '1px solid lightgrey', marginTop: '100px' }}></div>


                        <div style={{ ...this.state.imgDict, marginTop: 120 }} >
                            <div style={{ backgroundImage: `url(${OFFICE})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', ...this.state.Hight, borderRadius: '50%', }}>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginLeft: '10px', ...this.state.marginTop }}>
                                <div style={{}}>
                                    <section>
                                        <div style={{ backgroundColor: '', textAlign: 'center' }}>
                                            <strong style={{ fontSize: '2.5rem' }}>
                                                Meeting Room
                                                    </strong>
                                            <h3 style={{ marginTop: '30px' }}>
                                                Rent it while you don't use it
                                                    </h3>
                                        </div>
                                        <div style={{ maxWidth: '300px', textAlign: 'center', backgroundColor: '' }}>
                                            <p style={{}}>

                                            </p>
                                        </div>

                                    </section>
                                </div>
                            </div>
                        </div>




                        <div style={{ borderTop: '1px solid lightgrey', marginTop: '100px' }}></div>






                    </div>






                </div>


                <div className="container" style={{ marginTop: '3rem' }}>
                    <Slider {...settingsa}  >
                        <div style={{}} >
                            <div className="firstinslider" style={{ ...this.state.sliderimagesize, }} >


                            </div>
                            <div style={{ textAlign: 'center', marginTop: '40px' }}>
                                <p style={{ ...this.state.DiscimageBottom, fontFamily: 'Arial, Helvetica, sans-serif', }}>There is a space that fits your needs</p>

                                <p style={{ ...this.state.TopimageBottom, fontFamily: 'founders_bold', ...this.state.marginTopfroTop }}>- Screening Rooms -</p>

                            </div>


                        </div>
                        <div style={{}} >
                            <div className="secondinslider" style={{ ...this.state.sliderimagesize, }} >


                            </div>
                            <div style={{ textAlign: 'center', marginTop: '40px' }}>
                                <p style={{ ...this.state.DiscimageBottom, fontFamily: 'Arial, Helvetica, sans-serif', }}>There is a space that fits your needs</p>

                                <p style={{ ...this.state.TopimageBottom, fontFamily: 'founders_bold', ...this.state.marginTopfroTop }}>- Lecture Halls -</p>

                            </div>

                        </div>
                        <div style={{}} >
                            <div className="thirdinslider" style={{ ...this.state.sliderimagesize, }} >


                            </div>
                            <div style={{ textAlign: 'center', marginTop: '40px' }}>
                                <p style={{ ...this.state.DiscimageBottom, fontFamily: 'Arial, Helvetica, sans-serif', }}>There is a space that fits your needs</p>

                                <p style={{ ...this.state.TopimageBottom, fontFamily: 'founders_bold', ...this.state.marginTopfroTop }}>- Recording Studios -</p>

                            </div>
                        </div>

                    </Slider>

                </div>
                <div style={{ marginTop: '8rem' }}>


                    <footer style={{ ...this.state.footerWidth, margin: 'auto' }}>
                        <div className="row" style={{ marginTop: '3rem', paddingBottom: '1.2rem', paddingTop: '1rem', borderTop: '1px solid lightgrey' }}>
                            <span style={{ color: '#86868b', fontSize: '12px', lineHeight: 1.33337, fontWeight: 400, letterSpacing: '-.01em;', }} className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-6">© 2020 Copyright:
                        <span style={{ color: '#515154', fontSize: '12px', lineHeight: 1.33337, fontWeight: 400, letterSpacing: '-.01em;', }}><a style={{ color: '#707070', }} href="https://hads.sa/">hads.sa</a></span>
                            </span>

                            <span className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-6" style={{ color: '#707070', fontSize: '12px', lineHeight: 1.33337, fontWeight: 400, letterSpacing: '-.01em;', backgroundColor: '' }} ><span className="termsinLandingpage" style={{ color: '#707070', float: 'right', cursor: 'pointer', }} onClick={() => this.props.history.push('/terms&agreements/')} >terms&agreements</span>  </span>

                        </div>







                    </footer>
                </div>








            </div >

        )
    }
}