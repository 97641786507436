import DateFnsUtils from '@date-io/date-fns';
import AppBar from '@material-ui/core/AppBar';
import Button1 from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import 'react-day-picker/lib/style.css';
import swal from 'sweetalert2';
import axios from '../../axios';
import yourspace from '../../components/AdminLogin/yourspace.svg';
import GoogleMap from '../../components/MapComponent/MapComponent';
import Pictures from '../../components/Pictures/Pictures';
import "./details_style.css";


const mql1 = window.matchMedia(`(min-width: 530px)`);
const mql = window.matchMedia(`(min-width: 850px)`);
const mql2 = window.matchMedia(`(min-width: 1438px)`);
const mql3 = window.matchMedia(`(min-width: 1260px)`);
const mql4 = window.matchMedia(`(min-width: 1300px)`);
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
export default class PropertyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fontSize: {},
      btn_fontSize: {},
      img_size: { minHeight: "100vh", width: '100%' },
      img_display: "none",
      num_of_guests: 1,
      selectedDate: '',
      info1: {
        display: "grid",
        gridTemplate: "auto auto",
      },
      centerLabel: {},
      Margin: {},
      calenderMargin: {},
      smallMargin: "10px",
      floatRight: { float: "right" },
      centerLabel2: {},
      big_info1: "10% auto 10%",
      gridColumn: "1",
      toggle_right_center: { textAlign: "center" },
      toggleB: "visible",
      toggleB2: "hidden",
      space_data: {},
      space_type: '',
      features_list: [],
      amenties_list: [],
      weeks_list: '',
      space_category: '',
      gridRowDic: {},
      marginLeft: {},
      spanMarginLift: {},
      spaceStartDate: '',
      spaceEndDate: '',
      minDev: {},
      smallestDivUnderPhoto: {},
      bigDivofImage: {},
      lastBigCotentDev: {},
      minDiv2: {},
      open: false,
      marginRightAmen: {},
      firstGridcontainerheight: {},
      secondGridcontainerheight: {},
      media: [],
      spaceMainInfo: {},
      firstItem: {},
      discWidth: '',
      lat: 0,
      lng: 0,
      spaceStartDate2: '',
      spaceEndDate2: '',
      avai_time: [],
      media_max_width: {},

      gridStyle: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit,minmax(2rem,1fr))',
        width: '100%',
        gridGap: '3px',
        backgroundColor: 'green'
      },
      bannarImageHight: '',
      special_room_type: '',
      hold_hourly_date: '',
      available_hours: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      time_c: [],
      spacerealvaluestartDate: '',
      spacerealvalueendDate: '',
      hold_hourly_daterealDate: '',

    };
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    // window.onscroll = () => this.myFunction();
  }
  componentWillMount() {
    mql1.addListener(this.mediaQueryChanged);
    mql.addListener(this.mediaQueryChanged);
    mql2.addListener(this.mediaQueryChanged);
    mql3.addListener(this.mediaQueryChanged);
    mql4.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql1.removeListener(this.mediaQueryChanged);
    mql.removeListener(this.mediaQueryChanged);
    mql2.removeListener(this.mediaQueryChanged);
    mql3.removeListener(this.mediaQueryChanged);
    mql4.removeListener(this.mediaQueryChanged);
  }
  getEvent = async () => {
    // console.log("this.props.space_stat != null: ", this.props.space_stat)
    // console.log("id_name: ", this.props.match.params.id_name)
    var endpoint = "/spaces/" + this.props.match.params.id_name
    let event1 = await axios.get(endpoint
    )
      .then(res => {
        if (res.data.is_daily_avi === true && res.data.is_hourly_avi === false)
          this.setState({ hold_value: 'Daily hold' })
        else if (res.data.is_daily_avi === false && res.data.is_hourly_avi === true)
          this.setState({ hold_value: 'Hourly hold' })
        else this.setState({ hold_value: 'Daily hold' })
        this.setState({ space_data: res.data, features_list: res.data.features, media: res.data.media, weeks_list: res.data.avi_days, spaceStartDate: res.data.contract_start_date, spaceEndDate: res.data.contract_end_date })
        this.setState({
          spaceStartDate2: res.data.contract_start_date,
          spaceEndDate2: res.data.contract_end_date,
          hold_hourly_date: res.data.contract_start_date,
          spacerealvaluestartDate: res.data.contract_start_date,
          hold_hourly_daterealDate: res.data.contract_start_date,
          spacerealvalueendDate: res.data.contract_end_date,
        })

        if (res.data.special_room_type !== null) {
          axios({
            method: 'GET',
            url: '/special_room_type/' + res.data.special_room_type + '/'
          }).then(response => {
            this.setState({
              special_room_type: response.data.title
            })
            // console.log('response' , response.data)
          })
        }


        // console.log('res', res.data)
        if (this.state.space_data.category === 2) {
          this.setState({
            space_category: 'meeting room'
          })
        } else if (this.state.space_data.category === 3) {
          this.setState({
            space_category: 'special room'
          })
        } else if (this.state.space_data.category === 4) {
          this.setState({
            space_category: 'office'
          })
        }
        this.props.get_hours(res.data.avi_time)
        localStorage.setItem('spaceEndDate', this.state.spaceEndDate)
      })
      .catch(e => { });
    if (this.props.space_stat === "0") {
      if (this.props.match.params.space_stat !== '1') {
        {
          document.getElementById("TopBar").style.width = "calc(100% - 240px)"
          document.getElementById("body").style.marginLeft = "240px";
        }
      }
    }
  }
  getEvent2 = async () => {
    await axios({
      method: 'GET',
      url: '/properties/' + this.props.match.params.p_id,
      headers: {
        'Authorization': 'jwt ' + localStorage.getItem('token')
      }
    }).then(response => {
      this.setState({
        lat: response.data.lat,
        lng: response.data.lon,
      })
    }).catch(err => {
      // console.log(err.message)
    })

    await axios({
      mehton: 'GET',
      url: '/properties/' + this.props.match.params.p_id + "/amenities/"
    }).then(res => {
      this.setState({ amenties_list: res.data })
      // console.log(res.data)
    })
      .catch(e => { return '' });
  }
  componentDidMount() {
    this.screen_sizes();
    this.getEvent()
    this.getEvent2()
    var today = new Date()
    var date = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate()
  }
  mediaQueryChanged() {
    this.screen_sizes();
  }
  screen_sizes() {
    if (mql1.matches) {
      this.setState({ floatRight: { float: "right" }, big_info1: "10% auto 10%" })
    }
    else {
      this.setState({ floatRight: {}, big_info1: "auto auto auto" })
    }
    if (mql.matches) {
      this.setState({
        fontSize: {}, btn_fontSize: {}, img_size: { minHeight: "50vh", width: '100%' }, img_display: "none", centerLabel: {}, gridRow: "1", gridColumn: "1", Margin: {},
        calenderMargin: {}, smallMargin: "10px", toggle_right_center: { textAlign: "right" }, toggleB: "visible", toggleB2: "hidden", gridRowDic: {}, discWidth: 700, media_max_width: { width: '100%' }, bannarImageHight: 600
      })
    }
    else {
      this.setState({
        fontSize: { fontSize: "13px" }, btn_fontSize: { fontSize: "10px" }, img_size: { width: '100%' }, img_display: "block", info1: {},
        centerLabel: { textAlign: "center" }, gridRow: "2", Margin: { margin: 'auto' }, calenderMargin: { width: 'fit-content', margin: 'auto' },
        smallMargin: "0px", gridColumn: "1/3", toggle_right_center: { textAlign: "center" }, toggleB: "visible", toggleB2: "visible", gridRowDic: { gridRow: "2", discWidth: '' }, media_max_width: { maxWidth: '300px' }, bannarImageHight: 300
      })
    }
    if (mql3.matches) {

      this.setState({ info1: { display: "grid", gridTemplate: "auto auto", }, })
    }
    else {
      this.setState({ info1: {}, })
    }
    if (mql2.matches) {


    }
    else {
      this.setState({ centerLabel2: { margin: "auto" } })


      // console.log("remove")
    }

  }

  handleDateChange = (data, value) => {
    let v = data.toString()
    let realvalue = v.slice(3, 15)
    let date = [
      { 'month': 'Jan', 'value': 1 }, { 'month': 'Fab', 'value': 2 }, { 'month': 'Mar', 'value': 3 },
      { 'month': 'Apr', 'value': 4 }, { 'month': 'May', 'value': 5 }, { 'month': 'Jun', 'value': 6 },
      { 'month': 'Jul', 'value': 7 }, { 'month': 'Aug', 'value': 8 }, { 'month': 'Sep', 'value': 9 },
      { 'month': 'Oct', 'value': 10 }, { 'month': 'Nov', 'value': 11 }, { 'month': 'Dec', 'value': 12 }
    ]
    let realvalue_month = realvalue.slice(1, 4)
    let get_month = ''
    for (var i = 0; i < date.length; i++) {
      if (realvalue_month === date[i].month)
        get_month = date[i].value
    }
    let realvalue_day = realvalue.slice(5, 7)
    let realvalue_year = realvalue.slice(8, 12)



    let date_sent = realvalue_year + '-' + parseInt(get_month) + '-' + realvalue_day
    // console.log(date_sent)
    // console.log(realvalue_day , "/" , get_month , "/" , realvalue_year)
    if (value === 1)

      this.setState({ spaceStartDate2: data, spacerealvaluestartDate: date_sent })
    else if (value === 2)
      this.setState({ spaceEndDate2: data, spacerealvalueendDate: date_sent })
    else this.setState({ hold_hourly_date: data, hold_hourly_daterealDate: date_sent })
  };
  timeC(index) {

    var av_hours = this.state.available_hours
    var time_c = this.state.time_c
    if (av_hours[index] == '0') {
      av_hours[index] = '1'
      time_c[index] = { borderBottom: 'solid red', color: 'red', marginBottom: 5 }
      this.setState({
        available_hours: av_hours
      })
    }
    else if (av_hours[index] === '1') {
      time_c[index] = { color: '#08D888', borderBottom: 'solid #08D888' }
      av_hours[index] = '0'
      this.setState({
        available_hours: av_hours
      })
    }
    this.setState({ time_c: time_c, })
  }


  mainpage = () => {
    this.props.history.push('/properties/')
  }
  handleClose = () => {
    this.setState({
      open: false
    })
  }
  handledelete = () => {
    axios({
      url: "/spaces/" + this.state.space_data.id,
      method: "delete",
      headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
    }).then(response => {
      // console.log("delete: ", response.data)
      this.props.history.push({ pathname: '/myProperties/' })
    }).catch(err => {
      // console.log('space_idid d', this.state.space_data.id)
      // console.log(err.response)
    })
  }
  
  SubmitHold = () => {
    if (this.state.hold_value === 'Daily hold') {
      this.sendHoldRequest('Daily')
    } else {
      this.sendHoldRequest('Hourly')
    }
  }

  sendHoldRequest = (HoldType) => {
    var formData = new FormData();
    // console.log(this.state.spacerealvaluestartDate)
    // console.log(this.state.spacerealvalueendDate)

    // console.log(HoldType)
    if (HoldType == 'Daily') {
      formData.append('hold_type', '2')
      formData.append('hold_start_date', this.state.spacerealvaluestartDate)
      formData.append('hold_end_date', this.state.spacerealvalueendDate)

    } else {
      formData.append('hold_type', '1')
      formData.append('hold_start_date', this.state.hold_hourly_daterealDate)
      var avai_hours = this.state.available_hours.join('')
      formData.append('hold_time', avai_hours)
    }
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
    axios({
      method: 'POST',
      url: '/spaces/' + this.state.space_data.id + '/hold/',
      headers: { 'Authorization': 'jwt ' + localStorage.getItem('token') },
      data: formData
    }).then(response => {
      this.setState({ open: false })
      if (response.data.error)
        return swal.fire({
          icon: 'error',
          title: 'error',
          text: response.data.error,
          showCancelButton: true,
          // timer: 3500
        })

      return swal.fire({
        icon: 'success',
        title: 'success',
        text: 'Holding Successfully',
        showCancelButton: true,
        // timer: 3500
      })
    })
  }


  render() {

    const modifiersStyle =
      `.DayPicker-Day--highlighted {

          border-top-left-radius:50% !important;
          border-bottom-left-radius:50% !important;
          /*background-color: #4A90C6 !important;*/
        }
        .DayPicker-Day--highlighted2 {

          border-top-right-radius:50% !important;
          border-bottom-right-radius:50% !important;
          /*background-color: #4A90C6 !important;*/
        }
`;
    const modifiers = {
      highlighted: [new Date(2019, 9, 3), new Date(2019, 9, 21)],
      highlighted2: [new Date(2019, 9, 11), new Date(2019, 9, 2), new Date(2019, 9, 24)],

    };
    const features_arr = this.state.features_list
    const features_list =
      <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto', maxWidth: "400px", ...this.state.Margin, marginTop: '7px' }}>
        {features_arr.map(function (name, index) {
          // var y;
          // if(features_arr.length %2 === 0)
          //   y = features_arr.length/2
          // else
          var all, float = "none", marginRight = "20px";

          if (features_arr.length / 2 >= index) {
            float = "none"; marginRight = "20px"
          }
          else { float = "right"; marginRight = "0" }
          var first_halfLen = features_arr.length / 2
          //  console.log("features_arr.length: ", features_arr.length)
          //  console.log("first_half: ", first_halfLen)
          var first_half = [], second_half = [];
          if (index < first_halfLen)
            first_half =
              <div style={{ gridColumn: '1' }}>
                <p ><li style={{ fontSize: '16px', fontFamily: 'founders' }}>{features_arr[index].desc}</li> </p></div>
          else
            second_half =
              <div style={{ gridColumn: '2' }}>
                <p ><li style={{ fontSize: '16px', fontFamily: 'founders' }}>{features_arr[index].desc}</li> </p></div>

          return (
            <div key={index} style={{ marginRight: marginRight }}>
              {first_half}
              {second_half}
            </div>
          )
        })}
      </div>
    const avi_days = this.state.weeks_list
    const avi_days_arr = avi_days.split('')
    const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THRUSDAY', 'FRIDAY', 'SATURDAY']

    const avi_days_list =
      <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', maxWidth: "400px", ...this.state.Margin }}>
        {avi_days_arr.map(function (name, index) {
          var all, float = "left", marginRight = "20px";
          if (avi_days_arr.length / 2 >= index) {
            marginRight = "20px"
          }
          else { marginRight = "0" }
          var first_halfLen = avi_days_arr.length / 2
          var first_half = []
          first_half =
            <div style={{ gridColumn: '1' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={avi_days_arr[index] === "0" ? true : false}
                    value={avi_days_arr[index]}
                    style={{ color: 'rgb(57, 53, 78)' }}
                  />
                }
                label={<span style={{ fontSize: '14px', color: 'rgb(57, 53, 78' }}>{days[index]}</span>}
              />
            </div>
          return (
            <div key={index} style={{ marginRight: marginRight }}>
              {first_half}
            </div>
          )
        })}
      </div>

    var marginTop1 = 0

    const amenities_arr = this.state.amenties_list
    const amenities_list =
      <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto', maxWidth: "400px", ...this.state.Margin, marginTop: '7px' }}>
        {amenities_arr.map(function (name, index) {
          var all, float = "left", marginRight = "20px";
          if (amenities_arr.length / 2 >= index) {
            marginRight = "20px"
          }
          else { marginRight = "0" }
          var first_halfLen = amenities_arr.length / 2
          var first_half = [], second_half = [];
          if (index < first_halfLen)
            first_half =
              <div style={{ gridColumn: '1' }}>
                <p ><li style={{ fontSize: '16px', fontFamily: 'founders', color: 'rgb(57, 53, 78)' }}>{amenities_arr[index].desc}</li> </p></div>
          else
            second_half =
              <div style={{ gridColumn: '2' }}>
                <p ><li style={{ fontSize: '16px', fontFamily: 'founders', color: 'rgb(57, 53, 78)' }}>{amenities_arr[index].desc}</li> </p></div>
          return (
            <div key={index} style={{ marginRight: marginRight }}>
              {first_half}
              {second_half}
            </div>
          )
        })}
      </div>




    return (
      <div id="body"  >
        <AppBar >
          <Toolbar style={{ backgroundColor: 'white', height: '118px' }}>
            <div style={{ backgroundColor: '', marginLeft: '40px', marginTop: '' }} onClick={this.mainpage}>
              <img src={yourspace} style={{ width: '10.5rem', cursor: 'pointer' }} />
            </div>

          </Toolbar>
        </AppBar>

        <Grid container style={{ marginTop: 119, }} >
          <Grid container item lg={12} xl={12} md={12} sm={12} xs={12} style={{ position: 'relative' }} >
            <img src={this.state.space_data.image_url} style={{ height: this.state.bannarImageHight, width: "100%", objectFit: 'cover', objectPosition: 'center center', maxWidth: '100%' }} />
            {/* <span style={{paddingTop:"500px", position:"absolute", top:"80px"}}>edit</span> */}
            <div onClick={(e) => this.props.history.push({ pathname: '/addproperties/', state: { step: 5, space_id: this.state.space_data.id, property_id: this.state.space_data.property_id } })} style={{ position: 'absolute', float: 'right', top: 12, right: 12, visibility: this.state.toggleB }}>
              <div>
                <Button style={{ color: "white", border: "none", padding: "10px 30px", color: 'rgb(57, 53, 78)', height: '44px', width: '105px', textAlign: 'center', backgroundColor: 'white', borderRadius: '50px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.06)' }}>Edit</Button>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container direction="column" style={{ marginTop: 30, }}>
          <Grid container item justify="center" style={{ gridTemplateColumns: this.state.info1, marginTop: "20px", marginBottom: "20px" }} lg={12} md={12} sm={12} xs={12} xl={12}   >
            <Grid item style={{ backgroundColor: '', width: '1150px', }} >
              <div style={{ padding: "20px", gridColumn: 1, maxWidth: "1100px", backgroundColor: '' }}>
                <div style={{ backgroundColor: '', borderBottom: '0.0021px solid lightgrey' }}>
                  <Button className="edit_btn"
                    onClick={(e) => this.props.history.push({ pathname: '/addproperties/', state: { step: 1, space_id: this.state.space_data.id, property_id: this.state.space_data.property_id } })}
                    style={{ float: "right", color: 'rgb(57, 53, 78)', backgroundColor: 'white', borderRadius: '50px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', fontSize: '12px', border: '1px solid white' }}>Edit
                      </Button>
                  <strong style={{ fontFamily: 'founders_bold', fontSize: '41px', letterSpacing: 0, ...this.state.spaceName, lineHeight: '1em', color: 'rgb(57, 53, 78)' }}>
                    {this.state.space_data.name}
                  </strong>
                </div>
                <div style={{ marginTop: '7px' }}>
                  {this.state.special_room_type !== '' && this.state.space_data.category == 3 &&
                    <p style={{ fontFamily: 'founders', fontSize: '16px', lineHeight: '1.5em', color: 'rgb(57, 53, 78)', }}> {this.state.special_room_type} at {this.state.space_data.property_name} - Max.Num Guests: {this.state.space_data.capacity} - Space Size: {this.state.space_data.space_size} meter </p>
                  }
                  {this.state.space_data.category == 4 &&
                    <p style={{ fontFamily: 'founders', fontSize: '16px', lineHeight: '1.5em', color: 'rgb(57, 53, 78)' }}> {this.state.space_data.sub_category} at {this.state.space_data.property_name} - Max.Num Guests: {this.state.space_data.capacity} - Space Size: {this.state.space_data.space_size} meter </p>


                  }
                  {this.state.special_room_type == '' && this.state.space_data.category == 2 &&
                    <p style={{ fontFamily: 'founders', fontSize: '16px', lineHeight: '1.5em', color: 'rgb(57, 53, 78)' }}> {this.state.space_data.sub_category} {this.state.space_category} at {this.state.space_data.property_name} - Max.Num Guests: {this.state.space_data.capacity} - Space Size: {this.state.space_data.space_size} meter </p>

                  }

                </div>
                <div>
                  <p style={{ fontFamily: 'founders_bold', fontSize: '20px', color: 'rgb(57, 53, 78)' }}>Description:
                      {this.state.space_data.desc === '' &&
                      <p style={{ fontFamily: 'founders', fontSize: '16px' }}>No discription added.</p>

                    }
                    {this.state.space_data.desc !== '' &&
                      <p style={{ fontFamily: 'founders', fontSize: '16px' }}>{this.state.space_data.desc}</p>
                    }

                  </p>

                </div>


                {/* <div style={{ lineHeight: '1em' }}>
                  <p style={{ fontFamily: 'HelveticaNeue', fontSize: '20px' }} >Contract Start Date: {this.state.space_data.contract_start_date} </p>
                  <p style={{ fontFamily: 'HelveticaNeue', fontSize: '20px' }} >Contract End Date: {this.state.space_data.contract_end_date} </p>



                </div> */}
                <div style={{ marginTop: '35px' }}>
                  <div className="row" style={{ borderBottom: '1px solid lightgrey', paddingBottom: '3px' }}>
                    <div className="col">
                      <span style={{ fontFamily: 'founders_bold', fontSize: '20px', letterSpacing: 0, ...this.state.spaceName, lineHeight: '0em', }}>Reservation Information:</span>
                    </div>
                    <div className="col">
                      <Button style={{
                        color: 'rgb(57, 53, 78)', backgroundColor: 'white', borderRadius: '50px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', fontSize: '12px', border: '1px solid white', float: 'right'
                      }} onClick={(e) => this.props.history.push({ pathname: '/addproperties/', state: { step: 5, space_id: this.state.space_data.id, property_id: this.state.space_data.property_id } })}>Edit</Button>

                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.space_data.is_daily_avi === true ? true : false}

                            style={{ color: 'rgb(57, 53, 78)' }}
                          />
                        }
                        label='Daily Rent'
                      />
                      {this.state.space_data.is_daily_avi === true &&
                        <div style={{}}>
                          <p style={{ fontFamily: 'founders', lineHeight: '1em', fontSize: '14px', color: 'rgb(57, 53, 78)' }}>Check in time: {this.state.space_data.check_in_time}</p>
                          <p style={{ fontFamily: 'founders', lineHeight: '1em', fontSize: '14px', color: 'rgb(57, 53, 78)' }}>Check out time: {this.state.space_data.check_out_time}</p>
                          <span style={{ fontFamily: 'HelveticaNeue', lineHeight: '1em', fontSize: '12px', color: 'white', backgroundColor: 'rgb(57, 53, 78)', padding: '10px', borderRadius: '50px' }}>{this.state.space_data.price_daily} {this.state.space_data.currnecy_code}/DAY</span>
                        </div>
                      }

                    </div>
                    <div className="col">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.space_data.is_hourly_avi === true ? true : false}

                            style={{ color: 'rgb(57, 53, 78)' }}
                          />
                        }
                        label='Hourly Rent'
                      />
                      {this.state.space_data.is_hourly_avi === true &&
                        <div>
                          <span style={{ fontFamily: 'HelveticaNeue', fontSize: '12px', color: 'white', backgroundColor: 'rgb(57, 53, 78)', padding: '10px', borderRadius: '50px', marginLeft: '2px' }}> {this.state.space_data.price_hourly} {this.state.space_data.currnecy_code}/HOUR</span>
                        </div>
                      }


                    </div>
                  </div>
                </div>

              </div>

            </Grid>
            <Grid item >
              <div id="Reservation_element" style={{
                padding: "20px", width: '375px',
                height: "130px", display: 'flex', flexDirection: 'column', justify: 'flex-start', backgroundColor: '', border: '1px solid lightgrey',
              }}>

                <span style={{ fontFamily: 'founders_bold', fontSize: '20px'}}>Holding the space</span>
                

                <Button onClick={() => this.props.history.push({pathname:'/space/hold/'+this.state.space_data.id  , state: {spaceName:this.state.space_data.name}})}
                  variant="outlined" style={{ color: 'rgb(57, 53, 78)', backgroundColor: 'white', borderRadius: '50px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', fontSize: '12px', border: '1px solid white',marginTop:'auto'}}>
                  Hold
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Dialog open={this.state.open} onClose={this.handleClose} maxWidth="md" aria-labelledby="form-dialog-title">
          <DialogContent>
            <Grid container item justify="flex-start" style={{ backgroundColor: '' }}>
              <FormControl component="fieldset" style={{ marginTop: '20px' }}>
                <RadioGroup aria-label="gender" name="holding" value={this.state.hold_value} onChange={e => this.setState({ hold_value: e.target.value })}  >
                  {this.state.space_data.is_daily_avi === true && this.state.space_data.is_hourly_avi == false &&
                    <FormControlLabel value="Daily hold" control={<Radio style={{ color: 'rgb(57, 53, 78)' }} />} label="Daily hold" />
                  }
                  {this.state.space_data.is_daily_avi === false && this.state.space_data.is_hourly_avi == true &&
                    <FormControlLabel value="Hourly hold" control={<Radio style={{ color: 'rgb(57, 53, 78)' }} />} label="Hourly hold" />
                  }
                  {this.state.space_data.is_hourly_avi === true && this.state.space_data.is_daily_avi === true &&
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <FormControlLabel value="Daily hold" control={<Radio style={{ color: 'rgb(57, 53, 78)' }} />} label="Daily hold" />
                      <FormControlLabel value="Hourly hold" control={<Radio style={{ color: 'rgb(57, 53, 78)' }} />} label="Hourly hold" />
                    </div>
                  }
                </RadioGroup>
              </FormControl>
            </Grid>
            {this.state.space_data.is_daily_avi === true && this.state.hold_value == 'Daily hold' &&
              <Grid container justify="center" direction="column" alignContent>
                <Grid container item direction="column">
                  <Grid item style={{}}>
                    <Grid container item direction="column" justify="space-around">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item lg="12" sm="12" xs="12">
                          <KeyboardDatePicker
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="start-date"
                            style={{ width: '100%' }}
                            label="Start date"
                            value={this.state.spaceStartDate2}
                            minDate={this.state.spaceStartDate}
                            maxDate={this.state.spaceEndDate2}
                            onChange={(e) => this.handleDateChange(e, 1)}

                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                        <Grid item lg="12" sm="12" xs="12">
                          <KeyboardDatePicker
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="end-date"
                            style={{ width: '100%' }}
                            label="End date"
                            value={this.state.spaceEndDate2}
                            minDate={this.state.spaceStartDate2}
                            maxDate={this.state.spaceEndDate}
                            onChange={(e) => this.handleDateChange(e, 2)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
            {this.state.space_data.is_hourly_avi == true && this.state.hold_value === 'Hourly hold' &&
              <div>
                {this.state.space_data.is_hourly_avi == true && this.state.hold_value === 'Hourly hold' &&
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item lg="12" sm="12" xs="12">
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        style={{ width: '100%' }}

                        minDate={this.state.spaceStartDate}
                        maxDate={this.state.spaceEndDate}
                        label="Select Date"
                        value={this.state.hold_hourly_date}
                        onChange={(e) => this.handleDateChange(e, 3)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                }
                <Grid container item direction="column" justify="center" style={{ marginTop: '20px' }} >
                  <Grid item className='time' lg="12" style={{}}>
                    <Button1 onClick={(e) => this.timeC(0)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[0] }}  >1 AM</Button1>
                    <Button1 onClick={(e) => this.timeC(1)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[1] }} >2 AM</Button1>
                    <Button1 onClick={(e) => this.timeC(2)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[2] }} >3 AM</Button1>
                    <Button1 onClick={(e) => this.timeC(3)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[3] }} >4 AM</Button1>
                    <Button1 onClick={(e) => this.timeC(4)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[4] }} >5 AM</Button1>
                    <Button1 onClick={(e) => this.timeC(5)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[5] }} >6 AM</Button1>
                    <Button1 onClick={(e) => this.timeC(6)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[6] }} >7 AM</Button1>
                    <Button1 onClick={(e) => this.timeC(7)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[7] }} >8 AM</Button1>
                    <Button1 onClick={(e) => this.timeC(8)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[8] }} >9 AM</Button1>
                    <Button1 onClick={(e) => this.timeC(9)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[9] }} >10 AM</Button1>
                    <Button1 onClick={(e) => this.timeC(10)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[10] }} >11 AM</Button1>
                    <Button1 onClick={(e) => this.timeC(11)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[11] }} >12 PM</Button1>
                  </Grid>
                  <br />
                  <Grid item className='time' lg="12" style={{}}>
                    <Button1 onClick={(e) => this.timeC(12)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[12] }} >1 PM</Button1>
                    <Button1 onClick={(e) => this.timeC(13)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[13] }} >2 PM</Button1>
                    <Button1 onClick={(e) => this.timeC(14)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[14] }} >3 PM</Button1>
                    <Button1 onClick={(e) => this.timeC(15)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[15] }} >4 PM</Button1>
                    <Button1 onClick={(e) => this.timeC(16)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[16] }} >5 PM</Button1>
                    <Button1 onClick={(e) => this.timeC(17)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[17] }} >6 PM</Button1>
                    <Button1 onClick={(e) => this.timeC(18)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[18] }} >7 PM</Button1>
                    <Button1 onClick={(e) => this.timeC(19)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[19] }} >8 PM</Button1>
                    <Button1 onClick={(e) => this.timeC(20)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[20] }} >9 PM</Button1>
                    <Button1 onClick={(e) => this.timeC(21)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[21] }} >10 PM</Button1>
                    <Button1 onClick={(e) => this.timeC(22)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[22] }} >11 PM</Button1>
                    <Button1 onClick={(e) => this.timeC(23)} style={{ color: '#08D888', borderBottom: 'solid #08D888', ...this.state.time_c[23] }} >12 AM</Button1>
                  </Grid>

                  <Grid container item direction="column" style={{ marginTop: '25px' }}>
                    <Grid item style={{ color: 'black', }} > <span style={{ backgroundColor: '#08D888', height: '10px', width: '10px', borderRadius: '50%', display: 'inline-block' }}></span>  Available hours </Grid>
                    <Grid item style={{ color: 'black', marginTop: '8px' }}><span style={{ backgroundColor: 'red', height: '10px', width: '10px', borderRadius: '50%', display: 'inline-block' }}></span> Hold hours </Grid>

                  </Grid>
                </Grid>
              </div>
            }
          </DialogContent>
          <Grid container justify="space-around" alignContent="center" alignItems="center" style={{ marginTop: '90px', paddingBottom: '10px' }}>
            <Grid container item style={{ width: '70%' }}>

              <Grid item lg="6" xl="6" md="6" sm="6" xs="6" style={{ paddingLeft: '6px' }}>
                <Button onClick={this.handleClose} style={{ backgroundColor: 'rgb(57, 53, 78)', width: '100%', color: 'white', borderRadius: '50px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', border: '1px solid white' }}>
                  Cancel
                 </Button>
              </Grid>
              <Grid item lg="6" xl="6" md="6" sm="6" xs="6" style={{ paddingRight: '6px' }}>
                <Button onClick={this.SubmitHold} style={{ backgroundColor: 'white', width: '100%', color: 'rgb(57, 53, 78)', borderRadius: '50px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 8px 4px 0 rgba(0, 0, 0, 0.09)', border: '1px solid white' }}>
                  Send
               </Button>
              </Grid>
            </Grid>
          </Grid>

        </Dialog>

        {/* <Grid container item style={{backgroundColor:''}}>
                <div style={{backgroundColor:''}} > 
         <Pictures centerElements={this.state.centerLabel} centerLabel2 = {this.state.centerLabel2} toggle_right_center={this.state.toggle_right_center}
                media_pictures = {this.state.media} /> 
        </div>
                
                </Grid>  */}
        <Grid container direction="row" justify="center" style={{ backgroundColor: '', }} direction="row">
          <Grid container item direction="row" justify="flex-start" alignContent="center" alignItems="center" style={{ backgroundColor: '', width: '100%' }} xl={12} lg={12} md={12} xs={12} sm={12}>
            <Grid item style={{ backgroundColor: '', marginLeft: '6%', marginTop: '1%', width: '100%' }}>
              <React.Fragment >
                <div style={{ display: 'grid', }}>

                  <div >
                   

                    <Pictures centerElements={this.state.centerLabel} centerLabel2={this.state.centerLabel2} toggle_right_center={this.state.toggle_right_center}
                      media_pictures={this.state.media} gridStyle={this.state.gridStyle} />
                  </div>
                </div>
              </React.Fragment>
            </Grid>
          </Grid>
          <div className="container-fluid" style={{ width: '95%', margin: 'auto', }}>
            <Grid container item style={{ marginTop: marginTop1, backgroundColor: '' }} direction="row" >
              <Grid item style={{ backgroundColor: '', marginLeft: '3%', paddingBottom: '60px' }} xl={5} lg={5} md={12} sm={12} xs={12}  >
                <div style={{}}>
                  <Grid container item direction="row" style={{ backgroundColor: '', borderBottom: '1px solid lightgrey' }} justify="space-between">
                    <Grid item style={{ backgroundColor: '' }}>
                      <strong style={{ ...this.state.centerLabel, fontFamily: 'HelveticaNeue', fontSize: '24px', color: 'rgb(57, 53, 78)' }}>Features </strong>
                    </Grid>
                    <Grid item>
                      <div className="edit_btn" onClick={(e) => this.props.history.push({ pathname: '/addproperties/', state: { step: 3, space_id: this.state.space_data.id, property_id: this.state.space_data.property_id } })} style={{ gridRow: this.state.gridRow, gridColumn: "2", textAlign: "right" }}>
                        <Button style={{ color: 'rgb(57, 53, 78)', backgroundColor: 'white', borderRadius: '50px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', fontSize: '12px', border: '1px solid white' }}>Edit</Button>
                      </div>
                    </Grid>
                  </Grid>
                  {features_list}
                </div>

              </Grid>
              <Grid item style={{ backgroundColor: '', marginLeft: '3%', paddingBottom: '40px' }} xl={5} lg={5} md={12} sm={12} xs={12}>
                <div style={{}}>
                  <Grid container item direction="row" style={{ backgroundColor: '', borderBottom: '1px solid lightgrey' }} justify="space-between">
                    <Grid item>
                      <strong style={{ ...this.state.centerLabel, fontFamily: 'HelveticaNeue', fontSize: '24px', color: 'rgb(57, 53, 78)' }}>Amenities </strong>

                    </Grid>
                    <Grid item>
                      <div className="edit_btn" onClick={(e) => this.props.history.push({ pathname: '/editproperty/' + this.props.match.params.p_id })} style={{ gridRow: this.state.gridRow, gridColumn: "2", textAlign: "right" }}>
                        <Button style={{ color: 'rgb(57, 53, 78)', backgroundColor: 'white', borderRadius: '50px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', fontSize: '12px', border: '1px solid white' }}>Edit</Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                {amenities_list}
              </Grid>
            </Grid>

          </div>


          <div className="container-fluid" style={{ width: '95%', margin: 'auto', backgroundColor: '' }}>
            <Grid container item direction="row" style={{ backgroundColor: '', marginTop: 20, backgroundColor: '' }}  >
              <Grid item style={{ backgroundColor: '', marginLeft: '3%', backgroundColor: '' }} xl={5} lg={5} md={12} sm={12} xs={12}>
                <div style={{ backgroundColor: '', borderBottom: '', borderRadius: '', padding: '', boxShadow: '', backgroundColor: '', paddingBottom: '40px' }}>
                  <Grid container item direction="row" justify="space-between" style={{ backgroundColor: '', borderBottom: '1px solid lightgrey' }}>
                    <Grid item>
                      <strong style={{ ...this.state.centerLabel, fontFamily: 'HelveticaNeue', fontSize: '24px', color: 'rgb(57, 53, 78)' }}>Available Days </strong>
                    </Grid>
                    <Grid item>
                      <div className="edit_btn" onClick={(e) => this.props.history.push({ pathname: '/addproperties/', state: { step: 5, space_id: this.state.space_data.id, property_id: this.state.space_data.property_id } })} style={{ gridRow: this.state.gridRow, gridColumn: "2", textAlign: "right" }}>
                        <Button style={{
                          color: 'rgb(57, 53, 78)', backgroundColor: 'white', borderRadius: '50px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', fontSize: '12px', border: '1px solid white'
                        }}>Edit</Button>
                      </div>
                    </Grid>
                  </Grid>
                  {avi_days_list}
                </div>
              </Grid>
              {this.state.lng !== 0 &&
                <Grid item style={{ backgroundColor: '', marginLeft: '3%', }} xl={5} lg={5} md={12} sm={12} xs={12}>
                  <div style={{ gridColumn: "2", gridRow: "4", }}>
                    <div id="location-container" style={{ marginBottom: "20px", ...this.state.Margin, backgroundColor: '', marginTop: '10px' }}>
                      <strong style={{ marginLeft: '1rem', fontFamily: 'HelveticaNeue', fontSize: '24px', color: 'rgb(57, 53, 78)' }}>Location </strong>


                      <div id="google-map" style={{ width: "100%", }}>
                        <GoogleMap
                          property_id={this.props.match.params.p_id}
                          lat={this.state.lat}
                          lng={this.state.lng}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              }
            </Grid>


          </div>






        </Grid>





      </div>

    )
  }
}
