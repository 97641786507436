import React from 'react'
import YourSpace from '../Landing_page/images/yourspace2.png'
import DowloadIcom from '../Landing_page/images/Downloadicon.svg'
import Navbar from 'react-bootstrap/Navbar'

const mql = window.matchMedia(`(min-width:50em)`)
class Terms extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showSpanone: 'none',
            showSpanTwo: 'none',
            showSpanThree: 'none',
            showSpanFour: 'none',
            showSpanFive: 'none',
            showSpanSix: 'none',
            showSpanSeven: 'none',
            showSpanEight: 'none',
            showSpanNine: 'none',
            footerWidth:{}
        }
        this.componentMediaQuery = this.componentMediaQuery.bind(this)
    }
    componentDidMount() {
        localStorage.setItem('RegisterOrder', 'terms')
        this.componentMediaQuery()

    }
    componentWillMount(){   
        mql.addListener(this.componentMediaQuery)

    }
    componentWillUnmount(){
        mql.removeListener(this.componentMediaQuery)
    }
    componentMediaQuery(){
        if(mql.matches){
            this.setState({
                footerWidth:{
                    width:'35%'
                }
            })
        }else{
            this.setState({
                footerWidth:{
                    width:'90%'
                }
            })
        }
    }
    handleClick(event, number) {
        if (number === 1) {
            this.setState({ showSpanone: 'inline' })
        } else if (number === 2) {
            this.setState({ showSpanTwo: 'inline' })
        } else if (number === 3) {
            this.setState({ showSpanThree: 'inline' })
        } else if (number === 4) {
            this.setState({
                showSpanFour: 'inline'
            })
        } else if (number === 5) {
            this.setState({ showSpanFive: 'inline' })
        } else if (number === 6) {
            this.setState({ showSpanSix: 'inline' })
        } else if (number === 7) {
            this.setState({ showSpanSeven: 'inline' })
        } else if (number === 8) {
            this.setState({ showSpanEight: 'inline' })
        } else if (number === 9) {
            this.setState({ showSpanNine: 'inline' })
        }

    }
    render() {
        return (
            <div>
                <Navbar style={{ backgroundColor: '#08D888' }} variant="dark">
                    <Navbar.Brand href=""><img src={YourSpace} style={{ width: '11rem',cursor:'pointer' }} onClick={() => {
                        this.props.history.push('/')
                    }} /></Navbar.Brand>

                </Navbar>


                {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '60px' }} >
                    <button style={{ backgroundColor: '#08D888', color: 'white', width: '200px' }}>Print/Save</button>
                </div> */}

                <div className="container" style={{ marginTop: '70px', backgroundColor: '', textAlign: 'right' }} >
                    <h2 style={{ fontFamily: 'founders_bold', }}>الشروط والآحكام</h2>
                    <p style={{ fontSize: '25px', marginTop: '40px', fontFamily: 'founders', }}>المحتوى</p>
                    <ul style={{ marginLeft: '40px', textAlign: 'right', listStyleType: 'none' }}>
                        <li>
                            <a href="#terms_and_conditions" style={{ fontFamily: 'font_bold' }} >المقدمة -</a>
                        </li>
                        <li>
                            <a href="#payment_method" style={{ fontFamily: 'font_bold' }} > تعريفات -</a>
                        </li>
                        <li>
                            <a href="#amendment" style={{ fontFamily: 'font_bold' }}>الشروط والآحكام -</a>
                        </li>
                        <li>
                            <a href="#scope" style={{ fontFamily: 'font_bold' }} >طريقة الدفع -</a>
                        </li>

                        <li>
                            <a href="#limitation" style={{ fontFamily: 'font_bold' }}> تعديل الشروط والآحكام -</a>
                        </li>
                        <li>
                            <a href="#cancelation" style={{ fontFamily: 'font_bold' }}>  نطاق وطبيعة خدماتنا -</a>
                        </li>
                        <li>
                            <a href="#spaceaddition" style={{ fontFamily: 'font_bold' }}> القوة القاهرة -</a>

                        </li>
                        <li>
                            <a href="#checkidentity" style={{ fontFamily: 'font_bold' }}> ملفات تعريف الارتباط (الكوكيز) -</a>

                        </li>
                        <li>
                            <a href="#duration" style={{ fontFamily: 'font_bold' }}> حدودية المسؤولية -</a>

                        </li>
                        <li>
                            <a href="#upload" style={{ fontFamily: 'font_bold' }}> سیاسة الإلغاء واسترداد الرسوم الحجز والإلغاء -</a>

                        </li>

                        <li>
                            <a href="#intellectual" style={{ fontFamily: 'font_bold' }}> على التطبیق او الموقع الإلكتروني yourspacre  تقدیم اضافة مساحة عبر منصة -</a>

                        </li>
                        <li>
                            <a href="#imagedownload" style={{ fontFamily: 'font_bold' }}> تحميل الصور ومواقع المباني -</a>

                        </li>
                        <li>
                            <a href="#violations" style={{ fontFamily: 'font_bold' }}> حقوق الملكیة الفكریة  - </a>

                        </li>
                        <li>
                            <a href="#prohibited" style={{ fontFamily: 'font_bold' }}> معالجة المخالفات والتجاوزات  -</a>

                        </li>
                        <li>
                            <a href="#rejection" style={{ fontFamily: 'font_bold' }}> النشاطات المحظورة  -</a>

                        </li>
                        
                        <li>
                            <a href="#noreponsibility" style={{ fontFamily: 'font_bold' }}> الأخلاء من المسئولية -</a>

                        </li>
                        <li>
                            <a href="#compensation" style={{ fontFamily: 'font_bold' }}> التعویض  -</a>

                        </li>
                        <li>
                            <a href="#dispute" style={{ fontFamily: 'font_bold' }}> قانون حل النزاع  -</a>

                        </li>
                        <li>
                            <a href="#generalrules" style={{ fontFamily: 'font_bold' }}>أحكام عامة  -</a>
                        </li>


                    </ul>
                </div>
                <div class="container" style={{direction:'rtl',textAlign:'right'}}>
                    <p id="terms_and_conditions" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }}>المقدمة : </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    تتیح منصة yourspace  عبر موقعها عرض صور وكتالوجات عقاراتكم وفقا لشروط محددة، ومهمتنا
أن نفتح لك آفاقاً جدیدة لتستكشف العالم من حولك من خلال اتاحة الفرصة لكم لتقدیم أفضل المواقع
والمساحات الممیزة والمریحة في عالم العقار وأروع المعالم، فقط تمتع بزیارتها بالطریقة الأمثل عبر
منصة yourspace 





                      
                        <span style={{ fontFamily: 'founders_bold', display: this.state.showSpanSix === 'none' ? 'inline' : 'none' }} onClick={(e) => this.handleClick(e, 6)}> قراءة المزيد ....</span>
                        <span style={{ fontFamily: 'light', fontSize: 18, display: this.state.showSpanSix }}>تتيح منصة yourspace عبر موقعها عرض صور وكتالوجات عقاراتكم وفقا لشروط
محددة، ومهمتنا أن نفتح لك آفاقاً جديدة لتستكشف العالم من حولك من خلال اتاحة الفرصة لكم
لتقديم أفضل المواقع والمساحات المميزة والمريحة في عالم العقار وأروع المعالم، فقط تمتع
بزيارتها بالطريقة الأمثل عبر منصة yourspace.
حيث نهتم لأمرك باستخدام هذه المنصة لتقديم خدمة العملاء بأكثر من طريقة عبر منصة yourspace
 الالكترونية، ولذلك نفتح لك الباب أمام التجارب التي تناسب احتياجاتك من تسهيل
عملية البحث والاختيار وكذلك تسهل لك عملية الدفع مقابل رسوم الدخول على موقعنا من خلال
المنصة المتاحة بالإنترنت للبحث أو التأجير لمساحات أو مكاتب ذات مساحات مميزة وواسعة من
خلال منتجاتنا التي نوفرها كملاك للعقارات ذات المواقع الرائدة والاطلالات الساحرة.</span>

                    </span>

                    <p id="payment_method" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }}>تعريفات :</p>
                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>


                    1 .منصة yourspace أو مزود الخدمة: هي منصة متخصصة بعرض العقارات
والمساحات الخاصة بالملاك وترویجها بطرق حدیثة ومبتكرة عبر تطبیقاتها الإلكترونیة
والمواقع الخاصة بها وفق شروط وإجراءات معینة.
<br />2 .الملاك أو المالك: هم الأشخاص أو الشركات أصحاب العقارات الذین یقومون بعرض
وتسویق المساحات الخاصة بهم عبر منصة yourspace،
<br />3 .المستخدمین: هم الأشخاص أو الشركات الذین یستخدمون منصة yourspace للقیام
بحجوزات المكاتب أو المساحات الخاصة بهم والانتفاع بها خلال مدة الحجز.
<br />4 .الزائرین: هم الأشخاص الذین یقومون بزیارة الموقع وتصفحه واستعراض البیانات الخاصة
به عبر شبكات الانترنت.
            </span>
                    <p id="amendment" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }}>الشروط والآحكام :  </p>
                    <span style={{ fontFamily: 'light', fontSize: '18px', }}>

                    عزيزي مستخدم أو زائر منصة yourspace، تشكل هذه الشروط اتفاقية ملزمة قانونياً بينك
وبين yourspace حيث تحكم وصولك إلى yourspace منصتها واستخداماتها، بما
في ذلك أي نطاقات فرعية منه، وأي مواقع ويب أخرى تتيح او تقدم yourspace خدماتها
وتطبيقات الأجهزة المحمولة والأجهزة اللوحية وغيرها من الأجهزة الذكية وواجهات برنامج
التطبيق وجميع الخدمات المرتبطة (مجتمعة، خدمات موقع yourspace). يشار فيما بعد
إلى الموقع والتطبيق وخدمات yourspace مجتمعة باسم hads_ Platform;. يتم
تضمين شروط وأحكام هذه الاتفاقية أدناه،
            </span>

                    <p id="scope" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }}>طريقة الدفع :  </p>
                    <span style={{ fontFamily: 'light', fontSize: '18px', }}>
                    خدماتنا متوفرة على مدار (7/24 (لمساعدتك عبر المنصة الإلكترونیة التي توفرها yourspace ،على
مدار سبعة أیام في الأسبوع. فعند تقدیم طلب حجز أو أذن بالسماح بالدخول وفق رمز تحقق یصلك عبر
جوالك أو أي تطبیق أخر تستخدمه أو بریدك الالكتروني، كإشارة على قبول الطلب ومن ثم یتعین على
المستخدم أو طالب الخدمة أو المنتج أن یقوم بسداد الرسوم المحددة للعملیة ( ) بالریال/ الدولار أو
الیورو عبر استخدام بطاقة مصرفیة ساریة المفعول أو بطرق الدفع الأخرى المتفق علیها عزیزي
المستخدم أن موقع العقار تقریبي وغیر دقیق ولا یتم معرفة موقع العقار إلا بعد استكمال عملیة التسجیل
والحجز، لذلك عند تقدیمك كمستخدم طلب حجز او اذن بالسماح لك بالدخول وفق رمز التحقق المرسل
إلیك، علیك ابتدا ًء أن توافق على الشروط والأحكام التي تحكم عملیة دخولك وتمتعك بالمزایا والخصائص
 المتعلقة بخدمة الوصول الى الموقع أو المواقع 
                   
                        <span style={{ fontFamily: 'founders_bold', display: this.state.showSpanSeven === 'none' ? 'inline' : 'none' }} onClick={(e) => this.handleClick(e, 7)}> قراءة المزيد...</span>
                        <span style={{ display: this.state.showSpanSeven ,fontFamily: 'light', fontSize: '18px'}}>
                        التي ترغب وإتاحتها لك بعد تأكید الحجز سوف تصلك   
رسالة إلى جوالك او بریدك الالكتروني، كإشارة على قبول الطلب مع تزویدك بالموقع الدقیق للمكتب
المستأجر مع جمیع التفاصیل الأخرى اللازمة للحجز. عزیزي المستخدم أن استخدام منصتنا تجدها غنیة
بالمعلومات التي تقدم ما تفضله لك (كمستخدم) بالشكل الأنسب لك عند استخدام خدماتنا من خلال المنصة
بالإضافة لسهولة الاستخدام بالطریقة التي تضمن لك أفضل الأسعار المتوفرة
  
                        </span>

                    </span>


                    <p id="limitation" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >تعديل الشروط والآحكام :</p>
                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    یمكن لهذه الشروط والأحكام المنصوص علیها هنا أن تعدل من وقت لآخر، وهي تنطبق بشكل مباشر أو
غیر مباشر على جمیع خدماتنا المتاحة على شبكة الإنترنت أو من خلال أي جهاز محمول ومن خلال
البرید الإلكتروني والهاتف. بدخولكم وتصفحكم واستخدامكم لموقعنا (موقع الهاتف المحمول) أو أي من
تطبیقاتنا من خلال أي منصة (والمشار إلیها مجتمعة باسم yourspace و/ أو من خلال السعي
للبحث أو لحجز العقار أو المساحة أو المكتب ، فإنكم تق ّرون وتوافقون على أنكم قد قرأتم وفهمتم ووافقتم
على الشروط 
    <span style={{ fontFamily: 'founders_bold', display: this.state.showSpanEight === 'none' ? 'inline' : 'none' }} onClick={(e) => this.handleClick(e, 8)}>  قراءة المزيد ...</span>
                        <span style={{ display: this.state.showSpanEight,fontFamily: 'light', fontSize: '18px' }}>
                        والأحكام وتعدیلاتها التي تنص علیها هذه الوثیقة الالكترونیة (بما في ذلك بیان
الخصوصیة التي تغطي بحثكم والصور التي تختارونها، ومحتواها، والبنیة التحتیة لها، وخدمة الحجز
عبر الإنترنت (بأمان ) ، وتخضع للشروط والأحكام المذكورة أدناه yourspace
    {/* <span style={{ fontFamily: 'founders_bold', display: this.state.showSpanone === 'none' ? 'inline' : 'none' }} onClick={(e) => this.handleClick(e, 1)}> read more...</span> */}

                        </span>

                        <span style={{ fontFamily: 'light', fontSize: '20px', display: this.state.showSpanone }}>
                            conditions and availability of information and data) displayed on our platform.
                            The platform of Your Space does not constitute what you offer and should not be
                            considered as a recommendation or endorsement of the quality or level of service or
                            acceptance or classification or approval or as its products (main or additional) or
                            services) except as expressly stated or stated otherwise) .
                            Therefore, Your Space, as a provider of the Space platform , does not own, create,
                            available, control, manage, or provide any marketing lists or services, and Your Space is
                            not the organizer, seller, or lessor of buildings, spaces, or offices, where owners are
                            considered solely responsible for their listings, image displays, data and information.
                            When users make or accept a reservation, they enter into a direct contract with each
                            other. Therefore Your Space is not and does not become a party or other participant in
                            any contractual relationship between the owner or the user requesting the service or
                            product. Your Space must not be viewed as a real estate broker, insurance company, or
                            agent in any capacity, except as specified in the terms and conditions.





                            {/* <span style={{ fontFamily: 'founders_bold', display: this.state.showSpanNine === 'none' ? 'inline' : 'none' }} onClick={(e) => this.handleClick(e, 9)}> read more...</span> */}

                        </span>
                        <span style={{ display: this.state.showSpanNine }}>
                            C. The user must acknowledge that by reading these conditions, he agrees to release Your
                            Space from any responsibility related to the application of additional policies that may be

                            applied by your service provider and non-refundable / additional fees policies for
                            reservations / types of cards accepted). Late payment, wrong bank, credit card or
                            withdrawal data, invalid credit / withdrawal cards or insufficient funds are at the
                            responsibility of the user or visitor who made the reservation and attempted to pay with
                            the cards and you may not be entitled to a refund or any prepayment (non-refundable).
                            The service provider or owner has not agreed or permitted this as part of the prepayment
                            and cancellation policy.
                            {/* <span style={{ fontFamily: 'founders_bold', display: this.state.showSpaneTwo === 'none' ? 'inline' : 'none' }} onClick={(e) => this.handleClick(e, 2)}> read more...</span> */}

                        </span>
                    </span>

                    <span style={{ fontFamily: 'light', fontSize: '20px', display: this.state.showSpanTwo }}>
                        D. Verifying the identity of a user on the Internet is difficult, and we, as Your Space, do
                        not bear any responsibility for confirming the identity of any visitor or user of the
                        platform or who is booking or submitting a reservation request. Notwithstanding the
                        foregoing, for the purposes of transparency and fraud prevention, and as permitted by
                        applicable laws, and what is permitted by us under the terms and conditions, we do not
                        have any obligation to (1) require users to provide some form of official identity or other
                        information or a procedure . Additional checks are designed to help verifying the
                        identities of users or visitors to Your Space or others.
                        E. Your Space Corporation is not responsible for - any loss, lack or missing of any lost
                        data, omission or interruption in service, computer damage, system failure, or the cost of
                        alternative products or services, or for any compensation for personal or physical injury,
                        or with respect to (1) these terms, (2) or what arises from the use or inability to use the
                        Your Space platform due to any communications, interactions or meetings with other
                        owners, users, or other persons you communicate with, interact with, or meet as a result
                        of your use for Your Space platform website, (3) from publication or reservation of a list,
                        including provision or use of x other services, whether on the basis of a guarantee,
                        contract, damage (including negligence), product liability, or any other legal theory
                        reported to Your Space about it or not, with the possibility of such damage occurring,
                        even if the limited compensation provided Therefore, in respect of this agreement it did
                        not fulfill the purpose of it.
    </span>

                    <p id="cancelation" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >نطاق وطبيعة خدماتنا : </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    يتم توفير منصة yourspace على الانترنت التي تمكن المستخدم والباحث عن العقارات
والمكاتب والمساحات، والتسويق، التأجير والبحث عن المواقع من خلال منصة yourspace
 لإتاحة الوصول إليها من قبل المستخدمين والباحثين والمستأجرين للمكاتب
والمساحات لإتمام عملية الحجز والتأجير حيث يمكن للزوار المعنيين للمنصة اكتشاف أو البحث
أو المقارنة أو عمل طلب أو الحجز أو التأجير أو الشراء أو الدفع أو الاستفادة منها (على سبيل
المثال: حجز الموقع او المساحة من خلال تصوير الموقع)، عزيزي المستخدم إن دخولك على
موقع البحث لدى منصة yourspace يجعلك تدخل في علاقة مباشرة (ملزمة قانونياً)
لاستخدامك المنصة وكذلك مع مقدم الخدمة أو ملاك العقارات في حالة إجراء عملية البحث او
الذي يقوم بالحجز أو تأجير المنتج (العقارات ) أو تقديم خدمة    <span style={{ fontFamily: 'founders_bold', display: this.state.showSpanThree === 'none' ? 'inline' : 'none' }} onClick={(e) => this.handleClick(e, 3)}>   قراءة المزيد ...</span>
                        <span style={{ fontFamily: 'light', fontSize: '18px', display: this.state.showSpanThree }}>
                        (من المنصة التي تجري فيها
حجزك، لذا فإن yourspace هي وسيط بينك وبين مقدم او ملاك العقار أو المساحات يتم
من خلال منصتها ارسال تفاصيل العقار أو المساحة وحجمها وموقعها من خلال الصور التي تم
مشاهدتها . لذا فإن منصة yourspace تشدد على ضرورة الالتزام باي تعديل او تحديث يتم
على هذه الشروط والأحكام للتمتع بالبحث والقيام بالحجز قبل قيامكم بعملية الحجز.
 
</span>

                    </span>



                    <p id="spaceaddition" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders', textDecoration:'underline'}} >القوة القاهرة :</p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    تعني القوة القاهرة أي حدث یقع خارج سیطرة الطرفین، ویجعل تنفیذ الالتزام مستحیًلا أو غیر مكتمل
لأي طرف بسبب أي من الحوادث التالیة:
أ. على سبیل المثال لا الحصر (في حالة قیام العمیل أو المستخدم باستئجار عقار وفجأة انقطع التیار
الكهربائي بسبب لا یرجع إلى مالك العقار، عندئذ یلتزم مالك العقار إما بتعویض العمیل أو المستخدم بیوم
آخر أو بإعادة قیمة الإیجار ولا تتحمل yourspace  أي تعویض في  هذا الشأن.<br />
ب.  ظهور الأمراض والأوبئة التي تستلزم قیام الدولة بإجراءات معینة مثل جائحة كرونا أو غیرها من 
الأمراض<br />
ج. ظهور أخطاء أو فیروسات تؤدي إلى تلف بیانات المستخدم أو العمیل لدرجة أنهم غیر قادرین على
استخدام الموقع الخاص بك.<br />
د. موت العمیل او المستخدم أو صاحب العقار.<br />
ه. الكوارث الطبیعیة مثل الزلازل والبراكین والفیضانات وغیرها.<br />
وتعتبر التعاقدات في مرحلة القوة القاهرة قابلة للإلغاء بدون أي رسوم إضافیة یتحملها المتعاقدین. ویحق
لجمیع الأطراف المتعاقدة اعتبار العقد كان لم یكن.
                    </span>

                    <p id="checkidentity" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} > ملفات تعريف الارتباط (الكوكيز) :  </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    تحتفظ yourspace بملفات وحزم بيانات التي تمر عبرها الرسائل التي على الخادم الخاص
بها من وإلى المستخدم والباحث الذي يقوم بزيارة موقع المنصة. ومن ثم يخزن الموقع ما يقوم به
المستخدم سواء كانت رسالة أو معلومات يصل إليها المستخدم ويعرضها في ملف صغير يسمى
ملف تعريف الارتباط، وتحتوي هذه الملفات عادة على معلومات حول المستخدم الذي يزور
صفحة بموقع منصة yourspace ، بالإضافة إلى أي معلومات يقوم المستخدم بتعبئتها
                           <span style={{ fontFamily: 'founders_bold', display: this.state.showSpanFour === 'none' ? 'inline' : 'none' }} onClick={(e) => this.handleClick(e, 4)}>  قراءة المزيد ...</span>
                        <span style={{ fontFamily: 'light', fontSize: '18px', display: this.state.showSpanFour }}>
                           

                        تلقائيًا، مثل الاسم والبيانات او الصور أو مواقع العقارات ومساحاتها. هذه المعلومات او البيانات
يمكن الوصول اليها مرة اخرى بواسطة المستعرض أو المستخدم في كل مرة يقوم فيها بالوصول
إلى نفس الخادم من خلال هذه البيانات التي يستخدمها الخادم، بالتالي سيمكّن هذا الملف yourspace
 من تحديد هوية الباحث او طالب الخدمة أو المستخدم أو تتبع وصوله إلى الخادم.
 
                            </span>

                    </span>
                    <p id="duration" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >   حدودية المسؤولية : </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    أ - من المتفق علیه أن مسئولیة توفیر المواقع والبیانات والصور والبروشورات للعقارات والمساحات
والمكاتب وإرسالها في فیدیو لمنصة yourspace تقع على عاتق أصحاب العقارات أو المكاتب أو
المساحات وكذلك التحقق منها قبل اضافتها على المنصة ( التطبیق الذي یسهل الوصول إلى هذه
الفیدیوهات ) . عزیزي المستخدم أن منصة yourspace فقط تتوسط وتمنحك الفرصة حتى تتمكن من
التواصل مع أصحاب العقارات والمساحات والمكاتب الذین یقومون بالتسویق وعرض منتجاتهم من
مكاتب او مساحات على منصة yourspace ،وذلك عن طریق منصة yourspace للقیام بالبحث
والتأجیر والحجز، لذلك یكون اصحاب العقارات ( المنتجات) هم المسؤولین بشكل كامل عن تحدیث
الصور او الكتالوجات وكذلك جمیع الأسعار / الرسوم، والسیاسات والشروط وغیرها من المعلومات
ذات الصلة التي یتم عرضها على منصة yourspace .على الرغم من أننا سنستخدم مهاراتنا وعنایتنا
في أداء إجراءات خدمة عرض العقارات من خلال المنصة، لكننا لا یمكننا كمنصة (yourspace(أن
نضمن دقة جمیع المعلومات أو عدم دققتها أو كمالها أو صحتها للمستخدم ام الباحث، ولا یمكن أن
لسنا مسؤولین عن أي انقطاع
نكون مسؤولین عن أي أخطاء (بما في ذلك أخطاء بیانیة ومطبعیة)، أیضاً
(سواء كان ذلك بسبب أي انهیار أوإصلاح أو ترقیة أو صیانة المنصة لدینا سواء بشكل (مؤقت و / أو
جزئي) أو غیر ذلك)،سواء كانت تلك المعلومات غیر دقیقة أو مضللة أو غیر صحیحة أو عدم تسلیم
المعلومات. إذ تقع المسئولیة على عاتق كل مالك أو مستخدم للمنصة من اجل البحث او الحجز او
التاجیر مسؤو ًلا في جمیع الأوقات عن دقة واكتمال وصحة المعلومات (الوصفیة) (بما في ذلك الأسعار /



    
<span style={{ fontFamily: 'founders_bold', display: this.state.showSpanFive === 'none' ? 'inline' : 'none' }} onClick={(e) => this.handleClick(e, 5)}>قراءة المزيد ...</span>
                        <span style={{ fontFamily: 'light', fontSize: '18px', display: this.state.showSpanFive }}> 
                        الرسوم والسیاسات والشروط وتوفر المعلومات والبیانات) المعروضة على منصتنا. ولا تشكل المنصة
الخاصة ب yourspace ولا ینبغي للستخدم أن یعتبر ما تقوم به منصة یور اسبیس بمثابة توصیة أو
أو موافقة أو على المنتجا ت المعروضه
لمدى جودة أو مستوى الخدمة أو قبولها أو تصنیفاً
ً
تأییدا
(الرئیسیة أو الإضافیة) أو الخدمات المقدمة من الملاك) باستثناء ما هو مبین بصراحة أو منصوص علیه
خلاف ذلك.<br />
ب. إن yourspace كمقدم لمنصة yourspace  ، لا تملك أو تنشئ أو توفر أو تتحكم أو تدیر أو
تقدم أي قوائم أو خدمات تسویقیة، ولا تعتبر yourspace هي الجهة المنظمة أو بائع أو مؤجر المباني
أو المساحات أو المكاتب ، حیث یعتبر الملاك وحدهم مسؤولین عن قوائمهم وعروض الصور والبیانات
والمعلومات. وعندما یقوم المستخدمون بإجراء الحجز أو قبوله ، فإنهم یبرمون عقًدا مباش ًرا مع ملاك تلك
العقارات بمفهوم القانون للتعاقد ما یسمي ( الأیجاب والقبول) لأي معاملة تتم. بالتالي yourspace
ا في أي علاقة تعاقدیة أخرى بین الملاك والمستخدم وطالب الخدمة
ا أو مشاركً
لیست ولن تصبح طرفً
أو المنتج ولایجب أن ینظر على yourspace یور اسبیس باعتبارها سمسار عقارات أو شركة تأمین
أو وكیل بأي صفة ، سوى ما هو محدد في الشروط والاحكام بهذه الوثیقة الالكترونیة التي تحكم العلاقة
بین منصة yourspaceوالملاك والمستخدم <br />



ج. من المفهوم أن المستخدم بقراءته هذه الشروط یكون ملتزم ویقر أنه موافق على إخلاء yourspace من أي مسئولیة تتعلق بتطبیق السیاسات الإضافیة التي قد تطبق من قبل ملاك العقارات
والمساحات والمكاتب وسیاسات المالیة المتعلقة باحكام غیر قابل للاسترداد من الرسوم الإضافیة
لحجوزات أو أنواع البطاقات المقبولة). والدفع المتأخر والبنك الخطأ وبیانات بطاقة مصر فیة للأیداع
أو السحب، بطاقات سحب غیر صالحه أو أموال غیر كافیة لدى المستخدم ؛ هذه مجتمعة تكون على
مسؤولیة المستخدم وحده وقد لا یحق لك استرداد المبلغ أو أي دفع مسبق (غیر قابل للاسترداد) ما لم
یوافق مقدم الخدمة او المالك أو یسمح ذلك في إطار سیاسة الدفع  (المسبق والإلغاء.)<br />
د. أن منصة yourspace غیر مسؤولة عن أي فقد أو ضیاع او فوات اي من البیانات أو فقد النوایا
الحسنة أو انقطاع الخدمة أو الكمبیوتر تلف أو فشل النظام أو تحمل تكلفة المنتجات أو الخدمات البدیلة ،
أو عن أي أضرار فیما یتعلق بـ (1 (هذه الشروط ، (2 (من استخدام أو عدم القدرة على استخدام منصة
    yourspace بسبب من أي اتصالات أو تفاعلات أو اجتماعات أو فشل التواصل مع ملاك أو
مستخدمین آخرین أو أشخاص آخرین تتواصل معهم أو تتفاعل معهم أو تقابلهم نتیجة لاستخدامك لـ موقع
منصة yourspace ، أو (3 (فشل وصول حجز او تاجیرك وربطه مع الحجز الحقیقي او الذي تم



    اختیاره بناء على الصور او خطا بالمساحة أو حجز قائمة ، بما في ذلك توفیر أو استخدام خدمات
اخرى، سواء أكان ذلك على أساس الضمان أو العقد أو الضرر (بما في ذلك الإهمال) أو مسؤولیة المنتج
أو أي نظریة قانونیة أخرى تم إبلاغ yourspace عنها و الخاصة بها أم لا بإمكانیة حدوث مثل هذا
الضرر ، سوى التعویض المحدد المنصوص علیه في هذه الاتفاقیة وطبقا للسیاسات التعویض او
الاسترداد ادناه.<br />
ه. عزیزي المستخدم یحق لمنصة yourspace حذف التعلیقات الموجودة على صفحات العقارات إذا
كانت مخالفة للذوق او خادشة للحیاء.</span>
                    </span>
                    <p id="upload" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >سیاسة الإلغاء واسترداد الرسوم الحجز والإلغاء :  </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    أ. عزیزي المستخدم عند إجراءك عملیة البحث او حجز المكتب أو المساحة عن طریق منصة yourspace یعنى قبولك و موافقتك على شروط سیاسة الإلغاء والأسترداد في حالة عدم الحضور لأكمال
المعاملة ذات الصلة ببحثك عبر هذه المنصة، و موافقتك على أیة شروط وأحكام (تسلیم) إضافیة خاصة
بملاك المباني والمساحات او مقدمي الخدمة والتي قد تنطبق على حجزك (بما في ذلك الشروط
المفصلة لدى مقدم الخدمة والتي یتم توفیرها على المنصة بالإضافة إلى القواعد الداخلیة للمقدم المالي -
العربون)، بما في ذلك الخدمات المقدمة و/أو المنتجات المعروضة من قبل مقدم الخدمة. یمكن الحصول
على شروط وأحكام التسلیم/الشراء/استخدام/ لمقدم الخدمة ذو الصلة. یتم توفیر سیاسة الإلغاء وعدم
الحضور العامة من قبل ملاك للعقارات على موقعنا الإلكتروني على منصة یور اسبیس التي تحتوي على
معلومات مقدم الخدمة أو المنتج.
ب. أسعار ورسوم معینة وعروض خاصة غیر قابلة للإلغاء أو الاسترجاع أو التغییر. قد یقوم المستخدم
بتحملها في حالات معینة مثال ضرائب الدولة المطبقة في حالة الإلغاء غیر المجاني أو في حال عدم
الحضور. یرجى الوضع بأن حجز الخدمة / المكتب أو المساحة الذي یتطلب عربون أو دفع (كلي أو
جزئي) عندما یتم إلغاؤه ( یجب أن یسبقة إشعار مسبق محدد أو تحذیر) بقدر المبلغ / المبالغ (المتبقیة)
ذات الصلة .
ج. مع المتفق علیه انه یمكن استرداد المبلغ المدفوع من المستخدم وارجاعه الیه في حالة كان الإلغاء من
قبل مالك العقار او المساحة او المكتب أو تعویض المستخدم أو العمیل بموقع او مساحة او مكتب آخر أو
التنازل عن غرامات الإلغاء إذا كان الإلغاء بسبب ظروف غیر متوقعة خارجة عن سیطرة المستخدم أو
لیست بسببه.




ما إذا كان الالغاء او الاسترجاع بسبب یعود الى العمیل، عندئذ یحق للعمیل استرجاع كامل المبلغ
بالحالات التالیة:
(ا) إذا تم الالغاء قبل (48(ساعة من تاریخ او وقت اجراء الحجز او التاجیر،
(ب) بینما یحق للمستخدم او العمیل استرداد نصف المبلغ فقط إذا كان الإلغاء او الاسترداد قبل (24
)ساعة ،
(ج) لكن لا یمكن للمستخدم ان یسترد المبلغ المطلوب إذا طالب باسترداده في نفس یوم الحجز . في كلا
حالات اللالغاء والاسترجاع یتعین تقدیم الوثائق اللازمة لإثبات السبب , ولا تكون الإلغاءات بدون
غرامات متاحة إلا للظروف المخففة فقط التي تحدث قبل تاریخ الوصول الرسمي للحجز ..مثل المرض
الفجائي ، او وفاة احد افراد العائلة من الدرجة الاولي او اي حادث خارج عن السیطرة مع ضرورة تقدیم
الوثائق التي تؤید الوقائع في غضون (14 (یوما من الواقعة .


    عزیزي المستخدم في حال رغبتك بالتواصل لإضافة مساحة على منصة yourspace سوف تطرح أو
یكون سیاسات لمنح وتقدیم مدة او مدد محدده بحدود قصوى او ادني وذلك بصدد التثبت من صحة بیانات
المساحات او المكاتب وتطابقها مع الصور او الكتالوجات المعروضة من قبل المالك أو التطبیقات التي
یستخدمها المستخدم. (تحدد المدد من قبل مقدم الخدمة بالتشاور مع الملاك)
عزیزي المستخدم أنت تقر وتوافق بإطلاعك وقبولك على هذه الشروط والأحكام على أن مزود الخدمة (
المالك ) المعني هو المسؤول الوحید ویتحمل كل المسؤولیة والإلتزام فیما یتعلق بالخدمة (بما في ذلك أي
ضمانات وتعهدات یقدمها الملاك). لذا إن التعامل مع الشكاوى أو المطالبات المتعلقة بالعقارات او
المساحات المكتبیة (بما في ذلك ما یتعلق بسعر العرض (الخاص / الترویجي) أو السیاسة أو الطلبات
المحددة التي یقدمها العملاء) مع ملاك العقارات. و علیكم اخلاء منصة yourspace من أي التزام أو
مسؤولیة بما یتعلق بهذه الشكاوى والمطالبات و (المنتج).سواء أكان مزود الخدمة ( الملاك) بشأن خصم
رسوم من المستخدم أو العمیل مقابل الحجز أو الـتأجیر او البحث أو في حالة قیام منصةyourspace بتسهیل عملیة دفع رسوم أو سعر الخدمة عبر أي من حساباتها ومن ثم تحویلها إلى حساب ملاك
العقارات ))، ایضا یتعین على المستخدم یوافق ویقر بأن منصة yourspace هي المسؤولة في جمیع
الأوقات عن تحصیل، تحویل، حجز أو دفع الضرائب المطبقة والمستحقة على المبلغ الإجمالي لسعر
(المكتب / المساحة) أو رسوم للسلطات الضریبیة المختصة وأن yourspaceغیر ملزمة أو مسؤوله


عن تحویل أو تحصیل أو اقتطاع أو دفع الضرائب ذات الصلة المستحقة على سعر (المكتب / المساحة)
أو رسوم السلطات الضریبیة ذات الصلة.
                       

                    </span>
                    <p id="intellectual" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders', textDecoration:'underline'}} >تقدیم اضافة مساحة عبر  منصة yourspace على التطبیق او الموقع الإلكتروني :      </p>
                   
                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    عزيزي المستخدم في حال رغبتك بالتواصل لإضافة مساحة على منصة yourspace
سوف تطرح أو يكون هناك سياسات لمنح وتقديم مدة او مدد محدده بحدود قصوى او ادني وذلك
بصدد التثبت من صحة بيانات المساحات او المكاتب وتطابقها مع الصور او الكتالوجات
المعروضة من قبل المالك أو التطبيقات التي يستخدمها المستخدم. (تحدد المدد من قبل مقدم
الخدمة بالتشاور مع الملاك)
عزيزي المستخدم أنت تقر وتوافق بإطلاعك وقبولك على هذه الشروط والأحكام على أن مزود
الخدمة (المالك) المعني هو المسؤول الوحيد ويتحمل كل المسؤولية والإلتزام فيما يتعلق بالخدمة
(بما في ذلك أي ضمانات وتعهدات يقدمها الملاك). لذا إن التعامل مع الشكاوى أو المطالبات
المتعلقة بالعقارات او المساحات المكتبية (بما في ذلك ما يتعلق بسعر العرض (الخاص /
الترويجي) أو السياسة أو الطلبات المحددة التي يقدمها العملاء) مع ملاك العقارات. و عليكم
اخلاء منصة yourspace من أي التزام أو مسؤولية بما يتعلق بهذه الشكاوى والمطالبات
و (المنتج).سواء أكان مزود الخدمة ( الملاك) بشأن خصم رسوم من المستخدم أو العميل مقابل

الحجز أو الـتأجير او البحث أو في حالة قيام منصة yourspace بتسهيل عملية دفع رسوم
أو سعر الخدمة عبر أي من حساباتها ومن ثم تحويلها إلى حساب ملاك العقارات ))، ايضا يتعين
على المستخدم يوافق ويقر بأن منصة yourspace هي المسؤولة في جميع الأوقات عن
تحصيل، تحويل، حجز أو دفع الضرائب المطبقة والمستحقة على المبلغ الإجمالي لسعر (المكتب
/ المساحة) أو رسوم للسلطات الضريبية المختصة وأن yourspace غير ملزمة أو
مسؤوله عن تحويل أو تحصيل أو اقتطاع أو دفع الضرائب ذات الصلة المستحقة على سعر
(المكتب / المساحة) أو رسوم السلطات الضريبية ذات الصلة.
                    </span>

                    <p id="imagedownload" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} > تحميل الصور ومواقع المباني : </p>

<span style={{ fontFamily: 'light', fontSize: '18px' }}>
عزيزي المستخدم يجب أن تعلم أن المالك هو المسؤول مسؤولية مطلقة بتحميل الصور على نظام
التطبيق الخاص ب yourspace (مثلا: كإضافة الصور وغيرها)
</span>
                    <p id="violations" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >حقوق الملكیة الفكریة : </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    عزيزي المستخدم ما لم يكن منصوصاً خلاف ذلك، فإن البرمجيات والتطبيقات اللازمة لخدماتنا
أو المتاحة على منصتنا أو المستخدمة من قبل موقعنا،وحقوق الملكية الفكرية (بما في ذلك حقوق
التأليف والنشر وعرض الصور) لمحتويات منصتنا والمعلومات التي تفيد عنها والمواد المتوفرة
عليها، هي مملوكة من قبل أو مرخص باستخدامها من مورديها أو مزودي خدمات عرض
الصور والبيانات والبروشورات .تحتفظ yourspace بشكل حصري بملكية جميع الحقوق
وملكية العنوان والانتفاع في ولـِ (جميع حقوق الملكية الفكرية لـِ) (الشكل والفكرة (بما فيه البنية
الأساسية) لـِ) المنصة الذي تم عليه إتاحة الخدمة (بما في ذلك تعليقات المستخدمين المسموح لهم
بالدخول بعد دفع رسوم البحث على المنصة و ترجمة المحتوى) .غير مسموح او مخولاً
للمستخدم أو الملاك له القيام بنسخ أو كشط أو استخدام الرابط (المتاح له الوصول به والدخول
على yourspace لـِ أو نشر أو ترويج أو تسويق أو دمج أو استخدام أو جمع أو أي استخدام
آخر للمحتوى (بما في ذلك أية ترجمات أو تعليقات متعلقة بها) أو علامتنا التجارية دون
الحصول على إذن خطي من yourspace ، بالقدر الذي يمكنكم من إستخدام أو الجمع
(بشكل كلي أو جزئي) للمحتوى (المترجم) الخاص بنا (بما فيه تعليقات المستخدمين الآخرين أو
يمكنك بطريقة أخرى من امتلاك أية حقوق للملكية الفكرية في المنصة أو أي محتوى (مترجم) أو
التعليقات للمستخدمينء، تقوم بموجبه أنت ( الملاك او المستخدمين )بتحديد ونقل وتعيين مثل هذه
الحقوق للملكية الفكرية. أي استخدام غير قانوني أو أي من الإجراءات المذكورة أو السلوكيات
المذكورة آنفًا مما يشكل انتهاك مادّي لحقوق الملكية الفكرية (بما فيه حقوق النشر وحقوق
قاعدة البيانات الخاصة ب yourspace
                    </span>

                    <p id="prohibited" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >معالجة المخالفات والتجاوزات :   </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    يحق ل yourspace حظر المستخدم من الدخول على المنصة بشكل جزئي او نهائي في
حال ارتكابه أو صدور اي مخالفات تتعلق بسوء استخدام المنصة للدعاية او التعليقات المسيئة او
غير اللائقة (مثل التعليقات العنصرية، الهوية الجنسية، واو الميول الشخصية او الدين، او العرق
او نشر صور فاضحة خلافا لما خصصت له المنصة) أو قيامه بإتلاف او تشويه الصور الخاصة
بالمباني او المكاتب او المساحات المعروضة بالموقع،
                        </span>
                    <p id="rejection" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >النشاطات المحظورة : </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    إن yourspace هي وحدها المسؤولة عن الامتثال لأي وجميع القوانين والقواعد واللوائح
والالتزامات الضريبية التي قد تنطبق على استخدامك ل yourspace. فيما يتعلق باستخدامك
لـ yourspace فلن تقوم ولن تساعد الآخرين أو تمكنهم من: خرق أو التحايل على أي
قوانين أو لوائح أو اتفاقيات سارية مع أطراف ثالثة أو حقوق طرف ثالث أو شروطنا أو سياساتنا
أو معاييرنا ؛استخدام yourspace أو المحتوى الجماعي لأي أغراض تجارية أو أغراض
أخرى غير مسموح بها صراحةً بموجب هذه الشروط أو بطريقة تنطوي على زيف تأييد yourspace
 أو الشراكة أو تضليل الآخرين بطريقة أخرى فيما يتعلق بانتمائك إلى yourspace
 ؛نسخ أو تخزين أو الوصول إلى أو استخدام أي معلومات ، بما في ذلك معلومات
التعريف الشخصية عن أي عضو آخر ، المضمنة في yourspace بأية طريقة لا تتفق مع
سياسة خصوصية yourspace أو هذه الشروط أو التي تنتهك حقوق خصوصية الأعضاء
أو الغير.
                        </span>

                        <p id="noreponsibility" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders',textDecoration:'underline' }} >الأخلاء من المسئولية : </p>

<span style={{ fontFamily: 'light', fontSize: '18px' }}>
عزيزي المستخدم إذا اخترت استخدام yourspace فإنك تفعل ذلك طواعية وعلى
مسؤوليتك وحدك. يتم توفير منصة yourspace “، دون أي ضمان أو تعهد من أي نوع،
سواء كانت صريحة أو ضمنية. لذا يخلي المستخدم yourspace من المسؤولية القانونية
لجميع الصور والبيانات والبروشورات المتعلقة بالمباني والمكاتب او المساحات التي تم تحميلها
أو نشرها منصة yourspace ومن ثم تحميلها على تطبيقات او استخدامات المستخدم. حيث
تقع هذه المسئولية على عاتق المستخدم كونه من قام بتحميل الصور لذا عليه ضمان خلوها من أي
فيروسات أو فيروس حصان طروادة أو الملفات المصابة، وعليه أيضاً ضمان خلوها من أي مواد
إباحية غير قانونية أو فاحشة أو مهينة، ومكروهة أو غير لائقة وأي مواد أخرى تنتهك حقوق أي
طرف ثالث (حقوق الملكية الفكرية، أو حقوق الطبع والنشر أو الخصوصية). حيث أن yourspace
 لن تقوم بنشر أي صور غير مستوفية للمعايير المذكورة أعلاه. وتملك حق إزالتها
وحذفها في أي وقت وبدون أي إشعار مسبق. حيث ان موافقتكم وقبولكم على الشروط والاحكام
واللوائح والقواعد التي أتاحت لكم المجال بالمنصة للتحقيق والبحث والتأجير وتحميل صور
العقارات والمساحات التي على منصة يور اسبيس yourspace والتي قد تنطبق على قوائم
و / أو خدمات ملاك العقارات التي تتلقاها عبر التطبيق ومع ذلك لا يعني ذلك أنك تعتمد على أي
بيان قانون أو حقيقة أدلى بها موقع yourspace  المتعلقة بتسهيل وصولكم إلى صور
وكتالوجات ملاك العقارات او المساحات.
    </span>
                    <p id="compensation" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders', textDecoration:'underline'}} > التعویض : </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    عزيزي المستخدم يمكن أن يكون هنالك تعويض إلى الحد الأقصى المسموح به بموجب القانون
المعمول به، لذلك أنت توافق على إبراء yourspace وشركاتها الفرعية والشركات التابعة
لها والدفاع عنها (وفقًا لخيار yoursace)، بما في ذلك على سبيل المثال لا الحصر، ابراء
yourspace عن أي مدفوعات ناشئة عن أو إعفائها ومسؤوليها ومديريها وموظفيها
ووكلائها. عن أي مطالبات أو دعاوى ، دون تسبيب اي ضرر و من أو ضد أي مطالبات ،
مطلوبات ، أضرار ، خسائر ، و نفقات ، بما في ذلك ، على سبيل المثال لا الحصر ، رسوم
قانونية ومحاسبية معقولة ، ناشئة عن أو مرتبطة بأي طريقة بـ (1) خرقك لهذه الشروط أو
سياساتنا أو معاييرنا (2) استخدامك غير الصحيح لمنصة yourspace أو أي من خدمات
yourspace ، (3) الدفاع عن yourspace وتعويضها عن أي إصابات أو خسائر
أو أضرار (سواء كانت تعويضية أو مباشرة أو عرضية أو تبعية أو غير ذلك) من أي نوع ينشأ
فيما يتعلق أو نتيجة لهذا التفاعل أو المشاركة أو الاستخدام ، (4) أو خرقك لأي قوانين أو لوائح
أو حقوق للغير.
                        </span>
                    <p id="dispute" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders', textDecoration:'underline'}} >قانون حل النزاع : </p>

                    <span style={{ fontFamily: 'light', fontSize: '18px' }}>
                    أي نزاع ينشأ بين yourspace والمستخدمين بشان استخدامكم يجب ان يحل اولا وديا عبر
المفاوضات الودية، وفي حال فشل الحل الودي يلجأ إلى التحكيم السعودي اذا كان الطرفين يقيمون
بالمملكة العربية السعودية وفي حال كان احد الاطراف خارج المملكة واختار بالاتفاق مع yourspace
 اللجوء للتحكيم الذي تباشره احدى الهيئات الخارجية يجب أن يكون التحكيم عن طريق
محكم واحد يتفق الأطراف عليه لاحقاً أو يتفق المالك و طالب الخدمة أو المستخدم بشكل متبادل
على أن أي نزاع أو مطالبة أو خلاف ينشأ عن أو يتعلق بهذه الشروط أو قابلية التطبيق أو
الانتهاك أو الإنهاء أو الصلاحية أو التطبيق أو التفسير أو استخدام منصة yourspace أو
سيتم تسوية خدمة الدفع (يشار إليها مجتمعة ;المنازعات;) عن طريق التحكيم الفردي الملزم
(;اتفاقية التحكيم;). إذا كان هناك خلاف حول ما إذا كان يمكن تطبيق اتفاقية التحكيم هذه أو
تطبيقها على نزاعنا، فإنك توافق أنت وادارة yourspace على أن قرار التحكيم الذي يصدر
من المحكم بشأن هذه المشكلة يعتبر نهائي وملزم للأطراف.


قانون التحكيم المنطبق على النزاع:
يطبق قانون التحكيم السعودي الساري المفعول بالمملكة العربية السعودية.
                        </span>



                        <p id="generalrules" style={{ fontSize: '23px', marginTop: '50px', fontFamily: 'founders', textDecoration:'underline'}} >احكام عامة : </p>

<span style={{ fontFamily: 'light', fontSize: '18px' }}>
1. استثناء أي شروط اخرى قد يتم استكمالها بشروط وأحكام أو سياسات أو إرشادات أو
معايير إضافية، فإن هذه الشروط تشكل الاتفاقية الكاملة بين yourspace
وبينك فيما يتعلق بالموضوع الوارد هنا، وتحل محل أي وجميع التفاهمات أو
الاتفاقات الشفوية أو المكتوبة السابقة بين yourspace وأنت فيما يتعلق
بالوصول إلى منصة yourspace واستخدامها.

2. لا يشكل توقيعكم على هذه الشروط والأحكام بوجود اي علاقة عمل مشترك أو
شراكة أو وظيفة أو وكالة بينك وبين yourspace كنتيجة لهذه الاتفاقية أو
استخدامكم لمنصة yourspace

3. . لا تهدف هذه الشروط ولا يقصد منها منح أي حقوق أو تعويضات (إن وجدت)
لأي شخص آخر غير الأطراف الموقعة والقابلة لها والتي قد أطلعت عليها.

4. إذا اعتبر أي بند من هذه الشروط غير صالح أو غير قابل للتنفيذ، فسيتم إلغاء هذا
الحكم أو البند ولن يؤثر على صلاحية الأحكام والبنود الأخرى المتبقية وقابليتها
للتنفيذ.

5. إن فشل yourspace  في إنفاذ أي حق أو حكم في هذه الشروط لن يشكل
تنازلاً عن هذا الحق أو الحكم ما لم نقر بذلك ونوافق عليه كتابيًا. باستثناء ما هو
منصوص عليه صراحة في هذه الشروط، فإن ممارسة أي من الطرفين ومطالبته
لأي من التعويضات بموجب هذه الشروط لا يشكل إخلال بالمطالبة بأي تعويضات
أخرى بموجب هذه الشروط أو بموجب أي قانون آخر.

6. . لا يمكنك التنازل عن حقوقك والتزاماتك أو نقلها أو تفويضها المنصوص عليها
بهذه الوثيقة الالكترونية دون موافقة كتابية مسبقة من yourspace. ويجوز
لمنصة yourspace دون قيود، التنازل عن هذه الاتفاقية أو نقلها أو تفويضها
أي حقوق والتزامات بموجب هذه الاتفاقية، وفقًا لتقديرها الخاص، مع إشعار مسبق
بـ 30 يومًا.

7. ما لم ينص على خلاف ذلك، سيتم تقديم أي إشعارات أو مراسلات أخرى إلى
المستخدم او المستخدمين المطلوبة بموجب هذه الاتفاقية، إلكترونيًا أوتُقدم عبر
منصة yourspace او عبر البريد الإلكتروني أو إشعار منصة yourspace
 أو خدمة الرسائل (بما في ذلك الرسائل النصية القصيرة).

8. إذا اختلفت العبارات بين اللغة العربية واللغة الانجليزية فإن اللغة العربية هي اللغة
المعتبرة في هذا العقد.

9. تفسّر الإشارة إلى أي نظام أو لائحة أو قرار أو تعليمات على أنها تشمل التعديلات
التي تطرأ عليها من حين لآخر.


10. . أقرَّ كل طرف في هذه الاتفاقية بالتفهم الكامل لبنودها والإدراك التام لكافة ما ورد
فيها بعد الاطلاع عليها فيما يمتنع معه وبعد التوقيع عليها المعارضة بعدم الإدراك أو
الفهم الخاطئ أو الغبن أو خلافه.

11. يفسر أي التزام سلبي يُفرض على أي طرف بأنه التزام بعدم السماح بحدوث أثر ذلك
الشيء السلبي، كما يفسر أي التزام إيجابي يُفرض على أي طرف بأنه التزام بحدوث
ذلك الفعل أو الأثر المعني حدوثه.

    </span>




                </div>
                <footer style={{ ...this.state.footerWidth, margin: 'auto' }}>
                    <div className="row" style={{ marginTop: '3rem', paddingBottom: '1.2rem', paddingTop: '1rem', borderTop: '1px solid lightgrey' }}>
                        <span style={{ color: '#86868b', fontSize: '12px', lineHeight: 1.33337, fontWeight: 400, letterSpacing: '-.01em;', }} className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-6">© 2020 Copyright:
                        <span style={{ color: '#515154', fontSize: '12px', lineHeight: 1.33337, fontWeight: 400, letterSpacing: '-.01em;', }}><a style={{ color: '#707070', }} href="https://hads.sa/"> hads.sa</a></span>
                        </span>
                      
                        <span className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-6" style={{ color: '#707070', fontSize: '12px', lineHeight: 1.33337, fontWeight: 400, letterSpacing: '-.01em;', backgroundColor: '' }} ><span className="termsinLandingpage" style={{ color: '#707070', float: 'right',cursor:'pointer', }} onClick={() => this.props.history.push('/terms&agreements/')} >terms&agreements</span>  </span>

                    </div>







                </footer>

            </div>
        )
    }
}

export default Terms