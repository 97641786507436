import React from 'react'
import YourSpace from '../Landing_page/images/yourspace2.png'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import './Suggestions.css'
const mql = window.matchMedia(`(min-width: 950px)`);
class Suggestion extends React.Component{
    constructor(props){
        super(props)
        this.state={
            suggestion:'',
            width:{}
        }
        this.componentMediaQuery = this.componentMediaQuery.bind(this)
    }
     componentDidMount(){
         localStorage.setItem('RegisterOrder','seggust')
         this.componentMediaQuery()
     }
     componentWillMount(){
        mql.addListener(this.componentMediaQuery)
     }
     componentWillUnmount(){
        mql.removeListener(this.componentMediaQuery)
     }
     componentMediaQuery(){
        if(mql.matches){
            this.setState({
                width:{
                    width:'600px'
                }
            })
        }else{
            this.setState({
                width:{
                    width:'400px'
                }
            })
        }
     }
    render(){
        return(
            <div>
                <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
                    <div>
                    <img  src={YourSpace} alt="yourspace" id="img"/>
                    </div>

                    <div style={{marginTop:'70px',display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
                        <p style={{fontFamily:'font_bold',fontSize:'20px'}}>Add your feedback about our product..</p>
                        <TextField
                        id='suggestion'
                        className="suggesion"
                        onChange={this.handleChange}
                        placeholder='suggest...'
                        //style={{...this.state.width,margin:'auto'}}
                        margin='normal'
                        variant='outlined'
                        inputProps={{
                            maxLength: 2000,
                        }}
                        multiline
                        rows="9"
                        
                        multiline

                    
                        />

                    </div>
                    <div style={{marginTop:'80px'}}>
                        <Button style={{backgroundColor:'#08D888',color:'white',width:199}}>
                            Send
                        </Button>
                    </div>
                    
                </div>
            </div>
        )
    }
}
export default Suggestion