import React from 'react';
import { Tween } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';
import PhotoImage from './images/appScreenShots.png';
import Arrow from './images/arrow.png';
import Boy from './images/boy3.png';
import Calender from './images/Calender.png';
import Categories from './images/Categories.png';
import Clock from './images/clock.png';
import DownloadIcon from './images/Downloadicon.svg';
import Girl from './images/girl.png';
import Go from './images/go.png';
import OFFICE from './images/MeetingRoom.jpg';
import SPECIALROOM from './images/micro.jpg';
import MEETINGROOM from './images/OpenOffice2.jpg';
import Blue from './images/pay.png';
import Twoarrows from './images/replay.png';
import Share from './images/share.png';
import Blue2 from './images/wallet-2.png';
import WelcomingPurchase from './images/WelcomingPurchase.png';
import Yourspace from './images/yourspace2.png';
import YourSpaceWelcoming from './images/YourSpaceWelocming.png';
import './landingpage.css';


const mql = window.matchMedia(`(min-width:50em)`)

const styles = { transition: 'all 2s ease-out' }
export default class landingPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Defination: {},
            Description: {},
            Description_text: {},
            yourspace2: '',
            Defination_section: {},
            Defination_column: {},
            yourspaceDefination: {},
            RentSpace: {},
            boy: '',
            orderlist: {},
            yourspaceSteps: {},
            first: {},
            second: {},
            third: {},
            DisplayBoyAndGirl: {},
            scrollY: 0,
            circle: {},
            spreadImages: {},
            firstImageSpread: {},
            orderlist2: {},

            firstimage: {},

            secondImage: {},
            thirdImage: {},
            fourthimg: {},
            Highlights: {},
            HighlightsStyle: {},
            HighlightsGrid: {},
            spreadImagesContainer: {},
            clock: '',
            HighlightsGridfontStyle: {},
            HighlightsDisplayGrid: {},
            threeImagesContainer: {},
            threeimagesGrid: {},
            OfficeImage: {},
            scale: 1,
            scale2: 1,
            scale3: 1,
            yourspaceDefinationGrid: {},
            lastDiv: {},
            lastDivGrid: {},
            lastBigImage: {},
            lastDivImage: {},
            Grow: {},
            threeImageMainContainer: {},
            HighlightsMainContainer: {},
            yourspaceDefinationMainContainer: {},
            yourspaceStepsMainContainer: {},
            GrowTop: {},
            GoWidth: {},
            GoWidthImage: {},
            GrowMakeDiv: {},
            Description_text_bolder: {},
            lastDivContainer: {},
            threeImagesText: {},
            marginGrid: {},
            RentsTitle: {},
            footerWidth: {},
            hostyourspace: {}


        }
        this.componentMediaQuery = this.componentMediaQuery.bind(this)
    }
    componentDidMount() {
        this.componentMediaQuery()
        localStorage.setItem('RegisterOrder', 'landingpage')
    }
    componentWillMount() {
        mql.addListener(this.componentMediaQuery)
        window.addEventListener('scroll',
            this.handleScroll
        )



    }
    componentWillUnmount() {
        mql.removeListener(this.componentMediaQuery)

    }
    handleScroll = () => {
        this.setState({
            scrollY: window.scrollY
        })

        if (this.state.scrollY >= 0 && this.state.scrollY < 1400) {


            // apply position: fixed if you
            this.setState({
                circle: {
                    position: 'fixed',
                    top: '30%',
                    left: 0,

                }
            })

        } else {
            this.setState({
                circle: {
                    position: 'absolute',
                    top: 1700,
                    left: 0,
                }
            })

        }
    }
    componentMediaQuery() {

        if (mql.matches) {

            this.setState({
                Defination: {
                    fontSize: 85.53,
                    lineHeight: '1em',
                    fontFamily: 'font_bold',
                    color: 'black',

                },
                Description: {
                    marginTop: 530
                },

                Description_text: {
                    lineHeight: '1em',
                    fontSize: 31,
                    fontFamily: 'light',
                    color: 'black'
                },
                Description_text_bolder: {
                    lineHeight: '1em',
                    fontSize: 31,
                    fontFamily: 'font_bold',
                    color: 'black'

                },
                yourspace2: 420,
                Defination_section: {

                },
                Defination_column: {
                    marginTop: 130
                },
                yourspaceDefinationMainContainer: {

                },
                yourspaceDefinationGrid: {
                    display: 'grid',
                    gridTemplateColumns: ' 1fr 1fr 1fr 1fr',
                    gridTemplateAreas: `
                    'first first . .'
                    'first first . .'
                    '. . third third'
                    `,


                    paddingTop: '60px'



                },
                yourspaceDefination: {
                    maxWidth: '80%',
                    margin: 'auto',
                    backgroundColor: '',

                },
                first: {
                    gridArea: 'first',
                    backgroundColor: '',




                },
                second: {
                    gridArea: 'second',
                    backgroundColor: ''
                },
                third: {
                    gridArea: 'third',
                    backgroundColor: ''
                },
                RentSpace: {
                    fontSize: 37,
                    fontFamily: 'semibold'
                },
                boy: '4rem',
                clock: '60px',
                orderlist: {
                    fontSize: 31,
                    fontFamily: 'font_bold',
                    margintTop: 45,
                    lineHeight: '1.4em'

                },
                orderlist2: {
                    fontSize: 31,
                    fontFamily: 'font_bold',



                },
                yourspaceStepsMainContainer: {

                    paddingTop: '5rem'
                },
                yourspaceSteps: {
                    maxWidth: '80%',
                    margin: 'auto',

                },
                spreadImages: {
                    maxWidth: '80%',
                    margin: 'auto',
                    height: '50rem',
                    display: 'grid',
                    placeContent: 'center'



                },
                DisplayBoyAndGirl: {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit,minmax(13rem,1fr))',
                    gridGap: '90px'

                },
                firstImageSpread: {
                    width: 500
                },
                firstimage: {
                    width: '500px',
                    position: 'relative',
                    marginLeft: '40px',


                },
                secondImage: {
                    width: 500,
                    position: 'relative',
                    paddingTop: 220,
                    marginRight: '120px'

                },
                thirdImage: {
                    width: 500,
                    position: 'relative',
                    paddingTop: 100,


                },
                fourthimg: {
                    width: 500,
                    position: 'relative',
                    paddingTop: 370,
                    marginRight: '160px'

                },
                HighlightsMainContainer: {

                },
                Highlights: {
                    maxWidth: '75%',
                    margin: 'auto',


                },
                HighlightsStyle: {
                    fontSize: 37,
                    fontFamily: 'semibold'
                },
                HighlightsGrid: {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit,minmax(10rem,1fr))',
                    paddingTop: '50px',

                },
                spreadImagesContainer: {
                    height: '65rem',

                },
                HighlightsGridfontStyle: {
                    fontSize: 31,
                    fontFamily: 'font_bold',
                    lineHeight: '1em'
                },
                HighlightsDisplayGrid: {
                    display: 'grid',
                    gridGap: '70px',




                },
                threeImagesContainer: {

                    maxWidth: '75%',
                    margin: 'auto',


                },
                threeimagesGrid: {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit,minmax(10rem,1fr))',
                    gridColumnGap: 10
                },
                OfficeImage: {
                    width: '100%',
                    height: 603,


                },
                lastDiv: {
                    maxWidth: '70%',
                    margin: 'auto',

                    backgroundColor: ''
                },
                lastDivGrid: {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit,minmax(15rem,1fr))'
                },
                lastBigImage: {
                    width: '60rem',


                },
                lastDivImage: {

                },
                Grow: {
                    fontSize: '12.3rem',


                },
                GrowTop: {


                },
                threeImageMainContainer: {
                    paddingTop: '5rem',


                },
                GoWidth: {
                    width: '27rem',
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',



                    justifyContent: 'space-between'
                },
                GoWidthImage: {
                    width: 95.46,
                    height: 70.81
                },
                GrowMakeDiv: {

                },
                lastDivContainer: {
                    paddingTop: '10rem',

                },
                threeImagesText: {
                    position: 'absolute',
                    bottom: 0
                },
                marginGrid: {
                    margin: 'auto'
                },
                RentsTitle: {
                    maxWidth: '75%'
                },
                footerWidth: {
                    width: '35%'
                },
                hostyourspace: {
                    fontSize: '1.5rem'
                }



            })
        } else {
            this.setState({
                Defination: {
                    fontSize: 60.53,
                    lineHeight: '1em',
                    fontFamily: 'font_bold',
                    color: 'black',
                },
                Description: {
                    marginTop: 15
                },
                Description_text: {
                    lineHeight: '1em',
                    fontSize: 31,
                    fontFamily: 'light',
                    color: 'black'
                },
                Description_text_bolder: {
                    lineHeight: '1em',
                    fontSize: 31,
                    fontFamily: 'font_bold',
                    color: 'black'
                },
                yourspace2: 270,
                Defination_section: {
                    margintTop: '70px',



                },
                Defination_column: {
                    paddingTop: '0%'


                },

                RentSpace: {
                    fontSize: 27,
                    fontFamily: 'semibold'
                },
                yourspaceDefinationMainContainer: {



                },
                yourspaceDefinationGrid: {
                    display: 'grid',
                    gridTemplateColumns: 'auto',
                    paddingTop: '90px',
                    gridTemplateAreas: `
                        'first first first '
                        'third third third'
                        `,
                    gridGap: '70px',


                },
                yourspaceDefination: {

                    maxWidth: '90%',
                    margin: 'auto',


                },
                first: {
                    gridArea: 'first',
                },
                third: {
                    gridArea: 'third',
                    margintTop: '30px',

                },
                boy: '4rem',
                clock: '40px',
                orderlist: {
                    fontSize: 23,
                    fontFamily: 'font_bold',

                    lineHeight: '1.2em'

                },
                orderlist2: {
                    fontSize: 23,
                    fontFamily: 'font_bold',



                },
                yourspaceStepsMainContainer: {



                },
                yourspaceSteps: {
                    maxWidth: '90%',

                    margin: 'auto',
                },
                DisplayBoyAndGirl: {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit,minmax(20rem,1fr))',
                    backgroundColor: '',
                    gridGap: '40px'
                },
                spreadImages: {
                    maxWidth: '90%',
                    margin: 'auto',
                    height: '40rem',

                    display: 'grid',
                    placeContet: 'center'

                },
                firstImageSpread: {
                    width: 70
                }, firstimage: {
                    width: 320,
                    position: 'relative',

                    marginLeft: '50px'


                },
                secondImage: {
                    width: 320,
                    position: 'relative',
                    marginTop: 170,
                    marginLeft: 20
                },
                thirdImage: {
                    width: 320,
                    position: 'relative',
                    backgroundColor: '',
                    marginLeft: 30,
                    paddingTop: 80



                },
                fourthimg: {
                    width: 320,
                    position: 'relative',
                    marginTop: 280,
                    marginRight: 0




                },
                HighlightsStyle: {
                    fontSize: 30,
                    fontFamily: 'semibold'
                },
                spreadImagesContainer: {
                    height: '50rem',



                },

                HighlightsGridfontStyle: {
                    fontSize: 24,
                    fontFamily: 'font_bold',
                    lineHeight: '1em'
                },
                HighlightsGrid: {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit,minmax(14.7rem,1fr))',
                    gridRowGap: '35px',
                    paddingTop: '30px',
                },
                HighlightsMainContainer: {


                },
                Highlights: {
                    maxWidth: '90%',
                    margin: 'auto',

                },
                HighlightsDisplayGrid: {
                    display: 'grid',

                    gridRowGap: '40px',
                    gridColumnGap: '80px'

                },
                threeImagesContainer: {
                    maxWidth: '90%',
                    margin: 'auto',





                },
                threeimagesGrid: {
                    display: 'grid',


                },
                OfficeImage: {
                    width: '100%',
                    height: '20rem',


                },
                lastDiv: {
                    maxWidth: '80%',
                    margin: 'auto',

                    backgroundColor: ''

                },
                lastDivGrid: {
                    display: 'grid',
                    gridTemplateColumns: 'auto'
                },
                lastBigImage: {
                    width: '100%',

                },
                Grow: {
                    fontSize: '7rem'
                },
                threeImageMainContainer: {
                    paddingTop: '10rem'

                },
                GrowTop: {
                    paddingTop: '4rem',
                    lineHeight: '3rem',


                },
                GoWidth: {
                    width: '17rem',
                    display: 'grid',
                    gridTemplateColumns: 'auto auto'


                },
                GoWidthImage: {
                    width: '40%'
                },
                GrowMakeDiv: {
                    display: 'grid',
                    placeContent: 'center'
                },
                lastDivContainer: {
                    paddingTop: '15rem'
                },
                threeImagesText: {

                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                },
                RentsTitle: {
                    maxWidth: '100%'
                },
                footerWidth: {
                    width: '90%'
                },
                hostyourspace: {
                    fontSize: '0.9rem'
                }
            })
        }
    }
    handleMouseEnter = (value) => {
        if (value === 1) {
            this.setState({
                scale: 1.03
            })
        } else if (value === 2) {
            this.setState({
                scale2: 1.03
            })
        } else {
            this.setState({
                scale3: 1.03
            })
        }
    }
    handleMouseLeave = (value) => {
        if (value === 1) {
            this.setState({
                scale: 1
            })
        } else if (value === 2) {
            this.setState({
                scale2: 1
            })
        }
        else {
            this.setState({
                scale3: 1
            })
        }
    }
    render() {
        const SpreadImages = () => {
            if (mql.matches) {
                return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="SpreadImagesSecondDiv" >
                    <div style={{ position: 'absolute' }}>
                        <Controller >
                            <Scene
                                triggerElement=".SpreadImagesSecondDiv"
                                duration={300}


                            >
                                {(progress) => (
                                    <Tween
                                        to={1, { x: -160 }}
                                        ease="Strong.easeIn"
                                        totalProgress={progress}
                                        paused


                                    >
                                        <img src={WelcomingPurchase} style={{ ...this.state.fourthimg }} />
                                    </Tween>
                                )}

                            </Scene>
                        </Controller>

                    </div>
                    <div style={{ position: 'absolute' }}>
                        <img src={Calender} style={{ ...this.state.secondImage }} />

                    </div>
                    <div style={{ position: 'absolute' }}>
                        <Controller>
                            <Scene
                                triggerElement=".SpreadImagesSecondDiv"
                                duration={300}
                            >
                                {(progress) => (
                                    <Tween
                                        to={1, { x: 120 }}
                                        ease="Strong.easeIn"
                                        totalProgress={progress}
                                        paused
                                    >
                                        <img src={Categories} style={{ ...this.state.thirdImage }} />
                                    </Tween>
                                )}


                            </Scene>

                        </Controller>

                    </div>
                    <div style={{ position: 'absolute' }} >
                        <Controller>
                            <Scene
                                triggerElement=".SpreadImagesSecondDiv"
                                duration={300}
                            >
                                {(progress) => (
                                    <Tween
                                        to={1, { x: 260 }}
                                        ease="Strong.easeIn"
                                        totalProgress={progress}
                                        paused
                                    >
                                        <img src={YourSpaceWelcoming} style={{ ...this.state.firstimage }} />
                                    </Tween>
                                )}


                            </Scene>

                        </Controller>

                    </div>

                </div>
            } else {
                return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="SpreadImagesSecondDiv" >
                    <div style={{ position: 'absolute' }}>
                        <Controller>
                            <Scene
                                triggerElement=".SpreadImagesSecondDiv"
                                duration={300}
                            >
                                {(progress) => (
                                    <Tween
                                        to={1, { y: 50 }}
                                        ease="Strong.easeIn"
                                        totalProgress={progress}
                                        paused
                                    >
                                        <img src={WelcomingPurchase} style={{ ...this.state.fourthimg }} />
                                    </Tween>
                                )}

                            </Scene>
                        </Controller>

                    </div>
                    <div style={{ position: 'absolute' }}>
                        <Controller>
                            <Scene
                                triggerElement=".SpreadImagesSecondDiv"
                                duration={300}
                            >
                                {(progress) => (
                                    <Tween
                                        to={1, { y: 20 }}
                                        ease="Strong.easeOut"
                                        totalProgress={progress}
                                        paused
                                    >
                                        <img src={Calender} style={{ ...this.state.secondImage }} />
                                    </Tween>
                                )}

                            </Scene>
                        </Controller>


                    </div>
                    <div style={{ position: 'absolute' }}>
                        <Controller>
                            <Scene
                                triggerElement=".SpreadImagesSecondDiv"
                                duration={300}
                            >
                                {(progress) => (
                                    <Tween
                                        to={1, { y: -30 }}
                                        ease="Strong.easeOut"
                                        totalProgress={progress}
                                        paused
                                    >
                                        <img src={Categories} style={{ ...this.state.thirdImage }} />
                                    </Tween>
                                )}

                            </Scene>
                        </Controller>

                    </div>
                    <div style={{ position: 'absolute' }} >
                        <Controller>
                            <Scene
                                triggerElement=".SpreadImagesSecondDiv"
                                duration={300}
                            >
                                {(progress) => (
                                    <Tween
                                        to={1, { y: -70 }}
                                        ease="Strong.easeOut"
                                        totalProgress={progress}
                                        paused
                                    >
                                        <img src={YourSpaceWelcoming} style={{ ...this.state.firstimage }} />
                                    </Tween>
                                )}

                            </Scene>
                        </Controller>

                    </div>

                </div>
            }
        }
        return (
            <div className="mainbody">
                <div className="circle" style={{ ...this.state.circle }}>
                </div>
                <nav className="navbar navbar-default navbar-fixed-top justify-content-between" style={{ backgroundColor: 'transparent', zIndex: '1030', width: '100%', left: 0, right: 0, borderRadius: 0 }}>

                    <a className="navbar-brand" onClick={() => {
                        this.props.history.push('/')
                    }}>
                        <img src={Yourspace} alt="yourspace" className="yourspace" style={{ cursor: 'pointer' }} />
                    </a>
                    <span className="hostyourspace" style={{ fontSize: '1rem', color: '#08D888', marginBottom: '7px', ...this.state.hostyourspace, border: '0px white', backgroundColor: 'transparent', cursor: 'pointer' }} onClick={() => this.props.history.push('/host/')}>Host Your Space</span>
                    {/* <Button className="hostyourspace" style={{ fontSize: '1rem', color: '#08D888',marginBottom:'7px',...this.state.hostyourspace,border:'0px white',backgroundColor:'transparent'  }} onClick={() => this.props.history.push('/host/')}>Host Your Space</Button> */}

                </nav>
                <div className="container-fluid" style={{ ...this.state.yourspaceDefinationMainContainer, paddingTop: '40px' }}>

                    <div style={{ ...this.state.yourspaceDefination, backgroundColor: '' }}>
                        <div style={{ ...this.state.yourspaceDefinationGrid }}>
                            <div style={{ ...this.state.first, background: '' }} >
                                <div style={{}}>
                                    <strong className="Defination" style={{ ...this.state.Defination }}>
                                        <img src={Yourspace} alt="yourspace" className="yourspace2" style={{ width: this.state.yourspace2 }} /> the place where you will always find your space!
                                    </strong>
                                </div>
                                <div >
                                    <a href="https://apps.apple.com/us/app/yourspace-by-hads/id1497551327" style={{ backgroundColor: 'transparent', border: 'transparent' }}><img src={DownloadIcon} /></a>

                                </div>
                            </div>
                            <div style={{ ...this.state.second, }}>

                            </div>
                            <div className="" style={{ ...this.state.third }}>

                                <span className="Description_text" style={{ ...this.state.Description_text }}>For you who’s looking for a space to work, and also for you that probably has that extra space
                                    in the office no one is using. But also for you who’s looking for a meeting room because you have an important meeting, and you
                                    that have a meeting room that is available most of the time.


                                    <span style={{ ...this.state.Description_text_bolder, display: 'inline' }}>
                                        yourspace  is the app for both of you, so that everyone has an opportunity, to make and to grow. Go!'


                                    </span>


                                </span>
                            </div>

                        </div>



                    </div>
                </div>


                <div className="container-fluid" style={{ ...this.state.yourspaceStepsMainContainer }}>
                    <div className="" style={{ ...this.state.yourspaceSteps, }}>
                        <div className="" style={{ ...this.state.DisplayBoyAndGirl }}>
                            <div>
                                <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', gridGap: '10px', ...this.state.RentsTitle }}>
                                    <img src={Boy} className="boy" style={{ width: this.state.boy }} />

                                    <strong style={{ ...this.state.RentSpace, marginTop: '1rem', lineHeight: '1em' }}>Rent your extra space in just 4 easy steps!</strong>

                                </div>
                                <div>
                                    <div className="divBoy" className="col" style={{ marginTop: '30px', marginLeft: '2rem' }}>
                                        <p className="pp" style={{ ...this.state.orderlist2 }}><p style={{ lineHeight: '1em', marginTop: '0.6rem' }}>Create your account</p> </p>
                                        <p className="pp" style={{ ...this.state.orderlist2 }}><p style={{ lineHeight: '1em', marginTop: '0.6rem' }}>Select the type of space you have to rent</p></p>
                                        <p className="pp" style={{ ...this.state.orderlist2 }}><p style={{ lineHeight: '1em', marginTop: '0.6rem' }}>Choose the dates and hours available</p></p>
                                        <p className="pp" style={{ ...this.state.orderlist2 }}><p style={{ lineHeight: '1em', marginTop: '0.6rem' }}>Rent it, and get payed for the time  you don’t use it</p></p>
                                    </div>
                                </div>

                            </div>
                            <div>
                                <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', gridGap: 10, ...this.state.RentsTitle }}>
                                    <img src={Girl} className="girl" style={{ width: this.state.boy }} />
                                    <strong style={{ ...this.state.RentSpace, marginTop: '1rem', lineHeight: '1em' }}>Find your workspace in just 4 easy steps!</strong>
                                </div>
                                <div>
                                    <div className="divBoy" className="col" style={{ marginTop: '30px', marginLeft: '2rem' }}>
                                        <p className="pp1" style={{ fontSize: '20px', fontFamily: 'font_bold', ...this.state.orderlist2 }}><p style={{ lineHeight: '1em', marginTop: '0.6rem' }}>Create your account</p> </p>
                                        <p className="pp1" style={{ fontSize: '20px', fontFamily: 'font_bold', ...this.state.orderlist2 }}><p >Select the type of space you need</p></p>
                                        <p className="pp1" style={{ fontSize: '20px', fontFamily: 'font_bold', ...this.state.orderlist2 }}><p style={{ lineHeight: '1em', marginTop: '0.6rem' }}>Book the dates and hours you need it</p></p>
                                        <p className="pp1" style={{ fontSize: '20px', fontFamily: 'font_bold', ...this.state.orderlist2 }}><p style={{ lineHeight: '1em', marginTop: '0.6rem' }}>Rent it, and pay only for the time you need of</p></p>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>




                <div className="container-fluid" style={{ ...this.state.spreadImagesContainer }}>
                    <div style={{ ...this.state.spreadImages, }} >

                        <SpreadImages />



                    </div>
                </div>
                <div className="container-fluid" style={{ ...this.state.HighlightsMainContainer }}>
                    <div style={{ ...this.state.Highlights }}>
                        <div style={{ textAlign: 'center' }}>
                            <strong style={{ ...this.state.HighlightsStyle }}>Highlights</strong>
                        </div>
                        <div style={{ ...this.state.HighlightsGrid }}>
                            <div className="firstHighlighGrid" style={{ ...this.state.HighlightsDisplayGrid, }}>
                                <div style={{ display: 'grid', gridTemplateColumns: '4rem auto', maxWidth: '95%' }}>
                                    <div>
                                        <img src={Clock} style={{ width: this.state.clock }} />
                                    </div>
                                    <div style={{ marginLeft: '20px' }}>
                                        <span style={{ ...this.state.HighlightsGridfontStyle }}>Rent it while you don't use it</span>

                                    </div>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '4rem auto', maxWidth: '95%' }} >
                                    <div>
                                        <img src={Blue} style={{ width: this.state.clock }} />
                                    </div>
                                    <div style={{ marginLeft: 20 }}>
                                        <span style={{ ...this.state.HighlightsGridfontStyle }}>Pay only for the time you need</span>

                                    </div>
                                </div>
                            </div>
                            <div className="secondHighlighGrid" style={{ ...this.state.HighlightsDisplayGrid }}>

                                <div style={{ display: 'grid', gridTemplateColumns: '4rem auto' }}>
                                    <div>
                                        <img src={Arrow} style={{ width: this.state.clock }} />
                                    </div>
                                    <div style={{ marginLeft: '20px' }}>
                                        <span style={{ ...this.state.HighlightsGridfontStyle }}>An unsed space can be a source of revenue </span>

                                    </div>
                                </div>



                                <div style={{ display: 'grid', gridTemplateColumns: '4rem auto', maxWidth: '95%' }}>
                                    <div>
                                        <img src={Twoarrows} style={{ width: this.state.clock }} />
                                    </div>
                                    <div style={{ marginLeft: '20px' }}>
                                        <span style={{ ...this.state.HighlightsGridfontStyle }}>Change office when you feel like it </span>

                                    </div>
                                </div>


                            </div>

                            <div className="thirdHighlighGrid" style={{ ...this.state.HighlightsDisplayGrid }}>

                                <div style={{ display: 'grid', gridTemplateColumns: '4rem auto' }}>
                                    <div>
                                        <img src={Share} style={{ width: this.state.clock }} />
                                    </div>
                                    <div style={{ marginLeft: '20px', backgroundColor: '' }}>
                                        <span style={{ ...this.state.HighlightsGridfontStyle }}>To share is to care</span>

                                    </div>
                                </div>



                                <div style={{ display: 'grid', gridTemplateColumns: '4rem auto' }}>
                                    <div>
                                        <img src={Blue2} style={{ width: this.state.clock }} />
                                    </div>
                                    <div style={{ marginLeft: '20px' }}>
                                        <span style={{ ...this.state.HighlightsGridfontStyle, }}>There is a space that fits your budget</span>

                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                </div>
                <div className="container-fluid" style={{ backgroundColor: '', ...this.state.threeImageMainContainer }} >
                    <div className="" style={{ ...this.state.threeImagesContainer }}>
                        <div style={{ ...this.state.threeimagesGrid }}>
                            <Controller>
                                <Scene
                                    triggerElement=".threeImagesContainer"
                                    duration="300">
                                    {(progress) => (
                                        <Tween
                                            to={1, { y: 20 }}
                                            ease="Strong.easeOut"
                                            totalProgress={progress}
                                            paused>
                                            <div onMouseEnter={() => this.handleMouseEnter(1)} onMouseLeave={() => this.handleMouseLeave(1)} className="officesSpaces">
                                                <div style={{ backgroundImage: `url(${MEETINGROOM})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', ...this.state.OfficeImage, ...styles, transform: 'scale(' + this.state.scale + ')', borderRadius: '1%' }}>
                                                    <div style={{ ...this.state.threeImagesText }}>
                                                        <span style={{ color: 'white', fontSize: '2rem', marginLeft: '7px', }}>Office Spaces</span>
                                                    </div>

                                                </div>
                                            </div>

                                        </Tween>
                                    )}

                                </Scene>
                            </Controller>
                            <Controller >
                                <Scene
                                    triggerElement=".threeImagesContainer"
                                    duration="300">
                                    {(progress) => (
                                        <Tween
                                            to={1, { y: 50 }}
                                            ease="Strong.easeOut"
                                            totalProgress={progress}
                                            paused>
                                            <div onMouseEnter={() => this.handleMouseEnter(2)} onMouseLeave={() => this.handleMouseLeave(2)} style={{ marginTop: '3rem' }}>
                                                <div style={{ backgroundImage: `url(${OFFICE})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', ...this.state.OfficeImage, ...styles, transform: 'scale(' + this.state.scale2 + ')', borderRadius: '1%' }}>
                                                    <div style={{ ...this.state.threeImagesText }}>
                                                        <span style={{ color: 'white', fontSize: '2rem', marginLeft: '7px', }}>Meeting Rooms</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </Tween>
                                    )}
                                </Scene>
                            </Controller>
                            <Controller>
                                <Scene
                                    triggerElement=".threeImagesContainer"
                                    duration="300">
                                    {(progress) => (
                                        <Tween
                                            to={1, { y: 70 }}
                                            ease="Strong.easeOut"
                                            totalProgress={progress}
                                            paused>
                                            <div onMouseEnter={() => this.handleMouseEnter(3)} onMouseLeave={() => this.handleMouseLeave(3)} style={{ marginTop: '3rem' }}>
                                                <div style={{ backgroundImage: `url(${SPECIALROOM})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', ...this.state.OfficeImage, ...styles, transform: 'scale(' + this.state.scale3 + ')', borderRadius: '1%' }}>
                                                    <div style={{ ...this.state.threeImagesText }}>
                                                        <span style={{ color: 'white', fontSize: '2rem', marginLeft: '7px', }}>Special Rooms</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tween>
                                    )}
                                </Scene>
                            </Controller>



                        </div>

                    </div>
                </div>
                <div className="container-fluid" style={{ ...this.state.lastDivContainer }}>
                    <div style={{ textAlign: 'center' }}>
                        <strong style={{ ...this.state.HighlightsStyle }}>Download yourspace App</strong>
                    </div>

                    <div style={{ ...this.state.lastDiv }}>
                        <div className="row" style={{}}>
                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ backgroundColor: '', display: 'grid', placeContent: 'center' }}>
                                <img src={PhotoImage} style={{ ...this.state.lastBigImage }} />
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ display: 'grid', placeContent: 'center', backgroundColor: '' }}>
                                <div>

                                    <h1 style={{ color: '', ...this.state.Grow, fontFamily: 'font_bold', color: '#0000FF', lineHeight: '1em' }}>Grow
                                        <h1 style={{ color: '#08D888', ...this.state.Grow, fontFamily: 'font_bold', lineHeight: '0.5em' }}>Make</h1></h1>
                                    <div style={{ ...this.state.GoWidth }}>
                                        <div style={{}}>
                                            <img src={Go} style={{ ...this.state.GoWidthImage }} />
                                        </div>
                                        <div style={{ paddingTop: '0.5rem' }}>
                                            <a href="https://apps.apple.com/us/app/yourspace-by-hads/id1497551327" style={{ backgroundColor: 'transparent', border: 'transparent' }}><img src={DownloadIcon} /></a>

                                        </div>
                                    </div>

                                    {/* <h1 style={{color:'blue',...this.state.Grow,fontWeight:'bolder',paddingTop:'4rem',lineHeight:'1em'}}>Grow</h1>
                                    <h1 style={{color:'#08D888',...this.state.Grow,fontWeight:'bolder',lineHeight:'1em'}}>Make</h1> */}
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <footer style={{ ...this.state.footerWidth, margin: 'auto' }}>
                    <div className="row" style={{ marginTop: '3rem', paddingBottom: '1.2rem', paddingTop: '1rem', borderTop: '1px solid lightgrey' }}>
                        <span style={{ color: '#86868b', fontSize: '12px', lineHeight: 1.33337, fontWeight: 400, letterSpacing: '-.01em;', }} className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-4">© 2020 Copyright:
                            <span style={{ color: '#515154', fontSize: '12px', lineHeight: 1.33337, fontWeight: 400, letterSpacing: '-.01em;', }}><a style={{ color: '#707070', }} href="https://hads.sa/">hads.sa</a></span>
                        </span>

                        <span className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-4" style={{ color: '#707070', fontSize: '12px', lineHeight: 1.33337, fontWeight: 400, letterSpacing: '-.01em;', backgroundColor: '' }} ><span className="termsinLandingpage" style={{ color: '#707070', float: 'right', cursor: 'pointer', }} onClick={() => this.props.history.push('/privacy-policy')} >Privacy Policy</span>  </span>

                        <span className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-4" style={{ color: '#707070', fontSize: '12px', lineHeight: 1.33337, fontWeight: 400, letterSpacing: '-.01em;', backgroundColor: '' }} ><span className="termsinLandingpage" style={{ color: '#707070', float: 'right', cursor: 'pointer', }} onClick={() => this.props.history.push('/terms&agreements/')} >terms&agreements</span>  </span>
                    </div>
                </footer>


            </div>
        )
    }
}