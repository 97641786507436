
// import img1 from '../../images/office.jpg';
// import img2 from '../../images/officeLong.jpg';
// import img3 from '../../images/wideOffice.jpg';
// import img4 from '../../images/office4.jpg';
// import img5 from '../../images/office5.jpg';
// import img6 from '../../images/office6.jpg';
// import img7 from '../../images/office7.jpeg';
// import img8 from '../../images/office8.jpg';
import Button from '@material-ui/core/Button';
import React, { Component } from 'react';
import StackGrid from 'react-stack-grid';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Route, withRouter } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const mql = window.matchMedia(`(min-width: 850px)`);
class Pictures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StackGrid_st: {},
      open: false,
      // images: [img1, img2, img3, img4, img5, img6, img7, img8],
      images: [],
      content_type: [],
      current_img: 0,
      arrow_row: '1',
      img_column: '2',
      vertical_arrow: 'middle',
      loaded: false,
      img_style: {},
      gridStyle: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit,minmax(30rem,1fr))',
        gridGap: '7px'
      }

    };
    // this.fix_StackGrid_st = this.fix_StackGrid_st.bind(this);
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
  }
  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }
  componentDidMount() {
    this.screen_sizes();

  }
  mediaQueryChanged() {
    this.screen_sizes();
  }
  screen_sizes() {
    if (mql.matches) {
      this.setState({
        arrow_row: '1',
        img_column: '2',
        vertical_arrow: 'middle',
        img_style: {
          width: 200
        },
        gridStyle: {
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit,minmax(30rem,1fr))',
          maxWidth: 960,
          gridGap: '7px'
        }
      });
      // console.log(this.state.toggle_right_center)
    } else {
      this.setState({
        arrow_row: '2',
        img_column: '1/4',
        vertical_arrow: 'bottom',
        img_style: {
          width: 100
        },
        gridStyle: {
          display: 'grid',
          gridTemplateColumns: 'auto',
          gridGap: '7px',
          backgroundColor: ''
        }
      });
      // console.log(this.state.toggle_right_center)
    }
  }
  img_preview = () => {
    const classes = useStyles();
    return (
      <div style={{}}>
        {/* <button type="button" onClick={this.handleOpen}>
              react-transition-group
            </button> */}
        <Button
          color='primary'
          onClick={e => this.handleOpen(0)}
          style={{ margin: 'auto', outline: '0' }}>

        </Button>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          className={classes.modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={this.state.open}>
            <div
              style={{
                width: '90%',
                textAlign: 'center',
                height: '90%',
                border: 'none',
                display: 'grid',
                gridTemplateColumns: '40px auto 40px',
                padding: '20px 5px',
                gridTemplateRows: '90% 40px',

              }}
              className={classes.paper}>
              <div
                style={{
                  gridColumn: '1',
                  display: 'table',
                  height: '100%',
                  gridRow: this.state.arrow_row,
                }}>
                <div
                  style={{
                    display: 'table-cell',
                    verticalAlign: this.state.vertical_arrow,
                  }}>
                  <Button
                    style={{ outline: '0', minWidth: '10px' }}
                    onClick={this.pre_img}>
                    <ArrowBackIosIcon style={{ float: 'left' }} />
                  </Button>
                </div>
              </div>
              <div style={{ gridColumn: this.state.img_column }}>
                <span
                  style={{
                    display: 'inline-block',
                    height: '100%',

                    verticalAlign: 'middle',
                  }}></span>
                  {this.state.content_type[this.state.current_img] !== 'video' 
                  &&
                  <img
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    textAlign: 'center',
                    margin: '0',
                  }}
                  src={this.state.images[this.state.current_img]}
                  alt=''
                />
                  }
                  {this.state.content_type[this.state.current_img] === 'video' &&
                   <video autoplay="" loop="" muted="true" playsinline="" width="100%" height="100%"  controls>
                   <source src={this.state.images[this.state.current_img]} type="video/mp4" />
                   <source src={this.state.images[this.state.current_img]} type="video/ogg" />
                 </video>
                 }
                
              </div>
              <div
                style={{
                  gridColumn: '3',
                  display: 'table',
                  height: '100%',
                  gridRow: this.state.arrow_row,
                }}>
                <div
                  style={{
                    display: 'table-cell',
                    verticalAlign: this.state.vertical_arrow,
                  }}>
                  <Button
                    style={{ outline: '0', minWidth: '10px' }}
                    onClick={this.next_img}>
                    {' '}
                    <ArrowForwardIosIcon />{' '}
                  </Button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  };
  // handleOpen = img_index => {
  //   //   setOpen(true);
  //   this.setState({ open: true, current_img: img_index });
  //   // console.log("")
  // };

  handleClose = () => {
    //   setOpen(false);
    this.setState({ open: false, current_img: 0 });
  };
  next_img = () => {
    var current_img = this.state.current_img;
    current_img++;
    if (current_img == this.state.images.length) current_img = 0;
    this.setState({ current_img: current_img });
    // console.log('this.state.current_img; ', this.state.current_img);
  };
  pre_img = () => {
    var current_img = this.state.current_img;
    current_img--;
    if (current_img == -1) current_img = this.state.images.length - 1;
    this.setState({ current_img: current_img });
    // console.log('this.state.current_img; ', this.state.current_img);
  };
  componentDidMount() {
    // document.getElementById("StackGrid").style.width = "900px"
    var x;
    var done = false;
    var elem = document.getElementById('StackGrid');
    setTimeout(() => {
      // var elem = document.getElementById('StackGrid');
      // elem.style.width = "500px"
      // elem.style.backgroundColor = "blue"
      // console.log("late");
      this.setState({ StackGrid_st: { maxWidth: '900px', } });
    }, 100);
    //   this.fix_StackGrid_st();
    done = true;

    // console.log('here: ', x);
    // this.setState({images: this.props.media_pictures})
    if (this.props.isLoaded) {
      // console.log("this.props.media_pictures: ", this.props.media_pictures)

      this.setState({ images: this.props.media_pictures })

    } else
      return ''
  }
  //   fix_StackGrid_st(){
  //     console.log("here: ")
  //   }
  componentDidUpdate() {
    // if(this.props.media_pictures.isLoaded){
    //   console.log("update this.props.media_pictures: ", this.props.media_pictures)
    //   this.setState({images: this.props.media_pictures})
    //   }else 
    //   console.log("update not loaded")
    //   console.log("outside if update this.props.media_pictures: ", this.props.media_pictures)
    if (this.props.media_pictures.length !== 0 && !this.state.loaded) {
      // console.log("FINALLY LOADED ", this.props.media_pictures)
      var images_preview = []
      var content_type = []
      for (let i = 0; i < this.props.media_pictures.length; i++) {
        images_preview[i] = this.props.media_pictures[i].media_url
        content_type[i] = this.props.media_pictures[i].media_type
      }


      this.setState({ loaded: true, images: images_preview, content_type: content_type })
      // console.log("images after load: ", this.state.images)
    }
  }
  handleOpen = img_index => {
    //   setOpen(true);
    this.setState({ open: true, current_img: img_index });
    // console.log("")
  };
  render() {
    const img_style = this.state.img_style
    const handleOpen = this.handleOpen;
    // const handleOpen = img_index => {
    //   //   setOpen(true);
    //   this.setState({ open: true, current_img: img_index });
    //   // console.log("")
    // };
    // const [open, setOpen] = React.useState(false);
    const StackGrid_st = this.state.StackGrid_s;
    const centerLabel2 = this.props.centerLabel2;
    const elements = this.state.images;
    const content_type = this.state.content_type
    const media_pictures =
      <div

        //style={{ ...StackGrid_st, ...centerLabel2 }}
        style={{ display: 'flex', flexDirection: 'row' }}
        className="flex-container"
      >
        {elements.map(function (id, index) {
          return (

            <div key={index} onClick={e => handleOpen(index)} style={{ marginRight: 10, backgroundColor: '', zIndex: { index } }}>
              {content_type[index] === 'image' &&
                <img src={elements[index]} style={{ width: 200, height: 130, borderRadius: '5%' }} />
              }{content_type[index] === 'video' &&
                <video autoplay="" loop="" muted="true" playsinline="" width="200" height="130" style={{borderRadius: '5%'}} controls>
                  <source src={elements[index]} type="video/mp4" />
                  <source src={elements[index]} type="video/ogg" />
                </video>
              }

            </div>
          )
        })}
      </div>
    return (
      <div style={{ display: 'grid', gridTemplateColumns: '10% auto 10%' }}>
        {/* <img src={img1} style={img_style}/>
                <img src={img2} style={img_style}/>
                <img src={img3} style={img_style}/>
                <img src={img4} style={img_style}/>
                <img src={img1} style={img_style}/>
                <img src={img1} style={img_style}/>
                <img src={img1} style={img_style}/>
                <img src={img4} style={img_style}/> */}
        <div style={{ gridColumn: '1', gridRow: '1', maxWidth: '100%' }}>

          {media_pictures}
          {/* <StackGrid
            id='StackGrid'
            columnWidth={200}
            style={{ ...this.state.StackGrid_st, ...this.props.centerLabel2 }}>
            <div key='key1' onClick={e => this.handleOpen(0)}>
              <img src={this.state.images[0]} style={img_style} />
            </div>
            <div key='key2' onClick={e => this.handleOpen(1)}>
              <img src={this.state.images[1]} style={img_style} />
            </div>
            <div key='key3' onClick={e => this.handleOpen(2)}>
              <img src={this.state.images[2]} style={img_style} />
            </div>
            <div key='key4' onClick={e => this.handleOpen(3)}>
              <img src={this.state.images[3]} style={img_style} />
            </div>
            <div key='key5' onClick={e => this.handleOpen(4)}>
              <img src={this.state.images[4]} style={img_style} />
            </div>
            <div key='key6' onClick={e => this.handleOpen(5)}>
              <img src={this.state.images[5]} style={img_style} />
            </div>
            <div key='key7' onClick={e => this.handleOpen(6)}>
              <img src={this.state.images[6]} style={img_style} />
            </div>
            <div key='key8' onClick={e => this.handleOpen(7)}>
              <img src={this.state.images[7]} style={img_style} />
            </div>
          </StackGrid> */}
        </div>
        <div
          style={{
            gridColumn: '2',
            gridRow: '2',
            ...this.props.centerElements,
            marginTop: '20px',
          }}>
          <this.img_preview />
        </div>
      </div>
    );
  }
}
export default withRouter(Pictures);